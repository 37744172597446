import { stateFindResourceByCodeOrAlias } from '../resource/resource_selectors'
import { isDef, isDefVal } from '../../utils/objtools'
import { stateGetProductDefinitionFeaturedAPIs } from '../product/product_selectors'

/**
 * Get scopes names in an API definition
 */
export function stateGetApiDefinitionScopes(state, apiDefinitionResourceCode, swaggerVersion) {
  const apiDefinition = stateFindResourceByCodeOrAlias(state, 'api_def', apiDefinitionResourceCode)
  if (!apiDefinition) {
    return null
  }
  var scopes = {
    names: [],
    descriptions: {},
    context: {
      apiDefinitionResourceCodes: [apiDefinitionResourceCode],
      productDefinitionResourceCodes: [],
      productResourceCodes: [],
      api_ids: [apiDefinition.ids.api_id]
    }
  }
  if (swaggerVersion === 2){
    if (isDef(apiDefinition, 'data.definition.securityDefinitions')) {
      for (var securityDefinitionName in apiDefinition?.data?.definition.securityDefinitions) {
        const securityDefinition = apiDefinition?.data?.definition?.securityDefinitions[securityDefinitionName]
        if (isDefVal(securityDefinition, 'type') === 'oauth2' && isDef(securityDefinition, 'scopes')) {
          for (let name in securityDefinition?.scopes) {
            scopes.descriptions[name] = securityDefinition?.scopes[name]
          }
        }
      }
    }
    scopes.names = Object.keys(scopes?.descriptions)
  } else if (swaggerVersion === 3){
    if (isDef(apiDefinition, 'data.definition.security')){
      for (let securityName in apiDefinition?.data?.definition?.security) {
        const security = apiDefinition?.data?.definition?.security[securityName]
        for (let item in security) {
          scopes.names = security[item]
          // Description will be loaded from the catalog scope list
          scopes.names.forEach((name) => scopes.descriptions[name] = '')
        }
      }
    }

  }
  return scopes
}

/**
 * Get scopes names in a product definition's apis
 */
export function stateGetProductDefinitionScopes(state, productDefinitionResourceCode, productResourceCode) {
  const apis = stateGetProductDefinitionFeaturedAPIs(state, productDefinitionResourceCode)
  if (!apis) {
    return null
  }
  var scopes = {
    names: [],
    descriptions: {},
    context: {
      apiDefinitionResourceCodes: apis.api_def_resource_codes,
      productDefinitionResourceCodes: [productDefinitionResourceCode],
      productResourceCodes: [productResourceCode],
      api_ids: apis.api_ids
    }
  }
  for (var apiDefinitionResourceCode of apis.api_def_resource_codes) {
    var api_scopes = stateGetApiDefinitionScopes(state, apiDefinitionResourceCode)
    if (api_scopes) {
      scopes.descriptions = {...scopes.descriptions, ...api_scopes.descriptions}
    }
  }
  scopes.names = Object.keys(scopes.descriptions)
  return scopes
}

/**
 * Get a scope list effects on a resource list
 */
// pour une liste de names + contexte perimetre (liste apis / produits)
// summary : accès par api / operation / method : oui/non
export function stateGetScopesEffectsOnResources(state, names, resourceCodes) {
  if (!Array.isArray(names)) {
    names = [names]
  }
  if (!Array.isArray(resourceCodes)) {
    resourceCodes = [resourceCodes]
  }

  // TODO

}
