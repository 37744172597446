import React from 'react'
import { connect } from 'react-redux'
import { logRender } from '../../utils/logging'
import { withTranslation } from 'react-i18next'
import {deepEqualObjs2, isDef, isDefVal, filterObject, getFirstDef} from '../../utils/objtools'
import setDocumentTitle from '../../utils/browser/title'
import ApplicationFinalForm from './ApplicationFinalForm'
import { submitApplicationFinalFormNewHandler } from './application_routines'
import SingleHeader from '../ui/SingleHeader/SingleHeader'
import { bindPromiseCreators } from 'redux-saga-routines'
import { stateGetSelectedConsorgId, stateIsUserBelongsConsorg } from '../consorg/consorg_selectors'
import Visibility from '../../utils/debug/Visibility'
import { stateAuthIsUserLoggedIn } from '../../components/auth/auth_selectors'
import {
  getPortalPageQueryParams,
  getPortalCurrentOrganisationAndCatalogIds,
  getPortalCurrentCatalogId
} from '../portal/portal_selectors'
import {mapResourceFromIds, stateFindResourceByIds} from '../resource/resource_selectors'
import {buildExternalResourceInfos} from "../../utils/urls/external";

class ApplicationNew extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || this.props.tReady !== nextProps.tReady
    )
  }

  componentDidMount() {
    this.postRender()
  }

  componentDidUpdate() {
    this.postRender()
  }

  postRender() {
    if (this.props.tReady) {
      setDocumentTitle(this.props.t('application:form_new_title'))
    }

    if (this.props.redux.auth_adapters.need_request) {
      this.props.requestResource(this.props.redux.auth_adapters.external_resource_infos)
    }
    if (!this.props.redux.auth_adapters.found && this.props.redux.external_resource_infos) {
      this.props.requestResource(this.props.redux.external_resource_infos)
    }
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    let defaultAdapter = this.props.redux.catalogId === 'prod' ? 'safe' : 'safe-hml';

    var initialValues = {
      // Fields
      //title: 'My Application',
      relationship_consorg: this.props.redux.relationship_consorg,
      auth_adapters: this.props.redux.auth_adapters?.data,
      forcedAdapter: filterObject(getFirstDef(this.props.redux.auth_adapters, 'data', {}), "defaultOne", true)[0]?.name || defaultAdapter,
      //credentialRedirectUris: "https://example.fr\r\nhttps://example.fr/truc"
    }

    return (
      <Visibility
        condition={this.props.redux.authIsUserLoggedIn !== false}
        resourceErrorDetails="You must be logged in to create an application."
      >
        <div className={this.getItemClasses().join(' ')} >
          <div className="grid g1_root g1_1col">
            <SingleHeader key="header" item_type="application" consolidated={{title: this.props.t('application:form_new_title')}} pills={null} loading={false} />
            <ApplicationFinalForm form_op="new" onSubmit={this.props.submitApplicationFinalFormNewHandler} initialValues={initialValues} />
          </div>
        </div>
      </Visibility>
    )
  }

  getItemClasses() {
    var classes = [
      'application',
      'form_new',
      'form_page'
    ]
    return classes
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    lang_code: state.lang.lang_code,
    selected_consorg_id: stateGetSelectedConsorgId(state),
    authIsUserLoggedIn: stateAuthIsUserLoggedIn(state),
    portalPageQueryParams: getPortalPageQueryParams(state),
    catalogId: getPortalCurrentCatalogId(state)
  }
  let ids = getPortalCurrentOrganisationAndCatalogIds(state)
  // Default CO comes from currently selected or from query param
  redux.relationship_consorg = redux.selected_consorg_id
  if (isDef(redux, 'portalPageQueryParams.co')) {
    ids = {
      ...ids,
      consorg_uname: redux.portalPageQueryParams.co
    }
    var consorg = stateFindResourceByIds(state, 'consorg', ids)
    if (stateIsUserBelongsConsorg(state, isDefVal(consorg, 'external_resource_infos.resource_code'))) {
      redux.relationship_consorg = consorg.ids.consorg_id
    }
  }

  redux.auth_adapters =  mapResourceFromIds(state, 'auth_adapters', {organisation_id: ids?.organisation_id, catalog_id: ids?.catalog_id})
  redux.external_resource_infos = buildExternalResourceInfos('auth_adapters', {organisation_id: ids?.organisation_id, catalog_id: ids?.catalog_id})

  return {
    redux
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindPromiseCreators({
    submitApplicationFinalFormNewHandler
  }, dispatch),
  requestResource: (external_resource_infos) => dispatch({type:'RESOURCE_LOAD_REQUEST', external_resource_infos}),
})

ApplicationNew = withTranslation(
  ['common','application','credential']
)(ApplicationNew)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationNew)
