import React from 'react'
import {connect} from 'react-redux'
import {logRender} from '../../../utils/logging'
import {withStaticContentCode} from '../../static_content/static_content_hoc'
import {deepEqualObjs2, getFirstDef} from '../../../utils/objtools'
import AnimDelay from "../../ui/AnimDelay/AnimDelay";

class WorkflowFooter extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || !deepEqualObjs2(this.props.staticContent.data, nextProps.staticContent.data)
    )
  }

  render() {
    logRender(this)

    return (
      <div className={this.getItemClasses().join(' ')}>
        <AnimDelay renderDelay={500} animateAppearance={true} showDelay={800}>
          <div className="grid g1_root g1_1col">
            <div className="item">
              <h3>{getFirstDef(this.props, 'staticContent.data.step_done_title', '&nbsp;')}</h3>
              <div className="content">
                <div className="consume_data_anim"></div>

                <div className="common_errors">
                  <h5>{getFirstDef(this.props, 'staticContent.data.step_done_http_title', '&nbsp;')}</h5>
                  <div className="grid g1_432col">
                    <div>
                      <div className="pill tertiary">{getFirstDef(this.props, 'staticContent.data.step_done_http_401_label', '&nbsp;')}</div>
                      <p>{getFirstDef(this.props, 'staticContent.data.step_done_http_401_desc', '&nbsp;')}</p>
                      <ul>
                        <li>{getFirstDef(this.props, 'staticContent.data.step_done_http_401_li_1', '&nbsp;')}</li>
                        <li>{getFirstDef(this.props, 'staticContent.data.step_done_http_401_li_2', '&nbsp;')}</li>
                        <li>{getFirstDef(this.props, 'staticContent.data.step_done_http_401_li_3', '&nbsp;')}</li>
                      </ul>
                    </div>
                    <div>
                      <div className="pill tertiary">{getFirstDef(this.props, 'staticContent.data.step_done_http_403_label', '&nbsp;')}</div>
                      <p>{getFirstDef(this.props, 'staticContent.data.step_done_http_403_desc', '&nbsp;')}</p>
                    </div>
                    <div>
                      <div className="pill tertiary">{getFirstDef(this.props, 'staticContent.data.step_done_http_404_label', '&nbsp;')}</div>
                      <p>{getFirstDef(this.props, 'staticContent.data.step_done_http_404_desc', '&nbsp;')}</p>
                    </div>
                    <div>
                      <div className="pill tertiary">{getFirstDef(this.props, 'staticContent.data.step_done_http_500_label', '&nbsp;')}</div>
                      <p>{getFirstDef(this.props, 'staticContent.data.step_done_http_500_desc', '&nbsp;')}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </AnimDelay>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'workflow_footer'
    ]
    return classes;
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    lang_code: state.lang.lang_code,
  }


  return {
    redux
  }
}

export default connect(
  mapStateToProps,
  null
)(withStaticContentCode(
  'workflow'
)(WorkflowFooter))
