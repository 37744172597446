import React from 'react'
import { logRender } from '../../utils/logging'
import { withTranslation } from 'react-i18next'
import JsonExpandable from '../ui/JsonExpandable/JsonExpandable'
import { getFirstDef, getObjExcluding, deepEqualObjs2 } from '../../utils/objtools'
import UiProperty from '../ui/UiProperty/UiProperty'
import ItemGFMDescription, { needsGFM } from '../ui/ItemGFMDescription/ItemGFMDescription'

class ApiParameter extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.tReady !== nextProps.tReady
      || !deepEqualObjs2(this.props.data, nextProps.data)
    )
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    var title = <span key="name">{this.props.data.name}</span>
    if (getFirstDef(this.props.data, 'required', false))
      title = [title, <span key="required" className="required">&nbsp;*</span>]

    var infos = []
    var schema = getFirstDef(this.props.data, 'schema', null)
    if (schema)
      infos.push( <JsonExpandable key="schema" title="Schema" jsonobject={schema}/> )
    var enumeration = getFirstDef(this.props.data, 'enum', null)
    if (enumeration)
      infos.push( <JsonExpandable key="enumeration" title="Enumeration" jsonobject={enumeration}/> )
    var uncovered_members = getObjExcluding(this.props.data, ['type','name','description','required','in','schema','enum'])
    for (var uncovered_member_key in uncovered_members) {
      infos.push(<UiProperty key={uncovered_member_key} name={uncovered_member_key} value={uncovered_members[uncovered_member_key]} />)
    }

    var description = null
    if (!this.props.data.description)
      description = '-'
    else if (needsGFM(this.props.data.description))
      description = <ItemGFMDescription key="description" title="Description" content={this.props.data.description} />
    else
      description = this.props.data.description

    return (
      <div className={this.getItemClasses().join(' ')} >
        <div className="title">
          {title}
        </div>
        <div className="type">
          {this.props.data.type?this.props.data.type:'-'}
        </div>
        <div className="desc">
          {description}
        </div>
        <div className="infos">
          {infos.length?infos:'-'}
        </div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'api-parameter'
    ]
    return classes;
  }
}

export default withTranslation('api')(ApiParameter);
