import React from 'react'
import {logRender} from '../../../utils/logging'
import {deepEqualObjs2, isDef} from '../../../utils/objtools'
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {stateGetListItemsByIds} from "../../list/list_selectors";
import {getPortalCurrentOrganisationAndCatalogIds} from "../../portal/portal_selectors";


class UiDoubleCounter extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
    )
  }

  render() {
    logRender(this)

    var product_count
    var api_count

    if(isDef(this.props.redux.double_counter.products, 'length')){
      product_count = this.props.redux.double_counter.products.length
    }
    if(isDef(this.props.redux.double_counter.apis, 'length')){
      api_count = this.props.redux.double_counter.apis.length
    }

    return (
      <div className={this.getItemClasses().join(' ')}>
        <div>
          <div className="product_counter">
            <h1>{product_count}</h1>
            <h4>Products</h4>
          </div>
        </div>
        <div>
          <div className="api_counter">
            <h1>{api_count}</h1>
            <h4>Apis</h4>
          </div>
        </div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'ui_double_counter',
      'gridg',
      'g1_1t2t_2_1'
    ]
    return classes;
  }
}

const mapStateToProps = (state, ownProps) => {
  const portalIds = getPortalCurrentOrganisationAndCatalogIds(state)
  var redux = {
    lang_code: state.lang.lang_code,
    double_counter: {
      products: stateGetListItemsByIds(state, 'catalog_products', portalIds),
      apis: stateGetListItemsByIds(state, 'catalog_apis', portalIds)
    }
  }
  return {
    redux
  }
}

UiDoubleCounter = withTranslation(['common'])(UiDoubleCounter)

export default connect(mapStateToProps, null)(UiDoubleCounter)

