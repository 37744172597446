import React from 'react'
import { connect } from 'react-redux'
import { logRender } from '../../utils/logging'
import { getFirstDef } from '../../utils/objtools'
import { Form as FinalForm, Field as FinalField} from 'react-final-form'
import { withTranslation } from 'react-i18next'
import { Form } from 'react-bootstrap'
import UiButton from '../ui/buttons/UiButton'
import ControlTextarea from '../../utils/forms/ControlTextarea'
import Loader from '../loader/Loader'
import ControlCheck from "../../utils/forms/ControlCheck";
import branding from "../portal/branding";
import ControlJsonEditor from "../../utils/forms/ControlJsonEditor";
import ReactJsonSafe from "../ui/ReactJsonSafe/ReactJsonSafe";

class CredentialFinalForm extends React.Component {

  constructor(props) {
    super(props)

    this.validateForm = this.validateForm.bind(this)
  }

  validateForm = (values) => {
    const errors = {}

    // todo www ?? not valid for internal
    /*
    var re_redirectUris = /^(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)(\r?\n|$))*$/
    if (values.credentialRedirectUris && !re_redirectUris.test(values.credentialRedirectUris)) {
      errors.credentialRedirectUris = this.props.t('forms:error_redirectUris_pattern')
    }
    */

    // TODO Verify the regexp
    var re_credentialCertificate = /^-----BEGIN CERTIFICATE-----\r?\n((?!-----)\S+\r?\n)+-----END CERTIFICATE-----$/
    if (values.credentialCertificate && !re_credentialCertificate.test(values.credentialCertificate)) {
      errors.credentialCertificate = this.props.t('forms:error_credentialCertificate_pattern')
    }

    if (!values.forcedAdapter && !branding.open) {
      errors.forcedAdapter = this.props.t('forms:error_required')
    }

    return errors
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    if (['new','edit'].indexOf(getFirstDef(this.props, 'form_op', '')) < 0 ) {
      console.error('form_op is not defined or not handled')
      return null
    }

    const t = this.props.t

    let auth_form_fields = []

    for(let i in this.props.initialValues.auth_adapters){
      auth_form_fields.push(
        <FinalField name="forcedAdapter"
                    type='radio'
                    component={ControlCheck}
                    key={this.props.initialValues.auth_adapters[i].name}
                    label={this.props.initialValues.auth_adapters[i].label}
                    value={this.props.initialValues.auth_adapters[i].name}
                    id={this.props.initialValues.auth_adapters[i].name}
        />)
    }

    return (
      <FinalForm
        initialValues={this.props.initialValues}
        onSubmit={this.props.onSubmit}
        validate={this.validateForm}
      >
        {({ handleSubmit, pristine, form, submitting }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="credentialRedirectUris">
                <Form.Label>{t('application:field_credentialRedirectUris')}</Form.Label>
                <FinalField name="credentialRedirectUris" component={ControlTextarea} placeholder="" rows="3" />
                <Form.Text className="text-muted">{t('application:field_credentialRedirectUris_desc')}</Form.Text>
              </Form.Group>
              <Form.Group controlId="credentialCertificate">
                <Form.Label>{t('application:field_credentialCertificate')}</Form.Label>
                <FinalField name="credentialCertificate" component={ControlTextarea} placeholder="" rows="6" />
                <Form.Text className="text-muted">{t('application:field_credentialCertificate_desc')}</Form.Text>
              </Form.Group>

              {branding.open &&
                <Form.Group controlId="sealingCertificates">
                  <Form.Label>{t('application:field_sealingCertificates')}</Form.Label>
                  <FinalField name="sealingCertificates" component={ControlJsonEditor} placeholder={this.props.initialValues?.sealingCertificates ? JSON.parse(this.props.initialValues?.sealingCertificates) : {} }/>
                  <Form.Text className="text-muted">{t('application:field_sealingCertificates_desc')}</Form.Text>
                </Form.Group>
              }

              {branding.kind === "int" &&
                  <>
                    {this.props.initialValues.authorizedUsersClaims &&
                        <fieldset>
                          <label>{t('application:fieldset_authorized_users_claims')}</label>
                          <div className="standalone_field">
                            <ReactJsonSafe src={this.props.initialValues.authorizedUsersClaims} enableClipboard={true}
                                           collapsed={true} theme="summerfruit:inverted"/>
                          </div>
                        </fieldset>
                    }
                  </>
              }

              {!branding.open &&
                <fieldset>
                  <legend>{t('application:fieldset_authentication_method')}</legend>
                  <p>{t('application:fieldset_authentication_method_intro')}</p>
                  <Form.Group controlId="forcedAdapter">
                    {auth_form_fields}
                    {/*TODO this helper need to have a dynamic text */}
                    {/*<Form.Text className="text-muted">{t('application:field_authenticationMethod_desc')}</Form.Text>*/}
                  </Form.Group>
                </fieldset>
              }
              <div className="form-actions">
                <Loader loading={submitting} />
                <UiButton variant="primary" type="submit" disabled={(pristine && this.props.form_op==='edit') || submitting}>{t(this.props.form_op==='new'?'forms:submit_create':'forms:submit_update')}</UiButton>
                <UiButton variant="secondary" type="button" disabled={pristine||submitting} onClick={form.reset}>{t('forms:reset')}</UiButton>
              </div>


            </Form>
          )
        }}
      </FinalForm>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    lang_code: state.lang.lang_code
  }
  return {
    redux
  }
}

export default connect(
    mapStateToProps,
    null
  )(withTranslation(
    ['forms','application','enums']
  )(CredentialFinalForm))
