import { submitRequestMembershipFormAllowRoutine } from './membership_routines'
import { call } from 'redux-saga/effects'
import { alterItem } from '../item/item_alter_saga.js'
import genericFormSubmissionSaga from '../item/genericFormSubmissionSaga'
import { membershipStates } from '../../utils/enums/membershipStates'
import {stateAuthUserId} from "../auth/auth_selectors";
import {buildExternalResourceInfos} from "../../utils/urls/external";
import {put, select} from "@redux-saga/core/effects";

function* saga(action) {
  // prepare some ids
  var ids = {...action.payload.ids}

  var body = {
    "state": membershipStates.ACTIVE
  }

  // Alter the membership
  const {error, data} = yield call(alterItem, 'consorg_membership_request_invitation_alter', 'consorg_membership', ids, body)

  // Check if the current user has modified its own membership
  const currentUserId = yield select(stateAuthUserId)
  if (data.user.id === currentUserId) {
    // reload consorg list, this will cascade trigger user rights on COs
    var external_resource_infos_catalog_consorgs = buildExternalResourceInfos('catalog_consorgs', ids)
    yield put({type: 'RESOURCE_LOAD_REQUEST', external_resource_infos: external_resource_infos_catalog_consorgs, force:true})
  }


  // Generic unhandled error
  if (error)
    throw error

  // Check altered
  // TODO
  if (!data.id)
    throw new Error('Missing id in the accepted invitation.')
}

export function* submitAllowRequestMembershipForm(action) {
  yield call(genericFormSubmissionSaga, {
    saga,
    action,
    routine: submitRequestMembershipFormAllowRoutine,
    successfull_title: 'membership:successfull_request_allow_title',
    successfull_body: 'membership:successfull_request_allow_body',
    unsuccessfull_title: 'membership:unsuccessfull_request_allow_title',
    unsuccessfull_body: 'membership:unsuccessfull_request_allow_body'
  })
}
