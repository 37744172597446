import { submitCredentialFormResetRoutine } from './credential_routines'
import { put, call, delay } from 'redux-saga/effects'
import i18n from '../../utils/lang/i18n'
import { newItem } from '../item/item_new_saga.js'
import { modalAdd } from '../modals/modals_actions'
import genericFormSubmissionSaga from '../item/genericFormSubmissionSaga'

function* saga(action) {

  const {values, modalInfos} = action.payload
  // prepare some ids
  var ids = values.ids


  // Reset the credential
  var {error, data} = yield call(newItem, 'credential_reset', 'credential', ids)

  // Generic unhandled error
  if (error)
    throw error

  // Check created
  if (!data.id)
    throw new Error('No id found in the newly reseted credential.')
  if (!data.secret)
    throw new Error('No secret found in the newly reseted credential.')

  // // notify about credential client_id/secret
  modalInfos.closeModal()

  yield delay(500)
  yield put(modalAdd(
    'secret',
    {
      title: i18n.t('credential:secret_modal_title'),
      message: i18n.t('credential:secret_modal_message'),
      client_id: data.id,
      secret: data.secret
    }
  ))
}

export function* submitResetCredentialForm(action) {
  yield call(genericFormSubmissionSaga, {
    saga,
    action,
    routine: submitCredentialFormResetRoutine,
    successfull_title: 'credential:successfull_reset_title',
    successfull_body: 'credential:successfull_reset_body',
    unsuccessfull_title: 'credential:unsuccessfull_reset_title',
    unsuccessfull_body: 'credential:unsuccessfull_reset_body'
  })
}
