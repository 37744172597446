import { submitFormEditScopeCartRoutine } from './scope_routines'
import { put, call } from 'redux-saga/effects'
import genericFormSubmissionSaga from '../item/genericFormSubmissionSaga'

function* saga(action) {
  const values = {...action.payload}

  if (!values.scopeClaim || values.scopeClaim === '_none') {
    yield put({type: 'CART_SCOPES_REMOVE', ...values})
  }
  else {
    yield put({type: 'CART_SCOPES_EDIT', ...values})
  }
}

export function* submitEditScopeCartForm(action) {
  yield call(genericFormSubmissionSaga, {
    saga,
    action,
    routine: submitFormEditScopeCartRoutine,
    successfull_title: 'scope:successfull_editcart_title',
    successfull_body: 'scope:successfull_editcart_body',
    unsuccessfull_title: 'scope:unsuccessfull_editcart_title',
    unsuccessfull_body: 'scope:unsuccessfull_editcart_body'
  })
}
