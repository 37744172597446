import React from 'react'
import { connect } from '../../../utils/redux'
import { logRender } from '../../../utils/logging'
import { stateGetNLastSeenResourceCode } from '../../item/item_selectors'
import Teaser from '../../teaser/Teaser'
import { withTranslation } from 'react-i18next'
import UiButton from '../../ui/buttons/UiButton'
import { deepEqualObjs2, isDefVal } from '../../../utils/objtools'
import { stateGetSelectedConsorgResourceCode } from '../../consorg/consorg_selectors'
import { getPortalCurrentCatalogParam } from '../../portal/portal_selectors'
import Visibility from '../../../utils/debug/Visibility'
import { stateAuthIsUserLoggedIn } from '../../auth/auth_selectors'

class PaneConsOrgs extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || this.props.tReady !== nextProps.tReady
    )
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    var selectedConsorg = null
    if (this.props.redux.selectedConsorgResourceCode) {
      selectedConsorg =
        <div className="grid g2_1col">
          <Teaser key={this.props.redux.selectedConsorgResourceCode} item_type="consorg" resource_code={this.props.redux.selectedConsorgResourceCode} />
        </div>
    }
    else {
      selectedConsorg = <p className="empty_zone">{this.props.t('consorg:pane_current_none')}</p>
    }

    var last_seen = this.props.redux.last_seen_resource_codes
      .filter((resource_code)=>resource_code!==this.props.redux.selectedConsorgResourceCode)
      .slice(0, 4)
      .map((resource_code)=>
        <Teaser key={resource_code} item_type="consorg" resource_code={resource_code} />
      )
    if (last_seen.length > 0) {
      last_seen = (
        <div className="ggmenu-pane-section">
          <h3>{this.props.t('menu:lastseen_title')}</h3>
          <div className="grid g2_2col">
            {last_seen}
          </div>
        </div>
      )
    }

    return (
      <div className={this.getItemClasses().join(' ')}>
        <div className="grid g2_root g2_1col">

          <h2>{this.props.t('consorg:pane_title')}</h2>

          <div className="ggmenu-pane-section">
            <h3>{this.props.t('consorg:pane_current_title')}</h3>
            <p className="chapeau" key="chapeau">{this.props.t('consorg:pane_current_chapeau')}</p>
            {selectedConsorg}
          </div>

          <div className="grid g2_221col">
            <div className="ggmenu-pane-section">
              <h3>{this.props.t('consorg:pane_find_title')}</h3>
              <p className="chapeau" key="chapeau">{this.props.t('consorg:pane_find_chapeau')}</p>
              <UiButton variant="primary" onClick={()=>this.props.finderGo('consorg')} icon="search">{this.props.t('consorg:pane_find_button')}</UiButton>
            </div>

            <div className="ggmenu-pane-section">
              <Visibility condition={isDefVal(this.props.redux, 'consumerOrgsConfiguration.canUsersCreate') === true && this.props.redux.authIsUserLoggedIn === true}>
                <h3>{this.props.t('consorg:pane_create_title')}</h3>
                <p className="chapeau" key="chapeau">{this.props.t('consorg:pane_create_chapeau')}</p>
                <UiButton variant="secondary" onClick={()=>this.props.historyPush('consorg_new')} icon="add">{this.props.t('consorg:pane_create_button')}</UiButton>
              </Visibility>
            </div>
          </div>

          {last_seen}

        </div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'pane-consorgs'
    ]
    return classes;
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    lang_code: state.lang.lang_code,
    last_seen_resource_codes: stateGetNLastSeenResourceCode(state, 'consorg', 5),
    selectedConsorgResourceCode: stateGetSelectedConsorgResourceCode(state),
    consumerOrgsConfiguration: getPortalCurrentCatalogParam(state, 'consumerOrgsConfiguration'),
    authIsUserLoggedIn: stateAuthIsUserLoggedIn(state)
  }
  return {
    redux
  }
}

const mapDispatchToProps = dispatch => ({
  finderGo: (target) => { dispatch({type:'FINDER_GO', target}) },
  historyPush: (scheme_key) => { dispatch({type:'HISTORY_PUSH', scheme_key}) },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  ['item','lang','portal','auth']
)(withTranslation(['menu','consorg'])(PaneConsOrgs))