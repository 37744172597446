import React from 'react'
import { connect } from 'react-redux'
import { logRender } from '../../utils/logging'
import { withTranslation } from 'react-i18next'
import { deepEqualObjs2 } from '../../utils/objtools'
import { mapResourceFromCode, mapResourceFromIds } from '../resource/resource_selectors'
import Teaser from '../teaser/Teaser'
import Loader from '../loader/Loader'
import UiButton from '../ui/buttons/UiButton'

class ConsorgApplications extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || this.props.tReady !== nextProps.tReady
    )
  }

  componentDidMount() {
    this.postRender()
  }

  componentDidUpdate() {
    this.postRender()
  }

  postRender() {
    if (this.props.redux.applications.need_request) {
      this.props.requestResource(this.props.redux.applications.external_resource_infos)
    }
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    if (!this.props.redux.consorg.found)
      return null

    return (
      <div className={this.getItemClasses().join(' ')} >
        <div className="grid g1_root g1_1col">

          <h2>{this.props.t('consorg:tab_applications_title')}</h2>

          <h3>{this.props.t('consorg:tab_applications_create_title')}</h3>
          <UiButton variant="secondary" onClick={()=>this.props.historyPush('application_new', {co: this.props.redux.consorg.ids.consorg_uname})} icon="add">{this.props.t('consorg:tab_applications_create_button')}</UiButton>

          <h3>{this.props.t('consorg:tab_applications_list_title')}</h3>
          <div className="grid g1_432col">
            {(this.props.redux.applications.itemsResourceCodes.length > 0 &&
              this.props.redux.applications.itemsResourceCodes.map((resourceCode)=>{
                return (
                  <Teaser key={resourceCode} item_type="application" resource_code={resourceCode} />
                )
              })
            ) || ((!this.props.redux.applications.found || this.props.redux.applications.loading) &&
               <Loader loading={true} />
            ) ||
              <p className="empty_zone">{this.props.t('consorg:tab_applications_list_empty')}</p>
            }
          </div>

        </div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'consorg',
      'applications'
    ]
    return classes
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    consorg: mapResourceFromCode(state, 'consorg', ownProps.resourceCode),
    lang_code: state.lang.lang_code,
  }
  redux.applications = mapResourceFromIds(state, 'consorg_applications', redux.consorg.ids)
  return {
    redux
  }
}

const mapDispatchToProps = dispatch => ({
  requestResource: (external_resource_infos) => dispatch({type:'RESOURCE_LOAD_REQUEST', external_resource_infos}),
  historyPush: (scheme_key, queryParams) => { dispatch({type:'HISTORY_PUSH', scheme_key, queryParams}) }
})

ConsorgApplications = withTranslation(['common','consorg','membership'])(ConsorgApplications)

export default connect(mapStateToProps, mapDispatchToProps)(ConsorgApplications)