import React from 'react'
import { logRender } from '../../utils/logging'
import setDocumentTitle from '../../utils/browser/title'
import StaticContent from '../static_content/StaticContent'
import { connect } from 'react-redux'
import { mapResourceFromIds } from '../resource/resource_selectors'
import NotFound from '../ui/NotFound/NotFound'
import ResourceError from '../ui/ResourceError/ResourceError'
import { isDefVal, deepEqualObjs2 } from '../../utils/objtools'
import UiH1 from '../ui/titles/UiH1'

class StaticContentPage extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || this.props.tReady !== nextProps.tReady
    )
  }

  componentDidMount() {
    this.postRender()
  }

  componentDidUpdate() {
    this.postRender()
  }

  postRender() {
    setDocumentTitle(isDefVal(this.props, 'redux.static_content.consolidated.title'))
    this.props.dispatchDisplayResource(this.props.redux.resource.external_resource_infos)
  }

  render() {
    logRender(this)

    // Assume Optimistic Rendering
    if (this.props.redux.organisation_has_static_content === false || (this.props.redux.organisation_has_static_content && !this.props.redux.resource.found)) {
      return <NotFound />
    }
    if (this.props.redux.resource.error) {
      return <ResourceError />
    }

    return (
      <div className={this.getItemClasses().join(' ')} >
        <div className="grid g1_root g1_1col">
          <UiH1>{this.props.redux.resource.consolidated.title}</UiH1>
          <StaticContent staticContentCode={this.props.redux.resource.data.code} />
        </div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'static_content_page',
      'full'
    ]
    return classes;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    redux: {
      resource: mapResourceFromIds(state, 'static_content', {static_content_name: ownProps.page.routeProps.match.params.static_content_name, lang_code: state.lang.lang_code}),
      organisation_has_static_content: isDefVal(state, 'portal.context.organisation_has_static_content'),
    }
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchDisplayResource: (external_resource_infos) => dispatch({type:'DISPLAY_RESOURCE', external_resource_infos})
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StaticContentPage)
