import React from 'react';
import { ResponsiveLine } from '@nivo/line'

export const ResponseTimeGraph = ({ data, legend, prim_color, sec_color }) => (
  <ResponsiveLine
    data={data}
    margin={{ top: 50, right: 100, bottom: 80, left: 80 }}
    xScale={{ type: 'point' }}
    yScale={{
      type: 'linear',
      stacked: false,
      min: 'auto',
      max: 'auto' }}
    curve={'catmullRom'}
    enableArea={true}
    areaOpacity={0.05}
    areaBaselineValue={0}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      orient: 'bottom',
      tickSize: 6,
      tickPadding: 6,
      tickRotation: -45,
      //legend: 'Date',
      //legendOffset: 36,
      //legendPosition: 'middle'
    }}
    axisLeft={{
      orient: 'left',
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: legend+' (ms)',
      legendOffset: -50,
      legendPosition: 'middle'
    }}
    enableGridX={false}
    enableGridY={false}
    colors={[prim_color,sec_color]}
    pointSize={10}
    pointColor={{ theme: 'background' }}
    pointBorderWidth={2}
    pointBorderColor={{ from: 'serieColor' }}
    pointLabel="y"
    pointLabelYOffset={-12}
    debugSlices={false}
    crosshairType="x"
    useMesh={true}
    theme={{
      axis: {
          fontSize: "14px",
          tickColor: "#ffffff",
          ticks: {
            line: {
              stroke: "#ffffff"
            },
            text: {
              fill:"#FFFFFF"
            }
          },
          legend: {
            text: {
              fill: "#ffffff"
            }
          }
        },
    }}
    legends={[
      {
        anchor: 'top',
        direction: 'row',
        justify: false,
        translateX: 0,
        translateY: -40,
        itemsSpacing: 4,
        itemDirection: 'left-to-right',
        itemWidth: 92,
        itemHeight: 18,
        itemOpacity: 0.9,
        itemTextColor: "#ffffff",
        symbolSize: 16,
        symbolShape: 'circle',
        symbolBorderColor: 'rgba(0, 0, 0, .5)',
        effects: [
          {
            on: 'hover',
            style: {
              itemBackground: 'rgba(0, 0, 0, .03)',
              itemOpacity: 1
            }
          }
        ]
      }
    ]}
    yFormat={value => value + 'ms'}
    enableSlices="x"
    sliceTooltip={({ slice }) => {
      return (
        <div
          style={{
            background: '#151515',
            padding: '9px 12px',
            border: 'none',
          }}
        >
          <div>{slice.points[0].data.xFormatted}</div>
          {slice.points.map(point => (
            <div
              key={point.id}
              style={{
                color: point.serieColor,
                padding: '3px 0',
              }}
            >
              <strong>{point.serieId}:</strong> {point.data.yFormatted}
            </div>
          ))}
        </div>
      );
    }}
  />
)
