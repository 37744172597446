import React from 'react'
import { logRender } from '../../../utils/logging'
import { isDefVal } from '../../../utils/objtools'
import Icon from "../../icon/Icon";

class Expandable extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      opened: false
    }

    this.toggle = this.toggle.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.state.opened !== nextState.opened
    )
  }

  render() {
    logRender(this)

    var content = null
    if (this.state.opened) {
      content = <div className="expandable_content">{this.props.children}</div>
    }

    var summary = null
    if ((summary = isDefVal(this.props, 'summary')))
      summary = <span className="summary">{summary}</span>

    var tag = null
    var method = null
    if ((method = isDefVal(this.props, 'method')))
      tag = <div><span className={'tag '+method}>{method}</span></div>
    else if ((tag = isDefVal(this.props, 'tag')))
      tag = <div><span className={'tag'}>{tag}</span></div>

    return (
      <div className={this.getItemClasses().join(' ')} >
        <div className="expandable_header" onClick={this.toggle}>
          <div className="chevron"></div>
          {this.props.lock !== undefined && this.props.lock &&
            <div className="lock">
              <Icon type='open_lock'/>
            </div>
          }
          <div className="content">
            {tag}
            {' '}<span className="title">{this.props.title}</span>
            {' '}{summary}
          </div>

        </div>
        {content}
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'expandable'
    ]
    if (this.state.opened)
      classes.push('opened')
    if(this.props.disabled)
      classes.push('disabled')
    return classes;
  }

  toggle() {
    this.setState({
        opened: !this.state.opened
      })
  }
}

export default Expandable
