import { all } from 'redux-saga/effects'
import ggmenuSagas from './components/ggmenu/ggmenu_sagas'
import portalSagas from './components/portal/portal_sagas'
import itemSagas from './components/item/item_sagas'
import listSagas from './components/list/list_sagas'
import resourceSagas from './components/resource/resource_sagas'
import finderSagas from './components/finder/finder_sagas'
import historySagas from './utils/history/history_sagas'
import authSagas from './components/auth/auth_sagas'
import consorgSagas from './components/consorg/consorg_sagas'
import applicationSagas from './components/application/application_sagas'
import membershipSagas from './components/membership/membership_sagas'
import credentialSagas from './components/credential/credential_sagas'
import subscriptionPlansSagas from './components/subscription_plans/subscription_plans_sagas'
import subscriptionScopesSagas from "./components/subscription_scopes/subscription_scopes_sagas";
import scopesSagas from './components/scopes/scope_sagas'
import issueSagas from './components/issue/issue_sagas'
import commentSagas from './components/comment/comment_sagas'
import apiSagas from "./components/api/api_sagas";

function* rootSaga() {
  yield all([
    ...portalSagas,
    ...ggmenuSagas,
    ...listSagas,
    ...itemSagas,
    ...resourceSagas,
    ...finderSagas,
    ...historySagas,
    ...authSagas,
    ...consorgSagas,
    ...applicationSagas,
    ...membershipSagas,
    ...credentialSagas,
    ...subscriptionPlansSagas,
    ...subscriptionScopesSagas,
    ...scopesSagas,
    ...issueSagas,
    ...commentSagas,
    ...apiSagas
  ])
}

export default rootSaga
