import { isDefVal } from '../../../utils/objtools'
import { stateAuthIsUserLoggedIn } from '../../auth/auth_selectors'
import { stateIsPortalDebug } from '../../portal/portal_selectors'
import branding from '../../portal/branding'
import { stateGetCartScopeCount } from "../../cart/cart_selectors"
import { getPortalCurrentOrganisationParam } from '../../portal/portal_selectors'

// This is the intial state of the menu, ie its configuration
export const ggmenu_init_state = {
  pane_opened: false, // the pane or pane_group name if opened (always one at a time)
  header_status: 'full',
  items: {
    tech_external_urls: {
      name: 'tech_external_urls',
      type: 'normal',
      target_scheme_key: 'tech_external_urls',
      title: 'DEBUG External URLs',
      show: {
        desktop: 'footer3',
        tablet: 'footer3',
        mobile: 'footer3'
      },
      display_state_condition: stateIsPortalDebug
    },
    home: {
      name: 'home',
      type: 'normal',
      // target_uri: '/',
      target_scheme_key: 'catalog', // So that we have a clean link to lang/catalog and then avoid redirect
      component: 'ItemLogo',
      show: {
        desktop: 'left',
        tablet: 'left',
        mobile: 'left'
      }
    },
    cart: {
      name: 'cart',
      type: 'normal',
      component: 'ItemCart',
      target_scheme_key: 'subscription_scopes_new',
      show: {
        desktop: 'right',
        tablet: 'right',
        mobile: 'right',
      },
      display_state_condition: (state) => {
        return stateGetCartScopeCount(state) > 0
      }
    },
    finder: {
      name: 'finder',
      type: 'normal',
      title_i18n: 'menu:finder_title',
      icon_type: 'search',
      target_scheme_key: 'finder',
      show: {
        desktop: 'right',
        tablet: 'right',
        mobile: 'right'
      },
      display_state_condition: (state) => {
        return isDefVal(state, 'portal.context.catalog_has_products') === true
            || isDefVal(state, 'portal.context.catalog_has_apis') === true
            || isDefVal(state, 'portal.context.catalog_has_consorgs') === true
            //|| isDefVal(state, 'portal.context.catalog_has_applications') === true
      }
    },
    products: {
      name: 'products',
      type: 'opener',
      title_i18n: 'menu:products_title',
      target_pane: 'products',
      show: {
        desktop: 'right',
        tablet: 'right',
        mobile: false
      },
      display_state_condition: (state) => {
        return isDefVal(state, 'portal.context.catalog_has_products') === true
      }
    },
    apis: {
      name: 'apis',
      type: 'opener',
      title_i18n: 'menu:apis_title',
      target_pane: 'apis',
      show: {
        desktop: 'right',
        tablet: 'right',
        mobile: false
      },
      display_state_condition: (state) => {
        return isDefVal(state, 'portal.context.catalog_has_apis') === true
      }
    },
    scopes: {
      name: 'scopes',
      type: 'opener',
      title_i18n: 'menu:scopes_title',
      target_pane: 'scopes',
      show: {
        desktop: 'right',
        tablet: 'right',
        mobile: false
      },
      display_state_condition: (state) => {
        return isDefVal(state, 'portal.context.catalog_has_scopes') === true
      }
    },
    onboarding: {
      name: 'onboarding',
      type: 'normal',
      title_i18n: 'menu:onboarding_title',
      target_scheme_key: 'onboarding',
      show: {
        desktop: 'footer2',
        tablet: 'footer2',
        mobile: 'footer2'
      },
      display_state_condition: (state) => {
        return branding.open === true
      }
    },
    group_platforms: {
      name: 'group_platforms',
      type: 'normal',
      title_i18n: 'menu:group_platforms_title',
      target_scheme_key: 'group_platforms',
      show: {
        desktop: 'footer2',
        tablet: 'footer2',
        mobile: 'footer2'
      },
      display_state_condition: (state) => {
        return branding.open === true && branding.kind === "sg"
      }
    },
    kpis: {
      name: 'kpis',
      type: 'normal',
      title_i18n: 'menu:kpis_title',
      target_scheme_key: 'kpis',
      show: {
        desktop: 'footer2',
        tablet: 'footer2',
        mobile: 'footer2'
      },
      display_state_condition: (state) => {
        return branding.kind !== 'int'
      }
    },
    consorgs: {
      name: 'consorgs',
      type: 'opener',
      component: 'ItemConsorg',
      weight: -50,
      target_pane: 'consorgs',
      show: {
        desktop: 'bandeau',
        tablet: 'bandeau',
        mobile: 'bandeau'
      },
      display_state_condition: (state) => {
        // return isDefVal(state, 'portal.context.catalog_has_consorgs') === true && stateAuthIsUserLoggedIn(state) === true // TODO can user create consrgs in the catalog
        return stateAuthIsUserLoggedIn(state) === true
      }
    },
    applications: {
      name: 'applications',
      type: 'opener',
      title_i18n: 'menu:applications_title',
      target_pane: 'applications',
      show: {
        desktop: 'right',
        tablet: 'right',
        mobile: false
      },
      display_state_condition: (state) => {
        return state.portal.consorg.resourceCode
      }
    },
    user: {
      name: 'user',
      type: 'opener',
      component: 'ItemMe',
      target_pane: 'user',
      show: {
        desktop: 'bandeau',
        tablet: 'bandeau',
        mobile: 'bandeau',
      },
      display_state_condition: (state) => {
        return stateAuthIsUserLoggedIn(state) === true
      }
    },
    login: {
      name: 'login',
      type: 'action',
      action_type: 'AUTH_LOGIN',
      title_i18n: 'menu:log_in_title',
      icon_type: 'login',
      show: {
        desktop: 'bandeau',
        tablet: 'bandeau',
        mobile: 'bandeau'
      },
      display_state_condition: (state) => {
        return stateAuthIsUserLoggedIn(state) === false && getPortalCurrentOrganisationParam(state, 'usersConfiguration.authEnabled')
      }
    },
    // login2: {
    //   name: 'login2',
    //   type: 'action_button',
    //   action_type: 'AUTH_LOGIN',
    //   button_variant: 'primary',
    //   title_i18n: 'menu:log_in_title',
    //   icon_type: 'login',
    //   show: {
    //     desktop: 'bandeau',
    //     tablet: 'bandeau',
    //     mobile: 'bandeau'
    //   },
    //   display_state_condition: (state) => {
    //     return stateAuthIsUserLoggedIn(state) === false
    //   }
    // },
    burger: {
      name: 'burger',
      type: 'group_opener',
      target_pane_group: 'burgergroup',
      component: 'ItemBurger',
      show: {
        desktop: false,
        tablet: false,
        mobile: 'right'
      }
    },
    lang: {
      name: 'lang',
      type: 'autonomous',
      component: 'ItemLang',
      show: {
        desktop: 'bandeau',
        tablet: 'bandeau',
        mobile: 'bandeau'
      }
    },
  },
  pane_groups: {
    independantgroup: { // this is a special pane group that does not act as a pane group, each pane in each are independants
      name: 'independantgroup',
      status: 'closed',
      show: {
        desktop: true,
        tablet: true,
        mobile: true,
      }
    },
    burgergroup: {
      name: 'burgergroup',
      status: 'closed',
      show: {
        desktop: false,
        tablet: false,
        mobile: true,
      }
    }
  },
  panes: {
    applications: {
      name: 'applications',
      status: 'closed',
      type: 'pane',
      title_i18n : 'menu:applications_title',
      component: 'PaneApplications',
      show: {
        desktop: 'independantgroup',
        tablet: 'independantgroup',
        mobile: 'burgergroup',
      }
    },
    apis: {
      name: 'apis',
      status: 'closed',
      type: 'pane',
      title_i18n : 'menu:apis_title',
      component: 'PaneAPIs',
      show: {
        desktop: 'independantgroup',
        tablet: 'independantgroup',
        mobile: 'burgergroup',
      }
    },
    scopes: {
      name: 'scopes',
      status: 'closed',
      type: 'pane',
      title_i18n : 'menu:scopes_title',
      component: 'PaneScopes',
      show: {
        desktop: 'independantgroup',
        tablet: 'independantgroup',
        mobile: 'burgergroup',
      }
    },
    products: {
      name: 'products',
      status: 'closed',
      type: 'pane',
      title_i18n : 'menu:products_title',
      component: 'PaneProducts',
      show: {
        desktop: 'independantgroup',
        tablet: 'independantgroup',
        mobile: 'burgergroup',
      }
    },
    consorgs: {
      name: 'consorgs',
      status: 'closed',
      type: 'pane',
      title_i18n : 'menu:consorgs_title',
      component: 'PaneConsOrgs',
      show: {
        desktop: 'independantgroup',
        tablet: 'independantgroup',
        mobile: 'independantgroup',
      }
    },
    user: {
      name: 'user',
      status: 'closed',
      type: 'pane',
      title_i18n : 'menu:user_title',
      component: 'PaneUser',
      show: {
        desktop: 'independantgroup',
        tablet: 'independantgroup',
        mobile: 'independantgroup',
      }
    },
    organisations: {
      name: 'organisations',
      status: 'closed',
      type: 'pane',
      component: 'PaneOrganisations',
      show: {
        desktop: 'independantgroup',
        tablet: 'independantgroup',
        mobile: 'independantgroup',
      }
    },
    catalogs: {
      name: 'catalogs',
      status: 'closed',
      type: 'pane',
      component: 'PaneCatalogs',
      show: {
        desktop: 'independantgroup',
        tablet: 'independantgroup',
        mobile: 'independantgroup',
      }
    }
  }
}
