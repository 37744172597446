import Cookies from 'js-cookie';
import branding from '../components/portal/branding'

function fetchUrl(url, method, body, organization_id) {

  var fetchHeaders = new Headers()
  fetchHeaders.append("x-sparky-context", "consumer")
  fetchHeaders.append("accept", "application/json, */*")

  // retrieve the csrf token and inject it into the header
  const cookieName = 'CONSUMER-' + (branding.kind === 'int' ? 'rbdf' : branding.kind )+ '-TOKEN';
  const token = Cookies.get(cookieName.toUpperCase());
  if( token ) {
    fetchHeaders.append("X-XSRF-TOKEN", token)
  }

  if (organization_id)
    fetchHeaders.append("x-sparky-organization", organization_id)
  var fetchRequest = new Request(url)
  var fetchParams = {
    method,
    headers: fetchHeaders,
    mode: 'cors',
    credentials: 'include', // To send cookies on COR
    //credentials: 'same-origin'
  }

  if (body) {
    fetchHeaders.append("Content-Type", "application/json")
    fetchParams.body = JSON.stringify(body)
  }
  return fetch(fetchRequest, fetchParams)
    .then(fetch_response => ({ fetch_response }))
    .catch(fetch_error => ({ fetch_error }))
}

export function getUrl(url, organization_id) {
  return fetchUrl(url, 'GET', null, organization_id)
}

export function postUrlJson(url, body, organization_id) {
  return fetchUrl(url, 'POST', body, organization_id)
}

export function putUrlJson(url, body, organization_id) {
  return fetchUrl(url, 'PUT', body, organization_id)
}

export function patchUrlJson(url, body, organization_id) {
  return fetchUrl(url, 'PATCH', body, organization_id)
}

export function deleteUrl(url, organization_id) {
  return fetchUrl(url, 'DELETE', null, organization_id)
}
