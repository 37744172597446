import { createRoutine, promisifyRoutine } from 'redux-saga-routines'

// New Membership
// Routine for Form
export const submitMembershipFormNewRoutine = createRoutine('SUBMIT_MEMBERSHIP_FORM_NEW')
export const submitMembershipFinalFormNewHandler = promisifyRoutine(submitMembershipFormNewRoutine)

// Edit Membership
// Routine for Form
export const submitMembershipFormEditRoutine = createRoutine('SUBMIT_MEMBERSHIP_FORM_EDIT')
export const submitMembershipFinalFormEditHandler = promisifyRoutine(submitMembershipFormEditRoutine)

// Delete Membership
export const submitMembershipFormDeleteRoutine = createRoutine('SUBMIT_MEMBERSHIP_FORM_DELETE')

// Accept invitation
export const submitMembershipFormAcceptRoutine = createRoutine('SUBMIT_MEMBERSHIP_FORM_ACCEPT')

// Decline invitation
export const submitMembershipFormDeclineRoutine = createRoutine('SUBMIT_MEMBERSHIP_FORM_DECLINE')

// New membership request
// Routine for Form
export const submitRequestMembershipFormNewRoutine = createRoutine('SUBMIT_REQUEST_MEMBERSHIP_FORM_NEW')
export const submitRequestMembershipFinalFormNewHandler = promisifyRoutine(submitRequestMembershipFormNewRoutine)

// Allow membership request
export const submitRequestMembershipFormAllowRoutine = createRoutine('SUBMIT_REQUEST_MEMBERSHIP_FORM_ALLOW')

// Refuse membership request
export const submitRequestMembershipFormRefuseRoutine = createRoutine('SUBMIT_REQUEST_MEMBERSHIP_FORM_REFUSE')
