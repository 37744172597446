import React from 'react'
import {connect} from 'react-redux'
import {logRender} from '../../../utils/logging'
import {withStaticContentCode} from '../../static_content/static_content_hoc'
import {deepEqualObjs2, getFirstDef} from '../../../utils/objtools'
import AnimDelay from "../../ui/AnimDelay/AnimDelay";


class PushAbout extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.staticContent.data, nextProps.staticContent.data)
      || !deepEqualObjs2(this.props.redux, nextProps.redux)
    )
  }


  render() {
    logRender(this)


    return (
      <div className={this.getItemClasses().join(' ')}>

          <AnimDelay renderDelay={500} animateAppearance={true} showDelay={200}>
            <div className="push_content grid g1_root gridg g1_2t1t_2_1">
              <div className="description">
                <h2>{getFirstDef(this.props, 'staticContent.data.title', '&nbsp;')}</h2>
                <p>
                  {getFirstDef(this.props, 'staticContent.data.description_level_1', '&nbsp;')}
                </p>
                <p>
                  {getFirstDef(this.props, 'staticContent.data.description_level_2', '&nbsp;')}
                </p>
              </div>

              <div className="push_motion">
               {/*background animation here */}
              </div>
            </div>
          </AnimDelay>

      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'push',
      'about'
    ]
    return classes;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    redux: {
      lang_code: state.lang.lang_code
    }
  }
}

export default connect(
  mapStateToProps,
  null
)(withStaticContentCode(
  'push_about'
)(PushAbout))
