import React from 'react'
import {connect} from 'react-redux'
import {logRender} from '../../../utils/logging'
import {withStaticContentCode} from '../../static_content/static_content_hoc'
import {deepEqualObjs2, getFirstDef} from '../../../utils/objtools'
import AnimDelay from "../../ui/AnimDelay/AnimDelay";
import {withTranslation} from "react-i18next";


class PushSolutions extends React.Component {

    shouldComponentUpdate(nextProps, nextState) {

        return (
            !deepEqualObjs2(this.props.staticContent.data, nextProps.staticContent.data)
            || !deepEqualObjs2(this.props.redux, nextProps.redux)
        )
    }


    render() {
        logRender(this);
        if (!this.props.tReady)
            return null

        let solutions = [];

        for (let solution in this.props.staticContent.data.solutions) {
            solutions.push(this.props.staticContent.data.solutions[solution]);
        }

        return (
            <div className={this.getItemClasses().join(' ')}>
                <AnimDelay renderDelay={500} animateAppearance={true} showDelay={800}>
                    <div className="grid g1_root g1_1col">
                        <h2>{getFirstDef(this.props, 'staticContent.data.title', '&nbsp;')}</h2>
                        <div className="grid solutionsGrid spaceGrid">
                            {solutions.map(({id, name, description, url, image, button_name}) => (
                                <div className={[id, "solution"].join(' ')} key={id}>
                                    <div className="card">
                                        <img className="card-img-top" src={image} alt="..."/>
                                        <div className="card-body">
                                            <h4 className="card-text">{name}</h4>
                                            <p className="card-text description">{description}</p>
                                            <a className="link" href={button_name} onClick={()=>window.open(url, "_blank")}>{button_name}</a>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </AnimDelay>
            </div>
        )
    }

    handleClick(target) {
        this.props.finderGo(target)
    }

    getItemClasses() {
        var classes = [
            'push',
            'solutions'
        ]
        return classes;
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        redux: {
            lang_code: state.lang.lang_code
        }
    }
}

const mapDispatchToProps = dispatch => ({
    finderGo: (target) => {
        dispatch({type: 'FINDER_GO', target})
    }
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStaticContentCode(
    'push_solutions'
)
(withTranslation(
    'api')
(PushSolutions)))
