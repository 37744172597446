import React, {Component} from 'react'
import {Router, Route, Switch} from "react-router-dom" // We user Router instead of BrowserRouter so that we create history by ourselves and can use it in sagas (history is required prop of Router but not BrowserRouter)
import MQSync from './utils/mqsync/mqsync'
import GGMenu from './components/ggmenu/GGMenu.js'
import Notifications from './components/notifications/Notifications'
import {url_schemes} from './utils/urls/internal'
import Page from './components/page/Page'
import Preloader from './components/preloader/Preloader'
import {connect} from './utils/redux'
import {logRender} from './utils/logging'
import Footer from './components/footer/Footer'
import {withTranslation} from 'react-i18next'
import history from './utils/history/history.js'
import Modals from './components/modals/Modals'
import {deepEqualObjs2} from './utils/objtools'

class App extends Component {

    shouldComponentUpdate(nextProps, nextState) {
        return (
            !deepEqualObjs2(this.props.redux, nextProps.redux)
            || this.props.tReady !== nextProps.tReady
        )
    }

    render() {
        logRender(this)
        var app_content = null

        // If we have no portal context, no organisation loaded or i18n not ready
        if (!this.props.redux.context_loaded || !this.props.redux.organisation_loaded || !this.props.tReady) {
            app_content = <Preloader key="app_preloader"/>
        } else {
            var routes = []
            for (var key in url_schemes) {
                const scheme = url_schemes[key]
                scheme.key = key
                routes.push(<Route exact={scheme.exact_route} key={scheme.key} path={scheme.uri_route}
                                   render={routeProps => (
                                       <Page scheme={scheme} routeProps={routeProps}/>
                                   )}/>)
            }
            app_content =
                <Switch>
                    {routes}
                    <Route>Not found</Route>
                </Switch>
        }

        var content = []
        content.push(
            <Router key="app_router" basename="/" history={history}>
                <div className="app_header">
                    <header>
                        <div className="app_header_content">
                            <GGMenu/>
                        </div>
                    </header>
                </div>
                <div className="app_content">
                    {app_content}
                </div>
                <div className="app_footer">
                    <Footer/>
                </div>
                <Modals/>
                {/*We hide the cookies banner because the CNIL rules changed*/}
                {/*The code is commented here so we can use it easily if necessary */}
                {/*{branding.open && <CookiesBanner />}*/}
            </Router>
        )

        return (
            <div className="App">
                {content}
                <div className="app_tools">
                    <MQSync/>
                </div>
                <Notifications/>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        redux: {
            context_loaded: state.portal.context_loaded,
            organisation_loaded: state.portal.context.organisation_loaded
        }
    }
}

export default connect(
    mapStateToProps,
    null,
    ['portal.context']
)(withTranslation()(App));
