import React from 'react'
import { connect } from 'react-redux'
import { logRender } from '../../../utils/logging'
import { withTranslation } from 'react-i18next'
import { deepEqualObjs2 } from '../../../utils/objtools'
import { ResponseTimeGraph } from './ResponseTimeGraph'
import { AvailabilityGraph } from './AvailabilityGraph'
import { AvailabilitySummaryGraph } from './AvailabilitySummaryGraph'
import { ResponseTimeSummaryGraph } from './ResponseTimeSummaryGraph'
import {FailureGraph} from "./FailureGraph";
import branding from "../../portal/branding";
import Visibility from "../../../utils/debug/Visibility";

class KpiPool extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.data, nextProps.data)
      || !deepEqualObjs2(this.props.redux, nextProps.redux)
      || this.props.tReady !== nextProps.tReady
    )
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    const data = this.props.data
    const t = this.props.t
    var serie_m1
    var serie_m3
    var placeholders

    serie_m1 = data.response_graph_data_m1[0].data
    serie_m3 = data.response_graph_data_m3[0].data
    placeholders = {
      x_start: serie_m1[0].x,
      x_end: serie_m3[serie_m3.length-1].x
    }

    var primary_graph_color = branding.kind === 'sg' ? '#e9041e' : branding.kind === 'cdn' ? '#2e84b7' : '#e9041e'
    var secondary_graph_color = branding.kind === 'sg' ? '#777777' : branding.kind === 'cdn' ? '#add8e6' : '#777777'

    return (
      <div className={this.getItemClasses().join(' ')} >
        <div className="grid g1_root g1_1col">

          <h2>{data.h2}</h2>

          <h3># {t('kpis:h3_response')}</h3>
          <h4>{t('kpis:h4_response_av', placeholders)}</h4>
          <div style={{width:'100%', height:'200px'}}>
            <ResponseTimeSummaryGraph data={data.response_summary_graph_data} legend={t('kpis:legend_response')} prim_color={primary_graph_color} sec_color={secondary_graph_color}/>
          </div>

          {/*Month 1*/}
          <h4>{t('kpis:h4_m1')} : {t('kpis:h4_response_da')}</h4>
          <div style={{width:'100%', height:'400px'}}>
            <ResponseTimeGraph data={data.response_graph_data_m1} legend={t('kpis:legend_response')} prim_color={primary_graph_color} sec_color={secondary_graph_color}/>
          </div>

          <Visibility condition={branding.kind === "cdn" && data.h2 === "PISP vs BEL"}>
            <i>{t('kpis:temp_msg_cdn_pisp_graph_resp_m1')}</i>
          </Visibility>

          {/*Month 2*/}
          <h4>{t('kpis:h4_m2')} : {t('kpis:h4_response_da')}</h4>
          <div style={{width:'100%', height:'400px'}}>
            <ResponseTimeGraph data={data.response_graph_data_m2} legend={t('kpis:legend_response')} prim_color={primary_graph_color} sec_color={secondary_graph_color}/>
          </div>

          <Visibility condition={branding.kind === "cdn" && data.h2 === "PISP vs BEL"}>
            <i>{t('kpis:temp_msg_cdn_pisp_graph_resp_m2')}</i>
          </Visibility>

          {/*Month 3*/}
          <h4>{t('kpis:h4_m3')} : {t('kpis:h4_response_da')}</h4>
          <div style={{width:'100%', height:'400px'}}>
            <ResponseTimeGraph data={data.response_graph_data_m3} legend={t('kpis:legend_response')} prim_color={primary_graph_color} sec_color={secondary_graph_color}/>
          </div>

          <Visibility condition={branding.kind === "sg" && data.h2 === "AISP vs B@d"}>
            <i>{t('kpis:temp_msg_sg_aisp_graph_resp_m3')}</i>
          </Visibility>

          <Visibility condition={branding.kind === "sg" && data.h2 === "PISP vs B@d"}>
            <i>{t('kpis:temp_msg_sg_pisp_graph_resp_m3')}</i>
          </Visibility>

          <Visibility condition={branding.kind === "cdn" && data.h2 === "PISP vs BEL"}>
            <i>{t('kpis:temp_msg_cdn_pisp_graph_resp_m3')}</i>
          </Visibility>



          <h3># {t('kpis:h3_availability')}</h3>
          <h4>{t('kpis:h4_availability_av', placeholders)}</h4>
          <div style={{width:'100%', height:'200px'}}>
            <AvailabilitySummaryGraph data={data.availability_summary_graph_data} legend={t('kpis:legend_availability')} prim_color={primary_graph_color} sec_color={secondary_graph_color}/>
          </div>

          {/*Month 1*/}
          <h4>{t('kpis:h4_m1')} : {t('kpis:h4_availability_da')}</h4>
          <div style={{width:'100%', height:'400px'}}>
            <AvailabilityGraph data={data.availability_graph_data_m1} legend={t('kpis:legend_availability')} prim_color={primary_graph_color} sec_color={secondary_graph_color}/>
          </div>

          <Visibility condition={branding.kind === "cdn" && data.h2 === "PISP vs BEL"}>
            <i>{t('kpis:temp_msg_cdn_pisp_graph_avail_m1')}</i>
          </Visibility>

          {/*Month 2*/}
          <h4>{t('kpis:h4_m2')} : {t('kpis:h4_availability_da')}</h4>
          <div style={{width:'100%', height:'400px'}}>
            <AvailabilityGraph data={data.availability_graph_data_m2} legend={t('kpis:legend_availability')} prim_color={primary_graph_color} sec_color={secondary_graph_color}/>
          </div>

          <Visibility condition={branding.kind === "cdn" && data.h2 === "PISP vs BEL"}>
            <i>{t('kpis:temp_msg_cdn_pisp_graph_avail_m2')}</i>
          </Visibility>

          {/*Month 3*/}
          <h4>{t('kpis:h4_m3')} : {t('kpis:h4_availability_da')}</h4>
          <div style={{width:'100%', height:'400px'}}>
            <AvailabilityGraph data={data.availability_graph_data_m3} legend={t('kpis:legend_availability')} prim_color={primary_graph_color} sec_color={secondary_graph_color}/>
          </div>

          <Visibility condition={branding.kind === "sg" && data.h2 === "AISP vs B@d"}>
            <i>{t('kpis:temp_msg_sg_aisp_graph_avail_m3')}</i>
          </Visibility>

          <Visibility condition={branding.kind === "sg" && data.h2 === "PISP vs B@d"}>
            <i>{t('kpis:temp_msg_sg_pisp_graph_avail_m3')}</i>
          </Visibility>

          <Visibility condition={branding.kind === "cdn" && data.h2 === "PISP vs BEL"}>
            <i>{t('kpis:temp_msg_cdn_pisp_graph_avail_m3')}</i>
          </Visibility>


          <h3># {t('kpis:h3_failures')}</h3>

          {/*Month 1*/}
          <h4>{t('kpis:h4_m1')} : {t('kpis:h4_failures_da')}</h4>
          <div style={{width:'100%', height:'400px'}}>
            <FailureGraph data={data.failures_graph_data_m1} legend={t('kpis:legend_failures')} prim_color={primary_graph_color} sec_color={secondary_graph_color}/>
          </div>

          {/*Month 2*/}
          <h4>{t('kpis:h4_m2')} : {t('kpis:h4_failures_da')}</h4>
          <div style={{width:'100%', height:'400px'}}>
            <FailureGraph data={data.failures_graph_data_m2} legend={t('kpis:legend_failures')} prim_color={primary_graph_color} sec_color={secondary_graph_color}/>
          </div>

          {/*Month 3*/}
          <h4>{t('kpis:h4_m3')} : {t('kpis:h4_failures_da')}</h4>
          <div style={{width:'100%', height:'400px'}}>
            <FailureGraph data={data.failures_graph_data_m3} legend={t('kpis:legend_failures')} prim_color={primary_graph_color} sec_color={secondary_graph_color}/>
          </div>

        </div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'kpi-pool'
    ]
    return classes;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    redux: {
      lang_code: state.lang.lang_code
    }
  }
}

export default connect(
  mapStateToProps,
  null
)(withTranslation(
  'kpis'
)(KpiPool))
