import { createRoutine, promisifyRoutine } from 'redux-saga-routines'

// New Subscription
// Routine for Form
export const submitSubscriptionScopesFormNewRoutine = createRoutine('SUBMIT_SUBSCRIPTION_SCOPES_FORM_NEW')
export const submitSubscriptionScopesFinalFormNewHandler = promisifyRoutine(submitSubscriptionScopesFormNewRoutine)

// Delete Subscription
export const submitSubscriptionScopesFormDeleteRoutine = createRoutine('SUBMIT_SUBSCRIPTION_SCOPES_FORM_DELETE')

// Cancel Subscription
export const submitSubscriptionScopesFormCancelRoutine = createRoutine('SUBMIT_SUBSCRIPTION_SCOPES_FORM_CANCEL')
