import { takeEvery } from 'redux-saga/effects'
import { submitSubscriptionPlansFormNewRoutine, submitSubscriptionPlansFormDeleteRoutine} from './subscription_plans_routines'
import { submitNewSubscriptionPlansForm } from './subscription_plans_new_saga'
import {submitDeleteSubscriptionPlansForm} from "./subscription_plans_delete_saga";


const subscriptionPlansSagas = [
  takeEvery(submitSubscriptionPlansFormNewRoutine.TRIGGER, submitNewSubscriptionPlansForm),
  takeEvery(submitSubscriptionPlansFormDeleteRoutine.TRIGGER, submitDeleteSubscriptionPlansForm),
]
export default subscriptionPlansSagas
