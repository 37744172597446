import React from 'react'
import { connect } from '../../../utils/redux'
import { logRender } from '../../../utils/logging'
import { withTranslation } from 'react-i18next'
import Icon from '../../icon/Icon'
import { stateGetCartScopeCount } from "../../cart/cart_selectors"

class ItemCart extends React.Component {

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    var title = this.props.t('menu:cart_title')

    var cartScopeCount = []
    if (this.props.redux.cartScopeCount && this.props.redux.cartScopeCount > 0) {
      cartScopeCount.push(<span key="scopes" className="notif_count">{this.props.redux.cartScopeCount}</span>)
    }

    return (
      <React.Fragment>
        <Icon type="black_cart" />
        <span>{title}</span>
        {cartScopeCount}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    redux: {
      cartScopeCount: stateGetCartScopeCount(state),
    }
  }
}

export default connect(
  mapStateToProps,
  null,
  ['cart.scopeCount']
)(withTranslation(['menu'])(ItemCart))
