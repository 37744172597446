import { submitApplicationFormNewRoutine } from './application_routines'
import { put, select, call, delay } from 'redux-saga/effects'
import { getPortalCurrentOrganisationAndCatalogIds } from '../portal/portal_selectors'
import { stateFindResourceByIds } from '../resource/resource_selectors'
import { getFirstDef, isDef } from '../../utils/objtools'
import i18n from '../../utils/lang/i18n'
import { newItem } from '../item/item_new_saga.js'
import { modalAdd } from '../modals/modals_actions'
import genericFormSubmissionSaga from '../item/genericFormSubmissionSaga'
import branding from "../portal/branding";
function* saga(action) {
  const values = {...action.payload}

  // prepare some ids
  var ids = yield select(getPortalCurrentOrganisationAndCatalogIds)
  ids.consorg_id = values.relationship_consorg
  // Get the consorg to have its ids propagate in reloaded lists and for later go to the app URL
  var consorg = yield select(stateFindResourceByIds, 'consorg', ids)
  if (!consorg) {
    throw new Error('Consumer Organization not found.')
  }
  ids = {
    ...consorg.ids,
    ...ids
  }

  var body = {
    "name": values.name,
    "title": values.title,
    "description": values.description,
    "irtCode":  getFirstDef(values, 'irtCode', null),
    "trigramCode": getFirstDef(values, 'trigramCode', null),
    "organization": ids.organisation_id,
    "catalog": ids.catalog_id,
    "consumerOrganization": ids.consorg_id,
    // "managers": [],
    "universe": '3.0', // TODO !!!!
    credential: {}
  }
  const redirectUris = getFirstDef(values, 'credentialRedirectUris', '').split("\n").map((uri)=>uri.trim()).filter((uri)=>uri!=='')
  if (redirectUris.length > 0) {
    body.credential.redirectUris = redirectUris
  }
  const certificate = getFirstDef(values, 'credentialCertificate', null)
  if (certificate) {
    body.credential.certificate = certificate
  }

  const sealingCertificates = getFirstDef(values, 'sealingCertificates', null)
  if (sealingCertificates?.json && branding.open) {
    body.credential.sealingCertificates = sealingCertificates.json
  }

  const forcedAdapter = getFirstDef(values, 'forcedAdapter', null)
  if (forcedAdapter && !branding.open) {
    body.credential.forcedAdapter = forcedAdapter
  }

  // Create the application
  var {error, data} = yield call(newItem, 'application_new', 'application', ids, body)

  // Generic unhandled error
  if (error)
    throw error

  // TODO handle specific erros (duplicate name)
  // var submission_error = {name: 'The consumer organization name already exists in the catalog'}
  // return submission_error

  // Check created
  if (!data.name)
    throw new Error('No name found in the newly created application.')
  if (!isDef(data, 'credential.id'))
    throw new Error('No id found in the newly created credential.')
  if (!isDef(data, 'credential.secret'))
    throw new Error('No secret found in the newly created credential.')
  if (!isDef(data, 'consumerOrganization'))
    throw new Error('No consumerOrganization id in the newly created application.')
  if (data.consumerOrganization !== ids.consorg_id)
    throw new Error('Consumer Organization id mismatch in the newly created application.')

  // notify about credential client_id/secret
  yield put(modalAdd(
    'secret',
    {
      title: i18n.t('credential:secret_modal_title'),
      message: i18n.t('credential:secret_modal_message'),
      client_id: data.credential.id,
      secret: data.credential.secret
    }
  ))

  // Extend ids
  ids = {
    ...ids,
    application_uname: data.name,
    application_id: data.id,
    tab_code: 'overview'
  }

  // go to the newly created application
  yield delay(500) // A tiny tempo to hide flashing "Not Found"
  yield put({type: 'HISTORY_PUSH', scheme_key:'application', ids})
}

export function* submitNewApplicationForm(action) {
  yield call(genericFormSubmissionSaga, {
    saga,
    action,
    routine: submitApplicationFormNewRoutine,
    successfull_title: 'application:successfull_create_title',
    successfull_body: 'application:successfull_create_body',
    unsuccessfull_title: 'application:unsuccessfull_create_title',
    unsuccessfull_body: 'application:unsuccessfull_create_body'
  })
}
