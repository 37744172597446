/**
 * Add a new modal
 */
export function modalAdd(modalType, modalProps) {
  return {
    type: 'MODAL_ADD',
    modalProps: modalProps,
    modalType: modalType
  }
}

/**
 * Mark a modal as displayed
 */
export function modalDisplayed(modal_id) {
  return {
    type: 'MODAL_DISPLAYED',
    modal_id
  }
}