import React from 'react'
import { logRender } from '../../../utils/logging'

class Pane extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      opened: false
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.state.opened !== nextState.opened
      || this.props.opened !== nextProps.opened
    )
  }

  render() {
    logRender(this)

    if (this.state.opened !== this.props.opened) {
      setTimeout(()=>{
        this.setState({
          opened: this.props.opened
        })
      },10)
    }

    var content = null
    if (this.state.opened || this.props.content_prepared) {
      content = this.props.children
    }

    return (
      <div className={this.getItemClasses().join(' ')} >
        <div className="pane_content">{content}</div>
        <div className="pane_overlay" onClick={this.props.onClickOverlay}></div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'ui-pane'
    ]
    if (this.state.opened)
      classes.push('opened')
    return classes;
  }
}

export default Pane;