
import { submitMembershipFormEditRoutine } from './membership_routines'
import { call, select, put } from 'redux-saga/effects'
import { alterItem } from '../item/item_alter_saga.js'
import genericFormSubmissionSaga from '../item/genericFormSubmissionSaga'
import { stateAuthUserId } from '../auth/auth_selectors'
import { isDef } from '../../utils/objtools'
import { buildExternalResourceInfos } from '../../utils/urls/external'

function* saga(action) {
  const {values, modalInfos} = action.payload

  var body = {
    "role": values.membershipRole
  }

  // prepare some ids
  var ids = {...values.ids }

  // Alter the membership
  const {error, data} = yield call(alterItem, 'consorg_membership_alter', 'consorg_membership', ids, body)

  // Generic unhandled error
  if (error)
    throw error

  // Check altered
  if (!data.id)
    throw new Error('Missing id in the edited membership.')
  if (!isDef(data, 'user.id'))
    throw new Error('Missing user.id in the edited membership.')

  // Check if the current user has modified its own membership
  const currentUserId = yield select(stateAuthUserId)
  if (data.user.id === currentUserId) {
    // reload consorg list, this will cascade trigger user rights on COs
    var external_resource_infos_catalog_consorgs = buildExternalResourceInfos('catalog_consorgs', ids)
    yield put({type: 'RESOURCE_LOAD_REQUEST', external_resource_infos: external_resource_infos_catalog_consorgs, force:true})
  }

  modalInfos.closeModal()
}

export function* submitEditMembershipForm(action) {
  yield call(genericFormSubmissionSaga, {
    saga,
    action,
    routine: submitMembershipFormEditRoutine,
    successfull_title: 'membership:successfull_edit_title',
    successfull_body: 'membership:successfull_edit_body',
    unsuccessfull_title: 'membership:unsuccessfull_edit_title',
    unsuccessfull_body: 'membership:unsuccessfull_edit_body'
  })
}