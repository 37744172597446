import { put, select, call } from 'redux-saga/effects'
import { getPortalOrganisationSparkyManagerEndpoint, getPortalCurrentOrganisationId, stateIsPortalDebug } from '../portal/portal_selectors'
import { putUrlJson } from '../../utils/fetch'
import { getFirstDef } from '../../utils/objtools'
import { buildExternalResourceInfos, buildExternalResourceUri, external_resource_schemes } from '../../utils/urls/external'
import HttpStatusError from '../../utils/error/HttpStatusError'

// Update item (PUT)
// The saga is designed for calls from parent saga, not for action triggering

export function* editItem(edit_scheme_key, scheme_key, ids, body) {
  var saga_error = null
  var url = null
  var status = null
  var data = null

  try {
    const sparky_manager_api_endpoint = yield select(getPortalOrganisationSparkyManagerEndpoint)
    if (!sparky_manager_api_endpoint)
      throw new Error("Could not get manager's api endpoint.")
    const organization_id = yield select(getPortalCurrentOrganisationId)
    if (!organization_id)
      throw new Error("Could not get organization_id.")

    var uri = yield buildExternalResourceUri(edit_scheme_key, ids)
    if (!uri)
      throw new Error("Could not build the edit uri.")

    url = sparky_manager_api_endpoint + uri

    const {fetch_response, fetch_error} = yield call(putUrlJson, url, body, organization_id)
    if (fetch_error)
      throw fetch_error
    if (!fetch_response)
      throw new Error ('Could not get response')
    status = fetch_response.status
    if (fetch_response.status !== 200) {
      throw new HttpStatusError(fetch_response.status)
    }
    data = yield fetch_response.json()

    if (!data.id)
      throw new Error("No id found in the edited item.")
    if (ids[scheme_key+'_id'] !== data.id)
      throw new Error('Ids mismatch.')

    // Store refresh when requested by edit scheme
    const edit_scheme = external_resource_schemes[edit_scheme_key]
    for (var refresh_force_resource_scheme_key of getFirstDef(edit_scheme, 'refresh_force_resources', [])) {
      var external_resource_infos_to_refresh = buildExternalResourceInfos(refresh_force_resource_scheme_key, ids)
      yield put({type: 'RESOURCE_LOAD_REQUEST', external_resource_infos: external_resource_infos_to_refresh, force:true})
    }
    // Event
    // var external_resource_infos = yield buildExternalResourceInfos(scheme_key, ids)
    // if (external_resource_infos)
    //   yield put({type: 'RESOURCE_UPDATED', external_resource_infos})

  }
  catch (catched_error) {
    saga_error = catched_error
  }

  if (yield select(stateIsPortalDebug)) {
    yield put({type: 'LOG_EXTERNAL_QUERY', scheme_key: edit_scheme_key, url, status, has_error: saga_error?true:false, has_data: data?true:false})
  }

  if (saga_error) {
    yield put({type: 'ITEM_QUERY_ERROR', query_scheme_key: edit_scheme_key, error: saga_error})
    return {error: saga_error}
  }

  return {data}
}