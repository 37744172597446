import React from 'react'
import {connect} from 'react-redux'
import {logRender} from '../../../utils/logging'
import {withStaticContentCode} from '../../static_content/static_content_hoc'
import {deepEqualObjs2, getFirstDef} from '../../../utils/objtools'
//import AnimDelay from "../../ui/AnimDelay/AnimDelay";
//import Icon from "../../icon/Icon";
import UiButton from "../../ui/buttons/UiButton";
import {withTranslation} from "react-i18next";
import AnimDelay from "../../ui/AnimDelay/AnimDelay";

//import JsonExpandable from "../../ui/JsonExpandable/JsonExpandable";


class PushProducts extends React.Component {

    shouldComponentUpdate(nextProps, nextState) {

        return (
            !deepEqualObjs2(this.props.staticContent.data, nextProps.staticContent.data)
            || !deepEqualObjs2(this.props.redux, nextProps.redux)
        )
    }


    render() {
        logRender(this);
        if (!this.props.tReady)
            return null

        let products = [];

        for (let product in this.props.staticContent.data.products) {
            products.push(this.props.staticContent.data.products[product]);
        }

        return (
            <div className={this.getItemClasses().join(' ')}>
                <AnimDelay renderDelay={500} animateAppearance={true} showDelay={800}>
                    <div className="grid g1_root g1_1col">
                        <h2>{getFirstDef(this.props, 'staticContent.data.title', '&nbsp;')}</h2>

                        <div className="grid g1_321col spaceGrid">

                            {products.map(({id, name, description, json_label, pane}) => (
                                <div className={[id, "product"].join(' ')} key={id}>
                                    <header className="item">
                                        <div className={["icon", id].join(' ')} title={name}></div>
                                        <h3>{name}</h3>
                                    </header>
                                    <p className="description">{description}</p>

                                </div>
                            ))}

                        </div>

                        {this.props.staticContent.data.btn_label !== "" && (
                            <div className="show_all">
                                <UiButton onClick={() => this.handleClick('product')}
                                          variant="secondary">{getFirstDef(this.props, 'staticContent.data.btn_label', '&nbsp;')}</UiButton>
                            </div>)}

                    </div>
                </AnimDelay>
            </div>
        )
    }

    handleClick(target) {
        this.props.finderGo(target)
    }

    getItemClasses() {
        var classes = [
            'push',
            'products'
        ]
        return classes;
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        redux: {
            lang_code: state.lang.lang_code
        }
    }
}

const mapDispatchToProps = dispatch => ({
    finderGo: (target) => {
        dispatch({type: 'FINDER_GO', target})
    }
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStaticContentCode(
    'push_products'
)
(withTranslation(
    'api')
(PushProducts)))
