import React from 'react'
import { connect } from 'react-redux'
import { logRender } from '../../utils/logging'
import { withTranslation } from 'react-i18next'
import { deepEqualObjs2,getFirstDef } from '../../utils/objtools'
import ApplicationFinalForm from './ApplicationFinalForm'
import { submitApplicationFinalFormEditHandler } from './application_routines'
import { bindPromiseCreators } from 'redux-saga-routines'

class ApplicationEdit extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || !deepEqualObjs2(this.props.application, nextProps.application)
      || this.props.tReady !== nextProps.tReady
    )
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    if (!this.props.application.loaded)
      return null

    var initialValues = {
      // Hidden
      ids: this.props.application.ids,
      // Fields
      title: getFirstDef(this.props.application.data, 'title', null),
      name: getFirstDef(this.props.application.data, 'name', null),
      description: getFirstDef(this.props.application.data, 'description', null),
      irtCode: getFirstDef(this.props.application.data, 'irtCode', null),
      trigramCode: getFirstDef(this.props.application.data, 'trigramCode', null),
    }

    return (
      <div className={this.getItemClasses().join(' ')} >
        <h2>{this.props.t('application:tab_edit_title')}</h2>
        <ApplicationFinalForm form_op="edit" onSubmit={this.props.submitApplicationFinalFormEditHandler} initialValues={initialValues}  />
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'application',
      'form_edit',
      'form_page'
    ]
    return classes
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    redux: {
      lang_code: state.lang.lang_code
    }
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindPromiseCreators({
    submitApplicationFinalFormEditHandler
  }, dispatch)
})

ApplicationEdit = withTranslation(
  ['common','application']
)(ApplicationEdit)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationEdit)
