import React from 'react'
import { Form } from 'react-bootstrap'
import Select from 'react-select'
import DOMpurify from 'dompurify'

const ControlSelectSearchable = ({ input, meta, options, isDisabled }) => {
    const escapedValue = DOMpurify.sanitize(input.value)
  // TODO NTH handle the warning case if needed (not handled in bootstrap ?)
  options = Object.keys(options).map(value => ({
    value,
    label: options[value]
  }))

  return (
    <div>
      <Select {...input}
              value={escapedValue}
              isInvalid={
                (meta.touched && (meta.error || meta.warn))
                || (meta.submitError && !meta.dirtySinceLastSubmit)
              }
              options={options}
              isDisabled= {isDisabled}
              placeholder="Type a word or few letters..."
      />
      {meta.touched &&
        ((meta.error && <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>) ||
         (meta.warning && <Form.Control.Feedback type="invalid">{meta.warning}</Form.Control.Feedback>))}

      {meta.submitError && !meta.dirtySinceLastSubmit ? <Form.Control.Feedback type="invalid">{meta.submitError}</Form.Control.Feedback> : null}

    </div>
  )
}

export default ControlSelectSearchable
