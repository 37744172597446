import React from 'react'
import { connect } from 'react-redux'
import { logRender } from '../../../utils/logging'
import { withTranslation } from 'react-i18next'
import UiH1 from '../titles/UiH1'

class LoginError extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      JSON.stringify(this.props.redux) !== JSON.stringify(nextProps.redux)
      || this.props.tReady !== nextProps.tReady
    )
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    return (
      <div className="login-error">
        <div className="grid g1_root g1_1col">
          <UiH1>{this.props.t('loginerror:title')}</UiH1>
          <p>{this.props.t('loginerror:message')}</p>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    lang_code: state.lang.lang_code
  }
  return {
    redux
  }
}

export default connect(
  mapStateToProps,
  null
)(withTranslation('loginerror')(LoginError));
