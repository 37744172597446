import React from 'react'
import { logRender } from '../../../utils/logging'
import ReactJson from 'react-json-view'
import { deepEqualObjs2 } from '../../../utils/objtools'

class ReactJsonSafe extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props, nextProps)
    )
  }

  render() {
    logRender(this)

    var jsonobject = {}
    if (this.props.src)
      try {
        jsonobject = JSON.parse(JSON.stringify(this.props.src)) // stringify/parse to solve a weird error in the component...
      } catch (e) {
        jsonobject = { 'error': 'Parsing Exception' }
      }
    return <ReactJson
        src={jsonobject}
        theme={this.props.theme || 'colors'}
        name={false} displayDataTypes={false}
        enableClipboard={this.props.enableClipboard || false}
        collapsed={this.props.collapsed || 2}
    />
  }
}

export default ReactJsonSafe;