var branding

switch (process.env.REACT_APP_PORTAL_KIND) {
  case "cdn":
    branding = {
      kind: 'cdn',
      theme: 'cdn',
      open: true
    }
    break
  case "sg":
    branding = {
      kind: 'sg',
      theme: 'sg',
      open: true
    }
    break
  case "int":
    branding = {
      kind: 'int',
      theme: 'sg',
      open: false
    }
    break
  case "sgf":
    branding = {
      kind: 'sgf',
      theme: 'sg',
      open: false
    }
    break
  case "baas":
    branding = {
      kind: 'baas',
      theme: 'baas',
      open: true
    }
    break
  case "assu-group":
    branding = {
      kind: 'assu-group',
      theme: 'sg',
      open: false
    }
    break
  case "assu-partners":
    branding = {
      kind: 'assu-partners',
      theme: 'sg',
      open: true
    }
    break
  case "cgi-intranet":
    branding = {
      kind: 'cgi-intranet',
      theme: 'sg',
      open: false
    }
    break
  case "cgi-extranet":
    branding = {
      kind: 'cgi-extranet',
      theme: 'sg',
      open: true
    }
    break
  case "sgbdp-internal":
    branding = {
      kind: 'sgbdp-internal',
      theme: 'sg',
      open: false
    }
    break
  case "sgbdp-open":
    branding = {
      kind: 'sgbdp-open',
      theme: 'sg',
      open: true
    }
    break
  case "bfcoi-internal":
    branding = {
      kind: 'bfcoi-internal',
      theme: 'sg',
      open: false
    }
    break
  case "bfcoi-open":
    branding = {
      kind: 'bfcoi-open',
      theme: 'sg',
      open: true
    }
    break
  case "sgnc-internal":
    branding = {
      kind: 'sgnc-internal',
      theme: 'sg',
      open: false
    }
    break
  case "sgnc-open":
    branding = {
      kind: 'sgnc-open',
      theme: 'sg',
      open: true
    }
    break
  default:
    throw new Error('unexpected REACT_APP_PORTAL_KIND value')
}
branding.env = process.env.NODE_ENV

console.log('branding', branding)

export default branding