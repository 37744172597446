import React from 'react'
import { connect } from '../../../utils/redux'
import { logRender } from '../../../utils/logging'
import Icon from '../../icon/Icon'
import { Link } from 'react-router-dom'
import { buildUri } from '../../../utils/urls/internal'
import { isDefVal } from '../../../utils/objtools'
import { stateFindResourceByIds } from '../../resource/resource_selectors'

class ItemLang extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return false
  }

  render() {
    logRender(this)

    return (
      <div className={this.getItemClasses().join(' ')}>
        <Lang lang_code="en" />
        <Lang lang_code="fr" />
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'item-lang'
    ]
    return classes;
  }
}

export default ItemLang


class _Lang extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      JSON.stringify(this.props.redux) !== JSON.stringify(nextProps.redux)
    )
  }

  render() {
    logRender(this)

    if (!this.props.redux.ids)
      return null

    var target_uri = buildUri(this.props.redux.scheme_key, this.props.redux.ids) + this.props.redux.route_search

    return (
      <div className={this.getItemClasses().join(' ')}>
        <Link to={target_uri} >
          <Icon type={this.props.lang_code} />
          {/* <span className="label">{this.props.lang_code}</span> */}
        </Link>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'lang'
    ]
    classes.push('lang-'+this.props.lang_code)
    if (this.props.redux.lang_code === this.props.lang_code)
      classes.push('active')
    return classes;
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    lang_code: state.lang.lang_code,
    route_ids: isDefVal(state.portal.page, 'routeProps.match.params'),
    route_search: isDefVal(state.portal.page, 'routeProps.location.search'),
    scheme_key: isDefVal(state.portal.page, 'scheme.key'),
    current_resource_ids: isDefVal(state.portal.resource, 'external_resource_infos.ids'),
    current_resource_scheme_key : isDefVal(state.portal.resource, 'external_resource_infos.scheme_key'),
    ids: null
  }
  // Trying to find the target resource in the requested language, otherwise we use route ids
  if (redux.route_ids) {
    var target_resource
    var target_resource_ids = { ...redux.current_resource_ids, lang_code: ownProps.lang_code}
    if (redux.current_resource_scheme_key && (target_resource = stateFindResourceByIds(state, redux.current_resource_scheme_key, target_resource_ids)))
      redux.ids = {...redux.route_ids, ...target_resource.ids}
    else
      redux.ids = {...redux.route_ids, lang_code: ownProps.lang_code}
  }
  return {
    redux
  }
}

const Lang = connect(
  mapStateToProps,
  null,
  ['item.items','lang','portal.page','portal.resource']
)(_Lang);