import React from 'react'
import { connect } from 'react-redux'
import { logRender } from '../../utils/logging'
import { withTranslation } from 'react-i18next'
import { isDef, deepEqualObjs2, getFirstDef } from '../../utils/objtools'
import { mapResourceFromCode } from '../resource/resource_selectors'
import ItemGFMDescription from '../ui/ItemGFMDescription/ItemGFMDescription'
import UiProperty from '../ui/UiProperty/UiProperty'
import {getPortalCurrentCatalogParam} from '../portal/portal_selectors'
import { resourceVisibilities_options } from '../../utils/enums/resourceVisibilities'
import { membershipVisibilities_options } from '../../utils/enums/membershipVisibilities'
import { allowMembershipRequest, allowMembershipRequest_options } from '../../utils/enums/allowMembershipRequest'
import { stateGetUserConsorgRole, stateGetUserConsorgMembershipResourceCode, stateIsResourceCodeSelectedConsorg, stateIsUserBelongsConsorg } from './consorg_selectors'
import { stateAuthIsUserLoggedIn, stateAuthUserHasMemberships } from '../auth/auth_selectors'
import { membershipRoles } from '../../utils/enums/membershipRoles'
import Membership from '../membership/Membership'
import UiButton from '../ui/buttons/UiButton'
import RequestMembershipFinalForm from "../membership/RequestMembershipFinalForm";
import Button from "react-bootstrap/Button";
import {bindPromiseCreators} from "redux-saga-routines";
import {submitRequestMembershipFinalFormNewHandler} from "../membership/membership_routines";
import {modalAdd} from "../modals/modals_actions";

class ConsorgOverview extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || this.props.tReady !== nextProps.tReady
    )
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    var presentation = null
    var presentation1 = []
    var presentation2 = []
    if (isDef(this.props.redux, 'consorg.consolidated.description'))
      presentation1.push(<ItemGFMDescription key="description" title="Description" content={this.props.redux.consorg.consolidated.description} />)
    //presentation2.push(<Button variant="primary" onClick={()=>this.historyPush('consorg_new')}>{this.props.t('consorg:edit_button')}</Button>)
    if (presentation1.length || presentation2.length)
      presentation = (
        <div className="item_full_presentation">
          <div className="presentation_content gridg g1_2t1t_2_1">
            <div key="presentation1">{presentation1}</div>
            <div key="presentation2">{presentation2}</div>
          </div>
        </div>
      )

    var actions = null
    if (!this.props.redux.consorg_active && this.props.redux.isUserBelongsConsorg) {
      actions =
        <React.Fragment>
          <h2>{this.props.t('consorg:tab_overview_actions')}</h2>
          <p>{this.props.t('consorg:tab_overview_actions_desc')}</p>
          <UiButton key="select_current_consorg" variant="secondary" onClick={()=>this.props.consorgSelectCurrent(this.props.resourceCode)} >{this.props.t('consorg:select_as_current_consorg')}</UiButton>
        </React.Fragment>
    }

    var request = null
    if (this.props.redux.userHasMemberships !== null && !this.props.redux.isUserBelongsConsorg && this.props.redux.consumerOrgsConfiguration.allowMembershipRequest === allowMembershipRequest.LOGGED_USERS) {
      request = (<Button variant="secondary" onClick={()=>this.createClick()}>{this.props.t('membership:request_membership_button')}</Button>)
    }

    var resource_properties = (
      <React.Fragment>
        <h2>{this.props.t('common:properties')}</h2>
        <div className="properties_list">
          <UiProperty key="id" name="id" value={getFirstDef(this.props.redux.consorg, 'data.id', '-')} />
          <UiProperty key="name" name={this.props.t('forms:field_name')} value={getFirstDef(this.props.redux.consorg, 'data.name', '-')} />
          <UiProperty key="organization" name={this.props.t('common:organization')} value={getFirstDef(this.props.redux.consorg, 'data.organization', '-')} />
          <UiProperty key="catalog" name={this.props.t('common:catalog')} value={getFirstDef(this.props.redux.consorg, 'data.catalog', '-')} />
        </div>
      </React.Fragment>
    )

    var catalog_properties = []
    if (this.props.redux.consumerOrgsConfiguration) {
      catalog_properties = (
        <React.Fragment>
          <h3>{this.props.t('consorg:catalog_properties')}</h3>
          <div className="properties_list">
            <p>{this.props.t('consorg:catalog_properties_desc')}</p>
            <UiProperty key="canOwnersDelete" name={this.props.t('consorg:field_canOwnersDelete')} value={this.props.redux.consumerOrgsConfiguration.canOwnersDelete?this.props.t('common:yes'):this.props.t('common:no')} />
            <UiProperty key="canOwnersEdit" name={this.props.t('consorg:field_canOwnersEdit')} value={this.props.redux.consumerOrgsConfiguration.canOwnersEdit?this.props.t('common:yes'):this.props.t('common:no')} />
            <UiProperty key="canUsersCreate" name={this.props.t('consorg:field_canUsersCreate')} value={this.props.redux.consumerOrgsConfiguration.canUsersCreate?this.props.t('common:yes'):this.props.t('common:no')} />
            <UiProperty key="resourceVisibility" name={this.props.t('consorg:field_resourceVisibility')} value={this.props.t(resourceVisibilities_options[this.props.redux.consumerOrgsConfiguration.resourceVisibility])} />
            <UiProperty key="membershipVisibility" name={this.props.t('consorg:field_membershipVisibility')} value={this.props.t(membershipVisibilities_options[this.props.redux.consumerOrgsConfiguration.membershipVisibility])} />
            <UiProperty key="allowMembershipRequest" name={this.props.t('consorg:field_allowMembershipRequest')} value={this.props.t(allowMembershipRequest_options[this.props.redux.consumerOrgsConfiguration.allowMembershipRequest])} />
          </div>
        </React.Fragment>
      )
    }

    var role_summary = []
    if (this.props.redux.authIsUserLoggedIn === false) {
      role_summary.push( <p key="role_summary">{this.props.t('consorg:my_role_not_connected')}</p> )
    }
    else if (this.props.redux.authIsUserLoggedIn === true) {
      if ( this.props.redux.userRole === membershipRoles.OWNER) {
        role_summary.push( <p key="role_summary">{this.props.t('consorg:my_role_owner')}</p> )
      }
      else if ( this.props.redux.userRole === membershipRoles.MEMBER) {
        role_summary.push( <p key="role_summary">{this.props.t('consorg:my_role_member')}</p> )
      }
      else if (this.props.redux.userHasMemberships !== null) {
        role_summary.push( <p key="role_summary">{this.props.t('consorg:my_role_not_belongs')}</p> )
      }
    }
    if (this.props.redux.membershipResourceCode) {
      role_summary.push( <Membership key="membership" itemType="consorg_membership" resourceCode={this.props.redux.membershipResourceCode} /> )
    }

    return (
      <div className={this.getItemClasses().join(' ')} >
        <div className="grid g1_root g1_1col">
          {presentation}
          <div className="grid g1_211col">
            <div>
              {role_summary.length > 0 &&
                <React.Fragment>
                  <h2>{this.props.t('consorg:my_role')}</h2>
                  {role_summary}
                  {request}
                </React.Fragment>
              }
            </div>
            <div>
              {actions}
              {resource_properties}
              {catalog_properties}
            </div>
          </div>
        </div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'consorg',
      'overview'
    ]
    return classes
  }

  createClick() {
    this.props.modalAdd(
      'form',
      {
        title: this.props.t('membership:request_modal_title'),
        message: this.props.t('membership:request_modal_message'),
        FormComponent: RequestMembershipFinalForm,
        formComponentProps: {
          form_op: "new",
          initialValues: {
            ids: this.props.redux.consorg.ids,
            membershipRole: membershipRoles.MEMBER
          }
        },
        submitAction: this.props.submitRequestMembershipFinalFormNewHandler,
        closeAction: null
      }
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    consorg: mapResourceFromCode(state, 'consorg', ownProps.resourceCode),
    consorg_active: stateIsResourceCodeSelectedConsorg(state, ownProps.resourceCode),
    isUserBelongsConsorg: stateIsUserBelongsConsorg(state, ownProps.resourceCode),
    lang_code: state.lang.lang_code,
    consumerOrgsConfiguration: getPortalCurrentCatalogParam(state, 'consumerOrgsConfiguration'),
    authIsUserLoggedIn: stateAuthIsUserLoggedIn(state),
    userHasMemberships: stateAuthUserHasMemberships(state),
    userRole: stateGetUserConsorgRole(state, ownProps.resourceCode),
    membershipResourceCode: stateGetUserConsorgMembershipResourceCode(state, ownProps.resourceCode)
  }

  return {
    redux
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindPromiseCreators({
    submitRequestMembershipFinalFormNewHandler,
    // other promise creators can be here...
  }, dispatch),
  modalAdd: (modalType, modalProps) => dispatch(modalAdd(modalType, modalProps)),
  consorgSelectCurrent: (resourceCode) => dispatch({type:'SELECT_CURRENT_CONSORG', resourceCode})
})

ConsorgOverview = withTranslation(['common','consorg','enums','forms','membership'])(ConsorgOverview)

export default connect(mapStateToProps, mapDispatchToProps)(ConsorgOverview)
