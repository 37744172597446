import React from 'react'
import { connect } from '../../utils/redux'
import { mapResourceFromIds } from '../resource/resource_selectors'
import { deepEqualObjs2 } from '../../utils/objtools'
import { logRender } from '../../utils/logging'

// We use a wrapper component to hide connect stuff from the wrapped component
// (and then allow it to have its own connection to state)

class _StaticContentLoader extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
    )
  }

  componentDidMount() {
    this.postRender()
  }

  componentDidUpdate() {
    this.postRender()
  }

  postRender() {
    if (this.props.redux.resource.need_request) {
      this.props.requestResource(this.props.redux.resource.external_resource_infos)
    }
  }

  render() {
    logRender(this)

    return (
      <React.Fragment>
        {React.Children.map(this.props.children, (child) => {
          return React.cloneElement(child, {staticContent: this.props.redux.resource})
        })}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    redux: {
      resource: mapResourceFromIds(state, 'static_content', {static_content_code: ownProps.staticContentCode, lang_code: state.lang.lang_code}),
      lang_code: state.lang.lang_code
    }
  }
}

const mapDispatchToProps = dispatch => ({
  requestResource: (external_resource_infos) => dispatch({type:'RESOURCE_LOAD_REQUEST', external_resource_infos})
})

const StaticContentLoader = connect(
  mapStateToProps,
  mapDispatchToProps,
  ['lang','item.items.static_content']
)(_StaticContentLoader)


/*
* HOC injects staticContent using staticContentCode prop
**/
export function withStaticContent(WrappedComponent) {

  return class extends React.Component {

    render() {
      // Filter out extra props that are specific to this HOC and shouldn't be passed through
      const { staticContentCode, staticContent, ...passThroughProps } = this.props

      return (
        <StaticContentLoader staticContentCode={staticContentCode} >
          <WrappedComponent {...passThroughProps} />
        </StaticContentLoader>
      )
    }
  }
}


/*
* HOC injects staticContent using staticContentCode parameter
**/
export const withStaticContentCode = (staticContentCode) => (WrappedComponent) => {

  return class extends React.Component {

    render() {
      // Filter out extra props that are specific to this HOC and shouldn't be passed through
      const { staticContent, ...passThroughProps } = this.props

      return (
        <StaticContentLoader staticContentCode={staticContentCode} >
          <WrappedComponent {...passThroughProps} />
        </StaticContentLoader>
      )
    }
  }
}