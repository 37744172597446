import { buildExternalResourceAliases, buildExternalResourceCode, external_resource_schemes } from '../../utils/urls/external'
import { isDefVal2, isDef } from '../../utils/objtools'
import { stateFindItemByCodeOrAlias, stateFindItemResourceCodeFromAlias } from '../item/item_selectors'
import { stateFindListByCodeOrAlias, stateFindListResourceCodeFromAlias } from '../list/list_selectors'

/**
 * Given a scheme_key and ids, we try to find an existing resource code
 */
export function stateFindResourceCodeByIds(state, scheme_key, ids) {
  var resource_code = buildExternalResourceCode(scheme_key, ids)
  if (resource_code)
    return resource_code
  // could not build the resource code with provided ids, try to build and find an alias
  for (var alias of buildExternalResourceAliases(scheme_key, ids)) {
    if ((resource_code = stateFindItemResourceCodeFromAlias(state, scheme_key, alias)))
      return resource_code
    if ((resource_code = stateFindListResourceCodeFromAlias(state, scheme_key, alias)))
      return resource_code
  }
  return null
}

/**
 * Given a scheme_key and ids, we try to find an existing resource item by building its resource code or resource alias in items (state.items)
 */
export function stateFindResourceByIds(state, scheme_key, ids) {
  var resource_code = stateFindResourceCodeByIds(state, scheme_key, ids)
  if (resource_code)
    return stateFindResourceByCodeOrAlias(state, scheme_key, resource_code)
  return null
}

/**
 * Given a scheme_key and resource_code/alias, try to find existing resource
 */
export function stateFindResourceByCodeOrAlias(state, scheme_key, resource_code) {
  var scheme = isDefVal2(external_resource_schemes, scheme_key)
  if (!scheme || !resource_code)
    return null
  switch(scheme.type) {
    case 'item':
      return stateFindItemByCodeOrAlias(state, scheme_key, resource_code)
    case 'list':
      return stateFindListByCodeOrAlias(state, scheme_key, resource_code)
    default:
      return null
  }
}

/**
 * Map a resource in connected component from its scheme_key and ids
 */
export function mapResourceFromIds(state, scheme_key, ids) {
  var resourceCode = stateFindResourceCodeByIds(state, scheme_key, ids)
  return mapResourceFromCode(state, scheme_key, resourceCode)
}

/**
 * Map a resource in connected component from its scheme_key and resourceCode or Alias
 */
export function mapResourceFromCode(state, scheme_key, resourceCode) {
  var mappedResource = {
    found: false,
    loaded: false,
    loading: false,
    error: false,
    need_request: false,
    consolidated: {},
    data: {},
    itemsResourceCodes: [],
    items: [],
    external_resource_infos: null,
    resource_code: null,
    ids: {},
  }
  var resource
  if ((resource = stateFindResourceByCodeOrAlias(state, scheme_key, resourceCode))) {
    mappedResource.found = true
    mappedResource.loaded = resource.status==='loaded'
    mappedResource.loading = resource.status==='loading'
    mappedResource.error = resource.status==='error'
    mappedResource.need_request = ['loaded','loading','error'].indexOf(resource.status) === -1
    mappedResource.consolidated = resource.consolidated
    mappedResource.external_resource_infos = resource.external_resource_infos
    // TODO find a way to load directly data in item
    mappedResource.list_data = resource.data
    switch(resource.external_resource_infos.scheme.type) {
      case 'item':
        mappedResource.data = resource.data
        break
      case 'list':
        mappedResource.itemsResourceCodes = resource.itemsResourceCodes
        if (isDef(mappedResource, 'external_resource_infos.scheme.item_type')) {
          for (var itemResourceCode of mappedResource.itemsResourceCodes) {
            mappedResource.items.push( mapResourceFromCode(state, mappedResource.external_resource_infos.scheme.item_type, itemResourceCode) )
          }
        }
        break
      default:
        break
    }
    mappedResource.resource_code = resource.external_resource_infos.resource_code
    mappedResource.ids = resource.ids
  }
  return mappedResource
}
