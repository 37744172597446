import React from 'react'
import {logRender} from '../../utils/logging'
import Icon from "../icon/Icon";


class Filter extends React.Component {

  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this)

  }

  render() {
    logRender(this);

    var label = this.props.label
    var type = this.props.type
    var icon = this.props.icon

    return (
      <div className={this.getItemClasses().join(' ')} onClick={() => this.handleClick(type, label)}>
        {label && label.length > 0 &&
          label
        }
        {icon && icon.length > 0 &&
          <Icon type={icon} />
        }
      </div>
    )
  }

  handleClick(type, label){
    this.props.clickHandler(type, label);
  }

  getItemClasses() {
    var classes = [
      'filter',
      'item'
    ];
    if (this.props.isActive) {
      classes.push('checked')
    }
    return classes;
  }
}

export default Filter;

