import { takeEvery } from 'redux-saga/effects'
import { submitCredentialFormNewRoutine, submitCredentialFormEditRoutine, submitCredentialFormDeleteRoutine, submitCredentialFormResetRoutine, submitCredentialFormVerifyRoutine } from './credential_routines'
import { submitNewCredentialForm } from './credential_new_saga'
import { submitEditCredentialForm } from './credential_edit_saga'
import { submitDeleteCredentialForm } from './credential_delete_saga'
import { submitResetCredentialForm } from './credential_reset_saga'
import { submitVerifyCredentialForm } from './credential_verify_saga'

const credentialSagas = [
  takeEvery(submitCredentialFormNewRoutine.TRIGGER, submitNewCredentialForm),
  takeEvery(submitCredentialFormEditRoutine.TRIGGER, submitEditCredentialForm),
  takeEvery(submitCredentialFormDeleteRoutine.TRIGGER, submitDeleteCredentialForm),
  takeEvery(submitCredentialFormResetRoutine.TRIGGER, submitResetCredentialForm),
  takeEvery(submitCredentialFormVerifyRoutine.TRIGGER, submitVerifyCredentialForm),
]
export default credentialSagas
