import React from 'react'
import {connect} from 'react-redux'
import {logRender} from '../../../utils/logging'
import {withStaticContentCode} from '../../static_content/static_content_hoc'
import {deepEqualObjs2, getFirstDef, isDef} from '../../../utils/objtools'
import UiButton from "../../ui/buttons/UiButton"
import {withTranslation} from "react-i18next"
import {stateAuthIsUserLoggedIn} from '../../auth/auth_selectors'
import AnimDelay from "../../ui/AnimDelay/AnimDelay";
import branding from "../../portal/branding";

class PushStart extends React.Component {

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.historyPushByResource = this.historyPushByResource.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.staticContent.data, nextProps.staticContent.data)
      || !deepEqualObjs2(this.props.redux, nextProps.redux)
    )
  }

  render() {

    logRender(this);
    if (!this.props.tReady)
      return null

    let steps = [];
    const step_button = []

    for (let step in this.props.staticContent.data.steps) {

      steps.push(this.props.staticContent.data.steps[step])

      if (this.props.staticContent.data.steps[step].btn_event === 'signing' && this.props.redux.is_user_logged_in)
        step_button.push(<h6 className="btn-holder">{this.props.staticContent.data.steps[step].requirement_label}</h6>)
      else
        step_button.push(<UiButton onClick={() => this.handleClick(this.props.staticContent.data.steps[step].btn_event, this.props.staticContent.data.steps[step].url)}
                                   variant="primary">{this.props.staticContent.data.steps[step].btn_label}</UiButton>)

    }

    return (
      <div className={this.getItemClasses().join(' ')}>
        <AnimDelay renderDelay={500} animateAppearance={true} showDelay={400}>
          <div className="push_content grid g1_root g1_1col">
            <h2>{getFirstDef(this.props, 'staticContent.data.title', '&nbsp;')}</h2>

            <div className="grid g1_321col pushStartFlex spaceGrid">

              {steps.map(({id, name, description, contactTitle, contactText, btn_label, btn_event}) => (
                <div className={[id, "step"].join(' ')} key={id}>
                  <header className="position">
                    <h3>{id}</h3>
                    <h3>{name}</h3>
                    {branding.kind !== 'baas' && <div className="undeline">&nbsp;</div>}
                  </header>
                  <p className="description">{description}</p>
                  <div className="form-actions">
                    {branding.kind !== 'baas' && step_button[id-1]}
                  </div>
                </div>
              ))}

            </div>
            {branding.kind === 'baas' &&
                <div className="push_contact">
                  <div className="contactBox">
                    <h3>{getFirstDef(this.props, 'staticContent.data.contact_title', '&nbsp;')}</h3>
                    <p>{getFirstDef(this.props, 'staticContent.data.contact_text', '&nbsp;')}</p>
                    <UiButton variant="primary" onClick={()=>window.open(this.getContactButtonUrl(this.props.redux.lang_code), "_blank")} icon="">{getFirstDef(this.props, 'staticContent.data.contact_button', '&nbsp;')}</UiButton>
                  </div>
                </div>
            }
          </div>
        </AnimDelay>
      </div>
    )
  }

  historyPushByResource(scheme_key) {
    this.props.historyPushByResource(scheme_key)
  }

  doLogin() {
    this.props.doLogin();
  }

  redirectToUrl(url) {
    window.open(url, '_blank')
  }

  getContactButtonUrl(lang) {
    return lang === 'en' ? this.props.redux.contact_button_url_en : this.props.redux.contact_button_url_fr
  }

  handleClick(target, url) {
    switch(target){
      case 'signing':
        if (!this.props.redux.is_user_logged_in)
          this.doLogin();
        break;
      case 'searching':
        this.props.finderGo('api');
        break;
      case 'demarrage':
        this.historyPushByResource(target)
        break;
      case 'external_link':
        this.redirectToUrl(url)
        break;
      default:
        console.log("the target id not valid")
    }
  }


  getItemClasses() {
    let classes = [
      'push',
      'starting'
    ]
    return classes;
  }
}

const mapStateToProps = (state, ownProps) => {
  let redux = {
      lang_code: state.lang.lang_code,
      is_user_logged_in: stateAuthIsUserLoggedIn(state),
      contact_button_url_en: null,
      contact_button_url_fr: null
    }

  if (isDef(state.portal, 'context.organisation.contact_button_url_en')) {
    redux.contact_button_url_en = state.portal.context.organisation.contact_button_url_en
  }

  if (isDef(state.portal, 'context.organisation.contact_button_url_fr')) {
    redux.contact_button_url_fr = state.portal.context.organisation.contact_button_url_fr
  }

  return {
    redux
  }
}

const mapDispatchToProps = dispatch => ({
  finderGo: (target) => {
    dispatch({type: 'FINDER_GO', target})
  },
  historyPushByResource: (static_content_code) => {
    dispatch({type:'HISTORY_PUSH_BY_RESOURCE', scheme_key:'static_content_page', scheme_key_resource:'static_content', ids:{static_content_code}})
  },
  doLogin: () => {
    dispatch({type: "AUTH_LOGIN"})
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStaticContentCode(
  'push_start'
)(withTranslation(
  'api')
(PushStart)))
