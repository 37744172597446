import { takeEvery } from 'redux-saga/effects'
import { submitMembershipFormNewRoutine, submitMembershipFormEditRoutine, submitMembershipFormDeleteRoutine, submitMembershipFormAcceptRoutine, submitMembershipFormDeclineRoutine, submitRequestMembershipFormNewRoutine, submitRequestMembershipFormAllowRoutine, submitRequestMembershipFormRefuseRoutine } from './membership_routines'
import { submitNewMembershipForm } from './membership_new_saga'
import { submitEditMembershipForm } from './membership_edit_saga'
import { submitDeleteMembershipForm } from './membership_delete_saga'
import { submitAcceptMembershipForm} from './membership_accept_saga'
import { submitDeclineMembershipForm} from './membership_decline_saga'
import {submitNewRequestMembershipForm} from "./request_membership_new_saga";
import {submitAllowRequestMembershipForm} from "./request_membership_allow_saga";
import {submitRefuseRequestMembershipForm} from "./request_membership_refuse_saga";

const membershipSagas = [
  takeEvery(submitMembershipFormNewRoutine.TRIGGER, submitNewMembershipForm),
  takeEvery(submitMembershipFormEditRoutine.TRIGGER, submitEditMembershipForm),
  takeEvery(submitMembershipFormDeleteRoutine.TRIGGER, submitDeleteMembershipForm),
  takeEvery(submitMembershipFormAcceptRoutine.TRIGGER, submitAcceptMembershipForm),
  takeEvery(submitMembershipFormDeclineRoutine.TRIGGER, submitDeclineMembershipForm),
  takeEvery(submitRequestMembershipFormNewRoutine.TRIGGER, submitNewRequestMembershipForm),
  takeEvery(submitRequestMembershipFormAllowRoutine.TRIGGER, submitAllowRequestMembershipForm),
  takeEvery(submitRequestMembershipFormRefuseRoutine.TRIGGER, submitRefuseRequestMembershipForm),
]
export default membershipSagas
