import React from 'react'
import i18n from '../../../utils/lang/i18n'
import UiProperty from "../../ui/UiProperty/UiProperty";
import Expandable from "../../ui/Expandable/Expandable";

const HttpResponseModal = ({ closeAction, title, message, request_id, timestamp, error_code, subject}) => {
  return (
    <div className="modal-content">
      <div className="modal-header">
        <h4 className="modal-title">HTTP ERROR : {title}</h4>
        <button type="button" className="close" aria-label="Close" onClick={closeAction}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <p>
          {message.length > 0 &&
            message
          }
          {message.length === 0 &&
          <i>{i18n.t('common:empty_error_description')}</i>
          }
        </p>

        <Expandable key="details" title="Details">
          <div className="standard_content">
            <UiProperty key={subject} name="Subject" value={subject} />
            <UiProperty key={error_code} name="Error code" value={error_code} />
            <UiProperty key={request_id} name="Request Id" value={request_id} />
            <UiProperty key={timestamp} name="Timestamp" value={timestamp} />
          </div>
        </Expandable>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={closeAction}>{i18n.t('common:close')}</button>
      </div>
    </div>
  )
}

export default HttpResponseModal
