import { isDefVal, isDef } from '../../utils/objtools'

export function check_incompatibilities(incompatibilities, values, errors) {
  for (var incompatible_field in incompatibilities) {
    for (var incompatible_field_value in incompatibilities[incompatible_field]) {
      if (isDefVal(values, incompatible_field) === incompatible_field_value) {
        for (var incompatible_target_field in incompatibilities[incompatible_field][incompatible_field_value]) {
          var incompatible_target_field_value = isDefVal(values, incompatible_target_field)
          if (incompatibilities[incompatible_field][incompatible_field_value][incompatible_target_field].values.indexOf(incompatible_target_field_value) >= 0) {
            if (!isDef(errors, incompatible_field)) {
              errors[incompatible_field] = incompatibilities[incompatible_field][incompatible_field_value][incompatible_target_field].error_message
            }
          }
        }
      }
    }
  }
}

export const machineNameRegExp = /^[0-9a-z]+[0-9a-z-]*[0-9a-z]+$/g

export function buildMachineName(title) {
  if (!title)
    title = ''
  while (title.indexOf('  ')>=0)
    title = title.replace(/ {2}/g, ' ')
  title = title.trim().replace(/ /g, '-').toLowerCase().replace(/[^0-9a-z-]/g, '')
  while (title.indexOf('--')>=0)
    title = title.replace(/--/g, '-')
  if (title[0]==='-')
    title = title.substr(1)
  if (title.slice(-1)==='-')
    title = title.slice(0, -1)
  return title
}