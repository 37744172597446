import React from 'react'
import { logRender } from '../../utils/logging'
import { Form as FinalForm} from 'react-final-form'
import { withTranslation } from 'react-i18next'
import { Form } from 'react-bootstrap'
import UiButton from '../ui/buttons/UiButton'
import Loader from '../loader/Loader'

const ApiDownloadWsdlFinalForm = ({t, onSubmit, initialValues}) => {

  logRender(this)
  if (!t)
    return null

  return (
    <FinalForm
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, submitting }) => {
        return (
          <Form className="inline" onSubmit={handleSubmit}>
            <div className="form-actions">
              <Loader loading={submitting} />
              <UiButton variant="secondary" icon="download" type="submit" disabled={submitting}>{t('api:download_wsdl')}</UiButton>
            </div>
          </Form>
        )
      }}
    </FinalForm>
  )
}

export default withTranslation(
  ['forms','api']
)(ApiDownloadWsdlFinalForm)
