import { getFirstDef, isDef } from '../../utils/objtools'

/**
 * Adds a new notification message
 */
export function notificationAdd(message) {
  return {
    type: 'NOTIFICATION_ADD',
    message_type: message.message_type?message.message_type:'info',
    title: message.title,
    date: message.date?message.date:Date.now(),
    body: message.body,
    autoclose_ms: message.autoclose_ms?message.autoclose_ms:5000
  }
}
export function notificationAddError(message) {
  var body = ''
  if (isDef(message, 'body')) {
    body += message.body
  }
  if (isDef(message, 'error')) {
    //console.error(message.error) // Debug notified error source
    body += ' ('+message.error.name+': '+message.error.message+')'
  }
  return notificationAdd({
    message_type: 'error',
    title: getFirstDef(message, ['title'], 'Error'),
    body: body.trim(),
    autoclose_ms: message.autoclose_ms?message.autoclose_ms:10000
  })
}

/**
 * Mark a notification message as displayed
 */
export function notificationDisplayed(message_id) {
  return {
    type: 'NOTIFICATION_DISPLAYED',
    message_id: message_id
  }
}
