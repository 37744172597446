import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

let i18nextOptions = {
  //lng: 'en',
  fallbackLng: 'en',
  debug: false, // allows to see missing translation in the console
  whitelist: ['en','fr'],
  nonExplicitWhitelist: false,
  defaultNS: 'common',
  //load: 'currentOnly',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },

  backend: {
    // path where resources get loaded from, or a function
    // returning a path:
    // function(lngs, namespaces) { return customPath; }
    // the returned path will interpolate lng, ns if provided like giving a static path
    loadPath: '/locales/{{lng}}/{{ns}}.json',
  },

  // resources: {
  //   en: {
  //     translation: {
  //       "key": "hello world"
  //     }
  //   },
  //   fr: {
  //     translation: {
  //       "key": "hello le Monde"
  //     }
  //   }
  // },

  detection: {
    order: ['navigator'],
    caches: [],
  },

  react: {
    useSuspense: false,
  }
}

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(i18nextOptions);

export default i18n;