import React from 'react'
import { connect } from 'react-redux'
import { logRender } from '../../utils/logging'
import { withTranslation } from 'react-i18next'
import { deepEqualObjs2, isDefVal, getFirstDef, isDef } from '../../utils/objtools'
import { mapResourceFromCode, mapResourceFromIds } from '../resource/resource_selectors'
import Icon from '../icon/Icon'
import Button from 'react-bootstrap/Button'
import MembershipFinalForm from './MembershipFinalForm'
import {
  submitMembershipFormDeleteRoutine,
  submitMembershipFormAcceptRoutine,
  submitMembershipFormDeclineRoutine,
  submitMembershipFinalFormEditHandler,
  submitRequestMembershipFormAllowRoutine, submitRequestMembershipFormRefuseRoutine
} from './membership_routines'
import { modalAdd } from '../modals/modals_actions'
import { bindPromiseCreators } from 'redux-saga-routines'
import { membershipRoles, membershipRoles_options } from '../../utils/enums/membershipRoles'
import { membershipStates, membershipStates_options } from '../../utils/enums/membershipStates'
import { buildUri } from '../../utils/urls/internal'
import { Link } from "react-router-dom"
import { formatDate } from '../../utils/date'
import Visibility from '../../utils/debug/Visibility'

class Membership extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || this.props.tReady !== nextProps.tReady
      || this.props.showConsorgLink !== nextProps.showConsorgLink
      || this.props.can_accept !== nextProps.can_accept
      || this.props.can_delete !== nextProps.can_delete
      || this.props.can_edit !== nextProps.can_edit
    )
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    const membership = this.props.redux.consorg_membership

    // Assume Optimistic Rendering
    if (membership.error) {
      return <p>Error</p>
    }

    const role = isDefVal(membership, 'data.role')
    if (Object.values(membershipRoles).indexOf(role) === -1) {
      console.error('Unexpected role in the membership')
      return null
    }
    const state = isDefVal(membership, 'data.state')
    if (Object.values(membershipStates).indexOf(state) === -1) {
      console.error('Unexpected state in the membership')
      return null
    }

    var title = null
    var email = null
    var matricule = null
    if (this.props.mode_consorg) {
      title = getFirstDef(this.props.redux.consorg, ['consolidated.title'],
                getFirstDef(this.props.redux.consorg_membership, ['data.consumerOrganizationTitle'],
                  'Unknown consumer organization '+this.props.redux.consorg_membership.ids.consorg_id
                )
              )
    }
    else {
      // TODO check all cases
      if (membership.data.state === membershipStates.PENDING) {
        title = getFirstDef(membership, ['data.user.name','data.user.email'], null) // we either have an email or a name depending on invitation mode
      }
      else if (membership.data.state === membershipStates.ACTIVE || membership.data.state === membershipStates.REQUESTED) {
        title = getFirstDef(membership, ['data.user.givenName'], '')+' '+getFirstDef(membership, ['data.user.familyName'], '')
        email = getFirstDef(membership, ['data.user.email'], null)
        matricule = getFirstDef(membership, ['data.user.name'], null)
      }
      else {
        title = 'Unexpected membership state'
      }
    }

    var content_pills = []
    var rolePrefix = ''
    if (state===membershipStates.PENDING) {
      rolePrefix = this.props.t('membership:invited_as') + ' '
    }
    if (state===membershipStates.REQUESTED) {
      rolePrefix = this.props.t('membership:requested_as') + ' '
    }
    content_pills.push(<div key="role" className="pill">{rolePrefix+this.props.t(membershipRoles_options[role])}</div>)
    content_pills.push(<div key="state" className="pill secondary">{this.props.t(membershipStates_options[state])}</div>)

    var dates = []
    if (isDef(this.props.redux.consorg_membership, 'consolidated.createdDate') && this.props.redux.consorg_membership.consolidated.createdDate)
      dates.push(<div title={this.props.t('common:created_label')} key="createdDate" className="bloc_date"><Icon type="date_created" />{formatDate(this.props.redux.consorg_membership.consolidated.createdDate)}</div>)
    if (isDef(this.props.redux.consorg_membership, 'consolidated.updatedDate') && this.props.redux.consorg_membership.consolidated.updatedDate && this.props.redux.consorg_membership.consolidated.updatedDate !== this.props.redux.consorg_membership.consolidated.createdDate)
      dates.push(<div title={this.props.t('common:updated_label')} key="updatedDate" className="bloc_date"><Icon type="date_updated" />{formatDate(this.props.redux.consorg_membership.consolidated.updatedDate)}</div>)

    var otherContentActions = []
    if (this.props.showConsorgLink && this.props.redux.consorg_uri) {
      otherContentActions.push(<Link key="go_consorg_button" to={this.props.redux.consorg_uri}><Button variant="secondary">{this.props.t('membership:go_consorg_button')}</Button></Link>)
    }

    return (
      <div className={this.getItemClasses().join(' ')} >
        <div className="content">
          <Icon type={role} />
          <div className="infos">
            {content_pills}
            <h4 key="title">{title}</h4>
            {email && <p key="detail_email"><a href={'mailto:'+email}>{email}</a></p>}
            {matricule && matricule!==email && <p key="detail_matricule">{matricule}</p>}
            {dates}
          </div>
          <div className="actions">
            <Visibility key="edit_button" condition={this.props.can_edit}>
              <Button key="edit_button" variant="secondary" onClick={()=>this.editClick()}>{this.props.t('membership:edit_button')}</Button>
            </Visibility>
            <Visibility key="accept_decline_buttons" condition={this.props.can_accept}>
              <Button key="accept_button" variant="primary" onClick={()=>this.acceptClick()}>{this.props.t('membership:accept_button')}</Button>
              <Button key="decline_button" variant="secondary" onClick={()=>this.declineClick()}>{this.props.t('membership:decline_button')}</Button>
            </Visibility>
            <Visibility key="allow_buttons" condition={this.props.can_allow}>
              <Button key="allow_button" variant="primary" onClick={()=>this.allowClick()}>{this.props.t('membership:allow_button')}</Button>
              <Button key="refuse_button" variant="secondary" onClick={()=>this.refuseClick()}>{this.props.t('membership:refuse_button')}</Button>
            </Visibility>
            <Visibility key="delete_button" condition={this.props.can_delete}>
              <Button key="delete_button" variant="danger" onClick={()=>this.deleteClick()}>{this.props.t('membership:delete_button')}</Button>
            </Visibility>
            {otherContentActions}
          </div>
        </div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'membership',
      'bloc'
    ]
    classes.push(this.props.item_type)
    return classes;
  }

  deleteClick() {
    this.props.modalAdd(
      'delete',
      {
        title: this.props.t('membership:delete_modal_title'),
        message: this.props.t('membership:delete_modal_message'),
        deleteAction: (modalInfo)=>{
          modalInfo.closeModal()
          this.props.submitMembershipFormDeleteRoutine({ids: this.props.redux.consorg_membership.ids, userOwnMembership: this.props.userOwnMembership})
        },
        closeAction: null
      }
    )
  }

  acceptClick() {
    this.props.modalAdd(
      'confirm',
      {
        title: this.props.t('membership:accept_modal_title'),
        message: this.props.t('membership:accept_modal_message'),
        confirm_button_label: this.props.t('membership:accept_modal_button'),
        confirmAction: (modalInfo)=>{
          modalInfo.closeModal()
          this.props.submitMembershipFormAcceptRoutine({ids: this.props.redux.consorg_membership.ids})
        },
        closeAction: null
      }
    )
  }

  declineClick() {
    this.props.modalAdd(
      'confirm',
      {
        title: this.props.t('membership:decline_modal_title'),
        message: this.props.t('membership:decline_modal_message'),
        confirm_button_label: this.props.t('membership:decline_modal_button'),
        confirmAction: (modalInfo)=>{
          modalInfo.closeModal()
          this.props.submitMembershipFormDeclineRoutine({ids: this.props.redux.consorg_membership.ids})
        },
        closeAction: null
      }
    )
  }

  allowClick() {
    this.props.modalAdd(
      'confirm',
      {
        title: this.props.t('membership:allow_modal_title'),
        message: this.props.t('membership:allow_modal_message'),
        confirm_button_label: this.props.t('membership:allow_modal_button'),
        confirmAction: (modalInfo)=>{
          modalInfo.closeModal()
          this.props.submitRequestMembershipFormAllowRoutine({ids: this.props.redux.consorg_membership.ids})
        },
        closeAction: null
      }
    )
  }

  refuseClick() {
    this.props.modalAdd(
      'confirm',
      {
        title: this.props.t('membership:refuse_modal_title'),
        message: this.props.t('membership:refuse_modal_message'),
        confirm_button_label: this.props.t('membership:refuse_modal_button'),
        confirmAction: (modalInfo)=>{
          modalInfo.closeModal()
          this.props.submitRequestMembershipFormRefuseRoutine({ids: this.props.redux.consorg_membership.ids})
        },
        closeAction: null
      }
    )
  }

  editClick() {
    this.props.modalAdd(
      'form',
      {
        title: this.props.t('membership:edit_modal_title'),
        message: this.props.t('membership:edit_modal_message'),
        FormComponent: MembershipFinalForm,
        formComponentProps: {
          form_op: "edit",
          initialValues: {
            ids: this.props.redux.consorg_membership.ids,
            membershipRole: this.props.redux.consorg_membership.data.role
          }
        },
        submitAction: this.props.submitMembershipFinalFormEditHandler,
        closeAction: null
      }
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    consorg_membership: mapResourceFromCode(state, ownProps.itemType, ownProps.resourceCode),
    lang_code: state.lang.lang_code
  }
  if (ownProps.showConsorgLink) {
    redux.consorg = mapResourceFromIds(state, 'consorg', redux.consorg_membership.ids)
    if (redux.consorg.found) {
      redux.consorg_uri = buildUri('consorg', {tab_code: 'overview', ...redux.consorg.ids, lang_code:redux.lang_code})
    }
  }
  return {
    redux
  }
}

const mapDispatchToProps = dispatch => ({
  submitMembershipFormDeleteRoutine: (payload) => dispatch(submitMembershipFormDeleteRoutine(payload)),
  submitMembershipFormAcceptRoutine: (payload) => dispatch(submitMembershipFormAcceptRoutine(payload)),
  submitMembershipFormDeclineRoutine: (payload) => dispatch(submitMembershipFormDeclineRoutine(payload)),
  submitRequestMembershipFormAllowRoutine: (payload) => dispatch(submitRequestMembershipFormAllowRoutine(payload)),
  submitRequestMembershipFormRefuseRoutine: (payload) => dispatch(submitRequestMembershipFormRefuseRoutine(payload)),
  ...bindPromiseCreators({
    submitMembershipFinalFormEditHandler
  }, dispatch),
  modalAdd: (modalType, modalProps) => dispatch(modalAdd(modalType, modalProps))
})

Membership = withTranslation(['common','membership','enums'])(Membership)

export default connect(mapStateToProps, mapDispatchToProps)(Membership)
