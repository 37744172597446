import React from 'react'
import {connect} from '../../utils/redux'
import {logRender} from '../../utils/logging'
import {withTranslation} from 'react-i18next'
import {deepEqualObjs2, getFirstDef, getObjExcluding, isDef, isDefVal,} from '../../utils/objtools'
import {mapResourceFromCode, mapResourceFromIds} from '../resource/resource_selectors'
import {identifySwaggerVersion} from '../../utils/swagger/identifySwaggerVersion'
import JsonExpandable from '../ui/JsonExpandable/JsonExpandable'
import Expandable from '../ui/Expandable/Expandable'
import ApiMethod from './ApiMethod'
import UiProperty from '../ui/UiProperty/UiProperty'
import UiButton from '../ui/buttons/UiButton'
import ItemContact from '../ui/ItemContact/ItemContact'
import ItemDocumentation from '../ui/ItemDocumentation/ItemDocumentation'
import ItemGFMDescription from '../ui/ItemGFMDescription/ItemGFMDescription'
import NotFound from '../ui/NotFound/NotFound'
import ResourceError from '../ui/ResourceError/ResourceError'
import setDocumentTitle from '../../utils/browser/title'
import SingleHeader from '../ui/SingleHeader/SingleHeader'
import {downloadFile} from '../../utils/file'
import {safeDump} from 'js-yaml'
import AnimDelay from '../ui/AnimDelay/AnimDelay'
import SubscriptionToScope from '../subscription_scopes/SubscriptionToScope'
import Visibility from "../../utils/debug/Visibility";
import {stateAuthIsUserLoggedIn} from "../auth/auth_selectors";
import {stateGetSelectedConsorgResourceCode} from "../consorg/consorg_selectors";
import branding from "../portal/branding";
import ApiRelatedProducts from "./ApiRelatedProducts";
import ItemTermsOfService from "../ui/ItemTermsOfService/ItemTermsOfService";
import ItemProperties from "../ui/ItemProperties/ItemProperties";
import ApiGatewayBasePath from "./ApiGatewayBasePath";
import ApiDeprecatedMessage from "./ApiDeprecatedMessage";
import {buildExternalResourceUri} from "../../utils/urls/external";
import {submitFinalFormDownloadApiWsdlHandler} from "./api_routines";
import {bindPromiseCreators} from "redux-saga-routines";
import ApiDownloadWsdlFinalForm from "./ApiDownloadWsdlFinalForm";
import ApiGatewayBasePathOasThree from "./ApiGatewayBasePathOasThree";

class Api extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || this.props.tReady !== nextProps.tReady
    )
  }

  componentDidMount() {
    this.postRender()
  }

  componentDidUpdate() {
    this.postRender()
  }

  postRender() {
    if (this.props.redux.api.need_request) {
      this.props.requestResource(this.props.redux.api.external_resource_infos)
    }
    setDocumentTitle(isDefVal(this.props, 'redux.api.consolidated.title'))
  }

  swaggerBtn = (swagger_developed) => {
    if (isDef(this.props.redux, 'api_def.data.definition') && isDef(this.props.redux, 'api.consolidated.name') && isDef(this.props.redux, 'api.consolidated.version')) {
      let swagger_version = isDefVal(swagger_developed, 'swagger')
      return (<UiButton variant="secondary" icon="download"
                        onClick={() => this.downloadSwagger()}>{this.props.t('api:download_swagger')}{swagger_version ? ' ' + swagger_version : ''}</UiButton>
      )
    }
    return '';
  }

  wsdlBtn = (swagger_developed) => {
    if (isDef(this.props.redux, 'api_def.data.definition')
      && isDef(this.props.redux, 'api.consolidated.name')
      && isDef(this.props.redux, 'api.consolidated.version')
      && (isDef(swagger_developed, 'x-type') || (isDef(swagger_developed, 'x-sg-configuration') && isDef(swagger_developed['x-sg-configuration'], 'type')))) {
      let api_type_legacy = isDefVal(swagger_developed['x-sg-configuration'], 'type')
      let api_type = isDefVal(swagger_developed, 'x-type')
      if ((api_type_legacy === 'wsdl' || api_type === 'wsdl') && this.props.redux.api.consolidated.universe !== '1.0') {
        return (<ApiDownloadWsdlFinalForm onSubmit={this.props.submitFinalFormDownloadApiWsdlHandler} initialValues={this.props.redux.api.ids}/>)
      }
    }
    return '';
  }
  
  buildApiPath = (swagger_developed) => {
    let api_path = []
    let paths = []
    let api_spec = identifySwaggerVersion(swagger_developed)
    if(api_spec === 2){
      for (let gateway of swagger_developed['x-sg-configuration'].gateways){
        api_path = {
          ...gateway, endpointUrl: gateway.url
        }
        Reflect.deleteProperty(api_path, 'url');
        if (isDef(swagger_developed, 'basePath'))
          api_path.basePath = swagger_developed.basePath
        paths.push(<ApiGatewayBasePath key={api_path.name} api_gateway_basepath={api_path} />)
      }
      return paths
    }
    else if(api_spec === 3){
      for (let server of swagger_developed.servers){
        api_path = {
          ...server, url: server.url
        }
        paths.push(<ApiGatewayBasePathOasThree key={api_path.url} api_gateway_basepath_url={api_path.url} />)
      }
      return paths
    }
  }

  buildSecurityMembers = (swagger_developed) => {
    let securityMembers = []
    let securityMember
    let api_spec = identifySwaggerVersion(swagger_developed)


    let collectedOauthFlows = []

    if (api_spec === 2){
      collectedOauthFlows.push(swagger_developed.securityDefinitions)
    } else if (api_spec === 3) {
      for (let item in swagger_developed?.components?.securitySchemes){
        collectedOauthFlows.push(swagger_developed?.components?.securitySchemes[item]?.flows)
      }
    }

    if(collectedOauthFlows){
      for (let flows of collectedOauthFlows){
        for (let flow in flows){
              let properties = []
              let tag = flow

              let collectedProperties = getObjExcluding(flows[flow], ['scopes'])
              for (let property in collectedProperties)
                properties.push(<UiProperty key={property} name={property} value={collectedProperties[property]} />)

              securityMember = (
                <div key={tag}>
                  <div className="head">
                    <div className="api-tag">{tag}</div>
                  </div>
                  <div className="body">
                    {properties}
                  </div>
                </div>
              )

              securityMembers.push(securityMember)
            }
      }

      return securityMembers
    }
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    // Assume Optimistic Rendering
    if (this.props.redux.catalog_has_apis === false || (this.props.redux.catalog_has_apis && !this.props.redux.api.found)) {
      return <NotFound
                target='api'
                resource_types={['private_api', 'api']}
                resource_name={this.props.redux.api_uname} />
    }
    if (this.props.redux.api.error || this.props.redux.api_def.error) {
      return <ResourceError />
    }

    var swagger_developed
    if ((swagger_developed = getFirstDef(this.props.redux, 'api_def.consolidated.swagger_developed', null))) {

      var DeprecatedMessage = (
        <ApiDeprecatedMessage key="api_deprecated_message" api_ids={this.props.redux.api.ids}/>
      )

      var presentation = null
      var presentation1 = []
      var presentation2 = []

      if (isDef(swagger_developed, 'info.contact') && !branding.open)
        presentation2.push(<ItemContact key="contact" contact={swagger_developed.info.contact} />)

      if (isDef(swagger_developed, 'externalDocs'))
        presentation2.push(<ItemDocumentation key="documentation" documentation={swagger_developed.externalDocs} />)

      if (isDef(swagger_developed, 'info'))
        presentation2.push(
          <ItemProperties key="properties" properties={swagger_developed}/>)

      if(this.props.redux.authIsUserLoggedIn && (branding.kind === 'sg' || branding.kind === 'cdn')){
        presentation2.push(<div key="new_issue" className="actions">
          <UiButton variant="secondary" onClick={()=>this.props.historyPush('issue_new', {co: this.props.redux.consorg.ids.consorg_uname})}>{this.props.t('consorg:new_issue_external_button')}</UiButton>
        </div>)
      }

      if(!branding.open){
        presentation2.push(<div key="go_kibana" className="actions">
          <UiButton variant="danger" type="button" icon="elastic" onClick={()=>window.open(this.props.redux.elasticsearch_api_url, "_blank")}>Go Elastic Kibana</UiButton>
        </div>)
      }

      presentation2.push(
        <div key="download" className="download">
          {this.swaggerBtn(swagger_developed)}
          {this.wsdlBtn(swagger_developed)}
        </div>
      )

      if (isDef(swagger_developed, 'info.description') || isDef(swagger_developed, 'tags'))
        presentation1.push(<ItemGFMDescription key="description" title="Description" content={swagger_developed.info.description} tags={swagger_developed.tags}/>)

      if (isDef(swagger_developed, 'info.termsOfService') && branding.open)
        presentation1.push(<ItemTermsOfService key="terms_of_service" content={swagger_developed.info.termsOfService} />)

      else if ((isDef(swagger_developed, 'info.termsOfService') || (isDef(swagger_developed, 'info.x-irt-code') && isDef(swagger_developed, 'info.x-trigram-code'))) && !branding.open){
        presentation1.push(<ItemTermsOfService key="terms_of_service" content={swagger_developed.info.termsOfService} code_irt={swagger_developed.info['x-irt-code']} trigram={swagger_developed.info['x-trigram-code']} audience={swagger_developed.info['x-api-audience']}/>)
      }

      if (presentation1.length || presentation2.length)
        presentation = (
          <AnimDelay key="presentation_full" animateAppearance={true} showDelay={0}>
            <div className="item_full_presentation">
              <div className="presentation_content gridg g1_2t1t_2_1">
                <div key="presentation1">{presentation1}</div>
                <div key="presentation2">{presentation2}</div>
              </div>
            </div>
          </AnimDelay>
        )

      var api_path
      if (swagger_developed)
        api_path = (
          <AnimDelay key="presentation1" animateAppearance={true} showDelay={200}>
            <h2>{this.props.t('api:paths')}</h2>
            {this.buildApiPath(swagger_developed)}
          </AnimDelay>
        )

      var security
      let security_members = this.buildSecurityMembers(swagger_developed)

      if(security_members?.length){
        security = (
          <AnimDelay key="security_definitions" animateAppearance={true} showDelay={100}>
            <div className="security_definitions">
              <h2>{this.props.t('api:security')}</h2>
              <div className="grid g1_211col">
                {security_members}
              </div>
            </div>
          </AnimDelay>
        )
      }

      var operations = {
        defaultSection: {
          name: 'default',
          content: []
        },
        customSections: []
      }
      if (isDef(swagger_developed, 'paths')) {
        let api_spec = identifySwaggerVersion(swagger_developed)
        for (var key_path in swagger_developed.paths) {
          for (var key_method in swagger_developed.paths[key_path]) {
            var method = swagger_developed.paths[key_path][key_method]
            var summary = getFirstDef(method, 'summary', null)
            const newSectionContent = (
                <Expandable key={key_path+'|'+key_method} method={key_method} title={key_path} summary={summary} lock={!!(method?.security?.length || security_members?.length)} disabled={!!method.deprecated}>
                  <ApiMethod key="method" path={key_path} method={key_method} data={method} api_spec={api_spec} />
                </Expandable>
            )
            if(method.tags) {
              method.tags.forEach((tag) => {
                if(operations.customSections.length) {
                  const matchingSection = operations.customSections.find((section) => section.name === tag)
                  if(matchingSection) {
                    matchingSection.content.push(newSectionContent)
                  } else {
                    operations.customSections.push({
                      name: tag,
                      content: [(newSectionContent)]
                    })
                  }
                } else {
                  operations.customSections.push({
                    name: tag,
                    content: [(newSectionContent)]
                  })
                }
              })
            } else {
              operations.defaultSection.content.push(newSectionContent)
            }
          }
        }
      }

      var operationsWrapper = []
      if (operations.defaultSection.content.length) {
        operationsWrapper.push(<h4 key={operations.defaultSection.name}>{operations.defaultSection.name}</h4>)
        operations.defaultSection.content.forEach((content, index) => operationsWrapper.push(
            <div className="expandable_container" key={operations.defaultSection.name+':'+index}>{content}</div>
        ))
      }
      if (operations.customSections.length) {
        operations.customSections.forEach((customSection) => {
          operationsWrapper.push(<h4 key={customSection.name}>{customSection.name}</h4>)
          customSection.content.forEach((content, index) => operationsWrapper.push(
              <div className="expandable_container" key={customSection.name+':'+index}>{content}</div>
          ))
        })
      }
      if (operationsWrapper.length) {
        operationsWrapper = (
            <AnimDelay animateAppearance={true} showDelay={300}>
              <h2>{this.props.t('api:operations')}</h2>
              {operationsWrapper}
            </AnimDelay>
        )
      }

      var definitions = []
      if (isDef(swagger_developed, 'definitions')) {
        for (var key_definition in swagger_developed.definitions) {
          definitions.push(
            <JsonExpandable key={key_definition} title={key_definition} jsonobject={swagger_developed.definitions[key_definition]}/>
          )
        }
      }
      if (definitions.length)
        definitions = (
          <AnimDelay animateAppearance={true} showDelay={400}>
            <h2>{this.props.t('api:definitions')}</h2>
            {definitions}
          </AnimDelay>
        )

      var raw_data = []
      if (isDef(this.props.redux, 'api_def.data.definition'))
        raw_data.push( <JsonExpandable key="swagger" title="Swagger" jsonobject={this.props.redux.api_def.data.definition}/> )
      raw_data.push( <JsonExpandable key="swagger_developed" title="Developed swagger" jsonobject={swagger_developed}/> )
      if (raw_data.length)
        raw_data = (
          <AnimDelay animateAppearance={true} showDelay={1000}>
            <h2>Raw</h2>
            {raw_data}
          </AnimDelay>
        )

      var relatedProducts = (
        <ApiRelatedProducts key="api_related_products" api_ids={this.props.redux.api.ids}/>
      )
    }

    var pills = []
    if (isDef(this.props.redux.api.consolidated, 'version'))
      pills.push(<div key="version" className="pill secondary">{this.props.redux.api.consolidated.version}</div>)
    if (isDef(this.props.redux.api.consolidated, 'state'))
      pills.push(<div key="state" className="pill secondary">{this.props.t('common:'+this.props.redux.api.consolidated.state)}</div>)

    if(!branding.open){
      if (isDef(this.props.redux.api, 'data.space') && this.props.redux.api.data.space !== ''){
        pills.push(<div key={this.props.redux.api.data.space}
                        className="pill highlight">{this.props.redux.api.data.space.toUpperCase()}</div>)
      }
      if (isDef(this.props.redux.api, 'data.domain') && this.props.redux.api.data.domain !== ''){
        pills.push(<div key={this.props.redux.api.data.domain}
                        className="pill highlight">{this.props.redux.api.data.domain.toUpperCase()}</div>)
      }
    }


    return (
      <div className={this.getItemClasses().join(' ')} >
        <div className="grid g1_root g1_1col">

          <SingleHeader item_type="api" consolidated={this.props.redux.api.consolidated} pills={pills} loading={this.props.redux.loading} />

          {DeprecatedMessage}

          {presentation}

          {api_path}

          {security}

          {operationsWrapper}

          {definitions}

          {this.props.redux.api.consolidated.universe === "3.0" && !this.props.redux.loading &&
          <SubscriptionToScope key="subscription" apiDefinitionResourceCode={this.props.redux.api_def.resource_code} swaggerVersion={identifySwaggerVersion(swagger_developed)}/>
          }

          {relatedProducts}

          <Visibility condition={false} >
            {raw_data}
          </Visibility>

        </div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'api',
      'full'
    ]
    classes.push(this.props.item_type)
    return classes;
  }

  downloadSwagger() {
    var json = JSON.parse(JSON.stringify(this.props.redux.api_def.data.definition))
    downloadFile(
      safeDump(json),
      'swagger_'+this.props.redux.api.consolidated.name+'_'+this.props.redux.api.consolidated.version+'.yaml',
      'application/json'
    )
  }

}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    api: mapResourceFromIds(state, 'api', ownProps.page.routeProps.match.params),
    api_def: mapResourceFromIds(state, 'api_def', ownProps.page.routeProps.match.params),
    api_uname: ownProps.page.routeProps.match.params.api_uname,
    loading: false,
    catalog_has_apis: isDefVal(state, 'portal.context.catalog_has_apis'),
    catalog_has_scopes: isDefVal(state, 'portal.context.catalog_has_scopes'),
    lang_code: state.lang.lang_code,
    sparky_manager_api_endpoint:state.portal.context.organisation.sparky_manager_api_endpoint,
    authIsUserLoggedIn: stateAuthIsUserLoggedIn(state)
  }

  if(redux.authIsUserLoggedIn){
    redux.consorg = mapResourceFromCode(state, 'consorg', stateGetSelectedConsorgResourceCode(state))
  }

  if(isDef(redux.api,'ids') && isDef(redux.api,'consolidated.name')){
    redux.elasticsearch_api_url = buildExternalResourceUri("elasticsearch_api_url",
      {
        catalog_id: redux.api.ids.catalog_id,
        organisation_id: redux.api.ids.organisation_id,
        api_uname: redux.api.consolidated.name, api_name: ':api_name',
        phrase: ':phrase', organization: ':organization', catalog:':catalog'})
  }

  redux.loading = redux.catalog_has_scopes === null || redux.catalog_has_apis === null || redux.api.loading || redux.api.need_request || redux.api_def.loading || redux.api_def.need_request
  return {
    redux
  }
}

const mapDispatchToProps = dispatch => ({
  requestResource: (external_resource_infos) => dispatch({type:'RESOURCE_LOAD_REQUEST', external_resource_infos}),
  historyPush: (scheme_key, queryParams) => { dispatch({type:'HISTORY_PUSH', scheme_key, queryParams}) },
  ...bindPromiseCreators({
    submitFinalFormDownloadApiWsdlHandler,
},dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  ['item','portal.context','lang']
)(withTranslation('api', 'consorg')(Api));
