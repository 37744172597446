import { put, call } from 'redux-saga/effects'
import { notificationAdd, notificationAddError } from '../notifications/notifications_actions'
import i18n from '../../utils/lang/i18n'

export default function* genericSubmissionSaga({saga, action, routine, successfull_title, successfull_body, unsuccessfull_title, unsuccessfull_body}) {
  var saga_error = null

  try {
    yield call(saga, action)

    // Success feedback
    yield put(notificationAdd({
      title: i18n.t(successfull_title),
      body: i18n.t(successfull_body)
    }))
    yield put(routine.success())
  }
  catch (catched_error) {
    saga_error = catched_error
  }

  if (saga_error) {
    // General error
    yield put(notificationAddError({
      title: i18n.t(unsuccessfull_title),
      body: i18n.t(unsuccessfull_body),
      error: saga_error
    }))
    yield put(routine.failure({ error: saga_error}))
  }

  // End routine lifecycle
  yield put(routine.fulfill())
}