import { isDefVal, isDef } from '../../utils/objtools'
import { parseQueryParams } from '../../utils/urls/tools'

export const getPortalCurrentCatalog = state => state.portal.context.catalog
export const getPortalCurrentCatalogId = state => state.portal.context.catalog.catalog_id
export const getPortalCurrentCatalogName = state => state.portal.context.catalog.name
export const getPortalCatalogs = state => state.portal.context.catalogs
export const getPortalDefaultCatalogId = state => state.portal.context.portal_default_catalog_id

export const getPortalCurrentOrganisation = state => state.portal.context.organisation
export const getPortalCurrentOrganisationId = state => state.portal.context.organisation.organisation_id
export const getPortalOrganisationSparkyManagerEndpoint = state => state.portal.context.organisation.sparky_manager_api_endpoint
export const getPortalOrganisationSparkyManagerAuthURL = state => state.portal.context.organisation.sparky_manager_auth_url
export const getPortalOrganisationSparkyManagerLogoutURL = state => state.portal.context.organisation.sparky_manager_logout_url

export const getPortalOrganisationBrandLogoUrl = state => state.portal.context.organisation.brand_logo_url

export const getPortalCurrentOrganisationAndCatalogIds = state => {
  return {
    organisation_id: getPortalCurrentOrganisationId(state),
    organisation_uname: getPortalCurrentOrganisationId(state),
    catalog_id: getPortalCurrentCatalogId(state),
    catalog_uname: getPortalCurrentCatalogId(state)
  }
}

export const getPortalPageSchemeKey = state => state.portal.page.scheme.key

export const getPortalPageMatchParams = state => state.portal.page.routeProps.match.params

export const getPortalPageQueryParams = state => {
  if (!isDef(state.portal.page.routeProps, 'location.search')) {
    return null
  }
  return parseQueryParams(state.portal.page.routeProps.location.search)
}

export const getPortalCurrentOrganisationParam = (state, path) => isDefVal(state.portal.context.organisationParams, path)

export const getPortalCurrentCatalogParam = (state, path) => isDefVal(state.portal.context.catalogParams, path)

export const statePortalContextCatalogHasConsorgs = (state) => state.portal.context.catalog_has_consorgs

export const stateIsPortalDebug = (state) => state.portal.debug
