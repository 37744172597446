import branding from '../../components/portal/branding'

export default function setDocumentTitle(title) {
  var document_title = ''
  switch(branding.kind) {
    case 'int':
      document_title = 'Sparky Consumer'
      break
    case 'sg':
      document_title = 'SG Open API'
      break
    case 'cdn':
      document_title = 'CDN Portail API'
      break
    case 'sgf':
      document_title = 'SGF API'
      break
    case 'baas':
      document_title = 'BAAS API'
      break
    case 'assu-group':
      document_title = 'ASSU GROUP API'
      break
    case 'assu-partners':
      document_title = 'ASSU PARTNERS API'
      break
    case 'cgi-intranet':
      document_title = 'CGI INTRANET API'
      break
    case 'cgi-extranet':
      document_title = 'CGI EXTRANET API'
      break
    case 'sgbdp-internal':
      document_title = 'SG BDP INTERNAL API'
      break
    case 'sgbdp-open':
      document_title = 'SG BDP OPEN API'
      break
    case 'bfcoi-internal':
      document_title = 'BFCOI INTERNAL API'
      break
    case 'bfcoi-open':
      document_title = 'BFCOI OPEN API'
      break
    case 'sgnc-internal':
      document_title = 'SG NC INTERNAL API'
      break
    case 'sgnc-open':
      document_title = 'SG NC OPEN API'
      break
    default:
      break
  }
  if (typeof title === 'string' && title.trim()!=='')
    document_title = title.trim() + ' | ' + document_title
  document.title = document_title
}