import React from 'react'
import { withTranslation } from 'react-i18next'
import SingleHeader from "../ui/SingleHeader/SingleHeader";
import {logRender} from "../../utils/logging";
import {mapResourceFromCode, mapResourceFromIds} from "../resource/resource_selectors";
import { connect } from 'react-redux'
import {deepEqualObjs2, getFirstDef, isDef} from "../../utils/objtools";
import Comment from "../comment/Comment";
import {dateFormatFromString} from "../../utils/date";
import Loader from "../loader/Loader";
import AnimDelay from "../ui/AnimDelay/AnimDelay";
import UiButton from "../ui/buttons/UiButton";
import CommentFinalForm from "../comment/CommentFinalForm";
import { submitCommentFinalFormNewHandler } from "../comment/comment_routines";
import {bindPromiseCreators} from "redux-saga-routines";
import SenderHeader from "../ui/SenderHeader/SenderHeader";
import {stateAuthIsUserLoggedIn} from "../auth/auth_selectors";
import Visibility from "../../utils/debug/Visibility";

class IssueEdit extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || this.props.tReady !== nextProps.tReady
    )
  }

  componentDidMount() {
    this.postRender()
  }

  componentDidUpdate() {
    this.postRender()
  }

  postRender() {
    if (this.props.redux.consorg.need_request) {
      this.props.requestResource(this.props.redux.consorg.external_resource_infos)
    }
    if (this.props.redux.consorg_issues.need_request) {
      this.props.requestResource(this.props.redux.consorg_issues.external_resource_infos)
    }
    if (this.props.redux.issue.need_request) {
      this.props.requestResource(this.props.redux.issue.external_resource_infos)
    }
    if (this.props.redux.issue_comments.need_request) {
      this.props.requestResource(this.props.redux.issue_comments.external_resource_infos)
    }
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    const issue = this.props.redux.issue

    // Assume Optimistic Rendering
    if (issue.error) {
      return <p>Error</p>
    }

    var issue_title
    issue_title = getFirstDef(issue, ['data.title'], null)

    var pills = []
    if (isDef(this.props.redux.issue, 'data.status'))
      pills.push(<div key="version" className="pill primary">{this.props.redux.issue.data.status}</div>)
    if (isDef(this.props.redux.issue, 'data.id'))
      pills.push(<div key="id" className="pill secondary">#{this.props.redux.issue.data.id}</div>)

    var issue_id
    issue_id = getFirstDef(issue, ['data.id'], null)

    var issue_created_at
    issue_created_at = dateFormatFromString(getFirstDef(issue, ['data.created_at'], null))

    var issue_user_name
    issue_user_name = getFirstDef(issue, ['data.username'], null)

    var issue_body
    issue_body = getFirstDef(issue, ['data.body'], null)

    var issue_comments = []
    if (this.props.redux.issue_comments.found) {
      var item
      for (item of this.props.redux.issue_comments.items) {
        issue_comments.push({
          comment_id: getFirstDef(item, ['data.id'], null),
          comment_body: getFirstDef(item, ['data.body'], null),
          comment_created_at: dateFormatFromString(getFirstDef(item, ['data.created_at'], null)),
          comment_user_name: getFirstDef(item, ['data.username'], null),
        })
      }
    }

    var initialValues = {
      consorg: this.props.redux.consorg,
      issue: this.props.redux.issue,
      user: this.props.redux.user
    }

    return (
      <Visibility
        condition={this.props.redux.authIsUserLoggedIn !== false}
        resourceErrorDetails="You must be logged in to consult an issue.">

        <div className={this.getItemClasses().join(' ')} >
          <div className="grid g1_root g1_1col">
            <SingleHeader key="header" item_type="issue" consolidated={{title: issue_title}} pills={pills} loading={this.props.redux.loading} />
            <AnimDelay animateAppearance={true} canShow={this.props.redux.issue.found && !this.props.redux.issue.loading} showDelay={200}>
              <div title={this.props.t('common:created_label')} key="createdDate" className="single_header_date bloc_date">
                <strong>{issue_user_name} </strong>
                opened this issue on {issue_created_at} - &nbsp;
                {issue_comments.length} comment{issue_comments.length > 1 && <span>s</span>}
              </div>
            </AnimDelay>
            <div className="actions_wrapper grid g1_root g1_2col">
              <div className="smart_filter">

              </div>
              <div className="actions">
                <UiButton variant="secondary" onClick={()=>this.props.historyPush('issue_new', {co: this.props.redux.consorg.ids.consorg_uname})} icon="add">{this.props.t('consorg:new_issue_button')}</UiButton>
              </div>
            </div>
            <div className="item_full_presentation">
              <div className="presentation_content gridg g1_2t1t_2_1">
                <div className="form">
                  <AnimDelay animateAppearance={true} canShow={this.props.redux.issue.found && !this.props.redux.issue.loading} showDelay={200}>
                    <Comment key={issue_id} item_type="main" user={issue_user_name} date={issue_created_at} body={issue_body}/>
                  </AnimDelay>

                  {(issue_comments.length > 0 &&
                    issue_comments.map((comment) => <Comment item_type="slave" key={comment.comment_id} user={comment.comment_user_name} date={comment.comment_created_at} body={comment.comment_body}/>)
                  ) ||
                  ((!this.props.redux.issue_comments.found || this.props.redux.issue_comments.loading) &&
                    <Loader loading={true} />
                  ) ||
                    <p className="empty_zone">{this.props.t('comment:tab_comment_list_empty')}</p>
                  }

                  <AnimDelay animateAppearance={true} canShow={this.props.redux.issue_comments.found && this.props.redux.issue_comments.loaded && this.props.redux.issue.data.status === "open"} showDelay={300}>
                    <hr/>

                    <SenderHeader key="sender_header" item_type="slave" user={this.props.redux.user}/>

                    <CommentFinalForm form_op="new" onSubmit={this.props.submitCommentFinalFormNewHandler} initialValues={initialValues}/>

                  </AnimDelay>

                </div>

                {/*TODO show when labels are managed */}
                {/*{this.props.redux.issue.found && !this.props.redux.issue.loading &&*/}
                {/*<div>*/}
                {/*  <h4>{this.props.t('issue:labels_title')}</h4>*/}
                {/*  <p className="empty_zone">{this.props.t('issue:empty_labels')}</p>*/}
                {/*</div>*/}
                {/*}*/}

              </div>
            </div>
          </div>
        </div>
      </Visibility>
    )
  }

  getItemClasses() {
    var classes = [
      'issue_edit',
      'form_new',
      'form_page'
    ]
    return classes;
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    lang_code: state.lang.lang_code,
    user: mapResourceFromCode(state, 'me', 'user/me'),
    consorg: mapResourceFromIds(state, 'consorg', ownProps.page.routeProps.match.params),
    authIsUserLoggedIn: stateAuthIsUserLoggedIn(state)
  }
  redux.consorg_issues = mapResourceFromIds(state, 'consorg_issues', redux.consorg.ids)
  redux.issue = mapResourceFromIds(state, 'issue', {...redux.consorg.ids, issue_id: ownProps.page.routeProps.match.params.issue_uname})
  redux.issue_comments = mapResourceFromIds(state, 'issue_comments', redux.issue.ids)

  redux.loading = redux.consorg.loading || redux.consorg.need_request || redux.consorg_issues.loading || redux.issue.loading || redux.issue.need_request || redux.issue_comments.loading || redux.issue_comments.need_request
  return {
    redux
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindPromiseCreators({
    submitCommentFinalFormNewHandler
  }, dispatch),
  requestResource: (external_resource_infos) => dispatch({type:'RESOURCE_LOAD_REQUEST', external_resource_infos}),
  historyPush: (scheme_key, queryParams) => { dispatch({type:'HISTORY_PUSH', scheme_key, queryParams}) }
})

IssueEdit = withTranslation(['common','consorg','issue','comment'])(IssueEdit)

export default connect(mapStateToProps, mapDispatchToProps)(IssueEdit)
