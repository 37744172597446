import { submitSubscriptionScopesFormNewRoutine } from './subscription_scopes_routines'
import {call, put, select} from 'redux-saga/effects'
import genericFormSubmissionSaga from '../item/genericFormSubmissionSaga'
import {
  getPortalCurrentOrganisationAndCatalogIds, getPortalCurrentOrganisationId,
  getPortalOrganisationSparkyManagerEndpoint, stateIsPortalDebug
} from "../portal/portal_selectors";
import {stateFindResourceByIds} from "../resource/resource_selectors";
import {
  buildExternalResourceInfos,
  buildExternalResourceUri, external_resource_schemes
} from "../../utils/urls/external";
import {postUrlJson} from "../../utils/fetch";
import HttpStatusError from "../../utils/error/HttpStatusError";
import {getFirstDef} from "../../utils/objtools";
import {modalAdd} from "../modals/modals_actions";
import i18n from "../../utils/lang/i18n";

function* saga(action) {
  const values = {...action.payload}

  // prepare some ids
  var ids = yield select(getPortalCurrentOrganisationAndCatalogIds)
  ids.consorg_id = values.selected_consorg_id


  ids.application_id = values.application



  // Get the consorg to have its ids propagate in reloaded lists and for later go to the app URL
  var consorg = yield select(stateFindResourceByIds, 'consorg', ids)
  if (!consorg) {
    throw new Error('Consumer Organization not found.')
  }

  ids = {
    ...consorg.ids,
    ...ids
  }

  var body = {
    claims: {}
  }

  for(let item in values.selectedScopes){
    body.claims[values.selectedScopes[item].name] = values.selectedScopes[item].scopeClaim
  }

  var saga_error = null
  var url = null
  var status = null
  var data = null

  var new_scheme_key = "subscription_scopes_new"

  try {
    const sparky_manager_api_endpoint = yield select(getPortalOrganisationSparkyManagerEndpoint)
    if (!sparky_manager_api_endpoint)
      throw new Error("Could not get manager's api endpoint.")
    const organization_id = yield select(getPortalCurrentOrganisationId)
    if (!organization_id)
      throw new Error("Could not get organization_id.")

    var uri = yield buildExternalResourceUri(new_scheme_key, ids)
    if (!uri)
      throw new Error("Could not build the creation uri.")

    url = sparky_manager_api_endpoint + uri

    const {fetch_response, fetch_error} = yield call(postUrlJson, url, body, organization_id)
    if (fetch_error)
      throw fetch_error
    if (!fetch_response)
      throw new Error ('Could not get response')
    status = fetch_response.status
    if (fetch_response.status !== 200 && fetch_response.status !== 201)  {
      throw new HttpStatusError(fetch_response.status)
    }
    data = yield fetch_response.json()

    if (!data[0].name)
      throw new Error("No name found in the created item.")

    // Store refresh when requested by new scheme
    const new_scheme = external_resource_schemes[new_scheme_key]
    for (var refresh_force_resource_scheme_key of getFirstDef(new_scheme, 'refresh_force_resources', [])) {
      var external_resource_infos_to_refresh = buildExternalResourceInfos(refresh_force_resource_scheme_key, ids)
      yield put({type: 'RESOURCE_LOAD_REQUEST', external_resource_infos: external_resource_infos_to_refresh, force:true})
    }

    if (data){
      for (let claim of data){
        if(!claim.name){
          throw new Error('A scope name is missing in the subscription response')
        }
        if(!claim.state){
          throw new Error('A state is missing in the subscription response')
        }
      }
    }

    yield put({type: 'CART_SCOPES_FLUSH'})

    // Subscription recap
    yield put(modalAdd(
      'summary',
      {
        title: i18n.t('subscriptionscopes:subscription_summary_modal_title'),
        message: i18n.t('subscriptionscopes:subscription_summary_modal_message'),
        summary_list: data
      }
    ))

    // Extend IDs and specify tab_code
    ids = {
      ...ids,
      tab_code: 'subscriptions'
    }

    yield put({type: 'HISTORY_PUSH_BY_RESOURCE', scheme_key:'application', scheme_key_resource:'application', ids})

  }

  catch (catched_error) {
    saga_error = catched_error
  }

  if (yield select(stateIsPortalDebug)) {
    yield put({type: 'LOG_EXTERNAL_QUERY', scheme_key: new_scheme_key, url, status, has_error: saga_error?true:false, has_data: data?true:false})
  }

  if (saga_error) {
    yield put({type: 'ITEM_QUERY_ERROR', query_scheme_key: new_scheme_key, error: saga_error})
    return {error: saga_error}
  }

}

export function* submitNewSubscriptionScopesForm(action) {
  yield call(genericFormSubmissionSaga, {
    saga,
    action,
    routine: submitSubscriptionScopesFormNewRoutine,
    successfull_title: 'subscriptionscopes:successfull_create_title',
    successfull_body: 'subscriptionscopes:successfull_create_body',
    unsuccessfull_title: 'subscriptionscopes:unsuccessfull_create_title',
    unsuccessfull_body: 'subscriptionscopes:unsuccessfull_create_body'
  })
}
