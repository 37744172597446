import React from 'react'
import { connect } from '../../utils/redux'
import { logRender } from '../../utils/logging'
import { withTranslation } from 'react-i18next'
import { isDef, isDefVal2, deepCopyObj, deepEqualObjs2 } from '../../utils/objtools'
import FinderFilterSummaryItem from './FinderFilterSummaryItem'
import Pane from '../ui/Pane/Pane'
import FinderFilter from './FinderFilter'

class FinderFilterSummary extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      value: null,
      redux_synced: true, // when true we are sync with redux, when false we have a value not yet sent to redux then we have to keep displaying our state value
      pane_opened: false
    }

    this.handleRemove = this.handleRemove.bind(this)
    this.openPane = this.openPane.bind(this)
    this.closePane = this.closePane.bind(this)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // Sync state with redux if permitted
    if (prevState.redux_synced) {
      return {
          value: nextProps.redux.value
        }
    }
    return null
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || this.props.tReady !== nextProps.tReady
      || !deepEqualObjs2(this.state.value, nextState.value)
      || this.state.pane_opened !== nextState.pane_opened
    )
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    if (!this.props.redux.filter)
      return null
    var filter = this.props.redux.filter

    var content = []
    var value = this.state.value
    switch (filter.type) {
      case 'input':
        if (value !== null && value.trim() !== '') {
          var input_words = value.split(' ')
          input_words = input_words.filter((word)=>word.trim()!=='')
          input_words = input_words.map((word)=>word.toLowerCase())
          input_words = [...new Set(input_words)]
          var handleRemoveWord = (input_word) => { return () => this.handleRemove(input_word) }
          for (var input_word of input_words) {
            content.push(
                <FinderFilterSummaryItem key={input_word} label={input_word} onClickRemove={handleRemoveWord(input_word)} />
              )
          }
        }
        break
      case 'checkbox':
      //case 'radio':
        if (!isDef(filter, 'options'))
          return null
        value = Array.isArray(value)?value:[]
        var handleRemove = (option_key) => { return () => this.handleRemove(option_key) }
        for (var option_key in filter.options) {
          var option = filter.options[option_key]
          var label = 'xxx'
          if (isDef(option,'title_i18n'))
            label = this.props.t(option.title_i18n)
          else if (isDef(option,'title'))
            label = option.title
          if (value.indexOf(option.name) !== -1)
            content.push(
                <FinderFilterSummaryItem key={option_key} label={label} onClickRemove={handleRemove(option_key)} />
              )
        }
        break
      default:
        return null
    }

    if (!content.length && !this.props.with_pane)
      return null

    var pane = null
    if (this.props.with_pane) {
      pane = (
        <Pane opened={this.state.pane_opened} content_prepared={true} onClickOverlay={this.closePane} >
          <h2>{this.props.t(filter.title_i18n)} ▼</h2>
          <FinderFilter filter_name={this.props.filter_name} />
        </Pane>
      )
    }

    var filter_label
    if (!this.props.with_pane)
      filter_label = <div className="label">{this.props.t(filter.title_i18n)}</div>
    else
      filter_label = <div className="label clickable" onClick={this.openPane}>{this.props.t(filter.title_i18n)} ▼</div>

    return (
      <div className={this.getItemClasses().join(' ')} >
        {filter_label}
        <div className="items">{content}</div>
        {pane}
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'filter-summary'
    ]
    if (this.props.redux.filter) {
      classes.push('name-'+this.props.redux.filter.name)
      classes.push('type-'+this.props.redux.filter.type)
    }
    return classes;
  }

  handleRemove(value_to_remove) {
    const name = this.props.filter_name
    if (typeof name !== 'undefined') {
      var new_value
      switch(this.props.redux.filter.type) {
        case 'input':
          var input_words = this.state.value.split(' ')
          input_words = input_words.filter((word)=>word.trim()!=='')
          input_words = input_words.map((word)=>word.toLowerCase())
          while (input_words.indexOf(value_to_remove) >= 0)
            input_words.splice(input_words.indexOf(value_to_remove), 1)
          new_value = input_words.join(' ')
          break
        case 'checkbox':
        //case 'radio':
          new_value = deepCopyObj(this.state.value)
          if (!Array.isArray(new_value))
            new_value = []
          var index = new_value.indexOf(value_to_remove)
          if (index !== -1)
            new_value.splice(index, 1)
          break
        default:
          return
      }
      this.setState({
        redux_synced: false,
        value: new_value
      })
      clearTimeout(this._debounce_timer)
      this._debounce_timer = setTimeout(
        ()=>{
          this.props.finderSetFilter(name, new_value)
          this.setState({
            redux_synced: true
          })
        }
        ,200 // Debounce timer
      )
    }
  }

  componentWillUnmount() {
    clearTimeout(this._debounce_timer)
  }

  openPane() {
    this.setState({
      pane_opened: true
    })
  }

  closePane() {
    this.setState({
      pane_opened: false
    })
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    lang_code: state.lang.lang_code,
    filter: null,
    value: null
  }
  var filter
  if ((filter = isDefVal2(state.finder.filters, ownProps.filter_name))) {
    redux.filter = filter
  }
  var value
  if ((value = isDefVal2(state.finder.search_state.filters, ownProps.filter_name))) {
    redux.value = value
  }
  return {
    redux
  }
}

const mapDispatchToProps = dispatch => ({
  finderSetFilter: (filter_name, filter_value) => {
    dispatch({type:'FINDER_FILTER_VALUE_REQUEST', filter_name, filter_value});
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  ['lang','finder']
)(withTranslation('finder')(FinderFilterSummary));
