
import { submitApplicationFormEditRoutine } from './application_routines'
import { put, call } from 'redux-saga/effects'
import { alterItem } from '../item/item_alter_saga.js'
import genericFormSubmissionSaga from '../item/genericFormSubmissionSaga'
import {getFirstDef} from "../../utils/objtools";

function* saga(action) {
  const values = {...action.payload}

  var body = {
    "name": values.name,
    "title": values.title,
    "description": values.description,
    "irtCode":  getFirstDef(values, 'irtCode', null),
    "trigramCode": getFirstDef(values, 'trigramCode', null)
  }

  // prepare some ids
  var ids = {...values.ids}

  // Update the application
  const {error, data} = yield call(alterItem, 'application_edit', 'application', ids, body)

  // Generic unhandled error
  if (error)
    throw error

  // TODO handle specific erros (duplicate name)
  // var submission_error = {name: 'The consumer organization name already exists in the catalog'}
  // return submission_error

  // Check edited
  if (!data.id || data.id !== ids.application_id)
    throw new Error('Missing or wrong id in the edited application.')

  // extend ids
  ids = {
    ...ids,
    application_uname: data.name,
    tab_code: 'overview'
  }

  // go to the updated application
  yield put({type: 'HISTORY_PUSH', scheme_key:'application', ids})
}

export function* submitEditApplicationForm(action) {
  yield call(genericFormSubmissionSaga, {
    saga,
    action,
    routine: submitApplicationFormEditRoutine,
    successfull_title: 'application:successfull_edit_title',
    successfull_body: 'application:successfull_edit_body',
    unsuccessfull_title: 'application:unsuccessfull_edit_title',
    unsuccessfull_body: 'application:unsuccessfull_edit_body'
  })
}
