import React from 'react'
import { logRender } from '../../utils/logging'
import { connect } from '../../utils/redux'
import { mapResourceFromIds } from '../resource/resource_selectors'
import {getFirstDef, isDef, isDefVal} from '../../utils/objtools'
import { withTranslation } from 'react-i18next'
import NotFound from '../ui/NotFound/NotFound'
import ResourceError from '../ui/ResourceError/ResourceError'
import SingleHeader from '../ui/SingleHeader/SingleHeader'
import ItemGFMDescription from '../ui/ItemGFMDescription/ItemGFMDescription'
import {stateGetCartScope, stateGetCartScopeCount} from "../cart/cart_selectors";
import ScopeCartFinalForm from "./ScopeCartFinalForm";
import {bindPromiseCreators} from "redux-saga-routines";
import {submitFinalFormAddScopeToCartHandler, submitFinalFormEditScopeCartHandler} from "./scope_routines";
import UiButton from "../ui/buttons/UiButton";
import UiItem from "../ui/UiItem/UiItem";
import AnimDelay from "../ui/AnimDelay/AnimDelay";
import ScopeFeaturedApis from "./ScopeFeaturedApis";

class Scope extends React.Component {

  componentDidMount() {
    this.postRender()
  }

  componentDidUpdate() {
    this.postRender()
  }

  postRender() {
    if (this.props.redux.scope.need_request) {
      this.props.requestResource(this.props.redux.scope.external_resource_infos)
    }
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    // Assume Optimistic Rendering
    if (this.props.redux.catalog_has_scopes === false || (this.props.redux.catalog_has_scopes && !this.props.redux.scope.found)) {
      return <NotFound/>
    }
    if (this.props.redux.scope.error) {
      return <ResourceError/>
    }

    var description = getFirstDef(this.props.redux.scope, 'data.'+this.props.redux.lang_code+'Description', null)
    if (description) {
      description = <ItemGFMDescription key="description" title="Description" content={description}/>
    }

    var pills = []
    if(isDef(this.props.redux.scope,'ids'))
    pills.push(<div key="name" className="pill">scope name : {this.props.redux.scope.ids.scope_name}</div>)

    const approval = isDefVal(this.props.redux.scope, 'data.approvalsRequired')
    var approval_desc = '-'
    if (approval === true) {
      approval_desc = this.props.t('scope:approval_required')
      pills.push(<div key="version" className="pill primary">{approval_desc}</div>)
    }
    else if (approval === false) {
      approval_desc = this.props.t('scope:no_approval_required')
      pills.push(<div key="version" className="pill secondary">{approval_desc}</div>)
    }

    var selected_scopeClaim = '_none'
    var form_op = 'new'
    if (this.props.redux.cartScope) {
      selected_scopeClaim = this.props.redux.cartScope.scopeClaim
      form_op = 'edit'
    }

    var initialValues = {
      // Hidden
      name: this.props.redux.scope.data.name,
      context: {
        apiDefinitionResourceCodes: [],
        api_ids: [],
        productDefinitionResourceCodes: [],
        productResourceCodes: []
      },
      // Fields
      scopeClaim: selected_scopeClaim
    }

    var scopeClaims_options = {
      "_none": '-'
    }

    var uiClaims = []
    for (var claimName in this.props.redux.scope.data.claimsGroup) {
      const claim = this.props.redux.scope.data.claimsGroup[claimName]
      var label = claimName
      if (isDef(claim, 'rateLimit')) {
        uiClaims.push(<UiItem key={label} title={label} subtitle={this.props.t('scope:claimProp_rateLimit')} regular_value={claim.rateLimit} type="regular"/>)
        label += ' ('+this.props.t('scope:claimProp_rateLimit')+' '+claim.rateLimit+')'
      }
      scopeClaims_options[claimName] = label
    }

    var uiAuthorizedFlows = []
    if(isDef(this.props.redux.scope.data,'authorizedFlows') && this.props.redux.scope.data.authorizedFlows.length > 0){
      for (var authorizedFlowIndex in this.props.redux.scope.data.authorizedFlows){
        const authorizedFlowName = this.props.redux.scope.data.authorizedFlows[authorizedFlowIndex]
        if(authorizedFlowName === "authorization_code")
          uiAuthorizedFlows.push(<UiItem key={this.props.t('scope:authorization_code')} subtitle={this.props.t('scope:authorization_code')} type="slim"/>)
        else if(authorizedFlowName === "client_credentials")
          uiAuthorizedFlows.push(<UiItem key={this.props.t('scope:client_credentials')} subtitle={this.props.t('scope:client_credentials')} type="slim"/>)
        else if(authorizedFlowName === "implicit")
          uiAuthorizedFlows.push(<UiItem key={this.props.t('scope:implicit')} subtitle={this.props.t('scope:implicit')} type="slim"/>)
      }
    }
    else if(isDef(this.props.redux.scope.data,'authorizedFlows') && this.props.redux.scope.data.authorizedFlows.length === 0){
      uiAuthorizedFlows.push(<UiItem key={this.props.t('scope:all')} subtitle={this.props.t('scope:all')} type="slim"/>)
    }


    if(isDef(this.props.redux,'scope.ids') && this.props.redux.scope.ids.scope_id){
      var relatedApis = (
        <ScopeFeaturedApis key={this.props.redux.scope.data.name} scope_ids={this.props.redux.scope.ids}/>
      )
    }

    return (
      <div className={this.getItemClasses().join(' ')}>
        <div className="grid g1_root g1_1col">
          <SingleHeader item_type="scope" consolidated={this.props.redux.scope.consolidated} pills={pills} loading={this.props.redux.loading}/>
          <AnimDelay animateAppearance={true} canShow={!this.props.redux.loading} showDelay={400}>
            <div className="item_full_presentation">
              <div className="presentation_content gridg g1_2t1t_2_1">
                <div>
                  {description}
                </div>

                <div>
                  <div>
                    <h4>{this.props.t('scope:claims_group')}</h4>
                    {uiClaims}
                  </div>
                  <div>
                    <h4>{this.props.t('scope:authorized_flows')}</h4>
                    {uiAuthorizedFlows}
                  </div>
                  {form_op === 'new' &&
                  <ScopeCartFinalForm form_op={form_op} onSubmit={this.props.submitFinalFormAddScopeToCartHandler} scopeClaims_options={scopeClaims_options} initialValues={initialValues} type="standalone"/>
                  }
                  {form_op === 'edit' &&
                  <ScopeCartFinalForm form_op={form_op} onSubmit={this.props.submitFinalFormEditScopeCartHandler} scopeClaims_options={scopeClaims_options} initialValues={initialValues} type="standalone"/>
                  }
                </div>
              </div>

              {this.props.redux.cartScopeCount > 0 &&
              <div className="subscription_actions">
                <UiButton variant="primary" onClick={()=>this.props.historyPush('subscription_scopes_new')}>
                  {this.props.t('subscriptionscopes:scopesCountButton', {
                    count: this.props.redux.cartScopeCount
                  })}
                </UiButton>
              </div>
              }
            </div>

            {relatedApis}

          </AnimDelay>

        </div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'scope',
      'full'
    ]
    return classes
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    lang_code: state.lang.lang_code,
    scope: mapResourceFromIds(state, 'scope', ownProps.page.routeProps.match.params),
    catalog_has_scopes: isDefVal(state, 'portal.context.catalog_has_scopes'),
    cartScopeCount: stateGetCartScopeCount(state)
  }


  if (isDef(redux.scope, 'data.name'))
  redux.cartScope = stateGetCartScope(state, redux.scope.data.name)

  redux.loading = redux.catalog_has_scopes === null || redux.scope.loading || redux.scope.need_request
  return {
    redux
  }
}

const mapDispatchToProps = dispatch => ({
  requestResource: (external_resource_infos) => dispatch({type:'RESOURCE_LOAD_REQUEST', external_resource_infos}),
  historyPush: (scheme_key) => { dispatch({type:'HISTORY_PUSH', scheme_key}) },
  ...bindPromiseCreators({
    submitFinalFormAddScopeToCartHandler,
    submitFinalFormEditScopeCartHandler
  }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  ['item.items.scope','cart.selectedScopes','lang','portal.context.catalog','portal.context.organisation']
)(withTranslation(
    ['common','scope','subscriptionscopes']
)(Scope))
