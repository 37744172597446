import React from 'react'
import { connect } from '../../utils/redux'
import { logRender } from '../../utils/logging'
import GGmenuItem from './GGMenuItem'
import GGMenuPaneGroup from './GGMenuPaneGroup'
import {deepEqualObjs2, getFirstDef, isDef} from '../../utils/objtools'
import AnimDelay from '../ui/AnimDelay/AnimDelay'
import branding from "../portal/branding";

class GGMenu extends React.Component {

  constructor(props) {
    super(props);

    this._st = window.scrollY;
    this._header_st_last = this._st;
    this._header_st_last_cran = this._st;
    this._header_move_seuil = 100;

    this.clickHandlerOverlay = this.clickHandlerOverlay.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
    )
  }

  render() {
    logRender(this)

    // Handle body classes (we use the bootstrap class that gets body scroll hidden)
    if (this.props.redux.pane_opened)
      document.body.classList.add('modal-open')
    else
      document.body.classList.remove('modal-open') // TODO voir si coût

    // Handle body class that let know the current header status
    if (!document.body.classList.contains('header-status-'+this.props.redux.header_status))
      document.body.classList.remove('header-status-full','header-status-partial','header-status-hidden')
      document.body.classList.add('header-status-'+this.props.redux.header_status)

    // Menu items
    var items = {
      left: [],
      center: [],
      right: [],
      bandeau: []
    }
    items.left = this.props.redux.items.left.map(item_key => {
      return <GGmenuItem key={item_key} name={item_key} />
    })
    items.center = this.props.redux.items.center.map(item_key => {
      return <GGmenuItem key={item_key} name={item_key} />
    })
    items.right = this.props.redux.items.right.map(item_key => {
      return <GGmenuItem key={item_key} name={item_key} />
    })
    items.bandeau = this.props.redux.items.bandeau.map(item_key => {
      return <GGmenuItem key={item_key} name={item_key} />
    })

    // Menu Pane Groups
    var pane_groups = []
    this.props.redux.pane_group_keys.forEach(pane_group_key => {
      pane_groups.push(
        <GGMenuPaneGroup key={pane_group_key} name={pane_group_key} />
      )
    });

    return (
      <div className={this.getItemClasses().join(' ')}>
        <div className="ggmenu-overlay" onClick={this.clickHandlerOverlay}></div>
        <div className="ggmenu-header">
          <div className="ggmenu-bandeau">
            <div className="ggmenu-bandeau-content grid g1_1col g1_root">
              <div>
                <AnimDelay renderDelay={500} animateAppearance={true} showDelay={0}>
                  <div className="ggmenu-bandeau-right">
                    {items.bandeau}
                  </div>
                  <div className="ggmenu-bandeau-left">
                    <a href={this.props.redux.brand_logo_url}>
                      <div className="brand_logo">{branding.kind === 'baas' && <span className="text_logo_baas">{this.props.redux.lang_code === 'fr' ? 'En savoir plus sur nos solutions' : 'Find out more about our solutions'}  &nbsp;&#x279C;</span>}</div>
                    </a>
                  </div>
                </AnimDelay>
              </div>
            </div>
          </div>
          <div className="ggmenu-menu">
            <div className="ggmenu-menu-content grid g1_1col g1_root">
              <div>
                <AnimDelay renderDelay={500} animateAppearance={true} showDelay={200}>
                  <div className="ggmenu-menu-right">
                    {items.right}
                  </div>
                  <div className="ggmenu-menu-center">
                    {items.center}
                  </div>
                  <div className="ggmenu-menu-left">
                    {items.left}
                  </div>
                </AnimDelay>
              </div>
            </div>
          </div>
        </div>
        <div className="ggmenu-panes">
          {pane_groups}
        </div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'ggmenu',
      'header-status-'+this.props.redux.header_status
    ]
    if (this.props.redux.pane_opened)
      classes.push('pane-opened')
    return classes;
  }

  clickHandlerOverlay() {
    this.props.overlayClick()
  }

  componentDidMount() {
    this._frameCount = 0
    this._frameId = window.requestAnimationFrame(this.frameLoop.bind(this));
    //this._intervalId = setInterval(this.frameLoop.bind(this), 500)
  }

  componentWillUnmount() {
    window.cancelAnimationFrame( this._frameId );
    //clearInterval(this._intervalId)
  }

  frameLoop() {
    this._frameCount++
    // Checking every N frames
    if (this._frameCount >= 10) {
      this._frameCount = 0
      var header_status_next = '';
      this._st = window.scrollY;
      if (this.props.redux.pane_opened) {
        if (this.props.redux.header_status === 'hidden')
          header_status_next = 'partial';
      }
      else if (this._st < 150) {
        header_status_next = 'full';
      }
      else if (this._st < 300) {
        header_status_next = 'partial';
      }
      else if (this._st > (this._header_st_last_cran + this._header_move_seuil)) {
        header_status_next = 'hidden';
      }
      else if (this._st < (this._header_st_last_cran - this._header_move_seuil)) {
        header_status_next = 'partial';
      }
      if ( this._st > (this._header_st_last_cran + this._header_move_seuil) || this._st < (this._header_st_last_cran - this._header_move_seuil) ) {
        this._header_st_last_cran = this._header_st_last;
      }
      this._header_st_last = this._st;
      if (header_status_next !== '' && this.props.redux.header_status !== header_status_next) {
        this.props.setHeaderStatus(header_status_next)
      }
    }
    this._frameId = window.requestAnimationFrame(this.frameLoop.bind(this));
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    pane_group_keys: [],
    items: {
      left: [],
      center: [],
      right: [],
      bandeau: []
    },
    pane_opened: state.ggmenu.pane_opened,
    header_status: state.ggmenu.header_status,
    brand_logo_url: null,
    lang_code: state.lang.lang_code
  }

  if (isDef(state.portal, 'context.organisation.brand_logo_url')) {
    redux.brand_logo_url = state.portal.context.organisation.brand_logo_url
  }

  for (var item_key in state.ggmenu.items) {
    if ( ['left','center','right','bandeau'].indexOf(state.ggmenu.items[item_key].show[state.mqsync.rv_group]) >= 0 ) {
      redux.items[state.ggmenu.items[item_key].show[state.mqsync.rv_group]].push({item_key, weight: getFirstDef(state.ggmenu.items[item_key], 'weight', 0)})
    }
  }
  for (var menuArea of ['left','center','right','bandeau']) {
    redux.items[menuArea] = redux.items[menuArea]
      .sort((a,b)=>b.weight-a.weight)
      .map((item)=>{return item.item_key})
  }
  for (var pane_group_key in state.ggmenu.pane_groups) {
    if (state.ggmenu.pane_groups[pane_group_key].show[state.mqsync.rv_group]) {
      redux.pane_group_keys.push(pane_group_key)
    }
  }
  return {
    redux
  }
}

const mapDispatchToProps = dispatch => ({
  overlayClick: () => { dispatch({type: 'GGMENU_OVERLAY_CLICK'}) },
  setHeaderStatus: (header_status) => { dispatch({type: 'GGMENU_SET_HEADER_STATUS', header_status}) }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  ['ggmenu','mqsync.rv_group', 'portal']
)(GGMenu)