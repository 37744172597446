
import { submitMembershipFormDeclineRoutine } from './membership_routines'
import { call } from 'redux-saga/effects'
import { alterItem } from '../item/item_alter_saga.js'
import genericFormSubmissionSaga from '../item/genericFormSubmissionSaga'
import { membershipStates } from '../../utils/enums/membershipStates'

function* saga(action) {
  // prepare some ids
  var ids = {...action.payload.ids}

  var body = {
    "state": membershipStates.DECLINED
  }

  // Alter the membership
  const {error, data} = yield call(alterItem, 'consorg_membership_invitation_alter', 'consorg_membership', ids, body)

  // Generic unhandled error
  if (error)
    throw error

  // Check altered
  // TODO
  if (!data.id)
    throw new Error('Missing id in the declined invitation.')
}

export function* submitDeclineMembershipForm(action) {
  yield call(genericFormSubmissionSaga, {
    saga,
    action,
    routine: submitMembershipFormDeclineRoutine,
    successfull_title: 'membership:successfull_decline_title',
    successfull_body: 'membership:successfull_decline_body',
    unsuccessfull_title: 'membership:unsuccessfull_decline_title',
    unsuccessfull_body: 'membership:unsuccessfull_decline_body'
  })
}