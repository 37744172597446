import React from 'react'
import { logRender } from '../../utils/logging'
import { withTranslation } from 'react-i18next'
import JsonExpandable from '../ui/JsonExpandable/JsonExpandable'
import { isDef } from '../../utils/objtools'
import ItemGFMDescription, { needsGFM } from '../ui/ItemGFMDescription/ItemGFMDescription'
import ReactJsonSafe from '../ui/ReactJsonSafe/ReactJsonSafe'
import i18n from '../../utils/lang/i18n'

class ApiResponse extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.tReady !== nextProps.tReady
      || JSON.stringify(this.props.data) !== JSON.stringify(nextProps.data)
    )
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    var description = null
    // add the description only if GFM is needed, otherwise it has already been included as expandable title
    if (needsGFM(this.props.data.description))
      description = <ItemGFMDescription title="Description" content={this.props.data.description} />

    var response_examples = []
    if (isDef(this.props.data, 'examples')) {
      for (var mime_type in this.props.data.examples) {
        var title = i18n.t('api:response_mime_example', {
            mime: mime_type
          })
        response_examples.push(<h4 key={'response_example_title_'+mime_type}>{title}</h4>)
        if (typeof this.props.data.schema.example === 'string')
          response_examples.push(<pre key={'response_example_'+mime_type}>{this.props.data.examples[mime_type]}</pre>)
        else
          response_examples.push(<ReactJsonSafe key={'response_example_'+mime_type} src={this.props.data.examples[mime_type]} />)
      }
    }

    var schema = null
    if (isDef(this.props.data, 'schema')) {
      var schema_title = 'Schema'
      if (isDef(this.props.data, 'schema.title'))
        schema_title += ' '+this.props.data.schema.title
      schema = [<h4 key="schematitle">{schema_title}</h4>]
      if (isDef(this.props.data, 'schema.description'))
        schema.push(<ItemGFMDescription key="schema_description" title="Description" content={this.props.data.schema.description} />)
      schema.push(<ReactJsonSafe key="schema" src={this.props.data.schema}/>)
    }

    var schema_example = null
    if (isDef(this.props.data, 'schema.example')) {
      schema_example = [<h4 key="exampletitle">Schema Example</h4>]
      if (typeof this.props.data.schema.example === 'string')
        schema_example.push(<pre key="example">{this.props.data.schema.example}</pre>)
      else
        schema_example.push(<ReactJsonSafe key="example" src={this.props.data.schema.example} />)
    }

    // TODO handle missing props & unexpected ones

    return (
      <div className={this.getItemClasses().join(' ')} >
        {description}
        {response_examples}
        {schema}
        {schema_example}
        <h4 key="rawdatatitle">Raw data</h4>
        <JsonExpandable key="rawdata" title="Response raw data" jsonobject={this.props.data}/>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'api-response'
    ]
    return classes;
  }
}

export default withTranslation('api')(ApiResponse);
