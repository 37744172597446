import React from 'react'
import { logRender } from '../../utils/logging'
import branding from '../portal/branding'
import {deepEqualObjs2} from "../../utils/objtools";

export const iconTypes = {
  organisation: '/icons/organisation.svg',
  catalog: '/icons/catalog.svg',
  api: '/icons/api.svg',
  arrow_right_short: '/icons/arrow_right_short.svg',
  product: '/icons/product.svg',
  product_black: '/icons/product_black.svg',
  user: '/icons/user.svg',
  consumer_org_member: '/icons/consumer_org_member.svg',
  consumer_org_owner: '/icons/consumer_org_owner_' + branding.theme + '.svg',
  en: '/icons/lang/en.svg',
  fr: '/icons/lang/fr.svg',
  sparky_loader: '/icons/sparky.svg',
  consorg: '/icons/consorg.svg',
  black_consorg: '/icons/black_consorg.svg',
  application: '/icons/application.svg',
  white_search: '/icons/white_search.svg',
  search: '/icons/search.svg',
  add: '/icons/add.svg',
  white_login: '/icons/white_login.svg',
  login: '/icons/login.svg',
  logout: '/icons/logout.svg',
  credential: '/icons/credential.svg',
  date_created: '/icons/date_created.svg',
  date_updated: '/icons/date_updated.svg',
  padlock: '/icons/padlock.svg',
  plan: '/icons/plan.svg',
  plan_white: '/icons/plan_white.svg',
  subscriptionscopes: '/icons/subscriptionscopes.svg',
  scope: '/icons/scope.svg',
  black_scope: '/icons/black_scope.svg',
  black_cart: '/icons/black_cart.svg',
  facebook: '/icons/facebook.svg',
  twitter: '/icons/twitter.svg',
  twitter_black: '/icons/twitter_black.svg',
  linkedin: '/icons/linkedin.svg',
  linkedin_black: '/icons/linkedin_black.svg',
  instagram: '/icons/instagram.svg',
  request: '/icons/request.svg',
  error: '/icons/error.svg',
  time: '/icons/time.svg',
  issue: '/icons/issue.svg',
  issue_black: '/icons/issue_black.svg',
  raw_black: '/icons/raw_black.svg',
  raw_white: '/icons/raw_white.svg',
  column_black: '/icons/column_black.svg',
  column_white: '/icons/column_white.svg',
  download: '/icons/download.svg',
  copy: '/icons/copy.svg',
  white_elastic: '/icons/white_elastic.svg',
  accounts: '/icons/accounts.svg',
  payments: '/icons/payments.svg',
  locations: '/icons/locations.svg',
  identity: '/icons/identity.svg',
  part_black: '/icons/part_black.svg',
  part_white: '/icons/part_white.svg',
  dsptwo_black: '/icons/dsptwo_black.svg',
  dsptwo_white: '/icons/dsptwo_white.svg',
  business_black: '/icons/business_black.svg',
  business_white: '/icons/business_white.svg',
  open_lock: '/icons/open_lock.svg',
  closed_lock: '/icons/closed_lock.svg',
}

class Icon extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || !deepEqualObjs2(this.props, nextProps.props)
    )
  }

  render() {
    logRender(this)

    return (
      <div className={this.getItemClasses().join(' ')}>
        <img src={iconTypes[this.props.type]} alt={'icon '+this.props.type} />
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'icon'
    ]
    return classes;
  }
}

export default Icon
