// Version avec split du path par les .
export function isDef(obj, path) {
  if (obj === null)
    return false
  if (!Array.isArray(path)) {
    path = path.split(".")
  }
  var objdeep = obj
  for (var key in path) {
    // le dernier element doit être défini
    if (parseInt(key,10) === path.length-1)
      return (typeof objdeep[path[key]] !== 'undefined')
    // les éléments intermédiaires doivent être des objets non null
    else if (typeof objdeep[path[key]] === 'object' && objdeep[path[key]] !== null)
      objdeep = objdeep[path[key]]
    else
      return false
  }
  return true
}
export function isDefVal(obj, path) {
  return getFirstDef(obj, [path], null)
}
// Version sans split du path par les .
export function isDef2(obj, path) {
  if (obj === null)
    return false
  if (!Array.isArray(path)) {
    path = [path]
  }
  var objdeep = obj
  for (var key in path) {
    // le dernier element doit être défini
    if (parseInt(key,10) === path.length-1)
      return (typeof objdeep[path[key]] !== 'undefined')
    // les éléments intermédiaires doivent être des objets non null
    else if (typeof objdeep[path[key]] === 'object' && objdeep[path[key]] !== null)
      objdeep = objdeep[path[key]]
    else
      return false
  }
  return true
}
export function isDefVal2(obj, path) {
  return getFirstDef2(obj, [path], null)
}

export function addIfUndefined(obj, member, value) {
  if (!isDef(obj, member))
    obj[member] = value
}

/**
 * Cherche plusieurs chemins dans un objet, et renvoie la première valeur ou defo si non trouvée.
 * @param {Object} obj Objet dans lequel on recherche
 * @param {*} paths Chemins de recherche, array of : arrays or strings that will be dot splitted
 */
export function getFirstDef(obj, paths, defo) {
  if (!Array.isArray(paths))
    paths = [paths]
  for (var path of paths) {
    if (isDef(obj, path))
      return getFromPath(obj, path)
  }
  return defo
}
/**
 * Cherche plusieurs chemins dans un objet, et renvoie la première valeur ou defo si non trouvée.
 * @param {Object} obj Objet dans lequel on recherche
 * @param {*} paths Chemins de recherche, array of : arrays or strings that will not be dot splitted
 */
export function getFirstDef2(obj, paths, defo) {
  if (!Array.isArray(paths))
    paths = [paths]
  for (var path of paths) {
    if (isDef2(obj, path))
      return getFromPath2(obj, path)
  }
  return defo
}

/**
 * Cherche un chemin dans un objet, et renvoie la valeur ou null si non trouvée.
 * @param {Object} obj Objet dans lequel on recherche
 * @param {*} path Chemin de recherche, array or string that will be dot splitted
 */
export function getFromPath(obj, path) {
  if (!Array.isArray(path))
    path = path.split(".")
  return getFromPath2(obj, path)
}
/**
 * Cherche un chemin dans un objet, et renvoie la valeur ou null si non trouvée.
 * @param {Object} obj Objet dans lequel on recherche
 * @param {*} path Chemin de recherche, array or string that will not be dot splitted
 */
export function getFromPath2(obj, path) {
  if (!Array.isArray(path))
    path = [path]
  var objdeep = obj
  for (var path_part of path) {
    if (typeof objdeep[path_part] !== 'undefined')
      objdeep = objdeep[path_part]
    else
      return null
  }
  return objdeep
}

export function deepCopyObj(obj) {
  return JSON.parse(JSON.stringify(obj))
}

export function copyObj(obj) {
  return {...obj}
}

export function propertyCopy(obj, prop_path) {
  if (!Array.isArray(prop_path)) {
    prop_path = prop_path.split(".")
  }
  var objdeep = obj
  for (var prop of prop_path) {
    if (Array.isArray(objdeep[prop]))
      objdeep[prop] = [...objdeep[prop]]
    else
      objdeep[prop] = {...objdeep[prop]}
    objdeep = objdeep[prop]
  }
}

export function isArrayOfStrings(obj) {
  if (!Array.isArray(obj))
    return false
  if (obj.length === 0)
    return false
  for (var element of obj) {
    if (typeof element !== 'string')
      return false
  }
  return true
}

// export function deepEqualObjs(obj1, obj2) {
//   console.time('deepEqualObjs');
//   var equals = JSON.stringify(obj1) === JSON.stringify(obj2)
//   console.timeEnd('deepEqualObjs');
//   return equals
// }

/**
 * Deep Obj comparison, trying to speed up things by comparing keys, then first level objs one by one
 */
export function deepEqualObjs2(a, b) {
  function deepEqualObjs2Recurse(a, b) {
    if (a === b)
      return true
    if (typeof a == 'object' && typeof b == 'object' && a !== null && b !== null) {
      var a_keys = Object.keys(a)
      var b_keys = Object.keys(b)
      if (JSON.stringify(a_keys) !== JSON.stringify(b_keys)) {
        return false
      }
      for(var key of a_keys) {
        if (!deepEqualObjs2Recurse(a[key], b[key]))
          return false
      }
      return true
    }
    return false
  }
  return deepEqualObjs2Recurse(a, b)
  // console.time('deepEqualObjs2');
  // var equals = deepEqualObjs2Recurse(a, b)
  // console.timeEnd('deepEqualObjs2');
  // if (equals !== (JSON.stringify(a) === JSON.stringify(b))) {
  //   console.error('equals:'+equals)
  //   console.error(deepCopyObj(a),deepCopyObj(b))
  // }
  // return equals
}
// debug version
// export function deepEqualObjs2trace(a, b) {
//   function deepEqualObjs2Recurse(a, b) {
//     if (a === b) {
//       console.log('===')
//       return true
//     }
//     if (typeof a == 'object' && typeof b == 'object' && a !== null && b !== null) {
//       var a_keys = Object.keys(a)
//       var b_keys = Object.keys(b)
//       if (JSON.stringify(a_keys) !== JSON.stringify(b_keys)) {
//         console.log('distinct keys list')
//         return false
//       }
//       for(var key of a_keys) {
//         if (!deepEqualObjs2Recurse(a[key], b[key])) {
//           console.log('distinct key '+key)
//           return false
//         }
//       }
//       console.log('all keys same')
//       return true
//     }
//     console.log('diff values : ')
//     console.log(a)
//     console.log(b)
//     return false
//   }
//   return deepEqualObjs2Recurse(a, b)
// }


//************************************

/**
* get new object with members excluding a list
*/
export function getObjExcluding(obj, excluding) {
  var new_obj = {...obj}
  if (Array.isArray(excluding) && excluding.length > 0) {
    for (var exclude of excluding) {
      if (isDef(new_obj, exclude) !== false)
        delete new_obj[exclude]
    }
  }
  return new_obj
}


//*************************

export function sortObjByKeys(obj) {
  var ordered = {}
  var ordered_keys = Object.keys(obj).sort()
  for (var key2 in ordered_keys) {
    ordered[ordered_keys[key2]] = obj[ordered_keys[key2]]
  }
  return ordered
}


//*************************

export function removeDuplicates(obj){
  var tags = obj;

  tags = tags.reduce((r, i) =>
      !r.some(j => Object.keys(i).length === Object.keys(j).length
        && !Object.keys(i).some(k => i[k] !== j[k])) ? [...r, i] : r,
    []);

  return tags;
}

//*************************

export function renameKey(obj, old_key, new_key) {
  // check if old key = new key
  if (old_key !== new_key) {
    Object.defineProperty(obj, new_key, // modify old key
      // fetch description from object
      Object.getOwnPropertyDescriptor(obj, old_key));
    delete obj[old_key];                // delete old key
  }
}


export function filterObject(obj, filter, filterValue) {
  return Object.keys(obj).reduce((acc, val) =>
    (obj[val][filter] !== filterValue ? acc : {
        ...acc,
        [val]: obj[val]
      }
    ), {});
}
