import React from 'react'
import {logRender} from '../../utils/logging'
import GGmenuZone from '../ggmenu/GGMenuZone'
import branding from '../portal/branding'
import { getFirstDef } from '../../utils/objtools'
import Icon from "../icon/Icon";
import Visibility from "../../utils/debug/Visibility";
import FooterConfig from './FooterConfig'

const {
  getBanner,
  getInternalLinks,
  getLogo,
  getCopyright,
  getSocialMedias,
} = FooterConfig


class Footer extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      JSON.stringify(this.props.redux) !== JSON.stringify(nextProps.redux)
    )
  }

  render() {
    logRender(this)

    const banner = getBanner(branding.kind)
    const internalLinks = getInternalLinks(branding.kind)
    const logo = getLogo(branding.kind)
    const socialMedias = getSocialMedias(branding.kind)

    return (
      <div className="footer">

        {banner &&
          <div className="banner"><img src={banner} alt="Sparky"/></div>
        }

        <div className="footer_content grid g1_442col g1_root">
          <>
            <GGmenuZone zone="footer1"/>
            {(branding.kind === "sg" || branding.kind === "cdn") && <GGmenuZone zone="footer2"/>}
          </>

          {internalLinks.length > 0 && (
              <div className="internal_links">
                {internalLinks.map((item) => (
                    <div key={item.label}><a href={item.link} target="_blank" rel="noopener noreferrer">{item.label}</a></div>
                ))}
              </div>
          )}

          {logo && <div className="logo"><img src={logo} alt="Sparky"/></div>}

          {socialMedias.length > 0 && (
              <div className="social_networks">
                {socialMedias.map((item) => (
                    <a href={item.link}
                       target="_blank"
                       rel="noopener noreferrer"
                       key={item.iconType}
                    >
                      <Icon type={item.iconType}/>
                    </a>
                ))}
              </div>
          )}

        </div>

        <div className="footer_legals grid g1_1col g1_root">
          All rights reserved &nbsp;
          <Visibility condition={false}>- {process.env.REACT_APP_NAME}</Visibility>
          <span>{getCopyright(branding.kind)}</span>
          <Visibility condition={branding.kind === "int"}>- {process.env.REACT_APP_VERSION}</Visibility>
          <Visibility condition={false}>- {getFirstDef(process.env, 'REACT_APP_COMMIT', 'xxxxx')}</Visibility>
        </div>


      </div>
    )
  }
}

export default Footer
