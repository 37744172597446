import React from 'react'
import {logRender} from '../../../utils/logging'
import {deepEqualObjs2, isDef} from '../../../utils/objtools'
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";


class UiLevel extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.level, nextProps.level)
      || this.props.title !== this.props.redux.current_catalog_id
    )
  }

  render() {
    logRender(this)

    var level = "level-" + this.props.level

    return (
      <div className={this.getItemClasses().join(' ')}>
          <h4>{this.props.title.toUpperCase()}</h4>
          <div className={["step", level].join(' ')} key={this.props.title}></div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'ui-level'
    ]
    if (this.props.title === this.props.redux.current_catalog_id) {
      classes.push('current')
    }
    return classes;
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    lang_code: state.lang.lang_code,
    current_catalog_id: null,
  }
  if (isDef(state.portal, 'context.catalog.catalog_id')) {
    redux.current_catalog_id = state.portal.context.catalog.catalog_id
  }
  return {
    redux
  }
}

UiLevel = withTranslation(['common'])(UiLevel)

export default connect(mapStateToProps, null)(UiLevel)

