import React from 'react'
import { connect } from '../../utils/redux'
import { logRender } from '../../utils/logging'
import { withTranslation } from 'react-i18next'
import { stateIsUserBelongsConsorg, stateGetSelectedConsorgResourceCode } from '../consorg/consorg_selectors'
import UiButton from '../ui/buttons/UiButton'

class TeaserToolsConsorg extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      JSON.stringify(this.props.redux) !== JSON.stringify(nextProps.redux)
      || this.props.tReady !== nextProps.tReady
    )
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    var tools = []
    if (this.props.redux.isUserBelongsConsorg && this.props.resourceCode !== this.props.redux.selectedConsorgResourceCode) {
      tools.push(<UiButton key="select_current_consorg" variant="secondary" onClick={()=>{this.props.consorgSelectCurrent(this.props.resourceCode);this.props.userChangeConsorg()}} >{this.props.t('consorg:select_as_current_consorg')}</UiButton>)
    }

    return (
      <div className={this.getItemClasses().join(' ')} >
        {tools}
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'teaser-tools',
      'teaser-tools-consorg'
    ]
    classes.push(this.props.item_type)
    return classes;
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    lang_code: state.lang.lang_code,
    isUserBelongsConsorg: stateIsUserBelongsConsorg(state, ownProps.resourceCode),
    selectedConsorgResourceCode: stateGetSelectedConsorgResourceCode(state)
  }
  return {
    redux
  }
}

const mapDispatchToProps = dispatch => ({
  consorgSelectCurrent: (resourceCode) => dispatch({type:'SELECT_CURRENT_CONSORG', resourceCode}),
  userChangeConsorg: (resourceCode) => dispatch({type:'USER_CHANGE_CONSORG', resourceCode})
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  ['lang','auth','list','portal']
)(withTranslation(['consorg'])(TeaserToolsConsorg))
