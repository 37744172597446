import ItemBurger from './ItemBurger'
import ItemLang from './ItemLang'
import ItemLogo from './ItemLogo'
import ItemConsorg from './ItemConsorg'
import ItemMe from './ItemMe'
import ItemCart from './ItemCart'

const itemTypes = {
  ItemBurger: ItemBurger,
  ItemLang: ItemLang,
  ItemLogo: ItemLogo,
  ItemConsorg: ItemConsorg,
  ItemMe: ItemMe,
  ItemCart: ItemCart
}

export default itemTypes