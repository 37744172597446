import { createRoutine, promisifyRoutine } from 'redux-saga-routines'

// Add scope to cart
// Routine for Form
export const submitFormAddScopeToCartRoutine = createRoutine('SUBMIT_SCOPE_FORM_ADD_TO_CART')
export const submitFinalFormAddScopeToCartHandler = promisifyRoutine(submitFormAddScopeToCartRoutine)

// Edit scope in cart (change claim / remove)
// Routine for Form
export const submitFormEditScopeCartRoutine = createRoutine('SUBMIT_SCOPE_FORM_EDIT_CART')
export const submitFinalFormEditScopeCartHandler = promisifyRoutine(submitFormEditScopeCartRoutine)