import React from 'react'
import { connect } from '../../utils/redux'
import { logRender } from '../../utils/logging'
import { withTranslation } from 'react-i18next'
import {deepEqualObjs2, filterObject, getFirstDef, isDef} from '../../utils/objtools'
import {mapResourceFromCode, mapResourceFromIds} from '../resource/resource_selectors'
import Icon from '../icon/Icon'
import Button from 'react-bootstrap/Button'
import CredentialFinalForm from './CredentialFinalForm'
import {
  submitCredentialFormDeleteRoutine,
  submitCredentialFinalFormEditHandler,
  submitCredentialFinalFormResetHandler,
  submitCredentialFinalFormVerifyHandler
} from './credential_routines'
import { modalAdd } from '../modals/modals_actions'
import { bindPromiseCreators } from 'redux-saga-routines'
import Expandable from '../ui/Expandable/Expandable'
import Visibility from '../../utils/debug/Visibility'
import CredentialResetFinalForm from "../credential/CredentialResetFinalForm";
import CredentialVerifyFinalForm from "../credential/CredentialVerifyFinalForm";
import {buildExternalResourceInfos} from "../../utils/urls/external";
import {getPortalCurrentCatalogId, getPortalCurrentOrganisationAndCatalogIds} from "../portal/portal_selectors";

class Credential extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || this.props.tReady !== nextProps.tReady
    )
  }

  componentDidMount() {
    this.postRender()
  }

  componentDidUpdate() {
    this.postRender()
  }

  postRender() {
    if (this.props.redux.auth_adapters.need_request) {
      this.props.requestResource(this.props.redux.auth_adapters.external_resource_infos)
    }
    if (!this.props.redux.auth_adapters.found && this.props.redux.external_resource_infos) {
      this.props.requestResource(this.props.redux.external_resource_infos)
    }
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    const credential = this.props.redux.credential

    // Assume Optimistic Rendering
    if (credential.error) {
      return <p>Error</p>
    }

    var title = getFirstDef(credential, ['data.id'], '--')

    var content_pills = []
    content_pills.push(<div key="type" className="pill">{this.props.t('credential:client_id_pill')}</div>)
    // content_pills.push(<div key="state" className="pill secondary">{this.props.t(membershipStates_options[state])}</div>)

    var details = []

    var redirectUris = []
    var i = 0
    for (var redirectUri of getFirstDef(credential, 'data.redirectUris', [])) {
      redirectUris.push(<p key={i}>{redirectUri}</p>)
      i++
    }
    if (redirectUris.length > 0) {
      details.push(
        <Expandable key="redirectUris" tag={redirectUris.length} title={this.props.t('application:field_credentialRedirectUris')}>
          <div className="standard_content">
            {redirectUris}
          </div>
        </Expandable>
      )
    }

    if (isDef(credential, 'data.certificate') && credential.data.certificate !== '') {
      details.push(
        <Expandable key="certificate" tag="1" title={this.props.t('application:field_credentialCertificate')}>
          <div className="standard_content">
            <pre>{credential.data.certificate}</pre>
          </div>
        </Expandable>
      )
    }



    return (
      <div className={this.getItemClasses().join(' ')} >
        <div className="content">
          <Icon type="credential" />
          <div className="infos">
            {content_pills}
            <h4 key="title">{title}</h4>
          </div>
          <div className="actions">
            <Visibility key="edit_button" condition={this.props.can_edit}>
              <Button key="edit_button" variant="secondary" onClick={()=>this.editClick()}>{this.props.t('credential:edit_button')}</Button>
            </Visibility>

            <Visibility key="verify_button" condition={this.props.can_verify}>
              <Button key="verify_button" variant="primary" onClick={()=>this.verifyClick()}>{this.props.t('credential:verify_button')}</Button>
            </Visibility>

            {/* at least one credential */}
            <Visibility key="delete_button" condition={this.props.can_delete}>
              <Button key="delete_button" variant="danger" onClick={()=>this.deleteClick()}>{this.props.t('credential:delete_button')}</Button>
            </Visibility>

            <Visibility key="reset_button" condition={this.props.can_edit}>
              <Button key="reset_button" variant="danger" onClick={()=>this.resetClick()}>{this.props.t('credential:reset_button')}</Button>
            </Visibility>

          </div>
          <div className="details">
            {details}
          </div>
        </div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'credential',
      'bloc'
    ]
    return classes
  }

  deleteClick() {
    this.props.modalAdd(
      'delete',
      {
        title: this.props.t('credential:delete_modal_title'),
        message: this.props.t('credential:delete_modal_message'),
        deleteAction: (modalInfo)=>{
          modalInfo.closeModal()
          this.props.submitCredentialFormDeleteRoutine({ids: this.props.redux.credential.ids})
        },
        closeAction: null
      }
    )
  }

  resetClick() {
    this.props.modalAdd(
      'form',
      {
        title: this.props.t('credential:reset_modal_title'),
        message: this.props.t('credential:reset_modal_message'),
        FormComponent: CredentialResetFinalForm,
        formComponentProps: {
          form_op: "new",
          initialValues: {
            ids: this.props.redux.credential.ids
          }
        },
        submitAction: this.props.submitCredentialFinalFormResetHandler,
        closeAction: false
      }
    )
  }

  editClick() {
    this.props.modalAdd(
      'form',
      {
        title: this.props.t('credential:edit_modal_title'),
        message: this.props.t('credential:edit_modal_message'),
        FormComponent: CredentialFinalForm,
        formComponentProps: {
          form_op: "edit",
          initialValues: {
            ids: this.props.redux.credential.ids,
            credentialRedirectUris: getFirstDef(this.props.redux.credential, 'data.redirectUris', []).join("\n"),
            credentialCertificate: getFirstDef(this.props.redux.credential, 'data.certificate', ''),
            sealingCertificates: getFirstDef(this.props.redux.credential, 'data.sealingCertificates', null),
            authorizedUsersClaims: getFirstDef(this.props.redux.credential, 'data.authorizedUsersClaims', null),
            auth_adapters: this.props.redux.auth_adapters?.data,
            forcedAdapter: getFirstDef(
                this.props.redux.credential, 'data.forcedAdapter',
                Object.values(filterObject(getFirstDef(this.props.redux.auth_adapters, 'data', {}), "defaultOne", true))[0]?.name
            ),
          }
        },
        submitAction: this.props.submitCredentialFinalFormEditHandler,
        closeAction: null
      }
    )
  }

  verifyClick() {
    this.props.modalAdd(
      'form',
      {
        title: this.props.t('credential:verify_modal_title'),
        message: this.props.t('credential:verify_modal_message'),
        FormComponent: CredentialVerifyFinalForm,
        formComponentProps: {
          form_op: "edit",
          initialValues: {
            ids: this.props.redux.credential.ids,
            credentialId: getFirstDef(this.props.redux.credential, 'data.id', '--')
          }
        },
        submitAction: this.props.submitCredentialFinalFormVerifyHandler,
        closeAction: null
      }
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    credential: mapResourceFromCode(state, 'credential', ownProps.resourceCode),
    lang_code: state.lang.lang_code,
    catalogId: getPortalCurrentCatalogId(state)
  }
  let ids = getPortalCurrentOrganisationAndCatalogIds(state)
  redux.auth_adapters =  mapResourceFromIds(state, 'auth_adapters', {organisation_id: ids?.organisation_id, catalog_id: ids?.catalog_id})
  redux.external_resource_infos = buildExternalResourceInfos('auth_adapters', {organisation_id: ids?.organisation_id, catalog_id: ids?.catalog_id})

  return {
    redux
  }
}

const mapDispatchToProps = dispatch => ({
  submitCredentialFormDeleteRoutine: (ids) => dispatch(submitCredentialFormDeleteRoutine(ids)),
  ...bindPromiseCreators({
    submitCredentialFinalFormEditHandler,
    submitCredentialFinalFormResetHandler,
    submitCredentialFinalFormVerifyHandler
    // other promise creators can be here...
  }, dispatch),
  modalAdd: (modalType, modalProps) => dispatch(modalAdd(modalType, modalProps)),
  requestResource: (external_resource_infos) => dispatch({type:'RESOURCE_LOAD_REQUEST', external_resource_infos}),
})

Credential = withTranslation(['common','credential','application'])(Credential)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  ['lang','item.items.credential','item']
)(Credential)
