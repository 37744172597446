import { submitCredentialFormNewRoutine } from './credential_routines'
import { put, call, delay } from 'redux-saga/effects'
import i18n from '../../utils/lang/i18n'
import { newItem } from '../item/item_new_saga.js'
import { getFirstDef } from '../../utils/objtools'
import { modalAdd } from '../modals/modals_actions'
import genericFormSubmissionSaga from '../item/genericFormSubmissionSaga'

function* saga(action) {
  const {values, modalInfos} = action.payload

  // prepare some ids
  var ids = {
    ...values.ids
  }

  var body = {
    redirectUris: [],
    certificate: ''
  }
  const redirectUris = getFirstDef(values, 'credentialRedirectUris', '').split("\n").map((uri)=>uri.trim()).filter((uri)=>uri!=='')
  if (redirectUris.length > 0) {
    body.redirectUris = redirectUris
  }
  const certificate = getFirstDef(values, 'credentialCertificate', null)
  if (certificate) {
    body.certificate = certificate
  }

  // Create the membership
  var {error, data} = yield call(newItem, 'credential_new', 'credential', ids, body)

  // Generic unhandled error
  if (error)
    throw error

  // Check created
  if (!data.id)
    throw new Error('No id found in the newly created credential.')
  if (!data.secret)
    throw new Error('No secret found in the newly created credential.')

  modalInfos.closeModal()

  // notify about credential client_id/secret
  yield delay(500)
  yield put(modalAdd(
    'secret',
    {
      title: i18n.t('credential:secret_modal_title'),
      message: i18n.t('credential:secret_modal_message'),
      client_id: data.id,
      secret: data.secret
    }
  ))
}

export function* submitNewCredentialForm(action) {
  yield call(genericFormSubmissionSaga, {
    saga,
    action,
    routine: submitCredentialFormNewRoutine,
    successfull_title: 'credential:successfull_create_title',
    successfull_body: 'credential:successfull_create_body',
    unsuccessfull_title: 'credential:unsuccessfull_create_title',
    unsuccessfull_body: 'credential:unsuccessfull_create_body'
  })
}