import React from 'react'
import { connect } from '../../utils/redux'
import { logRender } from '../../utils/logging'
import Modal from './Modal.js'
import { modalDisplayed } from './modals_actions'
import { deepEqualObjs2 } from '../../utils/objtools'

class Modals extends React.Component {

  constructor(props) {
    super(props);
    this.modalSetDisplayed = this.modalSetDisplayed.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.modals, nextProps.modals)
    )
  }

  render() {
    logRender(this)
    var modals = []
    var modal
    for (var modal_id in this.props.modals) {
      modal = this.props.modals[modal_id]
      modals.push(
        <Modal show={modal.displayed_date===0} key={modal_id} modal_id={modal_id} modalSetDisplayed={this.modalSetDisplayed} modalType={modal.modalType} modalProps={modal.modalProps} />
      )
    }
    return (
      <div className="modals" >
        {modals}
      </div>
    )
  }

  modalSetDisplayed(modal_id) {
    this.props.modalDisplayed(modal_id)
  }
}

const mapStateToProps = (state, ownProps) => {
  var modals = {}
  var modal
  for (var modal_id in state.modals.modals) {
    modal = state.modals.modals[modal_id]
    // on ne garde que les modals non encore masquées ou masquées depuis moins de 5 sec (pour laisser le temps de l'anim de masquage)
    if (modal.displayed_date===0 || Date.now()-modal.displayed_date<5000) {
      modals[modal_id] = {
        modalType: modal.modalType,
        modalProps: modal.modalProps,
        displayed_date: modal.displayed_date
      }
    }
  }
  return {
    modals: modals
  }
}

const mapDispatchToProps = dispatch => ({
  modalDisplayed: (modal_id) => { dispatch(modalDisplayed(modal_id)) }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  ['modals']
)(Modals)
