import { isDef, isDefVal } from '../objtools'
import Home from '../../components/home/Home'
import NotFound from '../../components/ui/NotFound/NotFound'
import Finder from '../../components/finder/Finder'
import Product from '../../components/product/Product'
import Api from '../../components/api/Api'
import ConsorgNew from '../../components/consorg/ConsorgNew'
import Consorg from '../../components/consorg/Consorg'
import ApplicationNew from '../../components/application/ApplicationNew'
import Application from '../../components/application/Application'
import MyMemberships from '../../components/membership/MyMemberships'
import StaticContentPage from '../../components/static_content/StaticContentPage'
import { getPatternIds } from './tools'
import TechExternalUrls from './TechExternalUrls'
import KPIs from '../../components/kpis/KPIs'
import SubscriptionScopesNew from "../../components/subscription_scopes/SubscriptionScopesNew"
import Scope from '../../components/scopes/Scope'
import IssueEdit from '../../components/issue/IssueEdit'
import IssueNew from '../../components/issue/IssueNew'
import OnBoarding from "../../components/on_boarding/OnBoarding";
import GroupPlatforms from "../../components/group_platforms/GroupPlatforms";

/**
 * Map des uris internes
 */
const _url_schemes = {

  // Temporary routes (//TODO remove completly or condition on prod build ?)

  tech_external_urls:{
    uri_route: '/:lang_code(en|fr)/:catalog_uname/tech_external_urls',
    exact_route: true,
    uri_pattern: '/:lang_code/:catalog_uname/tech_external_urls',
    component: TechExternalUrls
  },

  // Normal routes

  home:{
    uri_route: '/:lang_code(en|fr)', // for the route build in App
    exact_route: true, // for the route build in App
    uri_pattern: '/:lang_code', // to build the uri
    component: Home // Component that will be rendered in Page
  },

  catalog:{
    uri_route: '/:lang_code(en|fr)/:catalog_uname',
    exact_route: true,
    uri_pattern: '/:lang_code/:catalog_uname',
    component: Home
  },

  finder:{
    uri_route: '/:lang_code(en|fr)/:catalog_uname/find',
    exact_route: true,
    uri_pattern: '/:lang_code/:catalog_uname/find',
    component: Finder
  },

  kpis:{
    uri_route: '/:lang_code(en|fr)/:catalog_uname/kpis',
    exact_route: true,
    uri_pattern: '/:lang_code/:catalog_uname/kpis',
    component: KPIs
  },
  onboarding:{
    uri_route: '/:lang_code(en|fr)/:catalog_uname/onboarding',
    exact_route: true,
    uri_pattern: '/:lang_code/:catalog_uname/onboarding',
    component: OnBoarding
  },
  group_platforms:{
    uri_route: '/:lang_code(en|fr)/:catalog_uname/group_platforms',
    exact_route: true,
    uri_pattern: '/:lang_code/:catalog_uname/group_platforms',
    component: GroupPlatforms
  },



  my_memberships:{
    uri_route: '/:lang_code(en|fr)/:catalog_uname/memberships',
    exact_route: true,
    uri_pattern: '/:lang_code/:catalog_uname/memberships',
    component: MyMemberships
  },

  api:{
    uri_route: '/:lang_code(en|fr)/:catalog_uname/api/:api_uname',
    exact_route: true,
    uri_pattern: '/:lang_code/:catalog_uname/api/:api_uname',
    component: Api
  },

  scope:{
    uri_route: '/:lang_code(en|fr)/:catalog_uname/scope/:scope_uname',
    exact_route: true,
    uri_pattern: '/:lang_code/:catalog_uname/scope/:scope_uname',
    component: Scope
  },

  product:{
    uri_route: '/:lang_code(en|fr)/:catalog_uname/prod/:product_uname',
    exact_route: true,
    uri_pattern: '/:lang_code/:catalog_uname/prod/:product_uname',
    component: Product
  },

  consorg_default:{
    uri_route: '/:lang_code(en|fr)/:catalog_uname/co/:consorg_uname',
    exact_route: true,
    redirect_to: {scheme_key:'consorg', ids:{tab_code: 'overview'}} // this is a redirect to consorg, with some default ids
  },
  consorg:{
    uri_route: '/:lang_code(en|fr)/:catalog_uname/co/:consorg_uname/:tab_code',
    exact_route: true,
    uri_pattern: '/:lang_code/:catalog_uname/co/:consorg_uname/:tab_code',
    component: Consorg,
  },
  consorg_new:{
    uri_route: '/:lang_code(en|fr)/:catalog_uname/new_co',
    exact_route: true,
    uri_pattern: '/:lang_code/:catalog_uname/new_co',
    component: ConsorgNew,
  },

  application_default:{
    uri_route: '/:lang_code(en|fr)/:catalog_uname/co/:consorg_uname/app/:application_uname',
    exact_route: true,
    redirect_to: {scheme_key:'application', ids:{tab_code: 'overview'}} // this is a redirect to application, with some default ids
  },
  application:{
    uri_route: '/:lang_code(en|fr)/:catalog_uname/co/:consorg_uname/app/:application_uname/:tab_code',
    exact_route: true,
    uri_pattern: '/:lang_code/:catalog_uname/co/:consorg_uname/app/:application_uname/:tab_code',
    component: Application,
  },
  application_new:{
    uri_route: '/:lang_code(en|fr)/:catalog_uname/new_app',
    exact_route: true,
    uri_pattern: '/:lang_code/:catalog_uname/new_app',
    component: ApplicationNew,
  },

  issue:{
    uri_route: '/:lang_code(en|fr)/:catalog_uname/co/:consorg_uname/issues/:issue_uname',
    exact_route: true,
    uri_pattern: '/:lang_code/:catalog_uname/co/:consorg_uname/issues/:issue_uname',
    component: IssueEdit,
  },
  issue_new:{
    uri_route: '/:lang_code(en|fr)/:catalog_uname/new_issue',
    exact_route: true,
    uri_pattern: '/:lang_code/:catalog_uname/new_issue',
    component: IssueNew,
  },

  subscription_scopes_new:{
    uri_route: '/:lang_code(en|fr)/:catalog_uname/new_scopes_subscription',
    exact_route: true,
    uri_pattern: '/:lang_code/:catalog_uname/new_scopes_subscription',
    component: SubscriptionScopesNew,
  },

  static_content_page:{
    uri_route: '/:lang_code(en|fr)/:catalog_uname/:static_content_name',
    exact_route: true,
    uri_pattern: '/:lang_code/:catalog_uname/:static_content_name',
    component: StaticContentPage,
  },

  // Special 404 routes

  notfound0:{
    uri_route: '/:lang_code(en|fr)/:catalog_uname',
    exact_route: false,
    uri_pattern: '/:lang_code/:catalog_uname',
    component: NotFound
  },
  notfound1:{
    uri_route: '/:lang_code(en|fr)',
    exact_route: false,
    uri_pattern: '/:lang_code',
    component: NotFound
  },
  notfound2:{
    uri_route: '/',
    exact_route: false,
    uri_pattern: '/',
    component: NotFound
  }
}
for (var scheme_key in _url_schemes) {
  _url_schemes[scheme_key].scheme_key = scheme_key
  if (isDef(_url_schemes[scheme_key], 'uri_pattern'))
    _url_schemes[scheme_key].uri_pattern_ids = getPatternIds(_url_schemes[scheme_key].uri_pattern)
}
export const url_schemes = _url_schemes

/**
 * Build INTERNAL uri from type and ids
 * @param {string} type
 * @param {array} ids
 */
export function buildUri(type, ids) {
  var scheme
  if ((scheme = isDefVal(url_schemes, type))) {
    var uri_id
    var uri = scheme.uri_pattern
    for (uri_id of scheme.uri_pattern_ids) {
      if (!isDef(ids, uri_id)) {
        console.warn("buildUri: missing "+uri_id+" for "+type)
        return null
      }
      uri = uri.replace(':'+uri_id, ids[uri_id])
    }
    return uri
  }
  console.error("buildUri: no case found for "+type)
  return null
}
