import React from 'react'
import { connect } from '../../../utils/redux'
import { logRender } from '../../../utils/logging'
import { withTranslation } from 'react-i18next'
import Icon from '../../icon/Icon'
import { mapSelectedConsorg } from '../../consorg/consorg_selectors'
import { getFirstDef } from '../../../utils/objtools'

class ItemConsorg extends React.Component {

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    var title = getFirstDef(this.props.redux, 'selectedConsorg.consolidated.title', this.props.t('menu:consorgs_title'))

    return (
      <React.Fragment>
        <Icon type="black_consorg" />
        <span>{title}</span>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    redux: {
      selectedConsorg: mapSelectedConsorg(state)
    }
  }
}

export default connect(
  mapStateToProps,
  null,
  ['item.items.consorg','portal.consorg']
)(withTranslation(['menu'])(ItemConsorg))