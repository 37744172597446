import { submitMembershipFormNewRoutine } from './membership_routines'
import { call } from 'redux-saga/effects'
import { newItem } from '../item/item_new_saga.js'
import genericFormSubmissionSaga from '../item/genericFormSubmissionSaga'

function* saga(action) {
  const {values, modalInfos} = action.payload

  var body = {
    role: values.membershipRole,
  }
  switch(values.consumerOrgMemberInvitationField) {
    case "mail":
      body.subject = values.userEmail
      break
    case "matricule":
      body.subject = values.userMatricule
      break
    default:
      throw new Error('consumerOrgMemberInvitationField unexpected value.')
  }

  // prepare some ids
  var ids = {
    ...values.ids,
  }

  // Create the membership
  var {error, data} = yield call(newItem, 'consorg_membership_new', 'consorg_membership', ids, body)

  // Generic unhandled error
  if (error)
    throw error

  // Check created
  if (!data.id)
    throw new Error('No id found in the newly created membership.')

  modalInfos.closeModal()
}

export function* submitNewMembershipForm(action) {
  yield call(genericFormSubmissionSaga, {
    saga,
    action,
    routine: submitMembershipFormNewRoutine,
    successfull_title: 'membership:successfull_create_title',
    successfull_body: 'membership:successfull_create_body',
    unsuccessfull_title: 'membership:unsuccessfull_create_title',
    unsuccessfull_body: 'membership:unsuccessfull_create_body'
  })
}