import React from 'react'
import { logRender } from '../../../utils/logging'
import { withTranslation } from 'react-i18next'
import { isDef, deepEqualObjs2 } from '../../../utils/objtools'

class ItemDocumentation extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.tReady !== nextProps.tReady
      || !deepEqualObjs2(this.props.documentation, nextProps.documentation)
      //|| JSON.stringify(this.props.documentation) !== JSON.stringify(nextProps.documentation)
    )
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    var content = []
    if (isDef(this.props, 'documentation.description'))
      content.push(<div key="description" className="description">{this.props.documentation.description}</div>)
    if (isDef(this.props, 'documentation.url'))
      content.push(<div key="url" className="url"><a target="_blank" rel="noopener noreferrer" href={this.props.documentation.url}>{this.props.documentation.url}</a></div>)

    return (
      <div className={this.getItemClasses().join(' ')} >
        <h4>Documentation</h4>
        {content}
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'item-documentation'
    ]
    return classes;
  }
}

export default withTranslation('item')(ItemDocumentation);