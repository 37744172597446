import {submitFormDownloadApiWsdlRoutine} from './api_routines'
import { put, call } from 'redux-saga/effects'
import genericFormSubmissionSaga from '../item/genericFormSubmissionSaga'
import {select} from "@redux-saga/core/effects";
import {
  getPortalCurrentOrganisationId,
  getPortalOrganisationSparkyManagerEndpoint,
  stateIsPortalDebug
} from "../portal/portal_selectors";
import {
  buildExternalResourceUri,
} from "../../utils/urls/external";
import {getUrl} from "../../utils/fetch";
import {modalAdd} from "../modals/modals_actions";
import {getFirstDef} from "../../utils/objtools";
import HttpStatusError from "../../utils/error/HttpStatusError";
import {downloadFile} from "../../utils/file";

function* saga(action) {
  const values = {...action.payload}

  var saga_error = null
  var url = null
  var status = null
  var data = null

  var new_scheme_key = "api_wsdl"

  try {

    const sparky_manager_api_endpoint = yield select(getPortalOrganisationSparkyManagerEndpoint)
    if (!sparky_manager_api_endpoint)
      throw new Error("Could not get manager's api endpoint.")

    const organization_id = yield select(getPortalCurrentOrganisationId)
    if (!organization_id)
      throw new Error("Could not get organization_id.")


    var uri = yield buildExternalResourceUri(new_scheme_key, values)
    if (!uri)
      throw new Error("Could not build the creation uri.")

    url = sparky_manager_api_endpoint + uri

    const {fetch_response, fetch_error} = yield call(getUrl, url, organization_id)

    if (fetch_error)
      throw fetch_error
    if (!fetch_response)
      throw new Error ('Could not get response')
    status = fetch_response.status
    if (fetch_response.status !== 200 && fetch_response.status !== 201)  {

      var error_response = yield fetch_response.json()

      yield put(modalAdd(
        'error',
        {
          title: fetch_response.status,
          message: getFirstDef(error_response.errors[0], 'defaultMessage', ''),
          error_code: error_response.errors[0].errorCode,
          subject: error_response.errors[0].subject,
          request_id: error_response.requestId,
          timestamp: error_response.timestamp,
        }
      ))

      throw new HttpStatusError(fetch_response.status)
    }

    data = yield fetch_response.text()

    yield downloadFile(
      data,
      values.api_uname + '.wsdl',
      'application/xml'
    )
  }

  catch (catched_error) {
    saga_error = catched_error
  }

  if (yield select(stateIsPortalDebug)) {
    yield put({type: 'LOG_EXTERNAL_QUERY', scheme_key: new_scheme_key, url, status, has_error: saga_error?true:false, has_data: data?true:false})
  }

  if (saga_error) {
    yield put({type: 'ITEM_QUERY_ERROR', query_scheme_key: new_scheme_key, error: saga_error})
    return {error: saga_error}
  }
}

export function* submitDownloadApiWsdlForm(action) {
  yield call(genericFormSubmissionSaga, {
    saga,
    action,
    routine: submitFormDownloadApiWsdlRoutine,
    successfull_title: 'api:successfull_download_wsdl_title',
    successfull_body: 'api:successfull_download_wsdl_body',
    unsuccessfull_title: 'api:unsuccessfull_download_wsdl_title',
    unsuccessfull_body: 'api:unsuccessfull_download_wsdl_body'
  })
}
