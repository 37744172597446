import React from 'react'

const FormModal = ({ closeAction, title, message, FormComponent, formComponentProps, submitAction }) => {
  return (
    <div className="modal-content">
      <div className="modal-header">
        <h4 className="modal-title">{title}</h4>
        <button type="button" className="close" aria-label="Close" onClick={closeAction}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <p>{message}</p>
      </div>
      <div className="modal-form">
        <FormComponent {...formComponentProps} onSubmit={submitAction} />
      </div>
    </div>
  )
}

export default FormModal