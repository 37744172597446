import { put, takeEvery, select } from 'redux-saga/effects'
import { getGGMenuState } from './ggmenu_selectors'
import { isDefVal, getFirstDef } from '../../utils/objtools'

const ggmenuSagas = [
  takeEvery("GGMENU_MENUITEM_OPENER_TRIGGER", menuitemOpenerTrigger),
  takeEvery("GGMENU_PANES_CLOSEALL", panesCloseAll),
  takeEvery("HISTORY_PUSH", panesCloseAll),
  takeEvery("DISPLAY_PAGE", panesCloseAll),
  takeEvery("GGMENU_OVERLAY_CLICK", overlayClick),
  takeEvery("LIST_LOAD_SUCCESS", feedStaticContentMenuItems)
]
export default ggmenuSagas

// menu item opener roll

function* menuitemOpenerTrigger(action) {
  try {
    const ggmenu = yield select(getGGMenuState)
    var item = ggmenu.items[action.item_key]
    switch(item.type) {
      case "opener":
        if (ggmenu.pane_opened !== item.target_pane) {
          if (ggmenu.pane_opened) {
            yield(put({type: 'GGMENU_PANES_CLOSEALL'}))
          }
          yield(put({type: 'GGMENU_PANE_OPEN', pane_key: item.target_pane}))
        }
        break
      case "group_opener":
        if (ggmenu.pane_opened !== item.target_pane) {
          if (ggmenu.pane_opened) {
            yield(put({type: 'GGMENU_PANES_CLOSEALL'}))
          }
          yield(put({type: 'GGMENU_PANEGROUP_OPEN', pane_group_key: item.target_pane_group}))
        }
        break
      default:
        break
    }
  } catch (error) {
    yield(put({...action, type: 'GGMENU_MENUITEM_OPENER_TRIGGER_ERROR'}))
  }
}

// click sur overlay

function* overlayClick() {
  try {
    yield(put({type: 'GGMENU_PANES_CLOSEALL'}))
  } catch (error) {
    yield(put({type: 'GGMENU_OVERLAY_CLICK_ERROR'}))
  }
}

// panes and pane groups close all

function* panesCloseAll() {
  try {
    const ggmenu = yield select(getGGMenuState)
    for (var pane_key in ggmenu.panes) {
      if (ggmenu.panes[pane_key].type === 'pane' && ggmenu.panes[pane_key].status !== 'closed') {
        yield(put({type: 'GGMENU_PANE_CLOSE', pane_key}))
      }
    }
    for (var pane_group_key in ggmenu.pane_groups) {
      if (ggmenu.pane_groups[pane_group_key].status !== 'closed') {
        yield(put({type: 'GGMENU_PANEGROUP_CLOSE', pane_group_key}))
      }
    }
  } catch (error) {
    yield(put({type: 'GGMENU_PANES_CLOSEALL_ERROR'}))
  }
}

// ajout des items de menu provenant du contenu static

function* feedStaticContentMenuItems(action) {
  const list = isDefVal(action, 'data')
  if (!list || list.length===0)
    return
  switch(isDefVal(action, 'external_resource_infos.scheme_key')) {
    case "organisation_static_contents":
      for (let item of list) {
        if (isDefVal(item, 'menus') && Array.isArray(item.menus)) {
          let ids = {
            lang_code: item.lang_code,
            static_content_name: item.name
          }
          for (let key in item.menus) {
            let title = getFirstDef(item.menus[key], 'title', item.title)
            let menu_item = {
              name: item.id+'-'+key,
              type: 'normal',
              title,
              target_scheme_key: 'static_content_page',
              ids,
              display_lang_condition: item.lang_code,
              show: item.menus[key].show
            }
            yield put({type: 'PORTAL_SET_MENU_ITEM', item: menu_item})
          }
        }
      }
      break
    default:
      break
  }
}
