import 'react-app-polyfill/ie11' // for ie 11
import 'react-app-polyfill/stable' // for ie 11
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import rootReducer from './reducers.js'
import { Provider } from 'react-redux'
import rootSaga from './sagas.js'
import { routinePromiseWatcherSaga } from 'redux-saga-routines'
import './utils/lang/i18n'

//import Worker1 from 'worker-loader!./Worker1.js'; // eslint-disable-line import/no-webpack-loader-syntax

// create the saga middleware
const sagaMiddleware = createSagaMiddleware()

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose // allow redux dev tools
//const composeEnhancers = compose // without redux dev tools

const store = createStore(
  rootReducer,
  /* preloadedState, */
  composeEnhancers(
    applyMiddleware(
      // thunkMiddleware, // lets us dispatch() functions
      // loggerMiddleware // neat middleware that logs actions
      sagaMiddleware
    )
  )
)

// Run the sagas
sagaMiddleware.run(rootSaga)
sagaMiddleware.run(routinePromiseWatcherSaga)

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// Run the init saga
store.dispatch({type: 'PORTAL_LOAD_CONTEXT_START'})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// export default store; // only for accessing config in utils



// const worker1 = new Worker1();
// worker1.postMessage({ a: 1 });
// worker1.addEventListener("message", function (event) {
//   console.log(event.data)
// });

console.log(`Version : ${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION}`)
console.log(`Commit : ${process.env.REACT_APP_COMMIT}`)