import React from 'react'
import {connect} from 'react-redux'
import {logRender} from '../../../utils/logging'
import {withTranslation} from 'react-i18next'
import {deepEqualObjs2} from '../../../utils/objtools'


class UiListItem extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || this.props.tReady !== nextProps.tReady
    )
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null


    return (
      <div className={this.getItemClasses().join(' ')}>
          <div className="pill primary">{this.props.opertation}</div>
          <div className="detail">
            <div className="operation_path">{this.props.path}</div>
            <div className="response_timestamp">
              <span>{this.props.response_time} ms | </span>
              <span>{this.props.timestamp}</span>
            </div>
          </div>
          <div className="code">
            {this.props.return_code}
          </div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'ui_list_item'
    ]
    return classes
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    lang_code: state.lang.lang_code
  }
  return {
    redux
  }
}

UiListItem = withTranslation(['common', 'application'])(UiListItem)

export default connect(mapStateToProps, null)(UiListItem)
