import React from 'react'
import { connect } from 'react-redux'
import { logRender } from '../../utils/logging'
import { getFirstDef } from '../../utils/objtools'
import { Form as FinalForm, Field as FinalField} from 'react-final-form'
import { withTranslation } from 'react-i18next'
import { Form } from 'react-bootstrap'
import UiButton from '../ui/buttons/UiButton'
import ControlCommentTextarea from '../../utils/forms/ControlCommentTextarea'
import Loader from '../loader/Loader'
import Button from "react-bootstrap/Button";
import {modalAdd} from "../modals/modals_actions";
import {submitIssueFormDeleteRoutine} from "../issue/issue_routines";


class CommentFinalForm extends React.Component {

  constructor(props) {
    super(props)

    this.validateForm = this.validateForm.bind(this)
  }

  validateForm = (values) => {
    const errors = {}

    if (!values.description) {
      errors.description = this.props.t('forms:error_required')
    }
    else if (values.description && values.description !== values.description.trim()) {
      errors.description = this.props.t('forms:error_no_begin_or_end_space')
    }
    return errors
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    if (['new','edit'].indexOf(getFirstDef(this.props, 'form_op', '')) < 0 ) {
      console.error('form_op is not defined or not handled')
      return null
    }

    const t = this.props.t

    return (
      <FinalForm
        initialValues={this.props.initialValues}
        onSubmit={this.props.onSubmit}
        validate={this.validateForm}
      >
        {({ handleSubmit, pristine, form, submitting }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="description">
                <Form.Label>{t('forms:field_comment')}</Form.Label>
                <FinalField name="description" type='text' component={ControlCommentTextarea} placeholder="" rows="5" />
                <Form.Text className="text-muted">{t('forms:field_comment_desc')}</Form.Text>
              </Form.Group>
              <div className="form-actions">
                <UiButton variant="primary" type="submit" disabled={pristine||submitting}>{t(this.props.form_op==='new'?'forms:submit_comment':'forms:submit_update')}</UiButton>
                <UiButton variant="secondary" type="button" disabled={pristine||submitting} onClick={form.reset}>{t('forms:reset')}</UiButton>
                <Button key="delete_button" variant="danger" onClick={()=>this.deleteClick()}>{this.props.t('issue:delete_button')}</Button>
                <Loader loading={submitting} />
              </div>
            </Form>
          )
        }}
      </FinalForm>
    )
  }

  deleteClick() {
    this.props.modalAdd(
      'delete',
      {
        title: this.props.t('issue:delete_modal_title'),
        message: this.props.t('issue:delete_modal_message'),
        deleteAction: (modalInfo)=>{
          modalInfo.closeModal()
          this.props.submitIssueFormDeleteRoutine({consorg: this.props.initialValues.consorg, issue: this.props.initialValues.issue})
        },
        closeAction: null
      }
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    lang_code: state.lang.lang_code
  }

  return {
    redux
  }
}

const mapDispatchToProps = dispatch => ({
  submitIssueFormDeleteRoutine: (initial_values) => dispatch(submitIssueFormDeleteRoutine(initial_values)),
  modalAdd: (modalType, modalProps) => dispatch(modalAdd(modalType, modalProps))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(
  ['forms','comment','issue']
)(CommentFinalForm))
