import React, { Component } from 'react'
import Visibility from '../../../utils/debug/Visibility'

class TabContent extends Component {
  render() {
    return (
      <div className={this.getItemClasses().join(' ')}>
        <Visibility condition={this.props.displayCondition} >
          {this.props.children}
        </Visibility>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'ui-tab-content',
      'ui-tab-code--'+this.props.tabCode
    ]
    return classes
  }
}

export default TabContent