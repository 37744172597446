import { takeEvery, select, put } from 'redux-saga/effects'
import { submitConsorgFormNewRoutine, submitConsorgFormEditRoutine, submitConsorgFormDeleteRoutine } from './consorg_routines'
import { submitNewConsorgForm } from './consorg_new_saga'
import { submitEditConsorgForm } from './consorg_edit_saga'
import { submitDeleteConsorgForm } from './consorg_delete_saga'
import {
  stateGetSelectedConsorg,
  stateIsUserBelongsConsorg,
  stateGetLastSelectedConsorgResourceCode,
  stateGetDefaultConsorgResourceCode
} from './consorg_selectors'
import { notificationAdd } from '../notifications/notifications_actions'
import i18n from '../../utils/lang/i18n'
import { buildExternalResourceInfos } from '../../utils/urls/external'
import { stateFindResourceByCodeOrAlias, stateFindResourceByIds } from '../resource/resource_selectors'
import { membershipRoles } from '../../utils/enums/membershipRoles'
import { membershipStates } from '../../utils/enums/membershipStates'
import {
  getPortalCurrentOrganisationAndCatalogIds,
  statePortalContextCatalogHasConsorgs
} from '../portal/portal_selectors'
import { stateAuthUserHasMemberships } from '../auth/auth_selectors'
import branding from "../portal/branding";

const consorgSagas = [
  takeEvery(submitConsorgFormNewRoutine.TRIGGER, submitNewConsorgForm),
  takeEvery(submitConsorgFormEditRoutine.TRIGGER, submitEditConsorgForm),
  takeEvery(submitConsorgFormDeleteRoutine.TRIGGER, submitDeleteConsorgForm),

  takeEvery("PORTAL_CATALOG_HAS_CONSORGS", feed_view__consorgs_user_belongs),
  takeEvery("AUTH_USER_HAS_MEMBERSHIPS", feed_view__consorgs_user_belongs),

  takeEvery("LIST_UPDATE_VIEW", selectLastConsorgOrDefault),
  takeEvery("SELECT_CURRENT_CONSORG", selectCurentConsorg),
  takeEvery("SET_CURRENT_CONSORG", curentConsorgHasBeenSet),
  takeEvery("USER_CHANGE_CONSORG", userChangeConsorg)
]
export default consorgSagas

// Feed the consorg list views

function* feed_view__consorgs_user_belongs(action) {
  const catalog_has_consorgs = yield select(statePortalContextCatalogHasConsorgs)
  const user_has_memberships = yield select(stateAuthUserHasMemberships)
  if (catalog_has_consorgs === null || user_has_memberships === null)
    return
  var consorgs_user_belongs = []
  var consorgs_user_owns = []
  var consorgs_user_memberships = {}
  if (action.value === true) {
    var my_memberships = yield select(stateFindResourceByCodeOrAlias, 'my_memberships', 'user/me/memberships')
    if (my_memberships) {
      for (var membershipResourceCode of my_memberships.itemsResourceCodes) {
        var membership = yield select(stateFindResourceByCodeOrAlias, 'consorg_membership', membershipResourceCode)
        if (!membership) {
          console.warn('Cannot find the membership found in my_memberships')
          continue
        }
        if (membership.data.state !== membershipStates.ACTIVE) {
          continue
        }
        var consorg = yield select(stateFindResourceByIds, 'consorg', membership.ids)
        if (!consorg) {
          console.warn('Cannot find the consorg referenced in the membership')
          continue
        }
        // we are very protective here against bad data
        if (membership.data.role === membershipRoles.OWNER || membership.data.role === membershipRoles.MEMBER) {
          consorgs_user_belongs.push(consorg.external_resource_infos.resource_code)
          consorgs_user_memberships[consorg.external_resource_infos.resource_code] = membershipResourceCode
        }
        if (membership.data.role === membershipRoles.OWNER ) {
          consorgs_user_owns.push(consorg.external_resource_infos.resource_code)
        }
      }
    }
  }
  yield put({type: 'LIST_UPDATE_VIEW', view_code: 'consorgs_user_belongs', content: consorgs_user_belongs})
  yield put({type: 'LIST_UPDATE_VIEW', view_code: 'consorgs_user_owns', content: consorgs_user_owns})
  yield put({type: 'LIST_UPDATE_VIEW', view_code: 'consorgs_user_memberships', content: consorgs_user_memberships})
}

// Select the last consumer org selected in the catalog or the default, if none is selected

function* selectLastConsorgOrDefault(action) {
  if (action.view_code === 'consorgs_user_belongs') {
    const selectedConsorg = yield select(stateGetSelectedConsorg)
    if (!selectedConsorg) {
      const lastSelectedConsorgResourceCode = yield select(stateGetLastSelectedConsorgResourceCode)
      if (lastSelectedConsorgResourceCode) {
        yield put({type: 'SELECT_CURRENT_CONSORG', resourceCode: lastSelectedConsorgResourceCode})
      }
      else if (!lastSelectedConsorgResourceCode){
        const defaultConsorg = yield select(stateGetDefaultConsorgResourceCode)
        yield put({type: 'SELECT_CURRENT_CONSORG', resourceCode: defaultConsorg})
      }
    }
  }
}

// Select the current consumer organisation

function* selectCurentConsorg(action) {
  const isUserBelongsConsorg = yield select(stateIsUserBelongsConsorg, action.resourceCode)
  if (isUserBelongsConsorg) {
    yield put({type: 'SET_CURRENT_CONSORG', resourceCode: action.resourceCode})
    yield put({type: 'GGMENU_PANES_CLOSEALL'})
    yield put({type: 'CART_SCOPES_FLUSH'})

    // TODO subsequent actions


  }
}

function* userChangeConsorg(action) {
  const portalCurrentOrganisationAndCatalogIds = yield select(getPortalCurrentOrganisationAndCatalogIds)

  yield put({type: 'FLUSH_PRIVATE_LISTS'})
  yield put({type: 'FLUSH_PRIVATE_ITEMS'})

  // We can activate the very strict mode by flushing also products. Backend will go 403
  // var external_resource_infos_products = buildExternalResourceInfos('catalog_products', portalCurrentOrganisationAndCatalogIds)
  // yield put({type: 'RESOURCE_LOAD_REQUEST', external_resource_infos: external_resource_infos_products, force:true})

  var external_resource_infos_apis = buildExternalResourceInfos('catalog_apis', portalCurrentOrganisationAndCatalogIds)
  yield put({type: 'RESOURCE_LOAD_REQUEST', external_resource_infos: external_resource_infos_apis, force:true})

  var external_resource_infos_scopes = buildExternalResourceInfos('catalog_scopes', portalCurrentOrganisationAndCatalogIds)
  yield put({type: 'RESOURCE_LOAD_REQUEST', external_resource_infos: external_resource_infos_scopes, force:true})
}

// The current consorg was set

function* curentConsorgHasBeenSet(action) {
  const selectedConsorg = yield select(stateGetSelectedConsorg)

  // Load consorg applications as needed for finder
  var external_resource_infos = buildExternalResourceInfos('consorg_applications', selectedConsorg.ids)
  yield put({type: 'RESOURCE_LOAD_REQUEST', external_resource_infos})

  if(branding.kind === 'sg' || branding.kind === 'cdn'){
    var external_resource_infos_issues = buildExternalResourceInfos('consorg_issues', selectedConsorg.ids)
    yield put({type: 'RESOURCE_LOAD_REQUEST', external_resource_infos: external_resource_infos_issues, force:true})
  }





  // Load private products and apis and scopes
  // TODO cleanup previously loaded private products (could be still visible in last seen)
  var external_resource_infos_private_products = buildExternalResourceInfos('catalog_private_products', selectedConsorg.ids)
  yield put({type: 'RESOURCE_LOAD_REQUEST', external_resource_infos: external_resource_infos_private_products, force:true})

  var external_resource_infos_private_apis = buildExternalResourceInfos('catalog_private_apis', selectedConsorg.ids)
  yield put({type: 'RESOURCE_LOAD_REQUEST', external_resource_infos: external_resource_infos_private_apis, force:true})

  var external_resource_infos_private_scopes = buildExternalResourceInfos('catalog_private_scopes', selectedConsorg.ids)
  yield put({type: 'RESOURCE_LOAD_REQUEST', external_resource_infos: external_resource_infos_private_scopes, force:true})

  // Notify user of selected consorg change
  var title = i18n.t('consorg:consorg_change_title')
  var body = i18n.t('consorg:consorg_change_body', {
    consorg_title: selectedConsorg.consolidated.title
  })
  yield put(notificationAdd({title, body, autoclose_ms: 4000}))
}
