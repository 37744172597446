import PaneProducts from './PaneProducts'
import PaneApplications from './PaneApplications'
import PaneUser from './PaneUser'
import PaneOrganisations from './PaneOrganisations'
import PaneCatalogs from './PaneCatalogs'
import PaneAPIs from './PaneAPIs'
import PaneScopes from './PaneScopes'
import PaneConsOrgs from './PaneConsOrgs'

const paneTypes = {
  PaneProducts: PaneProducts,
  PaneApplications: PaneApplications,
  PaneUser: PaneUser,
  PaneCatalogs: PaneCatalogs,
  PaneOrganisations: PaneOrganisations,
  PaneAPIs: PaneAPIs,
  PaneScopes: PaneScopes,
  PaneConsOrgs: PaneConsOrgs
}

export default paneTypes
