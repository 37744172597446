import { takeEvery } from 'redux-saga/effects'
import { submitFormAddScopeToCartRoutine } from './scope_routines'
import { submitAddScopeToCartForm } from './scope_addtocart_saga'
import { submitFormEditScopeCartRoutine } from './scope_routines'
import { submitEditScopeCartForm } from './scope_editcart_saga'

const scopesSagas = [
  takeEvery(submitFormAddScopeToCartRoutine.TRIGGER, submitAddScopeToCartForm),
  takeEvery(submitFormEditScopeCartRoutine.TRIGGER, submitEditScopeCartForm)
]
export default scopesSagas