import React from 'react'
import { logRender } from '../../../utils/logging'
import { withTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown/with-html'
import {deepEqualObjs2, isDef} from '../../../utils/objtools'
import ApiTag from "../../api/ApiTag";

export function needsGFM(text) {
  // TODO améliorer...
  return /<\/?[a-z][\s\S]*>/i.test(text) || text.indexOf("\n")>=0
}

class ItemGFMDescription extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.tReady !== nextProps.tReady
      || !deepEqualObjs2(this.props.content, nextProps.content)
      || this.state.hasError !== nextState.hasError
    )
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    console.error('ItemGFMDescription failed, will fallback on <pre> rendering')
    //console.error(error, info);
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    if (!this.props.content)
      return null

    var content = null

    if (this.state.hasError)
      content = <div>
        <p>Sorry, Github Markdown could not be rendered : </p>
        <pre>{this.props.content}</pre>
      </div>
    else
      // skipHtml={true}
      // escapeHtml={true} : n'agit que sur certaines balises ?
      content = <ReactMarkdown source={this.props.content} escapeHtml={false} />


    let title
    if(this.props.title)
    title = (<h4>{this.props.title}</h4>)

    if(content)
      content = (
        <React.Fragment>
          {title}
          {content}
        </React.Fragment>
      )

    var tags = []
    if (isDef(this.props, 'tags')) {
      for (var tag of this.props.tags) {
        tags.push(<ApiTag key={tag.name} tag={tag} />)
      }
    }

    if(tags.length)
      tags = (
        <React.Fragment>
          <h4>Tags</h4>
          {tags}
        </React.Fragment>
      )



    return (
      <div className={this.getItemClasses().join(' ')} >
        {content}
        {tags}
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'item-gfm-description'
    ]
    return classes;
  }
}

export default withTranslation('item')(ItemGFMDescription);
