import {submitIssueFormNewRoutine} from "./issue_routines"
import { put, select, call, delay } from 'redux-saga/effects'
import genericFormSubmissionSaga from '../item/genericFormSubmissionSaga'
import {getPortalCurrentOrganisationAndCatalogIds} from "../portal/portal_selectors"
import {stateFindResourceByIds} from "../resource/resource_selectors"
import {newItem} from "../item/item_new_saga"


function* saga(action) {
  const values = {...action.payload}

  // prepare some ids
  var ids = yield select(getPortalCurrentOrganisationAndCatalogIds)
  ids.consorg_id = values.relationship_consorg
  // Get the consorg to have its ids propagate in reloaded lists and for later go to the app URL
  var consorg = yield select(stateFindResourceByIds, 'consorg', ids)
  if (!consorg) {
    throw new Error('Consumer Organization not found.')
  }
  ids = {
    ...consorg.ids,
    ...ids
  }

  // We need to encode the title and the body to avoid WAF errors when prohibited characters are present

  var base64Title = btoa(unescape(encodeURIComponent(values.title)))
  var base64Body = btoa(unescape(encodeURIComponent(values.description)))

  var body = {
    "title": base64Title,
    "body": base64Body,
    "username": values.user.data.name
  }

  // Create the issue
  var {error, data} = yield call(newItem, 'issue_new', 'issue', ids, body)

  // Generic unhandled error
  if (error)
    throw error

  // Check created
  if (!data.title)
    throw new Error('No title found in the newly created issue.')
  if (!data.body)
    throw new Error('No body found in the newly created issue.')
  if (!data.id)
    throw new Error('No id found in the newly created issue.')
  if (!data.status)
    throw new Error('No status found in the newly created issue.')
  if (!data.username)
    throw new Error('No username found in the newly created issue.')

  // extend ids
  ids = {
    ...ids,
    tab_code: 'issues'
  }

  // go to the issues list where we will find the new created issue
  yield delay(500) // A tiny tempo to hide flashing "Not Found"
  yield put({type: 'HISTORY_PUSH', scheme_key:'consorg', ids})

}

export function* submitNewIssueForm(action) {
  yield call(genericFormSubmissionSaga, {
    saga,
    action,
    routine: submitIssueFormNewRoutine,
    successfull_title: 'issue:successfull_create_title',
    successfull_body: 'issue:successfull_create_body',
    unsuccessfull_title: 'issue:unsuccessfull_create_title',
    unsuccessfull_body: 'issue:unsuccessfull_create_body'
  })
}
