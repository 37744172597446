import { submitFormAddScopeToCartRoutine } from './scope_routines'
import { put, call } from 'redux-saga/effects'
import genericFormSubmissionSaga from '../item/genericFormSubmissionSaga'

function* saga(action) {
  const values = {...action.payload}

  yield put({type: 'CART_SCOPES_ADD', ...values})
}

export function* submitAddScopeToCartForm(action) {
  yield call(genericFormSubmissionSaga, {
    saga,
    action,
    routine: submitFormAddScopeToCartRoutine,
    successfull_title: 'scope:successfull_addtocart_title',
    successfull_body: 'scope:successfull_addtocart_body',
    unsuccessfull_title: 'scope:unsuccessfull_addtocart_title',
    unsuccessfull_body: 'scope:unsuccessfull_addtocart_body'
  })
}
