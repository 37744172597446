import React from 'react'
import ImgMedia from "../../ui/ImgMedia/ImgMedia";

const MediaModal = ({ closeAction, title, mediaName, organization }) => {
  return (
    <div className="modal-content">
      <div className="modal-header">
        <h4 className="modal-title">{title}</h4>
        <button type="button" className="close" aria-label="Close" onClick={closeAction}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <ImgMedia title={title} media_name={mediaName} organization={organization}/>
      </div>
    </div>
  )
}

export default MediaModal
