import React from 'react'
import { connect } from 'react-redux'
import { logRender } from '../../utils/logging'
import { withTranslation } from 'react-i18next'
import { deepEqualObjs2, getFirstDef } from '../../utils/objtools'
import ConsorgFinalForm from './ConsorgFinalForm'
import { submitConsorgFinalFormEditHandler } from './consorg_routines'
import { bindPromiseCreators } from 'redux-saga-routines'

class ConsorgEdit extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || !deepEqualObjs2(this.props.data, nextProps.data)
      || this.props.tReady !== nextProps.tReady
    )
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    if (!this.props.data.id)
      return null

    var initialValues = {
      // Hidden
      consorg_id: this.props.data.id,
      // Fields
      title: getFirstDef(this.props.data, 'title', null),
      name: getFirstDef(this.props.data, 'name', null),
      description: getFirstDef(this.props.data, 'description', null)
    }

    return (
      <div className={this.getItemClasses().join(' ')} >
        <h2>{this.props.t('consorg:tab_edit_title')}</h2>
        <ConsorgFinalForm form_op="edit" onSubmit={this.props.submitConsorgFinalFormEditHandler} initialValues={initialValues} />
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'consorg',
      'form_edit',
      'form_page'
    ]
    return classes
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    redux: {
      lang_code: state.lang.lang_code
    }
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindPromiseCreators({
    submitConsorgFinalFormEditHandler
  }, dispatch)
})

ConsorgEdit = withTranslation(
  ['common','consorg']
)(ConsorgEdit)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsorgEdit)