import React from 'react'
import { logRender } from '../../../utils/logging'

class ItemBurger extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return false
  }

  render() {
    logRender(this)
    return (
      <div className={this.getItemClasses().join(' ')}>
        <span className="burger"><span className="a"></span><span className="b"></span><span className="c"></span></span>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'item-burger'
    ]
    return classes;
  }
}

export default ItemBurger;