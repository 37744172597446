import React from 'react'
import { logRender } from '../../utils/logging'
import { withTranslation } from 'react-i18next'
import { isDef, deepEqualObjs2, getObjExcluding } from '../../utils/objtools'
import UiProperty from '../ui/UiProperty/UiProperty'

class ApiPath extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.tReady !== nextProps.tReady
      || !deepEqualObjs2(this.props.api_path, nextProps.api_path)
    )
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    var description = [];
    if (isDef(this.props, 'api_path.description'))
      description.push(<p key="description">{this.props.api_path.description}</p>)

    var url = []

    if(this.props.api_spec === 2){
      if (isDef(this.props, 'api_path.url'))
        url.push(<span key="url" className="url">{this.props.api_path.url}</span>)
      if (isDef(this.props, 'api_path.basePath')) {
        url.push(<span key="basePath" className="basePath">{this.props.api_path.basePath}</span>)
        description.push(<p key="basePath">{'+ Base path'}</p>)
      }
    }
    else if (this.props.api_spec === 3){
      // TODO handle array of servers => SGAPIM purpose
      if (isDef(this.props, 'api_path.servers'))
        url.push(<span key="url" className="url">{this.props.api_path.servers[0].url}</span>)
    }

    if (isDef(this.props, 'api_path.path')) {
      url.push(<span key="path" className="path">{this.props.api_path.path}</span>)
      description.push(<p key="path">{'+ Operation path'}</p>)
    }
    if (isDef(this.props, 'api_path.query_elements')) {
      var query_element_idx = 0;
      for (var query_element_key in this.props.api_path.query_elements) {
        query_element_idx++
        var query_glue = query_element_idx===1?'?':'&'
        url.push(<span key={"query_"+query_element_key} className="query">{query_glue}{query_element_key}={'{'}{this.props.api_path.query_elements[query_element_key]}{'}'}</span>)
      }
    }

    var infos = []
    var uncovered_members = getObjExcluding(this.props.api_path, ['url','description','basePath','path','method','query_elements','servers'])
    for (var uncovered_member_key in uncovered_members) {
      infos.push(<UiProperty key={uncovered_member_key} name={uncovered_member_key} value={uncovered_members[uncovered_member_key]} />)
    }

    return (
      <div className={this.getItemClasses().join(' ')} >
        <div className="url">
          {url}
        </div>
        <div className="desc">
          {description?description:'-'}
        </div>
        <div className="infos">
          {infos.length?infos:'-'}
        </div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'api-path'
    ]
    if (isDef(this.props, 'api_path.method'))
      classes.push(this.props.api_path.method)
    return classes;
  }
}

export default withTranslation('api')(ApiPath);
