import React from 'react'
import { Form } from 'react-bootstrap'
import DOMpurify from 'dompurify'

const ControlTextarea = ({ input, meta, placeholder, rows }) => {
    const escapedValue = DOMpurify.sanitize(input.value)

  return (
    <div>
      <Form.Control as="textarea"
        {...input}
        rows={rows}
        placeholder={placeholder}
        value={escapedValue}
        isInvalid={
          (meta.touched && (meta.error || meta.warn))
          || (meta.submitError && !meta.dirtySinceLastSubmit)
        }
      />

      {meta.touched &&
        ((meta.error && <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>) ||
         (meta.warning && <Form.Control.Feedback type="invalid">{meta.warning}</Form.Control.Feedback>))}

      {meta.submitError && !meta.dirtySinceLastSubmit ? <Form.Control.Feedback type="invalid">{meta.submitError}</Form.Control.Feedback> : null}

    </div>
  )
}

export default ControlTextarea