import React from 'react'
import i18n from '../../../utils/lang/i18n'

const SummaryModal = ({ closeAction, title, message, success, warning, error }) => {

  // var status = success ? "success" : warning ? "warning" : error ? "error" : ''

  return (
    <div className="modal-content">
      <div className="modal-header">
        <h4 className="modal-title">{title}</h4>
        <button type="button" className="close" aria-label="Close" onClick={closeAction}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {/*<div className={status}></div>*/}
        <p>{message}</p>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={closeAction}>{i18n.t('common:close')}</button>
      </div>
    </div>
  )

}



export default SummaryModal
