import React from 'react'
import { connect } from 'react-redux'
import { logRender } from '../../utils/logging'
import { withTranslation } from 'react-i18next'
import { isDefVal } from '../../utils/objtools'
import { mapResourceFromCode, mapResourceFromIds } from '../resource/resource_selectors'
import UiButton from "../ui/buttons/UiButton";
import Issue from "../issue/Issue";
import {stateIsUserBelongsConsorg, stateIsUserOwnsConsorg} from "./consorg_selectors";
import ResourceError from "../ui/ResourceError/ResourceError";
import Loader from "../loader/Loader";
import Visibility from "../../utils/debug/Visibility";
import AnimDelay from "../ui/AnimDelay/AnimDelay";
import Filter from "../filter/Filter";

class ConsorgIssues extends React.Component{

  constructor(props) {
    super(props)

    this.state = {
      openView: null,
      closedView: null,
      defaultView: 'open'
    }

    this.changeView = this.changeView.bind(this)
  }


  static getDerivedStateFromProps(props, state) {

    if (props.redux.issues.need_request) {
      props.requestResource(props.redux.issues.external_resource_infos)
    }

    var defaultView = state.openView === null

    return {
      openView: defaultView ? true : state.openView,
      closedView: state.closedView
  }
  }

  changeView (type){

    switch (type) {
      case 'open':
        this.setState({
          openView: true,
          closedView: false
        })
        break
      case 'closed':
        this.setState({
          openView: false,
          closedView: true
        });
        break
      default:
        console.log("the type is not valid")
    }
  }

  render () {
    logRender(this)
    if (!this.props.tReady)
      return null

    if (!this.props.redux.consorg.found)
      return null

    if (this.props.redux.issues.error || !this.props.redux.issues.found) {
      return <ResourceError />
    }

    var issues = {
      open: [],
      closed: []
    }

    const status_list = {
      OPEN: 'open',
      CLOSED: 'closed'
    }

    if (this.props.redux.issues.found) {

      var item
      for (item of this.props.redux.issues.items) {

        const status = isDefVal(item, 'data.status')
        if (Object.values(status_list).indexOf(status) === -1) {
          console.error('Unexpected status in the issue')
          continue
        }
        if (status === "open") {
          issues.open.push({
            resourceCode: item.resource_code,
            can_delete : this.props.redux.userBelongsConsorg,
            can_edit: this.props.redux.userBelongsConsorg,
          })
        }
        else if (status === "closed") {
          issues.closed.push({
            resourceCode: item.resource_code,
            can_delete : this.props.redux.userBelongsConsorg,
            can_edit: this.props.redux.userBelongsConsorg,
          })
        }
      }
    }

    return (

      <div className={this.getItemClasses().join(' ')} >
        <div className="grid g1_root g1_1col">

          <h2>{this.props.t('consorg:tab_issues_title')}</h2>
          <p>{this.props.t('consorg:tab_issues_description')}</p>

          {/*<h3>{this.props.t('consorg:tab_issues_actions_title')}</h3>*/}
          <div className="actions_wrapper grid g1_root g1_2col">
            <div className="smart_filter">
              <Filter key="open" type="open" label={issues.open.length + ' ' + this.props.t('consorg:tab_issues_list_open')}
                      isActive={this.state.openView === true} clickHandler={this.changeView}/>
              <Filter key="closed" type="closed" label={issues.closed.length + ' ' + this.props.t('consorg:tab_issues_list_closed')}
                      isActive={this.state.closedView === true} clickHandler={this.changeView}/>
            </div>
            <div className="actions">
              <UiButton variant="secondary" onClick={()=>this.props.historyPush('issue_new', {co: this.props.redux.consorg.ids.consorg_uname})} icon="add">{this.props.t('consorg:new_issue_button')}</UiButton>
            </div>
          </div>

          <Visibility condition={this.state.openView}>
            <AnimDelay animateAppearance={true} showDelay={100}>
              {(issues.open.length > 0 &&
                issues.open.map((issue) => <Issue key={issue.resourceCode} can_edit={issue.can_edit} can_delete={issue.can_delete} itemType="issue" resourceCode={issue.resourceCode} consorg_uname={this.props.redux.consorg.ids.consorg_uname}/>)
              ) ||
              ((!this.props.redux.issues.found || this.props.redux.issues.loading) &&
                <Loader loading={true} />
              ) ||
              <p className="empty_zone">{this.props.t('consorg:tab_issues_list_open_empty')}</p>
              }
            </AnimDelay>
          </Visibility>

          <Visibility condition={this.state.closedView}>
            <AnimDelay animateAppearance={true} showDelay={100}>
              {(issues.closed.length > 0 &&
                issues.closed.map((issue) => <Issue key={issue.resourceCode} can_edit={issue.can_edit} can_delete={issue.can_delete} itemType="issue" resourceCode={issue.resourceCode} consorg_uname={this.props.redux.consorg.ids.consorg_uname}/>)
              ) ||
              ((!this.props.redux.issues.found || this.props.redux.issues.loading) &&
                <Loader loading={true} />
              ) ||
              <p className="empty_zone">{this.props.t('consorg:tab_issues_list_closed_empty')}</p>
              }
            </AnimDelay>
          </Visibility>

        </div>
      </div>

    )

  }

  getItemClasses() {
    var classes = [
      'consorg',
      'issues'
    ]
    return classes
  }


}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    consorg: mapResourceFromCode(state, 'consorg', ownProps.resourceCode),
    userBelongsConsorg: stateIsUserBelongsConsorg(state, ownProps.resourceCode),
    userOwnsConsorg: stateIsUserOwnsConsorg(state, ownProps.resourceCode),
    lang_code: state.lang.lang_code
  }
  redux.issues = mapResourceFromIds(state, 'consorg_issues', redux.consorg.ids)
  return {
    redux
  }
}

const mapDispatchToProps = dispatch => ({
  requestResource: (external_resource_infos) => dispatch({type:'RESOURCE_LOAD_REQUEST', external_resource_infos}),
  historyPush: (scheme_key, queryParams) => { dispatch({type:'HISTORY_PUSH', scheme_key, queryParams}) }
})

ConsorgIssues = withTranslation(['common','consorg','issue'])(ConsorgIssues)

export default connect(mapStateToProps, mapDispatchToProps)(ConsorgIssues)
