import React from 'react'
import { connect } from 'react-redux'
import { logRender } from '../../utils/logging'
import { withTranslation } from 'react-i18next'
import UiH1 from '../ui/titles/UiH1'
import { withStaticContentCode } from '../static_content/static_content_hoc'
import { getFirstDef, isDefVal } from '../../utils/objtools'
import AnimDelay from '../ui/AnimDelay/AnimDelay'
import UiIconBlock from "../ui/UiIconBlock/UiIconBlock";
import UiButton from "../ui/buttons/UiButton";
import Expandable from "../ui/Expandable/Expandable";
import FilterXl from "../filter_xl/FilterXl";
import Visibility from "../../utils/debug/Visibility";
import { HashLink } from 'react-router-hash-link';

class OnBoarding extends React.Component {


  constructor(props) {
    super(props)

    this.state = {
      dspTwoView: null,
      businessPartnerView: null,
      defaultView: 'dsp'
    }

    this.changeView = this.changeView.bind(this)
  }


  static getDerivedStateFromProps(props, state) {

    var defaultView = state.dspTwoView === null

    return {
      dspTwoView: defaultView ? true : state.dspTwoView,
      businessPartnerView: state.businessPartnerView
    }
  }


  changeView (type){

    switch (type) {
      case 'dsp':
        this.setState({
          dspTwoView: true,
          businessPartnerView: false
        });
        break
      case 'business':
        this.setState({
          dspTwoView: false,
          businessPartnerView: true
        });
        break
      default:
        console.log("the type is not valid")
    }
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    if (!this.props.staticContent.found) {
      return null
    }

    const canshow = this.props.staticContent.loaded === true

    let breadcrumb = (
      <div className="c_breadcrumb">
        <HashLink smooth to="#sign_uc">{getFirstDef(this.props, 'staticContent.data.sign_uc.breadcrumb', '&nbsp;')}</HashLink>
        <HashLink smooth to="#consorg_uc">{getFirstDef(this.props, 'staticContent.data.consorg_uc.breadcrumb', '&nbsp;')}</HashLink>
        <HashLink smooth to="#client_app_uc">{getFirstDef(this.props, 'staticContent.data.client_app_uc.breadcrumb', '&nbsp;')}</HashLink>
        <HashLink smooth to="#test_sandbox_uc">{getFirstDef(this.props, 'staticContent.data.test_sandbox_uc.breadcrumb', '&nbsp;')}</HashLink>
        <HashLink smooth to="#become_partner_uc">{getFirstDef(this.props, 'staticContent.data.become_partner_uc.breadcrumb', '&nbsp;')}</HashLink>
        <HashLink smooth to="#go_live_uc">{getFirstDef(this.props, 'staticContent.data.go_live_uc.breadcrumb', '&nbsp;')}</HashLink>
      </div>
    )

    let explore_uc = (
      <AnimDelay animateAppearance={true} canShow={canshow} id="explore_uc">
        <h1 className="hidden_title" id="explore_uc">{getFirstDef(this.props, 'staticContent.data.explore_uc.step', '&nbsp;')}</h1>

        <h2>{getFirstDef(this.props, 'staticContent.data.explore_uc.title', '&nbsp;')}</h2>
        <div className="grid g1_211col">
          <div className="illustration">
            <img src={getFirstDef(this.props, 'staticContent.data.explore_uc.image', '')} alt={getFirstDef(this.props, 'staticContent.data.explore_uc.title', '')}/>
          </div>
          <div className="content">
            <p>{getFirstDef(this.props, 'staticContent.data.explore_uc.description', '&nbsp;')}</p>
            <div className="static_teasers">
              {getFirstDef(this.props, 'staticContent.data.explore_uc.products', []).map(({title, subtitle, icon}) => (
                  <UiIconBlock key={title} icon={icon} title={title} subtitle={subtitle}/>
              ))}
            </div>
            <div className="action_btns">
              <h5>& more ...</h5>
              <UiButton onClick={() => this.handleClick('product')}
                        variant="secondary" icon="product_black">{getFirstDef(this.props, 'staticContent.data.explore_uc.btn_label', '&nbsp;')}</UiButton>
            </div>
          </div>
        </div>
      </AnimDelay>
    )

    let sign_uc = (
      <AnimDelay animateAppearance={true} canShow={canshow}>
        <h1 className="hidden_title" id="sign_uc">{getFirstDef(this.props, 'staticContent.data.sign_uc.step', '&nbsp;')}</h1>

        <div className="grid g1_211col">
          <div className="content">
            <h2>{getFirstDef(this.props, 'staticContent.data.sign_uc.title', '&nbsp;')}</h2>

            <h4>{getFirstDef(this.props, 'staticContent.data.sign_uc.subtitle', '&nbsp;')}</h4>
            <p>{getFirstDef(this.props, 'staticContent.data.sign_uc.description', '&nbsp;')}</p>

            <div className="action_btns">
              <UiButton onClick={()=>window.open("https://sg-signin.societegenerale.fr/#/auth/sign-up", "_blank")}
                        variant="secondary" icon={getFirstDef(this.props, 'staticContent.data.sign_uc.btn_icon', '')}>{getFirstDef(this.props, 'staticContent.data.sign_uc.btn_label', '&nbsp;')}</UiButton>
            </div>
          </div>
          <div className="illustration bg">
            <img src={getFirstDef(this.props, 'staticContent.data.sign_uc.image', '')} alt={getFirstDef(this.props, 'staticContent.data.sign_uc.image', '')}/>
          </div>
        </div>
      </AnimDelay>
    )

    let consorg_uc = (
      <AnimDelay animateAppearance={true} canShow={canshow}>
        <h1 className="hidden_title" id="consorg_uc">{getFirstDef(this.props, 'staticContent.data.consorg_uc.step', '&nbsp;')}</h1>

        <h2>{getFirstDef(this.props, 'staticContent.data.consorg_uc.title', '&nbsp;')}</h2>
        <p>{getFirstDef(this.props, 'staticContent.data.consorg_uc.description', '&nbsp;')}</p>

        <div className="illustration large">
          <img src={getFirstDef(this.props, 'staticContent.data.consorg_uc.image', '')} alt={getFirstDef(this.props, 'staticContent.data.consorg_uc.title', '')}/>
        </div>

        <div className="grid g1_211col">

          <div className="create">
            <h4>{getFirstDef(this.props, 'staticContent.data.consorg_uc.create.title', '&nbsp;')}</h4>
            <p>{getFirstDef(this.props, 'staticContent.data.consorg_uc.create.description', '&nbsp;')}</p>
            <Expandable key="create_preview" title="Preview">
              <div className="illustration bg">
                <img src={getFirstDef(this.props, 'staticContent.data.consorg_uc.create.image', '')} alt={getFirstDef(this.props, 'staticContent.data.consorg_uc.create.title', '')}/>
              </div>
            </Expandable>
          </div>

          <div className="select">
            <h4>{getFirstDef(this.props, 'staticContent.data.consorg_uc.select.title', '&nbsp;')}</h4>
            <p>{getFirstDef(this.props, 'staticContent.data.consorg_uc.select.description', '&nbsp;')}</p>
            <Expandable key="select_preview" title="Preview">
              <div className="illustration bg">
                <img src={getFirstDef(this.props, 'staticContent.data.consorg_uc.select.image', '')} alt={getFirstDef(this.props, 'staticContent.data.consorg_uc.select.title', '')}/>
              </div>
            </Expandable>
          </div>

          <div className="invite">
            <h4>{getFirstDef(this.props, 'staticContent.data.consorg_uc.invite.title', '&nbsp;')}</h4>
            <p>{getFirstDef(this.props, 'staticContent.data.consorg_uc.invite.description', '&nbsp;')}</p>
            <Expandable key="invite_preview" title="Preview">
              <div className="illustration bg">
                <img src={getFirstDef(this.props, 'staticContent.data.consorg_uc.invite.image', '')} alt={getFirstDef(this.props, 'staticContent.data.consorg_uc.invite.title', '')}/>
              </div>
            </Expandable>
          </div>

        </div>
      </AnimDelay>
    )

    let client_app_uc = (
      <AnimDelay animateAppearance={true} canShow={canshow}>
        <h1 className="hidden_title" id="client_app_uc">{getFirstDef(this.props, 'staticContent.data.client_app_uc.step', '&nbsp;')}</h1>

        <h2>{getFirstDef(this.props, 'staticContent.data.client_app_uc.title', '&nbsp;')}</h2>
        <p>{getFirstDef(this.props, 'staticContent.data.client_app_uc.description', '&nbsp;')}</p>

        <div className="grid g1_211col">

          <div className="create">
            <h4>{getFirstDef(this.props, 'staticContent.data.client_app_uc.create.title', '&nbsp;')}</h4>
            <p>{getFirstDef(this.props, 'staticContent.data.client_app_uc.create.description', '&nbsp;')}</p>
            <Expandable key="create_preview" title="Preview">
              <div className="illustration bg">
                <img src={getFirstDef(this.props, 'staticContent.data.client_app_uc.create.image', '')} alt={getFirstDef(this.props, 'staticContent.data.client_app_uc.create.title', '')}/>
              </div>
            </Expandable>
          </div>

          <div className="verify">
            <h4>{getFirstDef(this.props, 'staticContent.data.client_app_uc.verify.title', '&nbsp;')}</h4>
            <p>{getFirstDef(this.props, 'staticContent.data.client_app_uc.verify.description', '&nbsp;')}</p>
            <Expandable key="select_preview" title="Preview">
              <div className="illustration bg">
                <img src={getFirstDef(this.props, 'staticContent.data.client_app_uc.verify.image', '')} alt={getFirstDef(this.props, 'staticContent.data.client_app_uc.verify.image', '')}/>
              </div>
            </Expandable>
          </div>

        </div>
      </AnimDelay>
    )

    let test_sandbox_uc = (
      <AnimDelay animateAppearance={true} canShow={canshow}>
        <h1 className="hidden_title" id="test_sandbox_uc">{getFirstDef(this.props, 'staticContent.data.test_sandbox_uc.step', '&nbsp;')}</h1>

        <div className="item_full_presentation">
          <h2>{getFirstDef(this.props, 'staticContent.data.test_sandbox_uc.title', '&nbsp;')}</h2>
          <p>{getFirstDef(this.props, 'staticContent.data.test_sandbox_uc.description', '&nbsp;')}</p>
          <div className="presentation_content gridg g1_2t1t_2_1">
            <div className="content">
              <div className="video">
                <video id="Subscribe_in_sandbox_tutorial" controls>
                  <source src="/assets/onboarding/subscribe_in_sandbox_tutorial_720p.mp4" type="video/mp4"/>
                </video>
              </div>
            </div>
            <div className="illustration tall">
              <img src={getFirstDef(this.props, 'staticContent.data.test_sandbox_uc.image', '')} alt={getFirstDef(this.props, 'staticContent.data.test_sandbox_uc.title', '')}/>
            </div>
          </div>
        </div>

      </AnimDelay>
    )

    let become_partner_uc = (
      <AnimDelay animateAppearance={true} canShow={canshow}>
        <h1 className="hidden_title" id="become_partner_uc">{getFirstDef(this.props, 'staticContent.data.become_partner_uc.step', '&nbsp;')}</h1>

        <h2>{getFirstDef(this.props, 'staticContent.data.become_partner_uc.title', '&nbsp;')}</h2>
        <p>{getFirstDef(this.props, 'staticContent.data.become_partner_uc.description', '&nbsp;')}</p>

        <div className="illustration large">
          <img src={getFirstDef(this.props, 'staticContent.data.become_partner_uc.image', '')} alt={getFirstDef(this.props, 'staticContent.data.consorg_uc.title', '')}/>
        </div>

        <div className="filters">
          <FilterXl key="dsp" type={getFirstDef(this.props, 'staticContent.data.become_partner_uc.dspTwo.type', '&nbsp;')}
                    label={getFirstDef(this.props, 'staticContent.data.become_partner_uc.dspTwo.title', '&nbsp;')}
                    icon={this.state.dspTwoView ? getFirstDef(this.props, 'staticContent.data.become_partner_uc.dspTwo.icon_w', '') : getFirstDef(this.props, 'staticContent.data.become_partner_uc.dspTwo.icon_b', '')}
                    isActive={this.state.dspTwoView === true} clickHandler={this.changeView}/>
          <FilterXl key="business" type={getFirstDef(this.props, 'staticContent.data.become_partner_uc.business.type', '&nbsp;')}
                    label={getFirstDef(this.props, 'staticContent.data.become_partner_uc.business.title', '&nbsp;')}
                    icon={this.state.businessPartnerView ? getFirstDef(this.props, 'staticContent.data.become_partner_uc.business.icon_w', '') : getFirstDef(this.props, 'staticContent.data.become_partner_uc.business.icon_b', '')}
                    isActive={this.state.businessPartnerView === true} clickHandler={this.changeView}/>
        </div>

        <Visibility condition={this.state.dspTwoView}>
          <AnimDelay animateAppearance={true} canShow={this.state.dspTwoView} showDelay={200}>
            <p>{getFirstDef(this.props, 'staticContent.data.become_partner_uc.dspTwo.description', '&nbsp;')}</p>
            <HashLink smooth to="#request_support_uc">{getFirstDef(this.props, 'staticContent.data.become_partner_uc.dspTwo.btn_label', '&nbsp;')}</HashLink>
          </AnimDelay>
        </Visibility>

        <Visibility condition={this.state.businessPartnerView}>
          <AnimDelay animateAppearance={true} canShow={this.state.businessPartnerView} showDelay={200}>
            <h4>{getFirstDef(this.props, 'staticContent.data.become_partner_uc.business.content.pro.title', '&nbsp;')}</h4>
            <p>{getFirstDef(this.props, 'staticContent.data.become_partner_uc.business.content.pro.terms', '&nbsp;')}</p>
            <UiButton onClick={()=>window.open(getFirstDef(this.props, 'staticContent.data.become_partner_uc.business.content.pro.link', ''), "_blank")}
                      variant="primary">{getFirstDef(this.props, 'staticContent.data.become_partner_uc.business.content.pro.btn_label', '&nbsp;')}</UiButton>

            <h4>{getFirstDef(this.props, 'staticContent.data.become_partner_uc.business.content.ent.title', '&nbsp;')}</h4>
            <p>{getFirstDef(this.props, 'staticContent.data.become_partner_uc.business.content.ent.terms', '&nbsp;')}</p>
            <UiButton onClick={()=>window.open(getFirstDef(this.props, 'staticContent.data.become_partner_uc.business.content.ent.link', ''), "_blank")}
                      variant="primary">{getFirstDef(this.props, 'staticContent.data.become_partner_uc.business.content.ent.btn_label', '&nbsp;')}</UiButton>
          </AnimDelay>
        </Visibility>

      </AnimDelay>
    )

    let go_live_uc = (
      <AnimDelay animateAppearance={true} canShow={canshow}>
        <h1 className="hidden_title" id="go_live_uc">{getFirstDef(this.props, 'staticContent.data.go_live_uc.step', '&nbsp;')}</h1>

        <div className="grid g1_211col">

          <div>
            <h2>{getFirstDef(this.props, 'staticContent.data.go_live_uc.title', '&nbsp;')}</h2>
            <p>{getFirstDef(this.props, 'staticContent.data.go_live_uc.description', '&nbsp;')}</p>

            <div className="switch_catalog mrg-bottom">
              <h4>{getFirstDef(this.props, 'staticContent.data.go_live_uc.switch_catalog.title', '&nbsp;')}</h4>
              <p>{getFirstDef(this.props, 'staticContent.data.go_live_uc.switch_catalog.description', '&nbsp;')}</p>
              <Expandable key="create_preview" title="Preview">
                <div className="illustration bg">
                  <img src={getFirstDef(this.props, 'staticContent.data.go_live_uc.switch_catalog.image', '')} alt={getFirstDef(this.props, 'staticContent.data.go_live_uc.switch_catalog.title', '')}/>
                </div>
              </Expandable>
            </div>

          </div>

          <div className="illustration tall">
            <img src={getFirstDef(this.props, 'staticContent.data.go_live_uc.image', '')} alt={getFirstDef(this.props, 'staticContent.data.go_live_uc.title', '')}/>
          </div>

        </div>
      </AnimDelay>
    )

    let request_support_uc = (
      <AnimDelay animateAppearance={true} canShow={canshow}>
        <h1 className="hidden_title" id="request_support_uc">{getFirstDef(this.props, 'staticContent.data.request_support_uc.step', '&nbsp;')}</h1>

        <h2>{getFirstDef(this.props, 'staticContent.data.request_support_uc.title', '&nbsp;')}</h2>
        <p>{getFirstDef(this.props, 'staticContent.data.request_support_uc.description', '&nbsp;')}</p>

        <div className="grid g1_211col">

          <div className="consorg">
            <h4>{getFirstDef(this.props, 'staticContent.data.request_support_uc.from_consorg_page.title', '&nbsp;')}</h4>
            <p>{getFirstDef(this.props, 'staticContent.data.request_support_uc.from_consorg_page.description', '&nbsp;')}</p>
            <Expandable key="create_preview" title="Preview">
              <div className="illustration bg">
                <img src={getFirstDef(this.props, 'staticContent.data.request_support_uc.from_consorg_page.image', '')} alt={getFirstDef(this.props, 'staticContent.data.request_support_uc.from_consorg_page.title', '')}/>
              </div>
            </Expandable>
          </div>

          <div className="product_or_api">
            <h4>{getFirstDef(this.props, 'staticContent.data.request_support_uc.from_product_or_api_page.title', '&nbsp;')}</h4>
            <p>{getFirstDef(this.props, 'staticContent.data.request_support_uc.from_product_or_api_page.description', '&nbsp;')}</p>
            <Expandable key="select_preview" title="Preview">
              <div className="illustration bg">
                <img src={getFirstDef(this.props, 'staticContent.data.request_support_uc.from_product_or_api_page.image', '')} alt={getFirstDef(this.props, 'staticContent.data.request_support_uc.from_product_or_api_page.image', '')}/>
              </div>
            </Expandable>
          </div>

        </div>

      </AnimDelay>
    )

    return (
      <div className={this.getItemClasses().join(' ')} >
        <div className="grid g1_root g1_1col">
          <UiH1>{this.props.t('onboarding:title')}</UiH1>
          <AnimDelay animateAppearance={true} canShow={canshow}>
            <p className="intro">{isDefVal(this.props.staticContent, 'data.intro')}</p>
          </AnimDelay>

          {breadcrumb}

          {explore_uc}

          {sign_uc}

          {consorg_uc}

          {client_app_uc}

          {test_sandbox_uc}

          {become_partner_uc}

          {go_live_uc}

          {request_support_uc}

        </div>
      </div>
    )
  }

  handleClick(target) {
    this.props.finderGo(target)
  }

  getItemClasses() {
    var classes = [
      'onboarding',
      'full'
    ]
    return classes;
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    lang_code: state.lang.lang_code
  }

  return {
    redux
  }
}

const mapDispatchToProps = dispatch => ({
  finderGo: (target) => {dispatch({type: 'FINDER_GO', target})},
  historyPush: (scheme_key, queryParams) => { dispatch({type:'HISTORY_PUSH', scheme_key, queryParams}) }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStaticContentCode(
  'onboarding'
)(withTranslation(
  'onboarding','consorg'
)(OnBoarding)))
