import React from 'react'
import { connect } from 'react-redux'
import { logRender } from '../../../utils/logging'
import { withTranslation } from 'react-i18next'
import {deepEqualObjs2, isDef} from '../../../utils/objtools'
import MediaSection from "../media_section/MediaSection";

class MediasPool extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (!deepEqualObjs2(this.props.redux, nextProps.redux)
      || !deepEqualObjs2(this.props.medias, nextProps.medias)
      || this.props.tReady !== nextProps.tReady
    )
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    var sections
    if(isDef(this.props,'medias.sections') && this.props.medias.sections.length > 0){
      sections = (
        this.props.medias.sections.map(({title,description,mediaName,textAlignment}) => (
          <MediaSection key={title} title={title} description={description} media_name={mediaName} text_align={textAlignment} organization={this.props.organization}/>
        ))
      )
    }

    return (
      <div className={this.getItemClasses().join(' ')}>
        <div className="grid g1_root g1_1col">
          <h2>{this.props.summary}</h2>
          <div className="wrapper">
            <div className="items">
              {sections}
            </div>
          </div>
        </div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'medias-pool'
    ]
    return classes;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    redux: {
      lang_code: state.lang.lang_code
    }
  }
}


MediasPool = withTranslation(['common'])(MediasPool)

export default connect(mapStateToProps, null)(MediasPool)
