import React from 'react'
import { connect } from 'react-redux'
import { logRender } from '../../utils/logging'
import { withTranslation } from 'react-i18next'
import {deepEqualObjs2, getFirstDef} from '../../utils/objtools'
import { mapResourceFromCode } from '../resource/resource_selectors'
import Icon from "../icon/Icon";
import Visibility from "../../utils/debug/Visibility";
import Button from "react-bootstrap/Button";
import {dateFormatFromString} from "../../utils/date";
import {buildUri} from "../../utils/urls/internal";
import {Link} from "react-router-dom";

class Issue extends React.Component{

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || this.props.tReady !== nextProps.tReady
      || this.props.can_accept !== nextProps.can_accept
      || this.props.can_delete !== nextProps.can_delete
      || this.props.can_edit !== nextProps.can_edit
    )
  }

  render () {
    logRender(this)

    const issue = this.props.redux.issue

    // Assume Optimistic Rendering
    if (issue.error) {
      return <p>Error</p>
    }

    var title
    title = getFirstDef(issue, ['data.title'], null)

    var id
    id = getFirstDef(issue, ['data.id'], null)

    var created_at
    created_at = dateFormatFromString(getFirstDef(issue, ['data.created_at'], null))


    var author
    author = getFirstDef(issue, ['data.username'], null)

    var status
    status = getFirstDef(issue, ['data.status'], null)

    var content_pills = []

    if (status === "open"){
      content_pills.push(<div key="open" className="pill">{status}</div>)
    }
    else if (status === "closed"){
      content_pills.push(<div key="closed" className="pill secondary">{status}</div>)
    }
    var link = (<Button key="edit_button" variant="secondary">{this.props.t('issue:consult_button')}</Button>)
    if (this.props.redux.uri) {
      link = <Link to={this.props.redux.uri}>{link}</Link>
    }

    return (

      <div className={this.getItemClasses().join(' ')} >
        <div className="content">
          <Icon type="issue_black" />
          <div className="infos">
            {content_pills}
            <h4 key="title">{title}</h4>
            <p className="issue_details">
              <span key="number">#{id} </span>
              <span key="opened_date">{this.props.t('issue:opened_date_description')} {created_at} </span>
              <span key="author">{this.props.t('issue:opened_by_description')} {author}</span>
            </p>
          </div>
          <div className="actions">
            <Visibility key="edit_button" condition={this.props.can_edit}>
              {link}
            </Visibility>
            {/*{otherContentActions}*/}
          </div>
        </div>
      </div>

    )

  }

  getItemClasses() {
    var classes = [
      'issue',
      'bloc'
    ]
    return classes
  }


}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    issue: mapResourceFromCode(state, ownProps.itemType, ownProps.resourceCode),
    lang_code: state.lang.lang_code
  }
  redux.uri = buildUri('issue', { ...redux.issue.ids,issue_uname:redux.issue.ids.issue_id, lang_code: redux.lang_code})

  return {
    redux
  }
}

const mapDispatchToProps = dispatch => ({
  historyPush: (scheme_key, queryParams) => { dispatch({type:'HISTORY_PUSH', scheme_key, queryParams}) }
})

Issue = withTranslation(['common','consorg','issue'])(Issue)

export default connect(mapStateToProps, mapDispatchToProps)(Issue)
