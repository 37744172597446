import React from 'react'
import {connect} from 'react-redux'
import {logRender} from '../../../utils/logging'
import {withStaticContentCode} from '../../static_content/static_content_hoc'
import {deepEqualObjs2, getFirstDef} from '../../../utils/objtools'
import AnimDelay from "../../ui/AnimDelay/AnimDelay";


class PushLevels extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {

    return (
      !deepEqualObjs2(this.props.staticContent.data, nextProps.staticContent.data)
      || !deepEqualObjs2(this.props.redux, nextProps.redux)
    )
  }

  render() {
    logRender(this)

    let ranks = []

    if (!this.props.staticContent.data.levels?.length) {
      return null
    }

    for (let level in this.props.staticContent.data.levels) {
      ranks.push(this.props.staticContent.data.levels[level]);
    }

    return (
      <div className={this.getItemClasses().join(' ')}>
        <AnimDelay renderDelay={500} animateAppearance={true} showDelay={600}>
          <div className="grid g1_root g1_1col">
            <h3>{getFirstDef(this.props, 'staticContent.data.title', '&nbsp;')}</h3>

            <div className="grid g1_211col">

              {ranks.map(({id, name, state, limit, type, data_type, cost}) => (
                <div className={[id, "rank"].join(' ')} key={id}>
                  <header>
                    <div className={["icon", id].join(' ')} title={name}></div>
                    <h3>{name}</h3>
                  </header>
                  <div className="terms">

                    <div className="pills">
                      <div className="pill">{state}</div>
                      <div className="pill">{limit}</div>
                      <div className="pill">{data_type}</div>
                      <div className="pill">{cost}</div>
                    </div>

                    {/*<p>*/}
                    {/*  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.*/}
                    {/*</p>*/}

                  </div>
                </div>
              ))}

            </div>
          </div>
        </AnimDelay>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'push',
      'levels'
    ]
    return classes;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    redux: {
      lang_code: state.lang.lang_code
    }
  }
}

export default connect(
  mapStateToProps,
  null
)(withStaticContentCode(
  'push_levels'
)(PushLevels))
