import React from 'react'
import { connect } from 'react-redux'
import { logRender } from '../../utils/logging'
import { getFirstDef, isDefVal } from '../../utils/objtools'
import { Form as FinalForm, Field as FinalField} from 'react-final-form'
import { withTranslation } from 'react-i18next'
import { Form } from 'react-bootstrap'
import UiButton from '../ui/buttons/UiButton'
import ControlInput from '../../utils/forms/ControlInput'
import ControlTextarea from '../../utils/forms/ControlTextarea'
import ControlSelect from '../../utils/forms/ControlSelect'
import ControlJsonEditor from '../../utils/forms/ControlJsonEditor'
import ControlCheck from '../../utils/forms/ControlCheck'
import { buildMachineName, machineNameRegExp } from '../../utils/forms/forms_tools'
import Loader from '../loader/Loader'
import { stateFindItemByCodeOrAlias } from '../item/item_selectors'
import createDecorator from 'final-form-calculate'
import { stateIsPortalDebug } from '../../components/portal/portal_selectors'
import branding from "../portal/branding";

const calculateFields = createDecorator(
  {
    field: 'title',
    updates: {
      name: (titleValue, allValues, prevValues) => {
        var previousVirtualName = buildMachineName(getFirstDef(prevValues, 'title', ''))
        var newName = buildMachineName(titleValue)
        if (previousVirtualName !== newName) {
          if (getFirstDef(allValues, 'name', '') === previousVirtualName || getFirstDef(allValues, 'name', '') === '') {
            return newName
          }
        }
        return allValues.name
      }
    }
  }
)

class ApplicationFinalForm extends React.Component {

  constructor(props) {
    super(props)

    this.validateForm = this.validateForm.bind(this)
  }

  validateForm = (values) => {
    const errors = {}

    if (!values.title) {
      errors.title = this.props.t('forms:error_required')
    }
    else if (values.title !== values.title.trim()) {
      errors.title = this.props.t('forms:error_no_begin_or_end_space')
    }

    if (!values.name) {
      errors.name = this.props.t('forms:error_required')
    }
    else if (!values.name.match(machineNameRegExp)) {
      errors.name = this.props.t('forms:error_technical_name_pattern')
    }

    if (!values.description) {
      errors.description = this.props.t('forms:error_required')
    }
    else if (values.description && values.description !== values.description.trim()) {
      errors.description = this.props.t('forms:error_no_begin_or_end_space')
    }

    // Verifier uniquement ces champs en OPEN
    if(!branding.open){
      if (!values.irtCode) {
        errors.irtCode = this.props.t('forms:error_required')
      }
      else if (values.irtCode && values.irtCode !== values.irtCode.trim()) {
        errors.irtCode = this.props.t('forms:error_no_begin_or_end_space')
      }

      if (!values.trigramCode) {
        errors.trigramCode = this.props.t('forms:error_required')
      }
      else if (values.trigramCode && values.trigramCode !== values.trigramCode.trim()) {
        errors.trigramCode = this.props.t('forms:error_no_begin_or_end_space')
      }
    }

    if (this.props.form_op === 'new') {

      var re_redirectUris = /^(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.?[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)(\r?\n|$))*$/
      if (values.credentialRedirectUris && !re_redirectUris.test(values.credentialRedirectUris)) {
        errors.credentialRedirectUris = this.props.t('forms:error_redirectUris_pattern')
      }

      // TODO Verify the regexp
      var re_credentialCertificate = /^-----BEGIN CERTIFICATE-----\r?\n((?!-----)\S+\r?\n)+-----END CERTIFICATE-----$/
      if (values.credentialCertificate && !re_credentialCertificate.test(values.credentialCertificate)) {
        errors.credentialCertificate = this.props.t('forms:error_credentialCertificate_pattern')
      }

      if (!values.forcedAdapter && !branding.open) {
        errors.forcedAdapter = this.props.t('forms:error_required')
      }

      if (!values.relationship_consorg || values.relationship_consorg==="_none") {
        errors.relationship_consorg = this.props.t('forms:error_required')
      }
    }

    return errors
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    // if (!this.props.redux.relationship_consorg_options_ready)
    //   return null

    if (['new','edit'].indexOf(getFirstDef(this.props, 'form_op', '')) < 0 ) {
      console.error('form_op is not defined or not handled')
      return null
    }

    const t = this.props.t

    let auth_form_fields = []

    for(let i in this.props.initialValues.auth_adapters){
      auth_form_fields.push(
        <FinalField name="forcedAdapter"
                    type='radio'
                    component={ControlCheck}
                    key={this.props.initialValues.auth_adapters[i].name}
                    label={this.props.initialValues.auth_adapters[i].label}
                    value={this.props.initialValues.auth_adapters[i].name}
                    id={this.props.initialValues.auth_adapters[i].name}
        />)
    }

    return (
      <FinalForm
        initialValues={this.props.initialValues}
        onSubmit={this.props.onSubmit}
        validate={this.validateForm}
        decorators={[calculateFields]}
      >
        {({ handleSubmit, pristine, form, submitting }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <fieldset>
                <h3>{t('application:fieldset_general_information')}</h3>
                <p>{t('application:fieldset_general_information_intro')}</p>
                <Form.Group controlId="title">
                  <Form.Label>{t('forms:field_title')}</Form.Label>
                  <FinalField name="title" type='text' component={ControlInput} placeholder={t('forms:field_title')} />
                  <Form.Text className="text-muted">{t('application:field_title_desc')}</Form.Text>
                </Form.Group>
                <Form.Group controlId="name">
                  <Form.Label>{t('forms:field_name')}</Form.Label>
                  <FinalField name="name" type='text' component={ControlInput} placeholder={t('forms:field_name')} />
                  <Form.Text className="text-muted">{t('forms:field_name_desc')}</Form.Text>
                </Form.Group>
                <Form.Group controlId="description">
                  <Form.Label>{t('forms:field_description')}</Form.Label>
                  <FinalField name="description" type='text' component={ControlTextarea} placeholder="" rows="5" />
                  <Form.Text className="text-muted">{t('forms:field_description_desc')}</Form.Text>
                </Form.Group>

                {!branding.open &&
                    <>
                      <Form.Group controlId="irtCode">
                        <Form.Label>{t('application:field_irt_code')}</Form.Label>
                        <FinalField name="irtCode" type='text' component={ControlInput} placeholder={t('application:field_irt_code')}/>
                        <Form.Text className="text-muted">{t('application:field_irt_code_desc')}</Form.Text>
                      </Form.Group>
                      <Form.Group controlId="trigramCode">
                        <Form.Label>{t('application:field_trigram_code')}</Form.Label>
                        <FinalField name="trigramCode" type='text' component={ControlInput} placeholder={t('application:field_trigram_code')} />
                        <Form.Text className="text-muted">{t('application:field_trigram_code_desc')}</Form.Text>
                      </Form.Group>
                    </>
                }
              </fieldset>

              <hr />

              {this.props.form_op === 'new' &&
                <>
                  <fieldset>
                    <h3>{t('application:fieldset_credential')}</h3>
                    <p>{t('application:fieldset_credential_intro')}</p>
                    <Form.Group controlId="credentialRedirectUris">
                      <Form.Label>{t('application:field_credentialRedirectUris')}</Form.Label>
                      <FinalField name="credentialRedirectUris" component={ControlTextarea} placeholder="" rows="5" />
                      <Form.Text className="text-muted">{t('application:field_credentialRedirectUris_desc')}</Form.Text>
                    </Form.Group>
                    <Form.Group controlId="credentialCertificate">
                      <Form.Label>{t('application:field_credentialCertificate')}</Form.Label>
                      <FinalField name="credentialCertificate" component={ControlTextarea} placeholder="" rows="5" />
                      <Form.Text className="text-muted">{t('application:field_credentialCertificate_desc')}</Form.Text>
                    </Form.Group>

                    {branding.open &&
                    <Form.Group controlId="sealingCertificates">
                      <Form.Label>{t('application:field_sealingCertificates')}</Form.Label>
                      <FinalField name="sealingCertificates" component={ControlJsonEditor} placeholder={{
                        "keys": [
                          {
                            "e": "AQAB",
                            "kid": "c884f0f1-b476-4514-9313-a838f8e38be2",
                            "kty": "RSA",
                            "n": "hlUv...",
                            "use": "sig",
                            "x5c": [
                              "This is an example placeholder..."
                            ],
                          }
                        ]}}/>
                      <Form.Text className="text-muted">{t('application:field_sealingCertificates_desc')}</Form.Text>
                    </Form.Group>
                    }
                  </fieldset>

                  {!branding.open &&
                  <fieldset>
                    <h3>{t('application:fieldset_authentication_method')}</h3>
                    <p>{t('application:fieldset_authentication_method_intro')}</p>
                    <Form.Group controlId="forcedAdapter">
                      {auth_form_fields}
                      {/*TODO this helper need to have a dynamic text */}
                      {/*<Form.Text className="text-muted">{t('application:field_authenticationMethod_desc')}</Form.Text>*/}
                    </Form.Group>
                  </fieldset>
                  }
                </>
              }

              <hr />

              {this.props.form_op === 'new' &&
                <fieldset>
                  <h3>{t('application:fieldset_relationship')}</h3>
                  <Form.Group controlId="relationship_consorg">
                    <Form.Label>{t('application:field_relationship_consorg')}</Form.Label>
                    <FinalField name="relationship_consorg" component={ControlSelect} options={this.props.redux.relationship_consorg_options} />
                    <Form.Text className="text-muted">{t('application:field_relationship_consorg_desc')}</Form.Text>
                  </Form.Group>
                </fieldset>
              }
              <div className="form-actions">
                <UiButton variant="primary" type="submit" disabled={pristine||submitting}>{t(this.props.form_op==='new'?'forms:submit_create':'forms:submit_update')}</UiButton>
                <UiButton variant="secondary" type="button" disabled={pristine||submitting} onClick={form.reset}>{t('forms:reset')}</UiButton>
                <Loader loading={submitting} />
              </div>
            </Form>
          )
        }}
      </FinalForm>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    lang_code: state.lang.lang_code,
    relationship_consorg_options: {"_none": '-'},
    //relationship_consorg_options_ready: false,
    stateIsPortalDebug: stateIsPortalDebug(state)
  }
  var consorg_resource_codes = isDefVal(state, 'list.consorgs_user_belongs')
  if (consorg_resource_codes && consorg_resource_codes.length > 0) {
    //redux.relationship_consorg_options_ready = true
    for (var consorg_resource_code of consorg_resource_codes) {
      var consorg = stateFindItemByCodeOrAlias(state, 'consorg', consorg_resource_code)
      redux.relationship_consorg_options[consorg.data.id] = consorg.consolidated.title
    }
  }
  return {
    redux
  }
}

export default connect(
    mapStateToProps,
    null
  )(withTranslation(
    ['forms','application','enums']
  )(ApplicationFinalForm))
