import React from 'react'
import { connect } from 'react-redux'
import { logRender } from '../../../utils/logging'
import { withTranslation } from 'react-i18next'
import setDocumentTitle from '../../../utils/browser/title'
import UiH1 from '../titles/UiH1'

class ResourceError extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      JSON.stringify(this.props.redux) !== JSON.stringify(nextProps.redux)
      || this.props.tReady !== nextProps.tReady
    )
  }

  componentDidMount() {
    setDocumentTitle('Resource Error')
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    return (
      <div className="resource-error">
        <div className="grid g1_root g1_1col">
          <UiH1>{this.props.t('resourceerror:title')}</UiH1>
          <p>{this.props.t('resourceerror:message')}</p>
          {this.props.details?<p><strong>Please mention the current url and the following details:</strong><br />{this.props.details}</p>:null}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    lang_code: state.lang.lang_code
  }
  return {
    redux
  }
}

export default connect(
  mapStateToProps,
  null
)(withTranslation('resourceerror')(ResourceError));
