import React from 'react'
import { logRender } from '../../utils/logging'
import { withTranslation } from 'react-i18next'
import { deepEqualObjs2 } from '../../utils/objtools'

class FinderFilterSummaryItem extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props, nextProps)
    )
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    return (
      <div className={this.getItemClasses().join(' ')} >
        <div className="content">
          <div className="label">{this.props.label}</div>
          <div className="remove" onClick={this.props.onClickRemove}></div>
        </div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'filter-summary-item'
    ]
    return classes;
  }
}

export default withTranslation('finder')(FinderFilterSummaryItem);
