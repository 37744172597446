import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { bindPromiseCreators } from 'redux-saga-routines'
import setDocumentTitle from '../../utils/browser/title'
import { logRender } from '../../utils/logging'
import { deepEqualObjs2, isDefVal } from '../../utils/objtools'
import { getPortalCurrentCatalogParam } from '../portal/portal_selectors'
import SingleHeader from '../ui/SingleHeader/SingleHeader'
import ConsorgFinalForm from './ConsorgFinalForm'
import { submitConsorgFinalFormNewHandler } from './consorg_routines'
import Visibility from '../../utils/debug/Visibility'
import { stateAuthIsUserLoggedIn } from '../../components/auth/auth_selectors'

class ConsorgNew extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || this.props.tReady !== nextProps.tReady
    )
  }

  componentDidMount() {
    this.postRender()
  }

  componentDidUpdate() {
    this.postRender()
  }

  postRender() {
    if (this.props.tReady) {
      setDocumentTitle(this.props.t('consorg:form_new_title'))
    }
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    var initialValues = {
      // Fields
      // title: 'My Organisation'
    }

    return (
      <Visibility
        condition={isDefVal(this.props.redux, 'consumerOrgsConfiguration.canUsersCreate') === true && this.props.redux.authIsUserLoggedIn === true}
        resourceErrorDetails="Catalog does not allow consumer organization creation."
      >
        <div className={this.getItemClasses().join(' ')} >
          <div className="grid g1_root g1_1col">
            <SingleHeader key="header" item_type="consorg" consolidated={{title: this.props.t('consorg:form_new_title')}} pills={null} loading={false} />
            <ConsorgFinalForm form_op="new" onSubmit={this.props.submitConsorgFinalFormNewHandler} initialValues={initialValues} />
          </div>
        </div>
      </Visibility>
    )
  }

  getItemClasses() {
    var classes = [
      'consorg',
      'form_new',
      'form_page'
    ]
    return classes
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    redux: {
      lang_code: state.lang.lang_code,
      consumerOrgsConfiguration: getPortalCurrentCatalogParam(state, 'consumerOrgsConfiguration'),
      authIsUserLoggedIn: stateAuthIsUserLoggedIn(state)
    }
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindPromiseCreators({
    submitConsorgFinalFormNewHandler,
    // other promise creators can be here...
  }, dispatch)
})

ConsorgNew = withTranslation(
  ['common','consorg']
)(ConsorgNew)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsorgNew)