import { createRoutine, promisifyRoutine } from 'redux-saga-routines'

// New Consorg
// Routine for Form
export const submitConsorgFormNewRoutine = createRoutine('SUBMIT_CONSORG_FORM_NEW')
export const submitConsorgFinalFormNewHandler = promisifyRoutine(submitConsorgFormNewRoutine)

// Edit Consorg
// Routine for Form
export const submitConsorgFormEditRoutine = createRoutine('SUBMIT_CONSORG_FORM_EDIT')
export const submitConsorgFinalFormEditHandler = promisifyRoutine(submitConsorgFormEditRoutine)

// Delete Consorg
export const submitConsorgFormDeleteRoutine = createRoutine('SUBMIT_CONSORG_FORM_DELETE')