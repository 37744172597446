import React from 'react'
import { logRender } from '../../utils/logging'
import {getFirstDef} from '../../utils/objtools'
import { Form as FinalForm, Field as FinalField} from 'react-final-form'
import { Form } from 'react-bootstrap'
import UiButton from '../ui/buttons/UiButton'
import Loader from '../loader/Loader'
import ControlSelect from "../../utils/forms/ControlSelect";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {mapResourceFromCode, mapResourceFromIds} from "../resource/resource_selectors";
import {stateGetSelectedConsorgResourceCode} from "../consorg/consorg_selectors";

class SubscriptionScopesFinalForm extends React.Component {

  constructor(props) {
    super(props)

    this.validateForm = this.validateForm.bind(this)
  }

  validateForm = (values) => {
    const errors = {}


    return errors
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    if (['new','edit'].indexOf(getFirstDef(this.props, 'form_op', '')) < 0 ) {
      console.error('form_op is not defined or not handled')
      return null
    }

    const t = this.props.t

    return (
      <FinalForm
        initialValues={this.props.initialValues}
        onSubmit={this.props.onSubmit}
        validate={this.validateForm}
      >
        {({ handleSubmit, pristine, form, submitting }) => {
          return (
            <Form onSubmit={handleSubmit} className="subscription-form">
              <Form.Group controlId="applications">
                <Form.Label>Application</Form.Label>
                <FinalField name="application" component={ControlSelect} options={this.props.redux.applications_belong_to_selected_consorg_options} />
                <Form.Text className="text-muted">{t('subscriptionscopes:field_application_list')}</Form.Text>
              </Form.Group>
              <div className="form-actions">
                <Loader loading={submitting} />
                <UiButton variant="primary" type="submit" disabled={(pristine && this.props.form_op==='edit') || submitting}>{t('subscriptionscopes:subscribe_button')}</UiButton>
              </div>
            </Form>
          )
        }}
      </FinalForm>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    lang_code: state.lang.lang_code,
    applications_belong_to_selected_consorg_options: {"_none": '-'},
    consorg: mapResourceFromCode(state, 'consorg', stateGetSelectedConsorgResourceCode(state)),
  }

  // Applications list belong to the current CO
  redux.application = mapResourceFromIds(state, 'consorg_applications', redux.consorg.ids)

  for(let item in redux.application.items){
    var id = redux.application.items[item].data.id
    redux.applications_belong_to_selected_consorg_options[id] = redux.application.items[item].consolidated.title
  }

  return {
    redux
  }
}

export default connect(
  mapStateToProps,
  null
)(withTranslation(
  ['forms','subscriptionscopes']
)(SubscriptionScopesFinalForm))
