import React from 'react'
import { logRender } from '../../utils/logging'
import { isDef, deepEqualObjs2 } from '../../utils/objtools'
import { withStaticContent } from './static_content_hoc'

class StaticContent extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props, nextProps)
    )
  }

  render() {
    logRender(this)

    var markup = ''
    if (isDef(this.props, 'staticContent.data.markup')) {
      markup = this.props.staticContent.data.markup
    }

    return (
      <div className={this.getItemClasses().join(' ')} >
        <div dangerouslySetInnerHTML={{__html: markup}}></div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'static_content'
    ]
    return classes;
  }
}

export default withStaticContent(StaticContent)