import { deepCopyObj, copyObj, propertyCopy, isDef } from '../../utils/objtools'
import { saveLocalStorage, loadLocalStorage } from '../../utils/localstorage'
import { parseQueryParams } from '../../utils/urls/tools'

// handler pour debug mutation du state
// const handler = {
//   // get(target, property, receiver) {
//   //   console.log('get', property)
//   //   return Reflect.get(target, property, receiver);
//   // },
//   set(target, property, value) {
//     console.log('set', property, value);
//     console.trace();
//     return Reflect.set(target, property, value);
//   }
//   // deleteProperty(target, property) {
//   //   return Reflect.deleteProperty(target, property);
//   // }
// };

var last_selected_consorgs = loadLocalStorage('sparky_consumer_last_selected_consorgs')
if (!last_selected_consorgs)
  last_selected_consorgs = {}

const redPortal = (
  state = {
    context_loaded: false,
    context_loading: false,
    context_error: false,
    context: {
      catalogParams: {},
      organisationParams: {}
    },
    // The current page
    page: {
      scheme: null,
      routeProps: null
    },
    // The current page displayed resource. /!\ may be outdated, not all pages dispatch their resource /!\
    resource: {
      external_resource_infos: null
    },
    // The current consorg
    consorg: {
      resourceCode: null
    },
    last_selected_consorgs,
    external_queries_logs: {},
    debug: false
  },
  action
) => {
  var new_state = null
  switch (action.type) {

    case "LOG_EXTERNAL_QUERY":
      // TODO remove or disable this for prod builds
      new_state = copyObj(state)
      propertyCopy(new_state, 'external_queries_logs')
      if (isDef(new_state.external_queries_logs, action.scheme_key)) {
        propertyCopy(new_state.external_queries_logs, action.scheme_key)
      }
      else {
        new_state.external_queries_logs[action.scheme_key] = []
      }
      new_state.external_queries_logs[action.scheme_key].push({url:action.url, status:action.status, has_error:action.has_error, has_data:action.has_data})
      break

    case "PORTAL_LOAD_CONTEXT_START":
      new_state = copyObj(state)
      new_state.context_loading = true
      break

    case "PORTAL_LOAD_CONTEXT_ERROR":
      new_state = copyObj(state)
      new_state.context_loading = false
      new_state.context_error = true
      break

    case "PORTAL_LOAD_CONTEXT_SUCCESS":
      new_state = copyObj(state)
      new_state.context_loading = false
      new_state.context_loaded = true
      new_state.context = {
          ...action.context,
          catalogParams: {},
          organisationParams: {}
        }
      break

    case 'PORTAL_CHANGE_CATALOG_START':
      new_state = copyObj(state)
      propertyCopy(new_state, 'context')
      new_state.context.catalog = new_state.context.catalogs[action.catalog_id]
      new_state.context.catalog_loaded = false
      new_state.context.catalog_error = false
      new_state.context.catalogParams = null
      new_state.context.catalog_has_products = null
      new_state.context.catalog_has_apis = null
      new_state.context.catalog_has_consorgs = null
      new_state.context.catalog_has_scopes = null
      //new_state.context.catalog_has_applications = null
      new_state.consorg = {
          resourceCode: null
        }
      break

    case "AUTH_HAS_LOGGED_OUT":
      new_state = copyObj(state)
      new_state.consorg = {
        resourceCode: null
      }
      break

    case 'PORTAL_CATALOG_STATUS':
      new_state = copyObj(state)
      propertyCopy(new_state, 'context')
      new_state.context.catalog_loaded = action.status === 'success'
      new_state.context.catalog_error = action.status !== 'success'
      new_state.context.catalogParams = action.catalogParams
      break

    case 'PORTAL_ORGANISATION_STATUS':
      new_state = copyObj(state)
      propertyCopy(new_state, 'context')
      new_state.context.organisation_loaded = action.status === 'success'
      new_state.context.organisation_error = action.status !== 'success'
      new_state.context.organisationParams = action.organisationParams
      break

    case 'PORTAL_CATALOG_HAS_PRODUCTS':
      new_state = copyObj(state)
      propertyCopy(new_state, 'context')
      new_state.context.catalog_has_products = action.value
      break

    case 'PORTAL_CATALOG_HAS_APIS':
      new_state = copyObj(state)
      propertyCopy(new_state, 'context')
      new_state.context.catalog_has_apis = action.value
      break

    case 'PORTAL_CATALOG_HAS_CONSORGS':
      new_state = copyObj(state)
      propertyCopy(new_state, 'context')
      new_state.context.catalog_has_consorgs = action.value
      break

    case 'PORTAL_CATALOG_HAS_SCOPES':
      new_state = copyObj(state)
      propertyCopy(new_state, 'context')
      new_state.context.catalog_has_scopes = action.value
      break

    // case 'PORTAL_CATALOG_HAS_APPLICATIONS':
    //   new_state = copyObj(state)
    //   propertyCopy(new_state, 'context')
    //   new_state.context.catalog_has_applications = action.value
    //   break

    case 'PORTAL_ORGANISATION_HAS_STATIC_CONTENT':
      new_state = copyObj(state)
      propertyCopy(new_state, 'context')
      new_state.context.organisation_has_static_content = action.value
      break

    case 'PORTAL_CATALOG_UNAME_WRONG':
      new_state = copyObj(state)
      propertyCopy(new_state, 'context')
      new_state.context.catalog_uname_wrong = action.catalog_uname
      break

    case 'DISPLAY_PAGE':
      new_state = copyObj(state)
      propertyCopy(new_state, 'page')
      new_state.page.scheme = deepCopyObj(action.scheme)
      new_state.page.routeProps = deepCopyObj(action.routeProps)
      // Detect debug mode
      if (!new_state.debug) {
        const queryParams = parseQueryParams(action.routeProps.location.search)
        if (queryParams.test === "") {
          new_state.debug = true
        }
      }
      // exemple proxy pour detection mutation
      //new_state.page.routeProps.match.params = new Proxy(new_state.page.routeProps.match.params, handler)
      break

    case 'DISPLAY_RESOURCE':
      new_state = copyObj(state)
      propertyCopy(new_state, 'resource')
      new_state.resource.external_resource_infos = deepCopyObj(action.external_resource_infos)
      break

    case 'SET_CURRENT_CONSORG':
      new_state = copyObj(state)
      propertyCopy(new_state, 'consorg')
      new_state.consorg.resourceCode = action.resourceCode
      // store last selected consorg per catalog
      new_state.last_selected_consorgs[state.context.catalog.catalog_id] = action.resourceCode
      saveLocalStorage('sparky_consumer_last_selected_consorgs', new_state.last_selected_consorgs)
      break

    case 'RESOURCE_DELETED':
      if (action.external_resource_infos.resource_code === state.consorg.resourceCode) {
        new_state = copyObj(state)
        propertyCopy(new_state, 'consorg')
        new_state.consorg.resourceCode = null
      }
      break

    default:
      break
  }
  if (new_state) {
    // console.log('PORTAL-reducer from : ', isDefVal(state, 'page.routeProps.match.params.lang_code'))
    // console.log('PORTAL-reducer to : ', isDefVal(new_state, 'page.routeProps.match.params.lang_code'))
    return new_state
  }
  else {
    return state
  }
}

export default redPortal;
