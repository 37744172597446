import React from 'react'
import { connect } from '../../utils/redux'
import { logRender } from '../../utils/logging'
import GGmenuItem from './GGMenuItem'
import { deepEqualObjs2 } from '../../utils/objtools'

class GGMenuZone extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
    )
  }

  render() {
    logRender(this)

     // Menu items
    var items = []
    items = this.props.redux.items_keys.map(item_key => {
      return <GGmenuItem key={item_key} name={item_key} />
    })

    return (
      <div className={this.getItemClasses().join(' ')}>
        {items}
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'ggmenu-zone',
      'zone-'+this.props.zone
    ]
    return classes;
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    items_keys: []
  }
  for (var item_key in state.ggmenu.items) {
    if ( state.ggmenu.items[item_key].show[state.mqsync.rv_group] === ownProps.zone ) {
      redux.items_keys.push(item_key)
    }
  }
  return {
    redux
  }
}

export default connect(
  mapStateToProps,
  null,
  ['ggmenu.items','mqsync.rv_group']
)(GGMenuZone)
