export function dateParseString(date_string) {
  return new Date(Date.parse(date_string))
}

export function dateParseTS(date_ts) {
  if (!date_ts)
    return null
  return new Date(date_ts)
}

export function formatDate(date) {
  var date_options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit',  minute: '2-digit'}
  return date.toLocaleDateString('fr-FR', date_options)
}

export function dateFormatFromString(date_string) {
  var date = dateParseString(date_string)
  return formatDate(date)
}

export function dateFormatFromTS(date_ts) {
  var date = dateParseTS(date_ts)
  return formatDate(date)
}
