import {put, call, select, delay} from 'redux-saga/effects'
import genericSubmissionSaga from '../item/genericSubmissionSaga'
import {
  getPortalCurrentOrganisationId,
  getPortalOrganisationSparkyManagerEndpoint,
  stateIsPortalDebug
} from "../portal/portal_selectors";
import {
  buildExternalResourceInfos,
  buildExternalResourceUri,
  external_resource_schemes
} from "../../utils/urls/external";
import {deleteUrl} from "../../utils/fetch";
import HttpStatusError from "../../utils/error/HttpStatusError";
import {getFirstDef} from "../../utils/objtools";
import {submitSubscriptionPlansFormDeleteRoutine} from "./subscription_plans_routines";
import {modalAdd} from "../modals/modals_actions";

function* saga(action) {

  const {values, modalInfos} = action.payload
  // prepare some ids
  var ids = values.ids

  var saga_error = null
  var url = null
  var status = null
  var data = null

  var new_scheme_key = "subscription_plans_delete"

  try {
    const sparky_manager_api_endpoint = yield select(getPortalOrganisationSparkyManagerEndpoint)
    if (!sparky_manager_api_endpoint)
      throw new Error("Could not get manager's api endpoint.")
    const organization_id = yield select(getPortalCurrentOrganisationId)
    if (!organization_id)
      throw new Error("Could not get organization_id.")

    var uri = yield buildExternalResourceUri(new_scheme_key, ids)
    if (!uri)
      throw new Error("Could not build the creation uri.")

    url = sparky_manager_api_endpoint + uri

    const {fetch_response, fetch_error} = yield call(deleteUrl, url, organization_id)
    if (fetch_error)
      throw fetch_error
    if (!fetch_response)
      throw new Error ('Could not get response')
    status = fetch_response.status
    if (fetch_response.status !== 200 && fetch_response.status !== 201)  {

      var error_response = yield fetch_response.json()

      modalInfos.closeModal()
      yield delay(200)

      yield put(modalAdd(
        'error',
        {
          title: fetch_response.status,
          message: getFirstDef(error_response.errors[0], 'defaultMessage', ''),
          error_code: error_response.errors[0].errorCode,
          subject: error_response.errors[0].subject,
          request_id: error_response.requestId,
          timestamp: error_response.timestamp,
        }
      ))

      throw new HttpStatusError(fetch_response.status)
    }
    data = yield fetch_response.json()



    if (!data[0].name)
      throw new Error("No name found in the created item.")

    // Store refresh when requested by new scheme
    const new_scheme = external_resource_schemes[new_scheme_key]
    for (var refresh_force_resource_scheme_key of getFirstDef(new_scheme, 'refresh_force_resources', [])) {
      var external_resource_infos_to_refresh = buildExternalResourceInfos(refresh_force_resource_scheme_key, ids)
      yield put({type: 'RESOURCE_LOAD_REQUEST', external_resource_infos: external_resource_infos_to_refresh, force:true})
    }

    if (data){
      for (let claim of data){
        if(!claim.name){
          throw new Error('A plan name is missing in the unsubscription response')
        }
        if(!claim.state){
          throw new Error('A plan is missing in the unsubscription response')
        }
      }
    }

    modalInfos.closeModal()

  }

  catch (catched_error) {
    saga_error = catched_error
  }

  if (yield select(stateIsPortalDebug)) {
    yield put({type: 'LOG_EXTERNAL_QUERY', scheme_key: new_scheme_key, url, status, has_error: saga_error?true:false, has_data: data?true:false})
  }

  if (saga_error) {
    yield put({type: 'ITEM_QUERY_ERROR', query_scheme_key: new_scheme_key, error: saga_error})
    return {error: saga_error}
  }
}

export function* submitDeleteSubscriptionPlansForm(action) {
  yield call(genericSubmissionSaga, {
    saga,
    action,
    routine: submitSubscriptionPlansFormDeleteRoutine,
    successfull_title: 'plan_subscription:successfull_delete_title',
    successfull_body: 'plan_subscription:successfull_delete_body',
    unsuccessfull_title: 'plan_subscription:unsuccessfull_delete_title',
    unsuccessfull_body: 'plan_subscription:unsuccessfull_delete_body'
  })
}
