import { put, takeEvery, select, takeLatest, putResolve, delay } from 'redux-saga/effects'
import { notificationAddError } from '../notifications/notifications_actions'
import { stateGetFinderSearchState, stateGetFinderFilter } from './finder_selectors'
import { stateGetItemsOfTypes } from '../item/item_selectors'
import {
  stateGetListItemsByIds,
  stateGetListItemsDomainsByIds,
  stateGetListItemsSpacesByIds
} from '../list/list_selectors'
import { isDef, isDefVal, getFirstDef } from '../../utils/objtools'
import { getPortalCurrentOrganisationAndCatalogIds } from '../portal/portal_selectors'
import { stateGetSelectedConsorgId } from '../consorg/consorg_selectors'

const finderSagas = [
  takeEvery("LIST_LOAD_SUCCESS", filterDomainsRequest),
  takeEvery("LIST_LOAD_SUCCESS", filterSpacesRequest),
  takeEvery("FINDER_FILTER_VALUE_REQUEST", filterValueRequest),
  takeLatest("FINDER_REFRESH_RESULTS_REQUEST", finderRefreshResults),
  takeLatest("LIST_LOAD_SUCCESS", finderWatchLoadedData),
  takeLatest("FINDER_GO", finderGo)
]
export default finderSagas

// Finder Go

function* finderGo(action) {
  switch(action.target) {
    case "products_and_apis":
      yield put({type: 'FINDER_FILTER_VALUE_REQUEST', filter_name:"itemtype", filter_value:['private_product','private_api','product','api']})
      yield put({type: 'HISTORY_PUSH', scheme_key:'finder'})
      break
    case "product":
      yield put({type: 'FINDER_FILTER_VALUE_REQUEST', filter_name:"itemtype", filter_value:['private_product','product']})
      yield put({type: 'HISTORY_PUSH', scheme_key:'finder'})
      break
    case "api":
      yield put({type: 'FINDER_FILTER_VALUE_REQUEST', filter_name:"itemtype", filter_value:['private_api','api']})
      yield put({type: 'HISTORY_PUSH', scheme_key:'finder'})
      break
    case "application":
    case "consorg":
      yield put({type: 'FINDER_FILTER_VALUE_REQUEST', filter_name:"itemtype", filter_value:[action.target]})
      yield put({type: 'HISTORY_PUSH', scheme_key:'finder'})
      break
    case "scope":
      yield put({type: 'FINDER_FILTER_VALUE_REQUEST', filter_name:"itemtype", filter_value:['private_scope','scope']})
      yield put({type: 'HISTORY_PUSH', scheme_key:'finder'})
      break
    default:
      break
  }
}

// Request filter value modification

function* filterDomainsRequest() {

  var ids = yield select(getPortalCurrentOrganisationAndCatalogIds)

  const domains = yield select(stateGetListItemsDomainsByIds, 'catalog_products', ids)
  if(domains) {
    let domains_options = {}
    domains.forEach((domain) => domains_options = {...domains_options,
      [domain]:{
        name: domain,
        title: domain,
      }})
    yield put({type: 'FINDER_FILTER_DOMAINS_SET', domains_options})
  }
}

// Request filter value modification

function* filterSpacesRequest() {

  var ids = yield select(getPortalCurrentOrganisationAndCatalogIds)

  const spaces = yield select(stateGetListItemsSpacesByIds, 'catalog_products', ids)

  if(spaces) {
    let spaces_options = {}
    spaces.forEach((space) => spaces_options = {...spaces_options,
      [space]:{
        name: space,
        title: space,
      }})
    yield put({type: 'FINDER_FILTER_SPACES_SET', spaces_options})
  }
}

// Request filter value modification

function* filterValueRequest(action) {
  try {
    yield put({...action, type: 'FINDER_FILTER_VALUE_START'})

    //yield put({...action, type: 'FINDER_FILTER_VALUE_SET'})
    yield putResolve({...action, type: 'FINDER_FILTER_VALUE_SET'})

    yield put({type: 'FINDER_REFRESH_RESULTS_REQUEST'})

    yield put({...action, type: 'FINDER_FILTER_VALUE_SUCCESS'})
  }
  catch (error) {
    yield put(notificationAddError({error}))
    yield put({...action, type: 'FINDER_FILTER_VALUE_ERROR', error: {name: error.name, message: error.message}})
  }
}

//

function* finderWatchLoadedData(action) {
  if (action.type === "LIST_LOAD_SUCCESS") {
    switch(isDefVal(action, 'external_resource_infos.scheme_key')) {
      case "consorg_applications":
      case "catalog_private_products":
      case "catalog_private_scopes":
      case "catalog_private_apis":
        yield put({type: 'FINDER_REFRESH_RESULTS_REQUEST'})
        break
      default:
        break
    }
  }
}

// Refresh results

function* finderRefreshResults() {
  try {
    yield put({type: 'FINDER_REFRESH_RESULTS_START'})

    yield delay(50) // Debounce (we have debouncing on finderfilter component too)

    var search_state = yield select(stateGetFinderSearchState)

    var total_items = null
    var total_items_unfiltered = null // total before all filters but itemtype

    // Get items by selected types
    // itemtype filter
    var finderTypes = ['product','api','private_product','private_api','application','consorg','scope','private_scope']
    if (isDef(search_state.filters, 'itemtype') && search_state.filters.itemtype.length > 0)
      finderTypes = search_state.filters.itemtype
    var items = []
    // COs
    if (finderTypes.indexOf('consorg')>=0) {
      const consorgs = yield select(stateGetItemsOfTypes, ['consorg'])
      if (consorgs) {
        for (const consorg of consorgs) {
          items.push({item: consorg, fulltext_score: null})
        }
      }
    }
    var ids = yield select(getPortalCurrentOrganisationAndCatalogIds)
    // Public products
    if (finderTypes.indexOf('product')>=0) {
      const products = yield select(stateGetListItemsByIds, 'catalog_products', ids)
      if (products) {
        for (const product of products) {
          items.push({item: product, fulltext_score: null})
        }
      }
    }
    // Public apis
    if (finderTypes.indexOf('api')>=0) {
      const apis = yield select(stateGetListItemsByIds, 'catalog_apis', ids)
      if (apis) {
        const keys = [];
        for (const api of apis) {
          const key = api.consolidated.name + ':' + api.consolidated.version;
          if( !keys.includes(key) ) {
            keys.push(key)
            items.push({item: api, fulltext_score: null})
          }
        }
      }
    }
    // Public scopes
    if (finderTypes.indexOf('scope')>=0) {
      const scopes = yield select(stateGetListItemsByIds, 'catalog_scopes', ids)
      if (scopes) {
        for (const scope of scopes) {
          items.push({item: scope, fulltext_score: null})
        }
      }
    }
    var consorg_id = yield select(stateGetSelectedConsorgId)
    if (consorg_id) {
      ids = {...ids, consorg_id}
      // Apps
      if (finderTypes.indexOf('application')>=0) {
        const applications = yield select(stateGetListItemsByIds, 'consorg_applications', ids)
        if (applications) {
          for (const application of applications) {
            items.push({item: application, fulltext_score: null})
          }
        }
      }
      // Private products
      if (finderTypes.indexOf('private_product')>=0) {
        const private_products = yield select(stateGetListItemsByIds, 'catalog_private_products', ids)
        if (private_products) {
          for (const private_product of private_products) {
            items.push({item: private_product, fulltext_score: null})
          }
        }
      }
      // Private apis
      if (finderTypes.indexOf('private_api')>=0) {
        const private_apis = yield select(stateGetListItemsByIds, 'catalog_private_apis', ids)
        if (private_apis) {
          const keys = [];
          for (const private_api of private_apis) {
            const key = private_api.consolidated.name + ':' + private_api.consolidated.version;
            if( !keys.includes(key) ) {
              keys.push(key)
              items.push({item: private_api, fulltext_score: null})
            }
          }
        }
      }
      // Private scopes
      if (finderTypes.indexOf('private_scope')>=0) {
        const private_scopes = yield select(stateGetListItemsByIds, 'catalog_private_scopes', ids)
        if (private_scopes) {
          for (const private_scope of private_scopes) {
            items.push({item: private_scope, fulltext_score: null})
          }
        }
      }
    }
    total_items_unfiltered = items.length

    // Other filters
    var filtering_function_gen = () => {
      return (item)=>{
        return filter_input.indexOf(getFirstDef(item.item, filter_definition.item_field_path))>=0
      }
    }
    for (var filter_key in search_state.filters) {
      switch(filter_key) {
        case 'itemtype':
        case 'fulltext':
          break
        default:
          var filter_input = search_state.filters[filter_key]
          if (filter_input.length > 0) {
            var filter_definition = yield select(stateGetFinderFilter, filter_key)
            items = items.filter(filtering_function_gen())
          }
          break
      }
    }

    // fulltext filter
    if (isDef(search_state.filters, 'fulltext')) {
      var input = search_state.filters.fulltext.trim()
      if (input.length > 0) {
        var input_words = input.split(' ')
        input_words = input_words.filter((word)=>word.trim()!=='')
        input_words = input_words.map((word)=>word.toLowerCase())
        var filter = yield select(stateGetFinderFilter, 'fulltext')
        var fulltext_score
        var field
        var field_value
        var input_word
        for (var item_key in items) {
          fulltext_score = 0
          for (field of filter.rules.fields) {
            if ((field_value = isDefVal(items[item_key].item, field.path))) {
              field_value = ' '+field_value.toLowerCase().trim()+' '
              for (input_word of input_words) {
                if (field_value.indexOf(' '+input_word+' ')>=0)
                  fulltext_score += field.weight * 3
                else if (field_value.indexOf(' '+input_word)>=0)
                  fulltext_score += field.weight * 2
                else if (field_value.indexOf(input_word)>=0)
                  fulltext_score += field.weight * 1
              }
            }
          }
          items[item_key].fulltext_score = fulltext_score
        }
        items = items.filter((item)=>item.fulltext_score>0)
      }
    }

    // Sorting
    sortItems(items, 'fulltext_score_desc')

    // formating and dispatching
    total_items = items.length
    var results = {
      items:[],
      total_items_unfiltered,
      total_items
    }
    for (var item of items) {
      results.items.push({
        item_type: item.item.external_resource_infos.scheme_key,
        resource_code: item.item.external_resource_infos.resource_code,
        fulltext_score: item.fulltext_score
      })
    }
    yield put({type: 'FINDER_REFRESH_RESULTS_SET', results})

    yield put({type: 'FINDER_REFRESH_RESULTS_SUCCESS'})
  }
  catch (error) {
    yield put(notificationAddError({error}))
    yield put({type: 'FINDER_REFRESH_RESULTS_ERROR', error: {name: error.name, message: error.message}})
  }
}

// Result Sorting

function sortItems(items, sort_modes) {
  if (!Array.isArray(sort_modes)) {
    sort_modes = [sort_modes]
  }
  for (var sort_mode of sort_modes) {
    switch(sort_mode) {
      case 'fulltext_score_desc':
        items.sort(buildComparer('fulltext_score','DESC', false))
        break
      default:
        break
    }
  }
  return items
}

function buildComparer(field_path, order, lowercase) {
  var order_coeff = order==='ASC'?1:-1
  return function(a,b) {
    if (!isDef(a, field_path) || !isDef(b, field_path))
      return 0
    var av = a[field_path]
    var bv = b[field_path]
    if (lowercase) {
      av = av.toLowerCase()
      bv = bv.toLowerCase()
    }
    if (av < bv)
      return -1*order_coeff;
    if (av > bv)
      return 1*order_coeff;
    return 0
  }
}
