import React from 'react'
import { withTranslation } from 'react-i18next'
import {logRender} from "../../utils/logging";
import Icon from "../icon/Icon";

class Comment extends React.Component {

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    var user = this.props.user
    var date = this.props.date
    var body = this.props.body

    var icon_type
    if(this.props.item_type === "main")
      icon_type = "consumer_org_owner"
    else if (this.props.item_type === "slave")
      icon_type = "consumer_org_member"


    return (
      <div className={this.getItemClasses().join(' ')} >
        <div className="header">
          <Icon type={icon_type}/>
          <div className="infos">
            <p key="author" className="author"><strong>{user}</strong> commented on {date}</p>
          </div>
        </div>
        <div className="content">
          {body}
        </div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'comment',
      'bloc',
      this.props.item_type
    ]
    return classes;
  }
}

export default withTranslation(['common','comment'])(Comment)
