import React from 'react'
import { connect } from '../../utils/redux'
import { logRender } from '../../utils/logging'
import { withTranslation } from 'react-i18next'
import {deepEqualObjs2, getFirstDef, isDef} from '../../utils/objtools'
import {mapResourceFromCode, mapResourceFromIds} from '../resource/resource_selectors'
import Icon from '../icon/Icon'
import Button from 'react-bootstrap/Button'
import Expandable from '../ui/Expandable/Expandable'
import Visibility from '../../utils/debug/Visibility'
import { Link } from "react-router-dom"
import { buildUri } from '../../utils/urls/internal'
import UiProperty from '../ui/UiProperty/UiProperty'
import {modalAdd} from "../modals/modals_actions";
import {submitSubscriptionScopesFormDeleteRoutine, submitSubscriptionScopesFormCancelRoutine} from "../subscription_scopes/subscription_scopes_routines";
import {buildExternalResourceInfos} from "../../utils/urls/external";
import {getPortalCurrentOrganisationAndCatalogIds} from "../portal/portal_selectors";
import Teaser from "../teaser/Teaser";

class ScopeSubscription extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || this.props.tReady !== nextProps.tReady
    )
  }

  componentDidMount() {
    this.postRender()
  }

  componentDidUpdate() {
    this.postRender()
  }

  postRender() {

    if (this.props.redux.routed_apis.need_request) {
      this.props.requestResource(this.props.redux.routed_apis.external_resource_infos)
    }
    // TEMP
    if (!this.props.redux.routed_apis.found && this.props.redux.external_resource_infos) {
      this.props.requestResource(this.props.redux.external_resource_infos)
    }
    //
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    const scope_subscription = this.props.redux.scope_subscription

    // Assume Optimistic Rendering
    if (scope_subscription.error) {
      return <p>Error</p>
    }

    var title = getFirstDef(scope_subscription, ['data.scope.title'], '--')
    title = <Link to={this.props.redux.scope_uri}>{title}</Link>

    var status = getFirstDef(scope_subscription, ['data.status'], '--')

    var content_pills = []
    content_pills.push(<div key="type" className="pill">{this.props.t('scope_subscription:badge')}</div>)
    content_pills.push(<div key="status" className="pill secondary">{status}</div>)

    var details = []

    var claims = []
    var i = 0
    for (var claim of getFirstDef(scope_subscription, 'data.subscribedClaimsGroups', [])) {
      claims.push(<p key={i}>{claim}</p>)
      i++
    }
    if (claims.length > 0) {
      details.push(
        <Expandable key="claims" tag={claims.length} title={this.props.t('scope:field_scopeClaim')}>
          <div className="standard_content">
            {claims}
          </div>
        </Expandable>
      )
    }

    var routedApis = []
    if (isDef(this.props.redux.routed_apis, 'data') && Object.keys(this.props.redux.routed_apis.data).length){
      for (let item in this.props.redux.routed_apis.data){
        routedApis.push(<Teaser key={'api/'+this.props.redux.routed_apis.data[item].id} item_type="api" resource_code={'api/'+this.props.redux.routed_apis.data[item].id} is_routed={this.props.redux.routed_apis.data[item].isRouted}/>)
      }
    }

    if(routedApis.length > 0){
      routedApis = (
        <Expandable key="routed_apis" tag={routedApis.length} title={this.props.t('scope_subscription:routed_apis')}>
          <div className="standard_content grid g1_432col">
            {routedApis}
          </div>
        </Expandable>
      )
    }

    return (
      <div className={this.getItemClasses().join(' ')} >
        <div className="content">
          <Icon type="black_scope" />
          <div className="infos">
            {content_pills}
            <h4 key="title">{title}</h4>
          </div>
          <div className="actions">
            <Visibility key="delete_button" condition={this.props.can_delete}>
              <Button key="delete_button" variant="danger" onClick={()=>this.deleteClick()}>{this.props.t('scope_subscription:delete_button')}</Button>
            </Visibility>
            <Visibility key="cancel_button" condition={this.props.can_cancel}>
              <Button key="cancel_button" variant="danger" onClick={()=>this.cancelClick()}>{this.props.t('scope_subscription:cancel_button')}</Button>
            </Visibility>
          </div>
          <div className="details">
            <UiProperty key="code" name="Code" value={getFirstDef(scope_subscription, ['data.scope.name'], '--')} />
            {details}
            {routedApis}
          </div>
        </div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'scope_subscription',
      'bloc'
    ]
    return classes
  }

  deleteClick() {
    this.props.modalAdd(
      'delete',
      {
        title: this.props.t('scope_subscription:delete_modal_title'),
        message: this.props.t('scope_subscription:delete_modal_message'),
        deleteAction: (modalInfo)=>{
          modalInfo.closeModal()
          this.props.submitSubscriptionScopesFormDeleteRoutine({ids: this.props.redux.scope_subscription.ids})
        },
        closeAction: null
      }
    )
  }
  cancelClick() {
    this.props.modalAdd(
      'confirm',
      {
        title: this.props.t('scope_subscription:cancel_modal_title'),
        message: this.props.t('scope_subscription:cancel_modal_message'),
        confirm_button_label: this.props.t('scope_subscription:cancel_confirm_button'),
        confirmAction: (modalInfo)=>{
          modalInfo.closeModal()
          this.props.submitSubscriptionScopesFormCancelRoutine({ids: this.props.redux.scope_subscription.ids})
        },
        closeAction: null
      }
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const portalIds = getPortalCurrentOrganisationAndCatalogIds(state)
  var redux = {
    scope_subscription: mapResourceFromCode(state, 'scope_subscription', ownProps.resourceCode),
    lang_code: state.lang.lang_code,
  }
  if (redux.scope_subscription.found) {
    redux.scope_uri = buildUri('scope', {...redux.scope_subscription.ids, scope_uname:redux.scope_subscription.ids.scope_name, lang_code:redux.lang_code})
  }

  redux.routed_apis = mapResourceFromIds(state, 'scope_subscription_routed_apis', {...portalIds, ...redux.scope_subscription.ids, scope_uname: redux.scope_subscription.ids.scope_name})
  redux.external_resource_infos = buildExternalResourceInfos('scope_subscription_routed_apis', {...portalIds, ...redux.scope_subscription.ids, scope_uname: redux.scope_subscription.ids.scope_name})

  return {
    redux
  }
}

const mapDispatchToProps = dispatch => ({
  submitSubscriptionScopesFormDeleteRoutine: (scope_name) => dispatch(submitSubscriptionScopesFormDeleteRoutine(scope_name)),
  submitSubscriptionScopesFormCancelRoutine: (scope_name) => dispatch(submitSubscriptionScopesFormCancelRoutine(scope_name)),
  requestResource: (external_resource_infos) => dispatch({type:'RESOURCE_LOAD_REQUEST', external_resource_infos}),
  modalAdd: (modalType, modalProps) => dispatch(modalAdd(modalType, modalProps))
})

ScopeSubscription = withTranslation(['common','scope','scope_subscription'])(ScopeSubscription)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  ['item.items.scope_subscription','item.items.scope_subscription_routed_apis','lang']
)(ScopeSubscription)
