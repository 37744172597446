import React from 'react'
import { connect } from '../../utils/redux'
import { logRender } from '../../utils/logging'
import { withTranslation } from 'react-i18next'
import branding from '../portal/branding'

class Preloader extends React.Component {

  render() {
    logRender(this)
    // We do not check tReady here, we use à fallback key and want to inform about translation availability

    var title
    if (this.props.redux.organisation_error)
      title = (this.props.tReady?this.props.t('common:Sorry, your portal is temporarily unavailable'):'Your portal is temporarily unavailable') + '.'
    else
      title = (this.props.tReady?this.props.t('common:Preparing your portal'):'Preparing your portal') + '...'

    return (
      <div className="preloader">
        <div className="preloader_content">
          <div className="logo">
            <img src={"/assets/full_logo_"+branding.kind+ ".svg"} alt="Sparky Consumer Portal" />
          </div>
          <h1>{title}</h1>
          <p>Context: {this.props.redux.context_loaded?'✔️':'⏳'}</p>
          <p>Translations: {this.props.tReady?'✔️':'⏳'}</p>
          <p>Organisation: {this.props.redux.organisation_loaded?'✔️':this.props.redux.organisation_error?'❌':'⏳'}</p>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    redux: {
      context_loaded: state.portal.context_loaded,
      organisation_loaded: state.portal.context.organisation_loaded,
      organisation_error: state.portal.context.organisation_error
    }
  }
}

export default connect(
  mapStateToProps,
  null,
  'portal.context'
)(withTranslation(['common'])(Preloader))
