import { createRoutine, promisifyRoutine } from 'redux-saga-routines'

// New Credential
// Routine for Form
export const submitCredentialFormNewRoutine = createRoutine('SUBMIT_CREDENTIAL_FORM_NEW')
export const submitCredentialFinalFormNewHandler = promisifyRoutine(submitCredentialFormNewRoutine)

// Edit Credential
// Routine for Form
export const submitCredentialFormEditRoutine = createRoutine('SUBMIT_CREDENTIAL_FORM_EDIT')
export const submitCredentialFinalFormEditHandler = promisifyRoutine(submitCredentialFormEditRoutine)

// Reset Credential
// Routine for Form
export const submitCredentialFormResetRoutine = createRoutine('SUBMIT_CREDENTIAL_FORM_RESET')
export const submitCredentialFinalFormResetHandler = promisifyRoutine(submitCredentialFormResetRoutine)

// Verify Credential
// Routine for Form
export const submitCredentialFormVerifyRoutine = createRoutine('SUBMIT_CREDENTIAL_FORM_VERIFY')
export const submitCredentialFinalFormVerifyHandler = promisifyRoutine(submitCredentialFormVerifyRoutine)

// Delete Credential
export const submitCredentialFormDeleteRoutine = createRoutine('SUBMIT_CREDENTIAL_FORM_DELETE')
