import React from 'react'
import { connect } from '../../utils/redux'
import { logRender } from '../../utils/logging'
import Notification from './Notification'
import { notificationDisplayed } from './notifications_actions'

class Notifications extends React.Component {

  constructor(props) {
    super(props);
    this.onClose = this.onClose.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      JSON.stringify(this.props.messages) !== JSON.stringify(nextProps.messages)
    )
  }

  render() {
    logRender(this)
    var notifications = []
    var message
    for (var message_id in this.props.messages) {
      message = this.props.messages[message_id]
      notifications.push(<Notification show={message.displayed_date===0} key={message_id} message_id={message_id} message_type={message.message_type} title={message.title} date={message.date} body={message.body} onClose={this.onClose} autoclose_ms={message.autoclose_ms} />)
    }
    return (
      <div className="notifications" >
        {notifications}
      </div>
    )
  }

  onClose(message_id) {
    this.props.notificationDisplayed(message_id)
  }
}

const mapStateToProps = (state, ownProps) => {
  var messages = {}
  var message
  for (var message_id in state.notifications.messages) {
    message = state.notifications.messages[message_id]
    // on ne garde que les messages non encore masqués ou masqués depuis moins de 5 sec (pour laisser le temps de l'anim de masquage)
    if (message.displayed_date===0 || Date.now()-message.displayed_date<5000) {
      messages[message_id] = {
        message_type: message.message_type,
        title: message.title,
        date: message.date,
        body: message.body,
        displayed_date: message.displayed_date,
        autoclose_ms: message.autoclose_ms
      }
    }
  }
  return {
    messages: messages
  }
}

const mapDispatchToProps = dispatch => ({
  notificationDisplayed: (message_id) => { dispatch(notificationDisplayed(message_id)) }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  'notifications'
)(Notifications);
