export const loadLocalStorage = (name) => {
  try {
    const serializedState = localStorage.getItem(name);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
}

export const saveLocalStorage = (name, data) => {
  try {
    const serializedState = JSON.stringify(data);
    localStorage.setItem(name, serializedState);
  } catch {
    // ignore write errors
  }
}