import React, { useState, useEffect } from 'react'
import Spinner from 'react-bootstrap/Spinner'

export default function Loader(props) {
  const [loading, setLoading] = useState(false)

  const getClasses = function() {
    var classes = ['loader']
    if (loading)
      classes.push('loading')
    return classes
  }

  useEffect(() => {
    // Delay state change to allow css transition
    const timeout = setTimeout(()=>{
      setLoading(props.loading)
    }, 10)
    return () => {
      clearTimeout(timeout);
    }
  }, [props])

  return <div className={getClasses().join(' ')}>
    <Spinner animation="border" role="status">
      <span className="sr-only">Loading...</span>
    </Spinner>
  </div>
}