import {submitRequestMembershipFormNewRoutine} from './membership_routines'
import { call } from 'redux-saga/effects'
import { newItem } from '../item/item_new_saga.js'
import genericFormSubmissionSaga from '../item/genericFormSubmissionSaga'
import {delay, put} from "@redux-saga/core/effects";

function* saga(action) {
  const {values, modalInfos} = action.payload

  var body = {
    role: values.membershipRole,
  }




  // prepare some ids
  var ids = {
    ...values.ids,
  }

  // Create the membership
  var {error, data} = yield call(newItem, 'consorg_membership_request_new', 'consorg_membership', ids, body)

  // Generic unhandled error
  if (error)
    throw error

  // Check created
  if (!data.id)
    throw new Error('No id found in the newly created membership.')

  modalInfos.closeModal()

  // go to mymemberships and check the newly created request for membership
  yield delay(500) // A tiny tempo to hide flashing "Not Found"
  yield put({type: 'HISTORY_PUSH', scheme_key:'my_memberships', ids})

}

export function* submitNewRequestMembershipForm(action) {
  yield call(genericFormSubmissionSaga, {
    saga,
    action,
    routine: submitRequestMembershipFormNewRoutine,
    successfull_title: 'membership:successfull_request_create_title',
    successfull_body: 'membership:successfull_request_create_body',
    unsuccessfull_title: 'membership:unsuccessfull_request_create_title',
    unsuccessfull_body: 'membership:unsuccessfull_request_create_body'
  })
}
