import React from 'react'
import { connect } from '../../utils/redux'
import { logRender } from '../../utils/logging'
import { isDefVal, isDef } from '../../utils/objtools'
import Icon from '../icon/Icon'
import { Link } from "react-router-dom"
import { buildUri } from '../../utils/urls/internal'
import { withTranslation } from 'react-i18next'

class CatalogTeaser extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      JSON.stringify(this.props.redux) !== JSON.stringify(nextProps.redux)
      || this.props.tReady !== nextProps.tReady
    )
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    var catalog
    if (!(catalog = isDefVal(this.props.redux, 'catalog')))
      return null
    var content = <div className="content">
        <Icon type="catalog" />
        <div className="type">{this.props.t('catalog:badge')}</div>
        <div className="title">{catalog.title}</div>
        <div className="description">{catalog.description}</div>
      </div>
    if (this.props.with_link) {
      var ids = {
        lang_code: this.props.i18n.language,
        catalog_uname: catalog.name
      }
      content = <Link to={buildUri('catalog', ids)}>{content}</Link>
    }
    return (
      <div className={this.getItemClasses().join(' ')} >
        {content}
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'catalog',
      'teaser'
    ]
    return classes;
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    lang_code: state.lang.lang_code
  }
  if (isDef(ownProps, 'catalog_id')) {
    if (isDef(state.portal, 'context.catalogs.'+ownProps.catalog_id)) {
      redux.catalog = state.portal.context.catalogs[ownProps.catalog_id]
    }
  }
  return {
    redux
  }
}

export default connect(
  mapStateToProps,
  null,
  ['lang','portal.context']
)(withTranslation('catalog')(CatalogTeaser));
