import React from 'react'
import { logRender } from '../../utils/logging'
import { getFirstDef } from '../../utils/objtools'
import { Form as FinalForm, Field as FinalField} from 'react-final-form'
import { withTranslation } from 'react-i18next'
import { Form } from 'react-bootstrap'
import UiButton from '../ui/buttons/UiButton'
import ControlInput from '../../utils/forms/ControlInput'
import ControlSelect from '../../utils/forms/ControlSelect'
import { translate_enumeration } from '../../utils/enums/enums_tools'
import { membershipRoles_options } from '../../utils/enums/membershipRoles'
import Loader from '../loader/Loader'

class MembershipFinalForm extends React.Component {

  constructor(props) {
    super(props)

    this.validateForm = this.validateForm.bind(this)
  }

  validateForm(values) {
    const errors = {}

    switch (values.consumerOrgMemberInvitationField) {
      case "mail":
        var re_email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (!values.userEmail) {
          errors.userEmail = this.props.t('forms:error_required')
        }
        else if (!re_email.test(values.userEmail)) {
          errors.userEmail = this.props.t('forms:error_email_pattern')
        }
        break
      case "matricule":
        // var re_matricule = /^[AX][0-9]{6}$/
        if (!values.userMatricule) {
          errors.userMatricule = this.props.t('forms:error_required')
        }
        // TODO no pattern verification anymore : because of ASSU requirements
        // else if (!re_matricule.test(values.userMatricule)) {
        //   errors.userMatricule = this.props.t('forms:error_matricule_pattern')
        // }
        break
      default:
        break
    }

    return errors
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null
    if (['new','edit'].indexOf(getFirstDef(this.props, 'form_op', '')) < 0 ) {
      console.error('form_op is not defined or not handled')
      return null
    }

    const t = this.props.t

    var membershipRoles_options_translated = translate_enumeration(membershipRoles_options, t)

    return (
      <FinalForm
        initialValues={this.props.initialValues}
        onSubmit={this.props.onSubmit}
        validate={this.validateForm}
      >
        {({ handleSubmit, pristine, form, submitting }) => {
          return (
            <Form onSubmit={handleSubmit}>
              {this.props.initialValues.consumerOrgMemberInvitationField === 'matricule' &&
                <Form.Group controlId="userMatricule">
                  <Form.Label>{t('membership:field_userMatricule')}</Form.Label>
                  <FinalField name="userMatricule" type='text' component={ControlInput} placeholder="A123456" />
                  <Form.Text className="text-muted">{t('membership:field_userMatricule_desc')}</Form.Text>
                </Form.Group>
              }
              {this.props.initialValues.consumerOrgMemberInvitationField === 'mail' &&
                <Form.Group controlId="userEmail">
                  <Form.Label>{t('membership:field_userEmail')}</Form.Label>
                  <FinalField name="userEmail" type='text' component={ControlInput} placeholder="john@example.com" />
                  <Form.Text className="text-muted">{t('membership:field_userEmail_desc')}</Form.Text>
                </Form.Group>
              }
              <fieldset>
                <legend>{t('membership:fieldset_role')}</legend>
                <Form.Group controlId="membershipRole">
                  <Form.Label>{t('membership:field_membershipRole')}</Form.Label>
                  <FinalField name="membershipRole" component={ControlSelect} options={membershipRoles_options_translated} />
                  <Form.Text className="text-muted">{t('membership:field_membershipRole_desc')}</Form.Text>
                </Form.Group>
              </fieldset>
              <div className="form-actions">
                <Loader loading={submitting} />
                <UiButton variant="primary" type="submit" disabled={pristine||submitting}>{t(this.props.form_op==='new'?'membership:submit_invite':'forms:submit_update')}</UiButton>
                <UiButton variant="secondary" type="button" disabled={pristine||submitting} onClick={form.reset}>{t('forms:reset')}</UiButton>
              </div>
            </Form>
          )
        }}
      </FinalForm>
    )
  }
}

export default withTranslation(
    ['forms','membership','enums']
  )(MembershipFinalForm)