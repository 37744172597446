import React from 'react'
import {logRender} from '../../../utils/logging'
import {withTranslation} from 'react-i18next'
import Icon from "../../icon/Icon";
import {getFirstDef} from "../../../utils/objtools";

class SenderHeader extends React.Component {

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    const user = this.props.user

    // Assume Optimistic Rendering
    if (user.error) {
      return <p>Error</p>
    }

    var case_type
    if(this.props.item_type === "main"){
      case_type = this.props.t('issue:small_badge')
    }
    else if (this.props.item_type === "slave"){
      case_type = this.props.t('comment:small_badge')
    }

    // var given_name
    // given_name = getFirstDef(user, ['data.givenName'], null)
    //
    // var family_name
    // family_name = getFirstDef(user, ['data.familyName'], null)

    var name
    name = getFirstDef(user, ['data.name'], null)

    return (
      <div className={this.getItemClasses().join(' ')}>
        <div className="content">
          <Icon type="consumer_org_owner"/>
          <div className="infos">
            <p key="author" className="author"><strong>{name}</strong> {this.props.t('issue:sender_header')} {case_type}.</p>
          </div>
        </div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'sender_header',
      'bloc',
      this.props.item_type
    ]
    return classes;
  }
}

export default withTranslation(['common', 'issue'])(SenderHeader)
