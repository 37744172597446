import React from 'react'
import {connect} from 'react-redux'
import {logRender} from '../../utils/logging'
import {withTranslation} from 'react-i18next'
import {mapResourceFromIds} from '../resource/resource_selectors'
import AnimDelay from "../ui/AnimDelay/AnimDelay";
import {ResponseTimeApplicationGraph} from "../kpis/graphs/ResponseTimeApplicationGraph";
import ResourceError from "../ui/ResourceError/ResourceError";
import UiListItem from "../ui/UiListItem/UiListItem";
import Expandable from "../ui/Expandable/Expandable";
import UiBlock from "../ui/UiBlock/UiBlock";
import {isDef} from "../../utils/objtools";
import Loader from "../loader/Loader";
import {buildExternalResourceInfos} from "../../utils/urls/external";


class PeriodicalPool extends React.Component {

  componentDidMount() {
    this.postRender()
  }

  componentDidUpdate() {
    this.postRender()
  }

  postRender() {

    if (this.props.redux.application_analytics.need_request) {
      this.props.requestResource(this.props.redux.application_analytics.external_resource_infos)
    }

    if (!this.props.redux.application_analytics.found && this.props.redux.external_resource_infos) {
      this.props.requestResource(this.props.redux.external_resource_infos)
    }

  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    if (!this.props.redux.application_analytics.found)
      return null

    if (this.props.redux.application_analytics.error) {
      return <ResourceError />
    }

    var response_time_application_graph
    if(isDef(this.props.redux, 'application_analytics.data.response_time_by_period')){
      response_time_application_graph = (
        <ResponseTimeApplicationGraph data={this.props.redux.application_analytics.data.response_time_by_period} legend="Response Time"/>
        )
    }

    var api_calls = []

    if(isDef(this.props.redux,'application_analytics.data.last_100.api_calls')){

      if (this.props.redux.application_analytics.data.last_100.api_calls.length > 0) {
        let index_1 = 0
        for (let item of this.props.redux.application_analytics.data.last_100.api_calls) {
          api_calls.push(<UiListItem key={index_1} opertation={item.operation} timestamp={item.timestamp} path={item.path}
                                     return_code={item.return_code} response_time={item.response_time}/>)
          index_1++
        }
      }

      if (api_calls.length) {
        api_calls = (
          <Expandable key="api_errors" title={this.props.t('application_analytics:calls_list')}>
            <div className="standard_content">
              {api_calls}
            </div>
          </Expandable>
        )
      }

      else if (api_calls.length === 0) {
        api_calls = (
          <Expandable key="api_errors" title={this.props.t('application_analytics:calls_list')}>
            <div className="standard_content">
              {this.props.t('application_analytics:no_data')}
            </div>
          </Expandable>
        )
      }

    }

    if(isDef(this.props.redux,'application_analytics.data.last_100.api_errors')){

      var api_errors = []
      let index_2 = 0
      if (this.props.redux.application_analytics.data.last_100.api_errors.length > 0) {
        for (let item of this.props.redux.application_analytics.data.last_100.api_errors) {
          api_errors.push(<UiListItem key={index_2} opertation={item.operation} timestamp={item.timestamp} path={item.path}
                                     return_code={item.return_code} response_time={item.response_time}/>)
          index_2++
        }
      }

      if (api_errors.length) {
        api_errors = (
          <Expandable key="api_errors" title={this.props.t('application_analytics:errors_list')}>
            <div className="standard_content">
              {api_errors}
            </div>
          </Expandable>
        )
      }

      else if (api_errors.length === 0) {
        api_errors = (
          <Expandable key="api_errors" title={this.props.t('application_analytics:errors_list')}>
            <div className="standard_content">
              {this.props.t('application_analytics:no_data')}
            </div>
          </Expandable>
        )
      }
    }

    var total_calls_block
    if(isDef(this.props.redux,'application_analytics.data.last_30_days.total_calls')){
      total_calls_block = (
        <UiBlock value={this.props.redux.application_analytics.data.last_30_days.total_calls} title={this.props.t('application_analytics:total_calls_label')} unit="calls" icon="request"/>
      )
    }

    var total_error_block
    if(isDef(this.props.redux,'application_analytics.data.last_30_days.total_errors')){
      total_error_block = (
        <UiBlock value={this.props.redux.application_analytics.data.last_30_days.total_errors} title={this.props.t('application_analytics:total_errors_label')} unit="errors" icon="error"/>
      )
    }

    var average_response_time_block
    if(isDef(this.props.redux,'application_analytics.data.last_30_days.average_response_time')){
      average_response_time_block = (
        <UiBlock value={this.props.redux.application_analytics.data.last_30_days.average_response_time} title={this.props.t('application_analytics:average_response_time')} unit="ms"
                 icon="time"/>
      )
    }



    return (
      <div className={this.getItemClasses().join(' ')}>

        {(response_time_application_graph &&
          <AnimDelay animateAppearance={true} showDelay={200}>
            <div style={{width:'100%', height:'500px'}}>
              {response_time_application_graph}
            </div>
            <div className="highlights">
              <div className="presentation_content gridg g1_2t1t_2_1">
                <div className="calls_list">
                  <h3>{this.props.t('application_analytics:last_100_calls_title')}</h3>
                  <div className="grid g2_221col">
                    <div>{api_calls}</div>
                    <div>{api_errors}</div>
                  </div>
                </div>
                <div className="ui_blocks grid g1_1col">
                  <h3>{this.props.t('application_analytics:last_30_days_title')}</h3>
                  {total_calls_block}
                  {total_error_block}
                  {average_response_time_block}
                </div>
              </div>
            </div>
          </AnimDelay>
        ) ||
        ((!this.props.redux.application_analytics.found || this.props.redux.application_analytics.loading) &&
          <Loader loading={true} />
        ) ||
        <p className="empty_zone">{this.props.t('application_analytics:no_data')}</p>
        }

      </div>
    )
  }


  getItemClasses() {
    var classes = [
      'periodical_graph'
    ]
    return classes
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    lang_code: state.lang.lang_code,
    application_analytics: mapResourceFromIds(state, 'application_analytics', {...ownProps.ids,period : ownProps.query })
  }

  redux.external_resource_infos = buildExternalResourceInfos('application_analytics', {...ownProps.ids,period : ownProps.query })

  return {
    redux
  }
}

const mapDispatchToProps = dispatch => ({
  requestResource: (external_resource_infos) => dispatch({type: 'RESOURCE_LOAD_REQUEST', external_resource_infos})
})

PeriodicalPool = withTranslation(['common', 'application_analytics'])(PeriodicalPool)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PeriodicalPool)
