import React from 'react'
import { Form } from 'react-bootstrap'
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en'
import DOMpurify from 'dompurify'

const ControlJsonEditor = ({ input, meta, placeholder }) => {
    const escapedValue = DOMpurify.sanitize(input.value)
  // TODO NTH handle the warning case if needed (not handled in bootstrap ?)

  return (
    <div className="json_input">
        <JSONInput
            {...input}
            value={escapedValue}
            isInvalid={
                (meta.touched && (meta.error || meta.warn))
                || (meta.submitError && !meta.dirtySinceLastSubmit)
            }
            placeholder={placeholder}
            theme="light_mitsuketa_tribute"
            locale={locale}
            height='auto'
            width='100%'
        />
      {meta.touched &&
        ((meta.error && <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>) ||
         (meta.warning && <Form.Control.Feedback type="invalid">{meta.warning}</Form.Control.Feedback>))}

      {meta.submitError && !meta.dirtySinceLastSubmit ? <Form.Control.Feedback type="invalid">{meta.submitError}</Form.Control.Feedback> : null}

    </div>
  )
}

export default ControlJsonEditor
