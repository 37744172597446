import React from 'react'
import { logRender } from '../../utils/logging'
import { withTranslation } from 'react-i18next'
import { isDef, deepEqualObjs2 } from '../../utils/objtools'
import Icon from "../icon/Icon";

class ApiGatewayBasePath extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.tReady !== nextProps.tReady
      || !deepEqualObjs2(this.props.api_gateway_basepath, nextProps.api_gateway_basepath)
    )
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    var url = []
    if (isDef(this.props, 'api_gateway_basepath.endpointUrl'))
      url.push(<span key="endpointUrl" className="endpointUrl">{this.props.api_gateway_basepath.endpointUrl}</span>)
    if (isDef(this.props, 'api_gateway_basepath.basePath')) {
      url.push(<span key="basePath" className="basePath">{this.props.api_gateway_basepath.basePath}</span>)
    }


    function copyClipBoard(value) {
      var tempInput = document.createElement("input");
      tempInput.style = "position: absolute; left: -10000px; top: -10000px";
      tempInput.value = value;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
    }

    return (
      <div className={this.getItemClasses().join(' ')} >
        <div className="url">
          {url}
          <button className="copy_clipboard" onClick={()=>copyClipBoard(this.props.api_gateway_basepath.endpointUrl+this.props.api_gateway_basepath.basePath)}>
            <Icon type="copy" />
          </button>
        </div>
      </div>
    )
  }



  getItemClasses() {
    var classes = [
      'api-gateway-basepath'
    ]
    return classes;
  }
}

export default withTranslation('api')(ApiGatewayBasePath);
