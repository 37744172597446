export const membershipVisibilities = {
  EVERYONE: 'everyone',
  LOGGED_USERS: 'logged_users',
  PRIVATE_TO_MEMBERS: 'private_to_members'
}

export const membershipVisibilities_options = {
  [membershipVisibilities.EVERYONE]:"enums:membershipVisibility_option_everyone",
  [membershipVisibilities.LOGGED_USERS]:"enums:membershipVisibility_option_logged_users",
  [membershipVisibilities.PRIVATE_TO_MEMBERS]:"enums:membershipVisibility_option_private_to_members",
}