import React from 'react'
import { logRender } from '../../../utils/logging'
import { withTranslation } from 'react-i18next'
import { isDef, isDefVal } from '../../../utils/objtools'
import { formatDate } from '../../../utils/date'
import Loader from '../../loader/Loader'
import Icon from '../../icon/Icon'
import UiH1 from '../titles/UiH1'
import AnimDelay from '../AnimDelay/AnimDelay'

class SingleHeader extends React.Component {

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    var dates = []
    if (isDef(this.props, 'consolidated.createdDate') && this.props.consolidated.createdDate)
      dates.push(<div title={this.props.t('common:created_label')} key="createdDate" className="single_header_date bloc_date"><Icon type="date_created" />{formatDate(this.props.consolidated.createdDate)}</div>)
    if (isDef(this.props, 'consolidated.updatedDate') && this.props.consolidated.updatedDate && this.props.consolidated.updatedDate !== this.props.consolidated.createdDate)
      dates.push(<div title={this.props.t('common:updated_label')} key="updatedDate" className="single_header_date bloc_date"><Icon type="date_updated" />{formatDate(this.props.consolidated.updatedDate)}</div>)

    var title = isDefVal(this.props, 'consolidated.title', null)

    return (
      <div className={this.getItemClasses().join(' ')} >
        <AnimDelay animateAppearance={true} >
          <div className="single_header_icon">
            <Icon type={this.props.item_type} />
          </div>
          <div className="single_header_title">
            <div className="single_header_pills">
              <div className="pill">{this.props.t(this.props.item_type+':badge')}</div>
              {this.props.pills}
              <Loader loading={this.props.loading} />
            </div>
            {title && <UiH1>{title}</UiH1>}
          </div>
          <div className="single_header_dates">
            {dates}
          </div>
        </AnimDelay>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'single_header',
      this.props.item_type
    ]
    return classes;
  }
}

export default withTranslation(['common','consorg','api','product','application','subscriptionscopes','scope','issue'])(SingleHeader)
