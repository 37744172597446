import React from 'react'
import {logRender} from '../../utils/logging'
import Icon from "../icon/Icon";


class FilterXl extends React.Component {

  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this)

  }

  render() {
    logRender(this);

    var label = this.props.label
    var type = this.props.type
    var icon = this.props.icon

    return (
      <div className={this.getItemClasses().join(' ')} onClick={() => this.handleClick(type, label)}>

        {icon && icon.length > 0 &&
        <div className="icon_xl">
          <Icon type={icon} />
        </div>
        }

        {label && label.length > 0 &&
        <h4 className="label">
          {label}
        </h4>
        }

      </div>
    )
  }

  handleClick(type, label){
    this.props.clickHandler(type, label);
  }

  getItemClasses() {
    var classes = [
      'filter_xl',
      'item'
    ];
    if (this.props.isActive) {
      classes.push('checked')
    }
    return classes;
  }
}

export default FilterXl;
