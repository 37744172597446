import { connect as reduxConnect } from 'react-redux'

export const connect = (mapStateToProps, mapDispatchToProps, statePaths) => {
  return (Component) => reduxConnect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    {
      areStatesEqual: (nextState, prevState) => {
        if (!statePaths) {
          return nextState === prevState
        }
        if (!Array.isArray(statePaths)) {
          statePaths = [statePaths]
        }
        for (var statePath of statePaths) {
          if (getFromPath(prevState, statePath) !== getFromPath(nextState, statePath)) {
            return false
          }
        }
        return true
      }
    }
  )(Component)
}

function getFromPath(obj, path) {
  if (!Array.isArray(path))
    path = path.split(".")
  var objdeep = obj
  for (var path_part of path) {
    if (typeof objdeep[path_part] !== 'undefined')
      objdeep = objdeep[path_part]
    else
      return null
  }
  return objdeep
}