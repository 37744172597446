import React, { Component } from 'react'
import { connect } from '../redux'
import { stateIsPortalDebug } from '../../components/portal/portal_selectors'
import ResourceError from '../../components/ui/ResourceError/ResourceError'

class Visibility extends Component {

  render() {
    if (this.props.condition) {
      return (
        <React.Fragment>
          {this.props.children}
        </React.Fragment>
      )
    }
    else if (this.props.redux.stateIsPortalDebug) {
      return (
        <div className="debug_neutral_forced_show">
          {this.props.children}
        </div>
      )
    }
    else if (this.props.resourceErrorDetails) {
      return (
        <ResourceError details={this.props.resourceErrorDetails} />
      )
    }
    else {

      return null
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    redux: {
      stateIsPortalDebug: stateIsPortalDebug(state)
    }
  }
}

export default connect(
  mapStateToProps,
  null,
  'portal.debug'
)(Visibility)