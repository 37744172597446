import React from 'react'
import { connect } from '../../utils/redux'
import { logRender } from '../../utils/logging'
import { Link } from "react-router-dom"
import paneTypes from './panes'
import { isDef, deepEqualObjs2 } from '../../utils/objtools'
import { withTranslation } from 'react-i18next'
import AnimDelay from '../ui/AnimDelay/AnimDelay'

class GGMenuPane extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      accordeon_status: 'closed',
      accordeon_style: {}
    }

    this.titleClickHandler = this.titleClickHandler.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || this.props.tReady !== nextProps.tReady
      || this.state.accordeon_status !== nextState.accordeon_status
    )
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    var title = 'xxx'
    if (isDef(this.props.redux.pane, 'title_i18n'))
      title = this.props.t(this.props.redux.pane.title_i18n)
    else if (isDef(this.props.redux.pane, 'title'))
      title = this.props.redux.pane.title
    switch (this.props.redux.pane.type) {
      case 'link':
        return (
          <div className={this.getItemClasses().join(' ')}>
            <Link to={this.props.redux.pane.target_uri}>
              <div className="ggmenu-pane-link grid g2_1col g2_root">
                <span>{title}</span>
              </div>
            </Link>
          </div>
        )
      case 'pane':
        const PaneContent = paneTypes[this.props.redux.pane.component]
        return (
          <div className={this.getItemClasses().join(' ')}>
            <AnimDelay renderDelay={2000}>
              <div className="ggmenu-pane-title" onClick={this.titleClickHandler}>
                <div className="grid g2_1col g2_root">
                  <span>{title}</span>
                </div>
              </div>
              <div className="ggmenu-pane-content" style={this.state.accordeon_style}>
                <div className="ggmenu-pane-wrapper" ref={element => this.wrapperRef = element}>
                  <PaneContent />
                </div>
              </div>
            </AnimDelay>
          </div>
        )
      default:
        console.error('pane type error')
        return null
    }
  }

  getItemClasses() {
    var classes = [
      'ggmenu-pane',
      'name-'+this.props.name,
      'type-'+this.props.redux.pane.type,
      'accordeon-'+this.state.accordeon_status
    ]
    if (this.props.redux.pane.type === 'pane')
      classes.push('status-'+this.props.redux.pane.status)
    return classes;
  }

  titleClickHandler() {
    // - open sequence :
    //closed -> action open :
    // preopening
    // opening
    // opened
    // - close sequence :
    // opened -> action close :
    // preclosing
    // closing
    // closed
    // check si pas d'anim en cours
    if (['preclosing','closing','preopening','opening'].indexOf(this.state.accordeon_status) === -1) {
      if (this.state.accordeon_status === 'opened') {
        this.close_accordeon()
      }
      else {
        this.open_accordeon()
      }
    }
  }

  close_accordeon() {
    var content_wrapper_h = this.wrapperRef.clientHeight;
    // set fixed size (cannot animate between auto and value)
    this.setState({
      accordeon_status: 'preclosing',
      accordeon_style: {height: content_wrapper_h+'px'}
    })
    setTimeout((function() {
      // animating to 0
      this.setState({
        accordeon_status: 'closing',
        accordeon_style: {height: '0px'}
      })
    }).bind(this), 100); // preclose anim (>0 to fix FF bug)
    setTimeout((function() {
      // back to normal and closed
      this.setState({
        accordeon_status: 'closed',
        accordeon_style: {}
      })
    }).bind(this), 900);
  }

  open_accordeon() {
    var content_wrapper_h = this.wrapperRef.clientHeight;
    // set fixed size (cannot animate between auto and value)
    this.setState({
      accordeon_status: 'preopening',
      accordeon_style: {height: '0px'}
    })
    setTimeout((function() {
      // animating to content height
      this.setState({
        accordeon_status: 'opening',
        accordeon_style: {height: content_wrapper_h+'px'}
      })
    }).bind(this), 100);
    setTimeout((function() {
      // back to normal and closed
      this.setState({
        accordeon_status: 'opened',
        accordeon_style: {}
      })
    }).bind(this), 900);
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    redux: {
      lang_code: state.lang.lang_code,
      pane: state.ggmenu.panes[ownProps.name]
    }
  }
}

export default connect(
  mapStateToProps,
  null,
  ['ggmenu.panes','lang']
)(withTranslation('menu')(GGMenuPane));