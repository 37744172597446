import { deepCopyObj, getFirstDef, isDefVal, getFirstDef2, copyObj, propertyCopy, isDef } from '../../utils/objtools'
import { buildExternalResourceInfos, buildExternalResourceAliases, buildExternalResourceCode } from '../../utils/urls/external'
import { saveLocalStorage, loadLocalStorage } from '../../utils/localstorage'
import { swaggerDevelopRefs } from '../../utils/swagger/swagger'
import { dateParseTS } from '../../utils/date'

// Init some state from local storage
var last_seen_single = loadLocalStorage('sparky_consumer_last_seen_single')
if (!last_seen_single) {
  last_seen_single = {
      product: [],
      api: [],
      scope: [],
      consorg: [],
      application: []
    }
}
else {
  // check we have all arrays
  last_seen_single.product = getFirstDef(last_seen_single, 'product', [])
  last_seen_single.api = getFirstDef(last_seen_single, 'api', [])
  last_seen_single.scope = getFirstDef(last_seen_single, 'scope', [])
  last_seen_single.consorg = getFirstDef(last_seen_single, 'consorg', [])
  last_seen_single.application = getFirstDef(last_seen_single, 'application', [])
}

const redItem = (
  state = {
    // Loaded items data
    items: {
      organisation: {aliases:{}},
      catalog: {aliases:{}},
      api: {aliases:{}},
      api_def: {aliases:{}},
      product: {aliases:{}},
      product_def: {aliases:{}},
      scope: {aliases:{}},
      consorg: {aliases:{}},
      application: {aliases:{}},
      application_analytics: {aliases:{}},
      static_content: {aliases:{}},
      me: {aliases:{}},
      consorg_membership: {aliases:{}},
      issue: {aliases:{}},
      comment: {aliases:{}},
      credential: {aliases:{}},
      scope_subscription: {aliases:{}},
      scope_subscription_routed_apis: {aliases:{}},
      plan_subscription: {aliases:{}},
      api_kat_information: {aliases:{}},
    },
    // Brut, seen resource_code as single page, can belong to different catalog
    last_seen_single,
    // This is the usable data from last_seen_single for the current catalog
    last_seen_single_usable: {
      product: [],
      api: [],
      scope: [],
      consorg: [],
      application: []
    }
  },
  action
) => {
  var new_state = null
  var item, item_src, ids, scheme_key, item_type, aliases, alias
  switch (action.type) {

    case "PORTAL_CHANGE_CATALOG_START":
      // On catalog change we clear some data
      new_state = copyObj(state)
      propertyCopy(new_state, 'items')
      new_state.items.catalog = {aliases:{}}
      new_state.items.api = {aliases:{}}
      new_state.items.api_def = {aliases:{}}
      new_state.items.product = {aliases:{}}
      new_state.items.product_def = {aliases:{}}
      new_state.items.scope = {aliases:{}}
      new_state.items.consorg = {aliases:{}}
      new_state.items.application = {aliases:{}}
      new_state.items.application_analytics = {aliases:{}}
      new_state.items.consorg_membership = {aliases:{}}
      new_state.items.issue = {aliases:{}}
      new_state.items.comment = {aliases:{}}
      new_state.items.credential = {aliases:{}}
      new_state.items.scope_subscription = {aliases:{}}
      new_state.items.scope_subscription_routed_apis = {aliases:{}}
      new_state.items.plan_subscription = {aliases:{}}
      new_state.items.api_kat_information = {aliases:{}}
      new_state.last_seen_single_usable = {
        product: [],
        api: [],
        scope: [],
        consorg: [],
        application: []
      }
      break

    case "FLUSH_PRIVATE_ITEMS":
      // On catalog change we clear some data
      new_state = copyObj(state)
      propertyCopy(new_state, 'items')
      new_state.items.api = {aliases:{}}
      new_state.items.api_def = {aliases:{}}
      // We can activate the very strict mode by flushing also products. Backend will go 403
      // new_state.items.product = {aliases:{}}
      // new_state.items.product_def = {aliases:{}}
      new_state.items.scope = {aliases:{}}
      break

    case "AUTH_HAS_LOGGED_OUT":
      // On log out change we clear some data
      new_state = copyObj(state)
      propertyCopy(new_state, 'items')
      new_state.items.consorg = {aliases:{}}
      new_state.items.application = {aliases:{}}
      new_state.items.application_analytics = {aliases:{}}
      new_state.items.me = {aliases:{}}
      new_state.items.consorg_membership = {aliases:{}}
      new_state.items.issue = {aliases:{}}
      new_state.items.comment = {aliases:{}}
      new_state.items.credential = {aliases:{}}
      new_state.items.scope_subscription = {aliases:{}}
      new_state.items.scope_subscription_routed_apis = {aliases:{}}
      new_state.items.plan_subscription = {aliases:{}}
      new_state.items.api_kat_information = {aliases:{}}
      break

    // case "ITEM_WARMUP_REQUEST":
    //   // TODO if needed
    //   break

    case "ITEM_LOAD_START":
      new_state = copyObj(state)
      scheme_key = action.external_resource_infos.scheme_key
      propertyCopy(new_state, ['items', scheme_key, 'aliases'])
      item = {
        consolidated: {},
        ...getFirstDef2(new_state.items, [[scheme_key, action.external_resource_infos.resource_code]], null),
        external_resource_infos: action.external_resource_infos,
        status: 'loading'
      }
      item.ids = {...item.ids, ...consolidateIds(item, action.external_resource_infos, new_state)}
      // Save item
      new_state.items[scheme_key][action.external_resource_infos.resource_code] = item
      // Aliases
      aliases = buildExternalResourceAliases(scheme_key, item.ids)
      for (alias of aliases) {
        new_state.items[scheme_key].aliases[alias] = action.external_resource_infos.resource_code
      }
      break

    case "ITEM_LOAD_ERROR":
      new_state = copyObj(state)
      scheme_key = action.external_resource_infos.scheme_key
      propertyCopy(new_state, ['items', scheme_key])
      new_state.items[scheme_key][action.external_resource_infos.resource_code] = {
        ...getFirstDef2(new_state.items, [[scheme_key, action.external_resource_infos.resource_code]], null),
        status: 'error',
        external_resource_infos: action.external_resource_infos
      }
      break

    case "ITEM_LOAD_SUCCESS":
      new_state = copyObj(state)
      scheme_key = action.external_resource_infos.scheme_key
      item_type = action.external_resource_infos.scheme.item_type
      propertyCopy(new_state, ['items', scheme_key, 'aliases'])
      var previous_data = isDefVal(new_state.items, [scheme_key, action.external_resource_infos.resource_code, 'data'])
      item = {
        status: 'loaded',
        external_resource_infos: action.external_resource_infos,
        data: {...previous_data, ...action.data}
      }
      item.consolidated = consolidateItem(item_type, item.data, action, new_state)
      item.ids = {
        ...consolidateIds(item, action.external_resource_infos, new_state),
        ...gatherIds(item_type, item.data)
      }
      // Save item
      new_state.items[scheme_key][action.external_resource_infos.resource_code] = item
      // Aliases
      aliases = buildExternalResourceAliases(scheme_key, item.ids)
      for (alias of aliases) {
        new_state.items[scheme_key].aliases[alias] = action.external_resource_infos.resource_code
      }
      // debug to find some cases
      // var item_str = JSON.stringify(item)
      // if (item_str.indexOf('example')>0 && item_str.indexOf('json')>0)
      //   console.log(item.consolidated.title)
      break

    case "LIST_LOAD_SUCCESS":
      switch (action.external_resource_infos.scheme_key) {
        case "catalog_apis":
        case "catalog_products":
        case "catalog_private_apis":
        case "catalog_private_products":
        case "catalog_consorgs":
        case "catalog_scopes":
        case "catalog_private_scopes":
        case "organisation_static_contents":
        case "consorg_memberships":
        case "consorg_applications":
        case "consorg_issues":
        case "issue_comments":
        case "my_memberships":
        case "application_credentials":
        case "application_scope_subscriptions":
        case "application_plans_subscriptions":
          new_state = copyObj(state)
          item_type = action.external_resource_infos.scheme.item_type
          scheme_key = item_type // on assume ici que le type d'item de la liste correspond au scheme_key des items
          // Store list items as partial items
          propertyCopy(new_state, ['items', scheme_key, 'aliases'])
          for (var key in action.data) {
            item_src = action.data[key]
            ids = {
              ...action.external_resource_infos.ids,
              ...gatherIds(item_type, item_src)
            }
            var external_resource_infos = buildExternalResourceInfos(scheme_key, ids)
            if (!external_resource_infos) {
              console.error('Could not build external_resource_infos of a list item.', action, ids)
              continue
            }
            if (!isDef(new_state.items, [scheme_key, external_resource_infos.resource_code]) // item not yet existing
              || new_state.items[scheme_key][external_resource_infos.resource_code].status === 'partial' // can overwrite if not single loaded
            ) {
              item = {
                status: 'partial',
                external_resource_infos,
                data: item_src,
              }
              item.consolidated = consolidateItem(item_type, item_src, action, new_state)
              item.ids = consolidateIds(item, external_resource_infos, new_state)
              // TODO find a way to load directly data in item
              item.list_data = action.data
              // Save item
              new_state.items[scheme_key][external_resource_infos.resource_code] = item
              // Aliases
              aliases = buildExternalResourceAliases(scheme_key, item.ids)
              for (alias of aliases) {
                new_state.items[scheme_key].aliases[alias] = external_resource_infos.resource_code
              }
            }
          }
          // Refresh last_seen_single_usable
          new_state.last_seen_single_usable = buildLastSeenSingleUsable(new_state)
          break

        default:
          break
      }
      break

    case "ITEM_SEEN_SINGLE":
      new_state = copyObj(state)
      item_type = action.item_type
      // remove if existing
      var existing_index = new_state.last_seen_single[item_type].indexOf(action.resource_code)
      if (existing_index >= 0)
        new_state.last_seen_single[item_type].splice(existing_index, 1)
      // add at the end
      new_state.last_seen_single[item_type].push(action.resource_code)
      // pruning
      const max_len = 50
      if (new_state.last_seen_single[item_type].length > max_len)
        new_state.last_seen_single[item_type].splice(0, new_state.last_seen_single[item_type].length - max_len)
      // store in local storage
      saveLocalStorage('sparky_consumer_last_seen_single', new_state.last_seen_single)
      // Refresh last_seen_single_usable
      new_state.last_seen_single_usable = buildLastSeenSingleUsable(new_state)
      break

    case "RESOURCE_DELETED":
      new_state = copyObj(state)
      scheme_key = action.external_resource_infos.scheme_key
      if (isDef(new_state.items, [scheme_key, action.external_resource_infos.resource_code])) {
        propertyCopy(new_state, ['items', scheme_key, 'aliases'])
        delete new_state.items[scheme_key][action.external_resource_infos.resource_code]
        console.log("deleted item "+action.external_resource_infos.resource_code)
        aliases = buildExternalResourceAliases(scheme_key, action.external_resource_infos.ids)
        for (alias of aliases) {
          if (isDef(new_state.items[scheme_key].aliases, alias)) {
            delete new_state.items[scheme_key].aliases[alias]
            console.log("deleted alias "+alias)
          }
        }
      }
      break

    default:
      break
  }
  if (new_state) {
    return new_state
  }
  else {
    return state
  }
}

/**
 * Consolidate item fields
 * That list must work on single & lists for common items (prod, api, etc.)
 */
function consolidateItem(item_type, item_src, action, new_state) {
  var consolidated = {
    title: getFirstDef(item_src, ['title','definition.info.title'], null),
    name: getFirstDef(item_src, ['thirdSystemReference.name','name','definition.info.name','code','scope.name'], null),
    version: getFirstDef(item_src, ['version','definition.info.version'], null),
    domain: getFirstDef(item_src, ['domain'], null ),
    space: getFirstDef(item_src, ['space'], null ),
    state: getFirstDef(item_src, ['state'], null),
    description: getFirstDef(item_src, ['description', 'definition.info.description'], null),
    universe: getFirstDef(item_src, ['universe'], null ),
    createdDate: null,
    updatedDate: null,
    uname: null
  }
  if (isDef(item_src, 'createdDate')) {
    consolidated.createdDate = dateParseTS(item_src.createdDate)
  }
  else if (isDef(item_src, 'createdAt')) {
    consolidated.createdDate = new Date(item_src.createdAt)
  }
  else if (isDef(item_src, 'auditInformations.createdAt')) {
    consolidated.createdDate = new Date(item_src.auditInformations.createdAt)
  }
  if (isDef(item_src, 'updatedDate')) {
    consolidated.updatedDate = dateParseTS(item_src.updatedDate)
  }
  else if (isDef(item_src, 'updatedAt')) {
    consolidated.updatedDate = new Date(item_src.updatedAt)
  }
  else if (isDef(item_src, 'auditInformations.updatedAt')) {
    consolidated.updatedDate = new Date(item_src.auditInformations.updatedAt)
  }
  switch(item_type) {
    case 'catalog':
    case 'organisation':
    case 'consorg':
    case 'application':
    case 'issue':
    case 'comment':
    case 'scope':
    case 'plan':
      if (consolidated.name)
        consolidated.uname = consolidated.name
      break
    case 'api':
    case 'product':
      if (consolidated.name && consolidated.version)
        consolidated.uname = consolidated.name+':'+consolidated.version
      break
    case 'api_def':
      if (isDef(item_src, 'definition'))
        consolidated.swagger_developed = swaggerDevelopRefs(item_src.definition)
      break
    default:
      break
  }
  return consolidated
}

/**
 * Consolidate item ids
 */
function consolidateIds(item, external_resource_infos, new_state) {
  const item_type = external_resource_infos.scheme.item_type
  var ids = deepCopyObj(external_resource_infos.ids)
  if (item.consolidated.uname)
    ids[item_type+'_uname'] = item.consolidated.uname
  // Ids harvesting
  var target_resource_code, target_item
  for (var harvest_scheme_key of getFirstDef(external_resource_infos.scheme, 'harvest_ids', [])) {
    target_resource_code = buildExternalResourceCode(harvest_scheme_key, ids)
    if ((target_item = isDefVal(new_state.items, harvest_scheme_key+'.'+target_resource_code))) {
      ids = {...target_item.ids, ...ids}
    }
  }
  return ids
}

/**
 * Gather ids from data
 */
export function gatherIds(item_type, data) {

  var ids = {}
  if (isDef(data, 'id'))
    ids[item_type+'_id'] = data.id
  else if (isDef(data, 'scope.name'))
    ids['scope_id'] = data.scope.name

  if (isDef(data, 'subscriptionId'))
    ids['subscription_id'] = data.subscriptionId

  if (isDef(data, 'consumerOrganization') && data.consumerOrganization.trim()!=='') // TODO check this is an id ?
    ids['consorg_id'] = data.consumerOrganization

  if (isDef(data, 'lang_code')) {
    // for lists with item per language
    ids['lang_code'] = data.lang_code
  }

  if (isDef(data, 'name'))
    ids[item_type+'_name'] = data.name
  else if (isDef(data, 'scope.name'))
    ids['scope_name'] = data.scope.name

  if (isDef(data, 'code'))
    ids[item_type+'_code'] = data.code
  if (isDef(data, 'file_extension'))
    ids['file_extension'] = data.file_extension

  if(isDef(data, 'plan')){
    ids['plan_id'] = data.plan
    ids['plan_name'] = data.plan
  }


  // to map the name as the ID of the item
  // if (item_type === "scope" && isDef(data, 'name'))
  //   ids['scope_id'] = data.name
  return ids
}

/**
 * Consolidate last_seen_single to keep n items per type that are available in current catalog
 */
function buildLastSeenSingleUsable(state) {
  const max_len = 12
  var last_seen_single_usable = {}
  for (var item_type in state.last_seen_single) {
    last_seen_single_usable[item_type] = []
    var found = 0
    for (var i = state.last_seen_single[item_type].length - 1; i >= 0; i--) {
      if (isDef(state.items, [item_type, state.last_seen_single[item_type][i]])) {
        found++
        last_seen_single_usable[item_type].push(state.last_seen_single[item_type][i])
      }
      if (found >= max_len)
        break
    }
  }
  return last_seen_single_usable
}

export default redItem;
