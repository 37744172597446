import React from 'react'
import { connect } from 'react-redux'
import { logRender } from '../../utils/logging'
import { withTranslation } from 'react-i18next'
import { deepEqualObjs2, isDef } from '../../utils/objtools'
import setDocumentTitle from '../../utils/browser/title'
import {submitSubscriptionScopesFinalFormNewHandler} from "../subscription_scopes/subscription_scopes_routines"
import { bindPromiseCreators } from 'redux-saga-routines'
import {stateGetSelectedConsorgId} from '../consorg/consorg_selectors'
import { stateAuthIsUserLoggedIn } from '../auth/auth_selectors'
import { getPortalPageQueryParams} from '../portal/portal_selectors'
import SubscriptionScopesFinalForm from "./SubscriptionScopesFinalForm"
import SingleHeader from "../ui/SingleHeader/SingleHeader"
import {stateGetCartContext, stateGetCartScopeCount, stateGetCartSelectedScopes} from "../cart/cart_selectors"
import Teaser from "../teaser/Teaser"
import ScopeCard from "../scopes/ScopeCard"
import AnimDelay from "../ui/AnimDelay/AnimDelay"
import {stateGetProductDefinitionFeaturedAPIs} from "../product/product_selectors";

class SubscriptionScopesNew extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || this.props.tReady !== nextProps.tReady
    )
  }

  componentDidMount() {
    this.postRender()
  }

  componentDidUpdate() {
    this.postRender()
  }

  postRender() {
    if (this.props.tReady) {
      setDocumentTitle(this.props.t('subscriptionscopes:form_new_title'))
    }
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    var initialValues = {

      selected_consorg_id: this.props.redux.selected_consorg_id,
      selectedScopes: this.props.redux.selectedScopes

    }


    var selected_scopes = []
    if(this.props.redux.cartScopeCount > 0){
      for (let scope in this.props.redux.selectedScopes){
        selected_scopes.push(
          <ScopeCard key={'scope-'+scope} name={this.props.redux.selectedScopes[scope].name} scopeDescription="Description" context={this.props.redux.cartContext} />
        )
      }
    }

    if (selected_scopes.length) {
      selected_scopes = (
        <AnimDelay animateAppearance={true} showDelay={100}>
          <div>
            <h2>{this.props.t('subscriptionscopes:selected_scopes_title')}</h2>
            <div className="scope_list grid g2_221col">
              {selected_scopes}
            </div>
          </div>
        </AnimDelay>
      )
    }

    var scopesSummary
    if (this.props.redux.cartScopeCount === 0){
      scopesSummary = (
        <p>{this.props.t('subscriptionscopes:empty_cart_message')}</p>
      )
    }
    else{
      scopesSummary = (
        <div>
          {selected_scopes}
        </div>
      )
    }



    var subscriptionForm

    if (this.props.redux.authIsUserLoggedIn === false){
      subscriptionForm = (
        <p>{this.props.t('subscriptionscopes:not_logged_in_message')}</p>
      )
    }
    else if(!this.props.redux.selected_consorg_id){
      subscriptionForm = (
        <p>{this.props.t('subscriptionscopes:no_consorg_selected_message')}</p>
      )
    }
    else if(this.props.redux.selected_consorg_id && this.props.redux.authIsUserLoggedIn === true && this.props.redux.cartScopeCount > 0) {
      subscriptionForm = (
        <div>
          <h2>{this.props.t('subscriptionscopes:subscription_title')}</h2>
          <SubscriptionScopesFinalForm form_op="new" onSubmit={this.props.submitSubscriptionScopesFinalFormNewHandler} initialValues={initialValues} />
        </div>

      )
    }

    var productAndApisInContext = []
    if (isDef(this.props.redux, 'cartContext.productResourceCodes')){
      for (let item of this.props.redux.cartContext.productResourceCodes){
        productAndApisInContext.push(<Teaser key={item} item_type="product" resource_code={item}/>)
      }
    }

    if (isDef(this.props.redux, 'featuredApis.api_resource_codes')) {
      for (let api_resource_code of this.props.redux.featuredApis.api_resource_codes){
        productAndApisInContext.push(<Teaser key={api_resource_code} item_type="api" resource_code={api_resource_code}/>)
      }
    }

    return (
        <div className={this.getItemClasses().join(' ')} >
          <div className="grid g1_root g1_1col">
            <SingleHeader key="header" item_type="subscriptionscopes" consolidated={{title: this.props.t('subscriptionscopes:title')}} pills={null} loading={false} />
            <div className="item_full_presentation">
              <div className="presentation_content gridg g1_2t1t_2_1">
                <div key="presentation1">
                  {scopesSummary}
                </div>
                <div key="presentation2">
                  {subscriptionForm}
                </div>
              </div>
            </div>

            {/*since a scope can be selected in different ways, it is necessary to manage the case where the user does not go through a product or an API*/}
            {productAndApisInContext.length &&
            <React.Fragment>
              <h2>{this.props.t('subscriptionscopes:cart_context_title')}</h2>
              <div className="grid g1_432col">
                {productAndApisInContext}
              </div>
            </React.Fragment>
            }

          </div>
        </div>
    )
  }

  getItemClasses() {
    var classes = [
      'subscription',
    ]
    return classes
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    lang_code: state.lang.lang_code,
    selectedScopes: stateGetCartSelectedScopes(state),
    cartScopeCount: stateGetCartScopeCount(state),
    cartContext: stateGetCartContext(state),
    selected_consorg_id: stateGetSelectedConsorgId(state),
    authIsUserLoggedIn: stateAuthIsUserLoggedIn(state),
    portalPageQueryParams: getPortalPageQueryParams(state),
  }

  if(isDef(redux.cartContext,'productDefinitionResourceCodes')){
    redux.featuredApis = stateGetProductDefinitionFeaturedAPIs(state, redux.cartContext.productDefinitionResourceCodes)
  }

  return {
    redux
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindPromiseCreators({
    submitSubscriptionScopesFinalFormNewHandler
  }, dispatch)
})

SubscriptionScopesNew = withTranslation(
  ['common','subscriptionscopes']
)(SubscriptionScopesNew)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionScopesNew)
