import { isDefVal, getFirstDef } from '../../utils/objtools'
import { mapResourceFromCode, stateFindResourceByCodeOrAlias } from '../resource/resource_selectors'
import { membershipRoles } from '../../utils/enums/membershipRoles'

/**
 * Given a CO resourceCode, determine if user belongs to it (member or owner)
 */
export function stateIsUserBelongsConsorg(state, resourceCode) {
  if (state.auth.logged_in === false) {
    return false
  }
  if (state.auth.user_has_memberships === null || state.portal.context.catalog_has_consorgs === null) {
    // we do not yet know
    return null
  }
  const consorgs_user_belongs = isDefVal(state.list, 'consorgs_user_belongs')
  if (consorgs_user_belongs === null) {
    // we do not yet know
    return null
  }
  return (consorgs_user_belongs.indexOf(resourceCode) !== -1)
}

/**
 * Given a CO resourceCode, determine if user is a owner
 */
export function stateIsUserOwnsConsorg(state, resourceCode) {
  if (state.auth.logged_in === false) {
    return false
  }
  if (state.auth.user_has_memberships === null || state.portal.context.catalog_has_consorgs === null) {
    // we do not yet know
    return null
  }
  const consorgs_user_owns = isDefVal(state.list, 'consorgs_user_owns')
  if (consorgs_user_owns === null) {
    // we do not yet know
    return null
  }
  return (consorgs_user_owns.indexOf(resourceCode) !== -1)
}

/**
 * Given a CO resourceCode, determine user role
 */
export function stateGetUserConsorgRole(state, resourceCode) {
  if (stateIsUserOwnsConsorg(state, resourceCode) === true) {
    return membershipRoles.OWNER
  }
  if (stateIsUserBelongsConsorg(state, resourceCode) === true) {
    return membershipRoles.MEMBER
  }
  return null
}

/**
 * Given a CO resourceCode, get the user membership resource code
 */
export function stateGetUserConsorgMembershipResourceCode(state, resourceCode) {
  const consorgs_user_memberships = isDefVal(state.list, 'consorgs_user_memberships')
  if (consorgs_user_memberships === null) {
    return null
  }
  return isDefVal(consorgs_user_memberships, resourceCode)
}

/**
 * Get currently selected consorg resourceCode
 */
export function stateGetSelectedConsorgResourceCode(state) {
  return state.portal.consorg.resourceCode
}

/**
 * Map currently selected consorg resource
 */
export function mapSelectedConsorg(state) {
  var resourceCode = stateGetSelectedConsorgResourceCode(state)
  return mapResourceFromCode(state, 'consorg', resourceCode)
}

/**
 * Get currently selected consorg resource
 */
export function stateGetSelectedConsorg(state) {
  var resourceCode = stateGetSelectedConsorgResourceCode(state)
  return stateFindResourceByCodeOrAlias(state, 'consorg', resourceCode)
}

/**
 * Get currently selected consorg id
 */
export function stateGetSelectedConsorgId(state) {
  var resource = stateGetSelectedConsorg(state)
  if (resource)
    return resource.ids.consorg_id
  return null
}

/**
 * Check if a resource_code is the selected consorg
 */
export function stateIsResourceCodeSelectedConsorg(state, resourceCode) {
  var selectedResourceCode = stateGetSelectedConsorgResourceCode(state)
  return (selectedResourceCode && selectedResourceCode === resourceCode)
}

/**
 * Get last selected consorg in current catalog
 */
export function stateGetLastSelectedConsorgResourceCode(state) {
  var catalog_id = isDefVal(state.portal.context, 'catalog.catalog_id')
  if (catalog_id) {
    return getFirstDef(state.portal.last_selected_consorgs, catalog_id, null)
  }
  return null
}

/**
 * Get last selected consorg in current catalog
 */
export function stateGetDefaultConsorgResourceCode(state) {
  var consorgs_user_belongs = isDefVal(state.list, 'consorgs_user_belongs')
  if (consorgs_user_belongs) {
    return consorgs_user_belongs[0]
  }
  return null
}
