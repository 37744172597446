import React from 'react'
import { logRender } from '../../utils/logging'
import { connect } from '../../utils/redux'
import { withTranslation } from 'react-i18next'
import { mapResourceFromIds } from '../resource/resource_selectors'
import ScopeCard from '../scopes/ScopeCard'
import AnimDelay from '../ui/AnimDelay/AnimDelay'
import { stateGetProductDefinitionScopes, stateGetApiDefinitionScopes } from '../scopes/scope_selectors'
import { stateGetCartScopeCount } from "../cart/cart_selectors"
import UiButton from "../ui/buttons/UiButton"
import {deepEqualObjs2} from "../../utils/objtools"
import {stateAuthIsUserLoggedIn} from "../auth/auth_selectors";

class SubscriptionToScope extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || this.props.tReady !== nextProps.tReady
    )
  }

  componentDidMount() {
    this.postRender()
  }

  componentDidUpdate() {
    this.postRender()
  }

  postRender() {
    for (var api_resource of this.props.redux.api_resources){
      if (api_resource.need_request) {
        this.props.requestResource(api_resource.external_resource_infos)
      }
    }
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    var featured_scopes = []
    if (this.props.redux.scopes) {
      for (var name in this.props.redux.scopes.descriptions) {
        featured_scopes.push(
          <ScopeCard key={name} name={name} context={this.props.redux.scopes.context} />
        )
      }
    }

    if (featured_scopes.length)
      featured_scopes = (
        <AnimDelay animateAppearance={true}>
          <h2>Scopes</h2>
          <div className="scope_list grid g1_432col">
            {featured_scopes}
          </div>
          {this.props.redux.cartScopeCount > 0 &&
            <div className="subscription_actions">
              <UiButton variant="primary" onClick={()=>this.props.historyPush('subscription_scopes_new')}>
                {this.props.t('subscriptionscopes:scopesCountButton', {
                  count: this.props.redux.cartScopeCount
                })}
              </UiButton>
            </div>
          }
        </AnimDelay>
      )

    // TODO find a better way to inform users that they can have access to more informations about the possible subscriptions and other actions
    // let noScopesKnowMore
    // if(featured_scopes.length === 0 && isDef(this.props.redux,'scopes.names') && this.props.redux.scopes.names.length === 0 && branding.kind !== 'int') {
    //   noScopesKnowMore = (
    //     <AnimDelay animateAppearance={true} showDelay={2800}>
    //       <div className="hidden_block">
    //         <Visibility condition={this.props.redux.authIsUserLoggedIn}>
    //           <div><span className="emoji_xl" role="img" aria-label="emoji">🤝</span></div>
    //           <h3>{this.props.t('subscriptionscopes:know_more_about_logged_in')}</h3>
    //         </Visibility>
    //         <Visibility condition={!this.props.redux.authIsUserLoggedIn}>
    //           <div><span className="emoji_xl" role="img" aria-label="emoji">☝</span></div>
    //           <h3>{this.props.t('subscriptionscopes:know_more_about_anonymous')}</h3>
    //         </Visibility>
    //       </div>
    //     </AnimDelay>
    //   )
    // }


    return (
      <div className={this.getItemClasses().join(' ')}>
        {featured_scopes}

        {/*{noScopesKnowMore}*/}
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'subscription-scope'
    ]
    return classes;
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    scopes: null,
    cartScopeCount: stateGetCartScopeCount(state),
    authIsUserLoggedIn: stateAuthIsUserLoggedIn(state),
    api_resources: [] // API definitions we may have to trigger load
  }
  if (ownProps.productDefinitionResourceCode) {
    redux.scopes = stateGetProductDefinitionScopes(state, ownProps.productDefinitionResourceCode, ownProps.productResourceCode)
  }
  else if (ownProps.apiDefinitionResourceCode) {
    redux.scopes = stateGetApiDefinitionScopes(state, ownProps.apiDefinitionResourceCode, ownProps.swaggerVersion)
  }
  if (redux.scopes) {
    for (var api_id of redux.scopes.context.api_ids) {
      redux.api_resources.push(mapResourceFromIds(state, 'api', {api_id: api_id}))
    }
  }

  return {
    redux
  }
}

const mapDispatchToProps = dispatch => ({
  requestResource: (external_resource_infos) => dispatch({type:'RESOURCE_LOAD_REQUEST', external_resource_infos}),
  historyPush: (scheme_key) => { dispatch({type:'HISTORY_PUSH', scheme_key}) }
})


export default connect(
  mapStateToProps,
  mapDispatchToProps,
  ['item.items.api','item.items.api_def','item.items.product_def','cart.scopeCount']
)(withTranslation(['subscriptionscopes'])(SubscriptionToScope))


