import { ggmenu_init_state } from './config/ggmenu_init_state'
import { deepCopyObj, copyObj, propertyCopy } from '../../utils/objtools'

const redGGMenu = (
  state = ggmenu_init_state,
  action
) => {
  var new_state = null
  switch (action.type) {

    case 'GGMENU_PANE_OPEN':
      new_state = copyObj(state)
      propertyCopy(new_state, ['panes', action.pane_key])
      if (new_state.panes[action.pane_key].status !== 'opened') {
        new_state.panes[action.pane_key].status = 'opened'
      }
      new_state.pane_opened = action.pane_key
      break

    case 'GGMENU_PANEGROUP_OPEN':
      new_state = copyObj(state)
      propertyCopy(new_state, ['pane_groups', action.pane_group_key])
      if (new_state.pane_groups[action.pane_group_key].status !== 'opened') {
        new_state.pane_groups[action.pane_group_key].status = 'opened'
      }
      new_state.pane_opened = action.pane_group_key
      break

    case 'GGMENU_PANE_CLOSE':
      new_state = copyObj(state)
      propertyCopy(new_state, ['panes', action.pane_key])
      if (new_state.panes[action.pane_key].status !== 'closed') {
        new_state.panes[action.pane_key].status = 'closed'
      }
      new_state.pane_opened = false
      break

    case 'GGMENU_PANEGROUP_CLOSE':
      new_state = copyObj(state)
      propertyCopy(new_state, ['pane_groups', action.pane_group_key])
      if (new_state.pane_groups[action.pane_group_key].status !== 'closed') {
        new_state.pane_groups[action.pane_group_key].status = 'closed'
      }
      new_state.pane_opened = false
      break

    case 'GGMENU_SET_HEADER_STATUS':
      new_state = copyObj(state)
      new_state.header_status = action.header_status
      break

    case 'PORTAL_SET_MENU_ITEM':
      new_state = copyObj(state)
      propertyCopy(new_state, 'items')
      new_state.items[action.item.name] = deepCopyObj(action.item)
      break

    default:
      break
  }
  if (new_state) {
    return new_state
  }
  else {
    return state
  }
}

export default redGGMenu;
