import React, { Component } from 'react'
import { connect } from '../../../utils/redux'
import PropTypes from 'prop-types'
import TabHeader from './TabHeader'
import Visibility from '../../../utils/debug/Visibility'
import { stateIsPortalDebug } from '../../portal/portal_selectors'

class Tabs extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
    activeTabCode: PropTypes.string
  }

  componentDidMount() {
    this.postRender()
  }

  componentDidUpdate() {
    this.postRender()
  }

  postRender() {
    var tabFound = false
    for (var key in this.props.children) {
      if (this.props.children[key] && (this.props.children[key].props.displayCondition || this.props.redux.stateIsPortalDebug) && this.props.children[key].props.tabCode === this.props.activeTabCode) {
        tabFound = true
        break
      }
    }
    if (!tabFound) {
      this.props.historyPush({tab_code: 'overview'})
    }
  }

  onClickTabItem = (tabCode) => {
    this.props.historyPush({tab_code: tabCode})
  }

  render() {
    const {
      onClickTabItem,
      props: {
        children,
        activeTabCode
      }
    } = this

    return (
      <div className="ui-tabs">
        <ol className="ui-tabs-headers">
          {children.map((child) => {
            if (!child) {
              return null
            }
            const { tabCode, tabLabel, displayCondition } = child.props;
            return (
              <Visibility condition={displayCondition} key={tabCode}>
                <TabHeader
                  activeTabCode={activeTabCode}
                  tabCode={tabCode}
                  tabLabel={tabLabel}
                  onClick={onClickTabItem}
                />
              </Visibility>
            );
          })}
        </ol>
        <div className="ui-tabs-content">
          {children.map((child) => {
            if (!child) {
              return null
            }
            if (child.props.tabCode !== activeTabCode) {
              return undefined
            }
            return child
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    redux: {
      stateIsPortalDebug: stateIsPortalDebug(state)
    }
  }
}

const mapDispatchToProps = dispatch => ({
  historyPush: (ids) => { dispatch({type:'HISTORY_PUSH', ids}) }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  'portal.debug'
)(Tabs)