import { getFirstDef2, isDefVal2 } from '../../utils/objtools'
import { stateFindResourceCodeByIds } from '../resource/resource_selectors'
import { stateFindItemByCodeOrAlias } from '../item/item_selectors'

/**
 * Find list in state given a scheme_key and resource code or alias
 */
export function stateFindListByCodeOrAlias(state, scheme_key, resource_code_or_alias) {
  // Try to get the item directly (resource_code is a code)
  var list = getFirstDef2(state, [['list', 'lists', scheme_key, resource_code_or_alias]], null)
  if (list)
    return list
  // Try to get the item by alias (resource is an alias)
  var resource_code = getFirstDef2(state, [['list', 'lists', scheme_key, 'aliases', resource_code_or_alias]], null)
  if (resource_code)
    return getFirstDef2(state, [['list', 'lists', scheme_key, resource_code]], null)
  // The code is neither a code nor an alias
  return null
}

/**
 * Given an scheme_key and resource_alias, get the resource code
 */
export function stateFindListResourceCodeFromAlias(state, scheme_key, resource_alias) {
  return isDefVal2(state, ['list', 'lists', scheme_key, 'aliases', resource_alias])
}

/**
 * Given a list scheme_key and resource_code/alias, get its items
 */
export function stateGetListItemsByCodeOrAlias(state, scheme_key, resource_code_or_alias) {
  var list = stateFindListByCodeOrAlias(state, scheme_key, resource_code_or_alias)
  if (!list) {
    return null
  }
  return list.itemsResourceCodes.map(resource_code => {
    return stateFindItemByCodeOrAlias(state, list.external_resource_infos.scheme.item_type, resource_code)
  })
}

/**
 * Given a list scheme_key and ids, get its items
 */
export function stateGetListItemsByIds(state, scheme_key, ids) {
  var resource_code = stateFindResourceCodeByIds(state, scheme_key, ids)
  if (!resource_code) {
    return null
  }
  return stateGetListItemsByCodeOrAlias(state, scheme_key, resource_code)
}

/**
 * Given a list scheme_key and ids, get its items
 */
export function stateGetListItemsDomainsByIds(state, scheme_key, ids) {
  var resource_code = stateFindResourceCodeByIds(state, scheme_key, ids)
  if (!resource_code) {
    return null
  }
  let domains = []

  if(stateGetListItemsByCodeOrAlias(state, scheme_key, resource_code)) {
    stateGetListItemsByCodeOrAlias(state, scheme_key, resource_code).map((item) => {
      if (item?.consolidated?.domain && !domains.includes(item?.consolidated?.domain)) {
        domains.push(item?.consolidated?.domain);
      };
      return domains
    })
    return domains
  }
  return null
}

export function stateGetListItemsSpacesByIds(state, scheme_key, ids) {
  var resource_code = stateFindResourceCodeByIds(state, scheme_key, ids)
  if (!resource_code) {
    return null
  }
  let spaces = []

  if(stateGetListItemsByCodeOrAlias(state, scheme_key, resource_code)) {
    stateGetListItemsByCodeOrAlias(state, scheme_key, resource_code).map((item) => {
      if (item?.consolidated?.space && !spaces.includes(item?.consolidated?.space)) {
        spaces.push(item?.consolidated?.space);
      };
      return spaces
    })
    return spaces
  }
  return null
}