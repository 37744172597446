import React from 'react'
import {logRender} from "../../../utils/logging";
import { withTranslation } from 'react-i18next'

class UiMessage extends React.Component {

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null


    return (
      <div className={this.getItemClasses().join(' ')} >
        <p>{this.props.message}</p>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'ui-message'
    ]
    classes.push(this.props.type)
    return classes;
  }
}

export default withTranslation(['common','api'])(UiMessage)
