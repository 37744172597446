import React from 'react'
import { connect } from 'react-redux'
import { logRender } from '../../utils/logging'
import { withTranslation } from 'react-i18next'
import UiH1 from '../ui/titles/UiH1'
import { withStaticContentCode } from '../static_content/static_content_hoc'
import {getFirstDef, isDefVal} from '../../utils/objtools'
import AnimDelay from '../ui/AnimDelay/AnimDelay'
import KpiPool from './graphs/KpiPool'
import Filter from "../filter/Filter";
import branding from "../portal/branding";
import Visibility from "../../utils/debug/Visibility";

class KPIs extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      currentProfil: 'partPools',
    }
    this.changePoolsProfil = this.changePoolsProfil.bind(this)
  }

  static getDerivedStateFromProps(props, state){
    return {
      currentProfil: state.currentProfil,
    }
  }

  changePoolsProfil = (type) => {

    switch(type){
      case 'partPools':
        this.setState({
          currentProfil: 'partPools',
        });
        break;
      case 'proPools':
        this.setState({
          currentProfil: 'proPools',
        });
        break;
      case 'entPools':
        this.setState({
          currentProfil: 'entPools',
        });
        break;
      default:
        console.log("the type is not valid")
    }
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    if (!this.props.staticContent.found) {
      return null
    }

    const canshow = this.props.staticContent.loaded === true

    const { currentProfil } = this.state;

    var KpiPools = []

    for (const pool of getFirstDef(this.props.staticContent, `data.${this.state.currentProfil}`, [])) {
      KpiPools.push(<KpiPool key={pool.h2} data={pool} />)
    }


    return (
      <div className={this.getItemClasses().join(' ')} >
        <div className="grid g1_root g1_1col">
          <UiH1>{this.props.t('kpis:title')}</UiH1>
          <AnimDelay animateAppearance={true} canShow={canshow}>
            <p className="intro">{isDefVal(this.props.staticContent, 'data.intro')}</p>
          </AnimDelay>
          <Visibility condition={branding.kind === "sg"}>
            <h4>{this.props.t('kpis:filter_intro')}</h4>
            <div className="market_filters">
              <Filter key="partPools" type="partPools" label={this.props.t('kpis:filter_label_part')} isActive={currentProfil === 'partPools'} clickHandler={this.changePoolsProfil}/>
              <Filter key="proPools" type="proPools" label={this.props.t('kpis:filter_label_pro')} isActive={currentProfil === 'proPools'} clickHandler={this.changePoolsProfil}/>
              <Filter key="entPools" type="entPools" label={this.props.t('kpis:filter_label_ent')} isActive={currentProfil === 'entPools'} clickHandler={this.changePoolsProfil}/>
            </div>
          </Visibility>
        </div>
        <AnimDelay animateAppearance={true} canShow={canshow}>
          {KpiPools}
        </AnimDelay>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'kpis',
      'full'
    ]
    return classes;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    redux: {
      lang_code: state.lang.lang_code
    }
  }
}

export default connect(
  mapStateToProps,
  null
)(withStaticContentCode(
  'kpis'
)(withTranslation(
  'kpis'
)(KPIs)))
