import React from 'react'
import {connect} from 'react-redux'
import {logRender} from '../../../utils/logging'
import {withTranslation} from 'react-i18next'
import {deepEqualObjs2} from '../../../utils/objtools'
import ImgMedia from "../../ui/ImgMedia/ImgMedia";
import ReactMarkdown from "react-markdown/with-html";
import {modalAdd} from "../../modals/modals_actions";


class MediaSection extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || this.props.tReady !== nextProps.tReady
      || this.state.hasError !== nextState.hasError
    )
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    console.error('MediaSection failed, will fallback on <pre> rendering')
    //console.error(error, info);
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    if (!this.props.description)
      return null

    var content = null

    if (this.state.hasError)
      content = <div>
        <p>Sorry, Github Markdown could not be rendered : </p>
        <pre>{this.props.description}</pre>
      </div>
    else
      // skipHtml={true}
      // escapeHtml={true} : n'agit que sur certaines balises ?
      content = <ReactMarkdown source={this.props.description} escapeHtml={false} />

    if(content)
      content = (
        <React.Fragment>
          {content}
        </React.Fragment>
      )

    var title = this.props.title
    var mediaName = this.props.media_name

    return (
      <div className={this.getItemClasses().join(' ')}>
        <h3 className="title">{title}</h3>
        <div className="content">
          {this.props.description !== ' ' &&
            <div className="descriptions">
            {content}
            </div>
          }
          <div className="vignette">
            <ImgMedia title={title} media_name={mediaName} organization={this.props.organization} onClick={()=>this.extendMedia()}/>
          </div>
        </div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'media-section'
    ]
    classes.push(this.props.text_align)
    return classes
  }

  extendMedia() {
    this.props.modalAdd(
      'media',
      {
        title: this.props.title,
        mediaName: this.props.media_name,
        organization: this.props.organization,
        closeAction: null
      }
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    lang_code: state.lang.lang_code
  }
  return {
    redux
  }
}

const mapDispatchToProps = dispatch => ({
  modalAdd: (modalType, modalProps) => dispatch(modalAdd(modalType, modalProps)),
})

MediaSection = withTranslation(['common'])(MediaSection)

export default connect(mapStateToProps, mapDispatchToProps)(MediaSection)
