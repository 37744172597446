import React from 'react'
import { logRender } from '../../utils/logging'
import {isDef, removeDuplicates} from '../../utils/objtools'
import {mapResourceFromCode, mapResourceFromIds, stateFindResourceByCodeOrAlias} from '../resource/resource_selectors'
import {stateGetSelectedConsorgId, stateGetSelectedConsorgResourceCode} from '../consorg/consorg_selectors'
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import PlansFilters from "../plan/PlansFilters";
import Plan from "../plan/Plan";
import AnimDelay from "../ui/AnimDelay/AnimDelay";
import {modalAdd} from "../modals/modals_actions";
import SubscriptionPlansFinalForm from './SubscriptionPlansNewFinalForm'
import { bindPromiseCreators } from 'redux-saga-routines'
import {submitSubscriptionPlansFinalFormNewHandler} from "./subscription_plans_routines";

class SubscriptionToPlan extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      currentApproval: null,
      approvals: [],
      currentRate: null,
      rates: [],
      currentOperation: null,
      operations: [],
      isTagsNeedsInit: true
    }
    this.changeFilter = this.changeFilter.bind(this)
    this.subscribeToPlan = this.subscribeToPlan.bind(this)
  }

  static getDerivedStateFromProps(props, state){

    // Init all plans tags
    var approval_tags = []
    var approval_filters = state.approvals
    var rate_tags = []
    var rate_filters = state.rates
    var operations_tags = []
    var operations_filters = state.operations

    if (isDef(props.redux, 'data.definition.plans')){

      for (let plan in props.redux.data.definition.plans) {

        if(isDef(props.redux.data.definition.plans[plan], 'approval')){
          approval_tags.push(props.redux.data.definition.plans[plan].approval ? "requires approval" : "no approval required")
        }

        if (isDef(props.redux.data.definition.plans[plan], 'rate-limit')) {
          rate_tags.push(props.redux.data.definition.plans[plan]["rate-limit"].value)
        } else if (isDef(props.redux.data.definition.plans[plan], 'rate-limits') && Object.keys(props.redux.data.definition.plans[plan]['rate-limits'])[0]) {

            rate_tags.push(props.redux.data.definition.plans[plan]["rate-limits"][Object.keys(props.redux.data.definition.plans[plan]['rate-limits'])[0]].value)

        } else {
          rate_tags.push("unlimited");
        }

        if (isDef(props.redux.data.definition.plans[plan], 'apis')) {
          for (let api in Object.keys(props.redux.data.definition.plans[plan].apis)) {
            if(isDef(props.redux.data.definition.plans[plan].apis[Object.keys(props.redux.data.definition.plans[plan].apis)[api]], 'operations')){
              for(let operation in props.redux.data.definition.plans[plan].apis[Object.keys(props.redux.data.definition.plans[plan].apis)[api]].operations){
                operations_tags.push(props.redux.data.definition.plans[plan].apis[Object.keys(props.redux.data.definition.plans[plan].apis)[api]].operations[operation].operation)
              }
            }
            else{
              operations_tags.push("all operations")
            }
          }
          if (Object.keys(props.redux.data.definition.plans[plan].apis).length === 0){
            operations_tags.push("all operations")
          }
        }
        else{
          operations_tags.push("all operations")
        }

      }

      // Clean tages and remove duplicates

      approval_tags = removeDuplicates(approval_tags)
      rate_tags = removeDuplicates(rate_tags)
      operations_tags = removeDuplicates(operations_tags)

    }

    // Set the new tags in state if necessary

    if (state.isTagsNeedsInit){
      approval_filters = approval_tags
      rate_filters = rate_tags
      operations_filters = operations_tags
    }

    // If new tags should reset the state

    if(state.approvals.length !== approval_tags.length || state.rates.length !== rate_tags.length || state.operations.length !== operations_tags.length){
      var needsInit = true
    }

    return {
      currentApproval: approval_filters.length === 1 ? approval_filters[0] : state.currentApproval,
      approvals: approval_filters,
      currentRate: rate_filters.length === 1 ? rate_filters[0] : state.currentRate,
      rates: rate_filters,
      currentOperation: operations_filters.length === 1 ? operations_filters[0] : state.currentOperation,
      operations: operations_filters,
      isTagsNeedsInit: needsInit ? needsInit : state.isTagsNeedsInit
    }
  }

  changeFilter(type, label){

    switch(type){
      case 'approvals':
        if(label === this.state.currentApproval){
          this.setState({
            currentApproval: null,
            isTagsNeedsInit: false
          });
        }
        else{
          this.setState({
            currentApproval: label,
            isTagsNeedsInit: false
          });
        }

        break;
      case 'rates':
        if(label === this.state.currentRate){
          this.setState({
            currentRate: null,
            isTagsNeedsInit: false
          });
        }
        else{
          this.setState({
            currentRate: label,
            isTagsNeedsInit: false
          });
        }
        break;
      case 'operations':
        if(label === this.state.currentOperation){
          this.setState({
            currentOperation: null,
            isTagsNeedsInit: false
          });
        }
        else{
          this.setState({
            currentOperation: label,
            isTagsNeedsInit: false
          });
        }
        break;
      default:
        console.log("the type is not valid")
    }

  }


  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    var plans_filters = [];
    plans_filters.push(<PlansFilters key="plans_filters_bloc" approvals={this.state.approvals} rates={this.state.rates} operations={this.state.operations} state={this.state} filtersHandler={this.changeFilter}/>);

    var featured_plans = []
    if (isDef(this.props.redux, 'data.definition.plans')) {

      for (let plan in this.props.redux.data.definition.plans) {
        featured_plans.push(
          <Plan key={plan} title="plan" plan={this.props.redux.data.definition.plans[plan]}
                name={plan} state={this.state} api_counter={this.props.api_counter}
                subscriptionHandler={this.subscribeToPlan}/>
        );
      }
    }


    if (featured_plans.length)
      featured_plans = (
        <AnimDelay animateAppearance={true} showDelay={500}>
          <h2>{this.props.t('plan_subscription:title')}</h2>
          <div className="featured_plans_list grid g1_321col">
            {featured_plans}
          </div>
        </AnimDelay>
      )

    return (
      <div className={this.getItemClasses().join(' ')}>
        {plans_filters}
        {featured_plans}
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'subscription'
    ]
    return classes;
  }

  subscribeToPlan(name) {
    this.props.modalAdd(
      'form',
      {
        title: this.props.t('plan_subscription:subscription_modal_title'),
        message: this.props.t('plan_subscription:subscription_modal_message'),
        FormComponent: SubscriptionPlansFinalForm,
        formComponentProps: {
          form_op: "new",
          initialValues: {
            name: name,
            productId: this.props.redux.data.id,
            selected_consorg_id: this.props.redux.selected_consorg_id
          }
        },
        submitAction: this.props.submitSubscriptionPlansFinalFormNewHandler,
        closeAction: false
      }
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    lang_code: state.lang.lang_code,
    data: {},
    consorg: mapResourceFromCode(state, 'consorg', stateGetSelectedConsorgResourceCode(state)),
    selected_consorg_id: stateGetSelectedConsorgId(state),
  }
  redux.applications = mapResourceFromIds(state, 'consorg_applications', redux.consorg.ids)
  var resource
  if ((resource = stateFindResourceByCodeOrAlias(state, ownProps.item_type, ownProps.resource_code))) {
    redux.data = resource.data
  }
  return {
    redux
  }
}

const mapDispatchToProps = dispatch => ({
  // submitSubscriptionPlansFormNewRoutine: (payload) => dispatch(submitSubscriptionPlansFormNewRoutine(payload)),
  ...bindPromiseCreators({
    submitSubscriptionPlansFinalFormNewHandler
  }, dispatch),
  modalAdd: (modalType, modalProps) => dispatch(modalAdd(modalType, modalProps)),
  requestResource: (external_resource_infos) => dispatch({type:'RESOURCE_LOAD_REQUEST', external_resource_infos})
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(['product','plan_subscription'])(SubscriptionToPlan))


