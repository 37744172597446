import { call, put, takeEvery, select } from 'redux-saga/effects'
import { notificationAddError } from '../notifications/notifications_actions'
import { getPortalOrganisationSparkyManagerEndpoint, getPortalCurrentOrganisationId, stateIsPortalDebug } from '../portal/portal_selectors'
import { stateFindResourceByCodeOrAlias } from '../resource/resource_selectors'
import i18n from '../../utils/lang/i18n'
import { getUrl } from '../../utils/fetch'
import { isDefVal } from '../../utils/objtools'
import HttpStatusError from '../../utils/error/HttpStatusError'

const listSagas = [
  takeEvery("LIST_LOAD_REQUEST", loadList)
]
export default listSagas

// List Load

function* loadList(action) {
  var data = null
  var error = null
  var url = null
  var status = null

  try {
    var api_endpoint = null
    var organization_id = null
    switch(isDefVal(action.external_resource_infos, 'scheme.source')) {
      case "portal":
        api_endpoint = ''
        break
      default:
        api_endpoint = yield select(getPortalOrganisationSparkyManagerEndpoint)
        if (!api_endpoint)
          throw new Error("Could not get manager's api endpoint.")
        organization_id = yield select(getPortalCurrentOrganisationId)
        if (!organization_id)
          throw new Error("Could not get organization_id.")
        break
    }
    const force = isDefVal(action, 'force')===true
    var resource = yield select(stateFindResourceByCodeOrAlias, action.external_resource_infos.scheme.item_type, action.external_resource_infos.resource_code)
    if (!force && resource && (['loaded','loading'].indexOf(isDefVal(resource, 'status')) >= 0)) {
      //console.info('skipping LIST_LOAD_REQUEST')
      return
    }
    yield put({type: 'LIST_LOAD_START', external_resource_infos: action.external_resource_infos})
    url = api_endpoint + action.external_resource_infos.resource_uri
    const {fetch_response, fetch_error} = yield call(getUrl, url, organization_id)
    if (fetch_error) {
      throw fetch_error
    }
    status = fetch_response.status
    if (fetch_response.status !== 200) {
      throw new HttpStatusError(fetch_response.status)
    }
    data = yield fetch_response.json()
    // DEV ONLY TEMP : DATA LIMITATION FOR REDUX TOOLS SPEED
    // data = data.slice(0, 10)
  }
  catch (catched_error) {
    error = catched_error
  }
  if (error) {
    var body = i18n.t('common:list_load_error_body', {
      scheme_key: action.external_resource_infos.scheme_key,
      resource_uri: action.external_resource_infos.resource_uri
    })
    var title = i18n.t('common:list_load_error_title')
    yield put(notificationAddError({title, body, error}))
    yield put({type: 'LIST_LOAD_ERROR', external_resource_infos: action.external_resource_infos, error})
  }
  else if (data) {
    yield put({type: 'LIST_LOAD_SUCCESS', external_resource_infos: action.external_resource_infos, data})
  }
  if (yield select(stateIsPortalDebug)) {
    yield put({type: 'LOG_EXTERNAL_QUERY', scheme_key: action.external_resource_infos.scheme_key, url, status, has_error: error?true:false, has_data: data?true:false})
  }
}
