import { isDefVal } from '../../utils/objtools'

export function stateGetCartScopeCount(state) {
  return state.cart.scopeCount
}

export function stateGetCartScope(state, name) {
  return isDefVal(state.cart.selectedScopes, name)
}

export const stateGetCartSelectedScopes = state => state.cart.selectedScopes

export const stateGetCartContext = state => state.cart.consolidatedContext
