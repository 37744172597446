import React from 'react'
import { connect } from '../../utils/redux'
import { logRender } from '../../utils/logging'
import GGMenuPane from './GGMenuPane'
import { deepEqualObjs2 } from '../../utils/objtools'

class GGMenuPaneGroup extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
    )
  }

  render() {
    logRender(this)
    if (this.props.redux.pane_keys.length === 0)
      return null
    var panes = []
    this.props.redux.pane_keys.forEach(pane_key => {
      panes.push(
        <GGMenuPane key={pane_key} name={pane_key} />
      )
    });
    return (
      <div className={this.getItemClasses().join(' ')}>
        {panes}
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'ggmenu-pane-group',
      'name-'+this.props.name
    ]
    if (this.props.name !== 'independantgroup')
      classes.push('status-'+this.props.redux.group.status)
    return classes;
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    group: state.ggmenu.pane_groups[ownProps.name],
    pane_keys: []
  }
  for (var pane_key in state.ggmenu.panes) {
    if (state.ggmenu.panes[pane_key].show[state.mqsync.rv_group] === ownProps.name) {
      redux.pane_keys.push(pane_key)
    }
  }
  return {
    redux
  }
}

export default connect(
  mapStateToProps,
  null,
  ['ggmenu','mqsync']
)(GGMenuPaneGroup);
