import { submitCredentialFormVerifyRoutine } from './credential_routines'
import { put, call, delay } from 'redux-saga/effects'
import i18n from '../../utils/lang/i18n'
import { modalAdd } from '../modals/modals_actions'
import genericFormSubmissionSaga from '../item/genericFormSubmissionSaga'
import {select} from "@redux-saga/core/effects";
import {
  getPortalCurrentOrganisationId,
  getPortalOrganisationSparkyManagerEndpoint,
  stateIsPortalDebug
} from "../portal/portal_selectors";
import { buildExternalResourceUri } from "../../utils/urls/external";
import {postUrlJson} from "../../utils/fetch";
import HttpStatusError from "../../utils/error/HttpStatusError";
import {getFirstDef} from "../../utils/objtools";

function* saga(action) {

  const {values, modalInfos} = action.payload
  // prepare some ids
  var ids = values.ids

  var body = {
    secret: values.secret
  }

  var saga_error = null
  var url = null
  var status = null
  var data = null

  var new_scheme_key = "credential_verify"

  try {
    const sparky_manager_api_endpoint = yield select(getPortalOrganisationSparkyManagerEndpoint)
    if (!sparky_manager_api_endpoint)
      throw new Error("Could not get manager's api endpoint.")
    const organization_id = yield select(getPortalCurrentOrganisationId)
    if (!organization_id)
      throw new Error("Could not get organization_id.")

    var uri = yield buildExternalResourceUri(new_scheme_key, ids)
    if (!uri)
      throw new Error("Could not build the creation uri.")

    url = sparky_manager_api_endpoint + uri

    const {fetch_response, fetch_error} = yield call(postUrlJson, url, body, organization_id)

    if (fetch_error)
      throw fetch_error
    if (!fetch_response)
      throw new Error ('Could not get response')
    status = fetch_response.status
    if (fetch_response.status !== 200 && fetch_response.status !== 201)  {
      var error_response = yield fetch_response.json()

      modalInfos.closeModal()
      yield delay(200)

      yield put(modalAdd(
        'error',
        {
          title: fetch_response.status,
          message: getFirstDef(error_response.errors[0], 'defaultMessage', ''),
          error_code: error_response.errors[0].errorCode,
          subject: error_response.errors[0].subject,
          request_id: error_response.requestId,
          timestamp: error_response.timestamp,
        }
      ))

      throw new HttpStatusError(fetch_response.status)
    }
    data = yield fetch_response.json()

    modalInfos.closeModal()

    // Checking data
    if (!data.secretValid)
      //   throw new Error('No feedback found in this verification !')

    // Show the feedback after delay

    yield delay(500)

    yield put(modalAdd(
      'feedback',
      {
        title: i18n.t('credential:successfull_verify_title'),
        message: i18n.t(data.secretValid ?'credential:verify_corresponding':'credential:verify_not_corresponding'),
        success: !!data.secretValid,
        error: !data.secretValid
      }
    ))
  }

  catch (catched_error) {
    saga_error = catched_error
  }

  if (yield select(stateIsPortalDebug)) {
    yield put({type: 'LOG_EXTERNAL_QUERY', scheme_key: new_scheme_key, url, status, has_error: saga_error?true:false, has_data: data?true:false})
  }

  if (saga_error) {
    yield put({type: 'ITEM_QUERY_ERROR', query_scheme_key: new_scheme_key, error: saga_error})
    return {error: saga_error}
  }

}

export function* submitVerifyCredentialForm(action) {
  yield call(genericFormSubmissionSaga, {
    saga,
    action,
    routine: submitCredentialFormVerifyRoutine,
    successfull_title: 'credential:successfull_verify_title',
    successfull_body: 'credential:successfull_verify_body',
    unsuccessfull_title: 'credential:unsuccessfull_verify_title',
    unsuccessfull_body: 'credential:unsuccessfull_verify_body'
  })
}
