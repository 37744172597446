import {put, takeEvery, takeLatest, select, call} from 'redux-saga/effects'
import { notificationAddError } from '../notifications/notifications_actions'
import {
  buildExternalResourceInfos
} from '../../utils/urls/external'
import {
  getPortalOrganisationSparkyManagerAuthURL,
  getPortalOrganisationSparkyManagerLogoutURL,
  getPortalCurrentOrganisationAndCatalogIds, getPortalCurrentOrganisationId
} from '../portal/portal_selectors'
import {isDef, isDefVal} from '../../utils/objtools'
import { stateAuthIsUserLoggedIn } from '../auth/auth_selectors'
import { membershipStates } from '../../utils/enums/membershipStates'
import {postUrlJson} from "../../utils/fetch";
import HttpStatusError from "../../utils/error/HttpStatusError";

const authSagas = [
  takeEvery("PORTAL_CATALOG_STATUS", checkLogin),

  takeEvery("ITEM_LOAD_ERROR", updateLoginInfos),
  takeEvery("LIST_LOAD_ERROR", updateLoginInfos),
  takeEvery("ITEM_LOAD_SUCCESS", updateLoginInfos),
  takeEvery("ITEM_QUERY_ERROR", updateLoginInfos),

  takeLatest("AUTH_LOGIN", doLogin),
  takeLatest("AUTH_LOGOUT", doLogout),

  takeEvery("LIST_LOAD_SUCCESS", checkAuthContent)
]
export default authSagas

// On portal catalog status success
// start a /me to know current login status

function* checkLogin(action) {
  try {
    if (action.status === 'success') {
      // Ask for load of 'me' resource to know if we are logged in
      const ids =  yield select(getPortalCurrentOrganisationAndCatalogIds)
      var external_resource_infos = buildExternalResourceInfos('me', ids)
      yield put({type: 'RESOURCE_LOAD_REQUEST', external_resource_infos, force:true})
    }
  }
  catch (error) {
    yield put(notificationAddError({error}))
  }
}

// On item load success/error, update login infos when possible

function* updateLoginInfos(action) {
  if (isDefVal(action, 'external_resource_infos.scheme_key') === 'me') {

    const userWasLoggedIn = yield select(stateAuthIsUserLoggedIn)

    var ids
    var external_resource_infos

    if (action.type === 'ITEM_LOAD_SUCCESS' && isDef(action.data, 'id') && isDef(action.data, 'email')) {

      if (userWasLoggedIn === false || userWasLoggedIn === null) {
        yield put({type: 'AUTH_IS_LOGGED_IN', user_data: action.data})

        // Trigger some resources
        ids =  yield select(getPortalCurrentOrganisationAndCatalogIds)
        // User's memberships in catalog's consumer orgs
        external_resource_infos = buildExternalResourceInfos('my_memberships', ids)
        yield put({type: 'RESOURCE_LOAD_REQUEST', external_resource_infos, force:true})
      }

      return
    }

    if (userWasLoggedIn === true || userWasLoggedIn === null) {
      yield put({type: 'AUTH_NOT_LOGGED_IN'})

      if (userWasLoggedIn === true) {
        yield put({type: 'AUTH_HAS_LOGGED_OUT'})

        // Update some resources
        // Note la vidange est faite par ailleurs au niveau des reducers via AUTH_HAS_LOGGED_OUT
        ids = {
          ...yield select(getPortalCurrentOrganisationAndCatalogIds)
        }
        // Consorgs visible by the user in the catalog
        external_resource_infos = buildExternalResourceInfos('catalog_consorgs', ids)
        yield put({type: 'RESOURCE_LOAD_REQUEST', external_resource_infos, force:true})
        // refresh finder
        yield put({type: 'FINDER_REFRESH_RESULTS_REQUEST'})
      }

    }

  }
  // On other resources erros, we check login status
  else if ( ['ITEM_LOAD_ERROR','LIST_LOAD_ERROR','ITEM_QUERY_ERROR'].indexOf(action.type)>=0
         && [401, 403].indexOf(isDefVal(action, 'error.status'))>=0 )
  { // TODO Should only be 403 but backend is not consistent !
    console.log('Checking session status due to error')
    const ids =  yield select(getPortalCurrentOrganisationAndCatalogIds)
    external_resource_infos = buildExternalResourceInfos('me', ids)
    yield put({type: 'RESOURCE_LOAD_REQUEST', external_resource_infos, force:true})
  }
}

// Login flow

function* doLogin(action) {
  var auth_url = yield select(getPortalOrganisationSparkyManagerAuthURL)
  if (auth_url) {
    document.body.classList.add("offloading")
    if (isDef(window,'location.href')) {
      auth_url += '?redirect_uri='+window.location.href
    }
    window.location = auth_url //+ '?state=this_is_a_returned_state_test'
  }
}

// Login flow

function* doLogout(action) {
  var logout_url = yield select(getPortalOrganisationSparkyManagerLogoutURL)

  var saga_error = null
  const organization_id = yield select(getPortalCurrentOrganisationId)
  if (!organization_id)
    throw new Error("Could not get organization_id.")

  let body
  try {
    if (!logout_url)
      throw new Error("Could not get manager's logout endpoint.")

    const {fetch_response, fetch_error} = yield call(postUrlJson, logout_url, body, organization_id)

    if (fetch_error)
      throw fetch_error

    if (!fetch_response)
      throw new Error ('Could not get response')

    if (fetch_response.status !== 200 && fetch_response.status !== 201)  {
      throw new HttpStatusError(fetch_response.status)
    }

    yield put({type: 'AUTH_HAS_LOGGED_OUT'})
    yield put({type: 'GGMENU_PANES_CLOSEALL'})
    yield put({type: 'FINDER_REFRESH_RESULTS_REQUEST'})

    // TODO the simple way to flush private items in list is to relead the app

  }

  catch (catched_error) {
    saga_error = catched_error
  }

  if (saga_error) {
    yield put({type: 'ITEM_QUERY_ERROR', error: saga_error})
    return {error: saga_error}
  }
}

// Update availability of user content

function* checkAuthContent(action) {
  const list = isDefVal(action, 'data')
  const value = Array.isArray(list) && list.length>0
  switch(isDefVal(action, 'external_resource_infos.scheme_key')) {
    case "my_memberships":
      yield put({type: 'AUTH_USER_HAS_MEMBERSHIPS', value})
      const invitation_memberships = action.data.filter((membership) => membershipStates.PENDING === membership.state)
      if (invitation_memberships.length > 0) {
        yield put({type: 'AUTH_USER_HAS_INVITATIONS', count: invitation_memberships.length})
      }
      break
    default:
      break
  }
}


