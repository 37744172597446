import React from 'react'
import { connect } from '../../utils/redux'
import { logRender } from '../../utils/logging'
import { withTranslation } from 'react-i18next'
import Teaser from '../teaser/Teaser'
import FinderFilter from './FinderFilter'
import FinderLoadMore from './FinderLoadMore'
import FinderFilterSummary from './FinderFilterSummary'
import { deepEqualObjs2 } from '../../utils/objtools'
import setDocumentTitle from '../../utils/browser/title'
import UiH1 from '../ui/titles/UiH1'
import branding from "../portal/branding";

class Finder extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || this.props.tReady !== nextProps.tReady
    )
  }

  componentDidMount() {
    setDocumentTitle('Finder')
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    var items = []
    var pre_items = []
    var post_items = []
    //var total_items = null
    //var total_items_unfiltered = null

    var i = 0;
    for (var item of this.props.redux.results.items) {
      if (i<this.props.redux.search_state.visible_items) {
        items.push(<Teaser key={item.resource_code} item_type={item.item_type} resource_code={item.resource_code} e2e_key={'finder-'+item.resource_code}/>)
        i++
      }
    }

    pre_items.push(
      <FinderFilter key="fulltext" filter_name="fulltext" />
    )
    // pre_items.push(
    //   <FinderFilter key="itemtype" filter_name="itemtype" />
    // )
    // pre_items.push(
    //   <FinderFilter key="itemstate" filter_name="itemstate" />
    // )
    pre_items.push(
      <div className="filters_summaries" key="filters_summaries">
        <FinderFilterSummary with_pane={false} key="fulltext_summary" filter_name="fulltext" />
        <FinderFilterSummary with_pane={true} key="itemtype_summary" filter_name="itemtype" />
        <FinderFilterSummary with_pane={true} key="itemstate_summary" filter_name="itemstate" />
        {!branding.open &&
          <>
            <FinderFilterSummary with_pane={true} key="space_summary" filter_name="space" />
            <FinderFilterSummary with_pane={true} key="domain_summary" filter_name="domain" />
          </>
        }
      </div>
    )

    var count_infos_content = []
    if (this.props.redux.results.total_items > 0)
      count_infos_content.push(
        <h2 key="count_infos_h2">{this.props.t('finder:count_infos', { count: this.props.redux.results.total_items })}</h2>
      )
    else if (this.props.redux.results.total_items === 0)
      count_infos_content.push(
        <h2 key="count_infos_h2">{this.props.t('finder:count_infos_noresult')}</h2>
      )
    else
      count_infos_content.push(
        <h2 key="count_infos_h2">...</h2>
      )
    pre_items.push(
      <div key="count_infos" className="count_infos">{count_infos_content}</div>
    )

    if (this.props.redux.results.total_items > this.props.redux.search_state.visible_items) {
      post_items.push(<h3 key="visible_count_ratio">{this.props.redux.search_state.visible_items} / {this.props.redux.results.total_items}</h3>)
      post_items.push(<FinderLoadMore key="loadmore" />)
    }

    return (
      <div className="finder">
        <div className="grid g1_root g1_1col">
          <UiH1>{this.props.t('finder:title')}</UiH1>
          <div className="pre_items">
            {pre_items}
          </div>
          <div className="items grid g1_432col">
            {items}
          </div>
          <div className="post_items">
            {post_items}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    lang_code: state.lang.lang_code,
    results: state.finder.results,
    search_state: state.finder.search_state
  }
  return {
    redux
  }
}

export default connect(
  mapStateToProps,
  null,
  ['lang','finder']
)(withTranslation('finder')(Finder));
