import { createRoutine, promisifyRoutine } from 'redux-saga-routines'

// New Application
// Routine for Form
export const submitApplicationFormNewRoutine = createRoutine('SUBMIT_APPLICATION_FORM_NEW')
export const submitApplicationFinalFormNewHandler = promisifyRoutine(submitApplicationFormNewRoutine)

// Edit Application
// Routine for Form
export const submitApplicationFormEditRoutine = createRoutine('SUBMIT_APPLICATION_FORM_EDIT')
export const submitApplicationFinalFormEditHandler = promisifyRoutine(submitApplicationFormEditRoutine)

// Delete Application
export const submitApplicationFormDeleteRoutine = createRoutine('SUBMIT_APPLICATION_FORM_DELETE')
export const submitApplicationFinalFormDeleteHandler = promisifyRoutine(submitApplicationFormDeleteRoutine)
