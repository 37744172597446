import React from 'react'
import { logRender } from '../../utils/logging'
import UiH1 from '../../components/ui/titles/UiH1'
import { external_resource_schemes } from './external'
import { isDef, getFirstDef } from '../objtools'
import setDocumentTitle from '../browser/title'
import { connect } from 'react-redux'

class TechExternalUrls extends React.Component {

  componentDidMount() {
    setDocumentTitle('External URLs reference')
  }

  render() {
    logRender(this)

    const create_content = (scheme) => {
      var scheme_content = []
      scheme_content.push(<h3 key="h3">{scheme.scheme_key}</h3>)
      scheme_content.push(<p key="attr"><strong>Resource type:</strong> {scheme.type} <br /> <strong>Item type:</strong> {scheme.item_type} <br /> <strong>Source:</strong> {getFirstDef(scheme, 'source', 'manager')}</p>)
      scheme_content.push(<p key="info">{scheme.info}</p>)
      scheme_content.push(<pre key="uri_pattern" ><span role="img" aria-label="link">🔗</span> Current URI Pattern: {scheme.uri_pattern}</pre>)
      if (isDef(scheme, 'future_uri_pattern')) {
        scheme_content.push(<pre key="future_uri_pattern"><span role="img" aria-label="warning">⚠️</span> Future/expected URI Pattern : {scheme.future_uri_pattern}</pre>)
      }
      if (isDef(this.props.redux.external_queries_logs, scheme.scheme_key)) {
        scheme_content.push(
          <div key="logs">
            <h4>Last calls</h4>
            {this.props.redux.external_queries_logs[scheme.scheme_key].map((log, key)=>{
            return <p key={key}>{log.has_error?'❌':'✔️'} {log.has_data?'📦':'🗋'} <strong>{log.status}</strong> {log.url}</p>
            })}
          </div>
        )
      }
      scheme_content.push(<hr key="hr" />)
      return (
        <div key={scheme.scheme_key}>
          {scheme_content}
        </div>
      )
    }

    var content_lists = []
    var content_items = []
    var content_others = []
    for (var scheme_key in external_resource_schemes) {
      var scheme = external_resource_schemes[scheme_key]
      switch(scheme.type) {
        case 'list':
          content_lists.push(create_content(scheme))
          break
        case 'item':
          content_items.push(create_content(scheme))
          break
        default:
          content_others.push(create_content(scheme))
          break
      }

    }

    return (
      <div className={this.getItemClasses().join(' ')} >
        <div className="grid g1_root g1_1col">
          <UiH1>External URLs reference</UiH1>
          <h2>Lists</h2>
          <hr />
          {content_lists}
          <h2>Items</h2>
          <hr />
          {content_items}
          <h2>Others</h2>
          <hr />
          {content_others}
        </div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'tech-external-urls',
      'full'
    ]
    return classes;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    redux: {
      external_queries_logs: state.portal.external_queries_logs
    }
  }
}

export default connect(
  mapStateToProps,
  null
)(TechExternalUrls)
