import React from 'react'
import { logRender } from '../../utils/logging'
import { withTranslation } from 'react-i18next'
// import { isDef } from '../../utils/objtools'

class ApiTag extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.tReady !== nextProps.tReady
      || JSON.stringify(this.props.tag) !== JSON.stringify(nextProps.tag)
    )
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    var content = this.props.tag.name

    return (
      <div className={this.getItemClasses().join(' ')} >
        {content}
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'api-tag'
    ]
    return classes;
  }
}

export default withTranslation('api')(ApiTag);