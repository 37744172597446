import { combineReducers } from 'redux'
import redLang from './utils/lang/lang_reducer'
import redAuth from './components/auth/auth_reducer'
import redItem from './components/item/item_reducer'
import redPortal from './components/portal/portal_reducer'
import redNotifications from './components/notifications/notifications_reducer'
import redModals from './components/modals/modals_reducer'
import redMQSync from './utils/mqsync/mqsync_reducer'
import redGGMenu from './components/ggmenu/ggmenu_reducer'
import redList from './components/list/list_reducer'
import redFinder from './components/finder/finder_reducer'
import redCart from "./components/cart/cart_reducer";

const rootReducer = combineReducers({
  mqsync: redMQSync,
  ggmenu: redGGMenu,
  portal: redPortal,
  lang: redLang,
  auth: redAuth,
  notifications: redNotifications,
  modals: redModals,
  list: redList,
  item: redItem,
  finder: redFinder,
  cart: redCart
})

export default rootReducer
