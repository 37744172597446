import { put, takeEvery, select } from 'redux-saga/effects'
import { buildExternalResourceInfos } from '../../utils/urls/external'
import { isDef, isDefVal } from '../../utils/objtools'
import { stateFindResourceByCodeOrAlias } from './resource_selectors'

const resourceSagas = [
  takeEvery("RESOURCE_LOAD_REQUEST", loadResource),
  takeEvery("ITEM_LOAD_SUCCESS", loadResourceAssociates),
  takeEvery("LIST_LOAD_SUCCESS", loadResourceAssociates),
  takeEvery("ITEM_LOAD_SUCCESS", warmupResourceAssociates)
]
export default resourceSagas

/**
 * Request resource load
 * @param {*} action
 */
function* loadResource(action) {
  // TODO NTH? ability to delay resource load request (yield delay(2000)) (to have lower priorities)
  // Préparation en fonction des données fournies
  var external_resource_infos
  var resource
  if (isDef(action, 'external_resource_infos')) {
    external_resource_infos = action.external_resource_infos
    if (external_resource_infos) {
      resource = yield select(stateFindResourceByCodeOrAlias, external_resource_infos.scheme.item_type, external_resource_infos.resource_code)
    }
  }
  else if (isDef(action, 'item_type') && isDef(action, 'resource_code')) {
    // Try to find existing resource
    resource = yield select(stateFindResourceByCodeOrAlias, action.item_type, action.resource_code)
    if (resource) {
      external_resource_infos = resource.external_resource_infos
    }
    // Otherwise revert the code and build resource infos
    // Not needed yet
  }
  if (!external_resource_infos) {
    console.error('Could not identify the resource to load')
  }
  const force = isDefVal(action, 'force')===true
  if (!force && resource && (['loaded','loading'].indexOf(isDefVal(resource, 'status')) >= 0)) {
    return
  }
  // Dispatch en fonction du type
  if (external_resource_infos) {
    switch(external_resource_infos.scheme.type) {
      case 'list':
        yield put({type: 'LIST_LOAD_REQUEST', external_resource_infos, force})
        break
      case 'item':
        yield put({type: 'ITEM_LOAD_REQUEST', external_resource_infos, force})
        break
      default:
        break
    }
  }
}

/**
 * Request associates resources load
 * @param {*} action
 */
function* loadResourceAssociates(action) {
  if (isDef(action, 'external_resource_infos.scheme.load_associates') && isDef(action, 'external_resource_infos.ids')) {
    // Get the resource to have all ids (we have more ids after success than in the initial action)
    var resource = yield select(stateFindResourceByCodeOrAlias, action.external_resource_infos.scheme_key, action.external_resource_infos.resource_code)
    // request associated resources
    for (var scheme_key of action.external_resource_infos.scheme.load_associates) {
      // Parent ids should be enough for children and associates
      var ids = {...action.external_resource_infos.ids, ...resource.ids}
      var external_resource_infos = buildExternalResourceInfos(scheme_key, ids)
      yield put({type: 'RESOURCE_LOAD_REQUEST', external_resource_infos})
    }
  }
}

/**
 * Request associates resources warmup
 * @param {*} action
 */
function* warmupResourceAssociates(action) {
  if (isDef(action, 'external_resource_infos.scheme.warmup_associates') && isDef(action, 'external_resource_infos.ids')) {
    // Get the resource to have all ids (we have more ids after success than in the initial action)
    var resource = yield select(stateFindResourceByCodeOrAlias, action.external_resource_infos.scheme_key, action.external_resource_infos.resource_code)
    // request associated resources
    for(var scheme_key of action.external_resource_infos.scheme.warmup_associates) {
      // Parent ids should be enough for children
      var external_resource_infos = buildExternalResourceInfos(scheme_key, resource.ids)
      var associated_resource = yield select(stateFindResourceByCodeOrAlias, external_resource_infos.scheme_key, external_resource_infos.resource_code)
      if (!associated_resource) {
        // The resource does not exist, we can dispatch a warmup
        switch(external_resource_infos.scheme.type) {
          case 'item':
            yield put({type: 'ITEM_WARMUP_REQUEST', external_resource_infos})
            break
          case 'list':
            yield put({type: 'LIST_WARMUP_REQUEST', external_resource_infos})
            break
          default:
            break
        }
      }
    }
  }
}
