import React from 'react';
import { logRender } from '../../utils/logging'
import { modalTypes, modalActionCallbacks } from './types'
import { isDef } from '../../utils/objtools'

const MODAL_TYPES = {
  'confirm': modalTypes.ConfirmModal,
  'delete': modalTypes.DeleteModal,
  'secret': modalTypes.SecretModal,
  'form': modalTypes.FormModal,
  'summary': modalTypes.SummaryModal,
  'feedback': modalTypes.FeedbackModal,
  'reset': modalTypes.ResetModal,
  'error': modalTypes.HttpResponseModal,
  'media': modalTypes.MediaModal
}

class Modal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      phase: props.show?'pre-show':'hidden' // en pratique on va toujours init avec true...
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show && !this.props.show) {
      this.setState({phase: 'pre-hide'})
    }
  }

  render() {
    logRender(this)
    var wrapper_classes = ['modal_wrapper','type_'+this.props.modalType]
    if(this.state.phase === 'show' || this.state.phase === 'pre-hide')
      wrapper_classes.push('show')
    switch(this.state.phase) {
      case 'pre-show':
        setTimeout(() => {
          this.setState({phase: 'show'})
          this.forceUpdate()
        }, 100)
        // prepare autoclose if needed
        if(this.props.autoclose_ms > 0) {
          setTimeout(() => {
            this.props.onClose(this.props.modal_id)
          }, this.props.autoclose_ms)
        }
        break
      case 'pre-hide':
        setTimeout(() => {
          this.setState({phase: 'hidden'})
          this.forceUpdate()
        }, 10)
        break
      case 'hidden':
        break
      default:
        break
    }
    const ModalContent = MODAL_TYPES[this.props.modalType]
    // The info we will give to action (to allow it to close the modal, etc.)
    var modalInfos = {
      modal_id: this.props.modal_id,
      closeModal: () => this.props.modalSetDisplayed(this.props.modal_id)
    }
    // Adding modalInfos to existing actionCallbacks
    var modalActions = {}
    var actions = modalActionCallbacks
    actions.forEach((action)=>{
      if (this.props.modalProps[action])
        modalActions[action] = () => {
          this.props.modalProps[action](modalInfos)
        }
    })
    // Add submitAction with modalInfos
    if (isDef(this.props.modalProps, 'submitAction')) {
      modalActions.submitAction = (payload) => this.props.modalProps.submitAction({values:payload, modalInfos})
    }
    // At least add the close action if no closeAction callback exists
    if (!modalActions.closeAction)
      modalActions.closeAction = () => {
        this.props.modalSetDisplayed(this.props.modal_id)
      }
    return (
      <div className={wrapper_classes.join(' ')} >
        {/*The closeAction when the user click outside is disabled*/}
        {/*<div className="modal_overlay" onClick={modalActions.closeAction}></div>*/}

        <div className="modal_overlay"></div>
        <div className="modal_inner_wrapper">
          <ModalContent {...this.props.modalProps} {...modalActions} />
        </div>
      </div>
    )
  }
}

export default Modal
