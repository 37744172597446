
import { submitCredentialFormEditRoutine } from './credential_routines'
import { call } from 'redux-saga/effects'
import { getFirstDef } from '../../utils/objtools'
import { alterItem } from '../item/item_alter_saga.js'
import genericFormSubmissionSaga from '../item/genericFormSubmissionSaga'
import branding from "../portal/branding";

function* saga(action) {
  const {values, modalInfos} = action.payload

  // prepare some ids
  var ids = {
    ...values.ids
  }

  var body = {
    redirectUris: [],
    certificate: ''
  }

  const redirectUris = getFirstDef(values, 'credentialRedirectUris', '').split("\n").map((uri)=>uri.trim()).filter((uri)=>uri!=='')
  if (redirectUris.length > 0) {
    body.redirectUris = redirectUris
  }
  const certificate = getFirstDef(values, 'credentialCertificate', null)
  if (certificate) {
    body.certificate = certificate
  }

  const sealingCertificates = getFirstDef(values, 'sealingCertificates', null)
  if (sealingCertificates && branding.open) {
    body.sealingCertificates = sealingCertificates.json
  }

  const forcedAdapter = getFirstDef(values, 'forcedAdapter', null)
  if (forcedAdapter) {
    body.forcedAdapter = forcedAdapter
  }

  // Update the credential
  const {error, data} = yield call(alterItem, 'credential_edit', 'credential', ids, body)

  // Generic unhandled error
  if (error)
    throw error

  // Check edited
  if (!data.id || data.id !== ids.credential_id)
    throw new Error('Missing or wrong id in the edited credential.')

  modalInfos.closeModal()
}

export function* submitEditCredentialForm(action) {
  yield call(genericFormSubmissionSaga, {
    saga,
    action,
    routine: submitCredentialFormEditRoutine,
    successfull_title: 'credential:successfull_edit_title',
    successfull_body: 'credential:successfull_edit_body',
    unsuccessfull_title: 'credential:unsuccessfull_edit_title',
    unsuccessfull_body: 'credential:unsuccessfull_edit_body'
  })
}
