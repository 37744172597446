import React from 'react'
import { connect } from '../../../utils/redux'
import { logRender } from '../../../utils/logging'
import { deepEqualObjs2, getFirstDef, isDef } from '../../../utils/objtools'
import UiButton from '../../ui/buttons/UiButton'
import { withTranslation } from 'react-i18next'
import { mapResourceFromCode } from '../../resource/resource_selectors'
import UiProperty from '../../ui/UiProperty/UiProperty'
import {stateAuthIsUserLoggedIn} from "../../auth/auth_selectors";

class PaneUser extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || this.props.tReady !== nextProps.tReady
    )
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    var title = (getFirstDef(this.props.redux.user, ['data.givenName'], '')
                  +' '
                  +getFirstDef(this.props.redux.user, ['data.familyName'], '')
                ).trim()
    if (title === '') {
      title = getFirstDef(this.props.redux.user, ['data.name'], 'Me')
    }

    var myInformations = []
    for (var field of ['email','givenName','familyName','name','phoneNumber','structure','service']) {
      if (isDef(this.props.redux.user, 'data.'+field) && this.props.redux.user.data[field] !== '') {
        myInformations.push(<UiProperty key={field} name={this.props.t('user:field_'+field)} value={this.props.redux.user.data[field]} />)
      }
    }

    return (
      <div className={this.getItemClasses().join(' ')}>
        <div className="grid g2_root g2_1col">

          <h2>{title}</h2>

          <div className="grid g2_221col">
            <div className="ggmenu-pane-section">
              <h3>{this.props.t('user:pane_memberships_title')}</h3>
              <p className="chapeau" key="chapeau">{this.props.t('user:pane_memberships_chapeau')}</p>
              <UiButton variant="primary" onClick={()=>this.props.historyPush('my_memberships')} icon="search">{this.props.t('user:pane_memberships_button')}</UiButton>
            </div>

            <div className="ggmenu-pane-section">

            </div>
          </div>

          <div className="ggmenu-pane-section">
            <h3>{this.props.t('user:my_personal_info')}</h3>
            {myInformations}
          </div>

          {this.props.redux.authIsUserLoggedIn &&
            <div className="ggmenu-pane-section">
              <UiButton variant="secondary" onClick={()=>this.props.logout('my_memberships')} icon="logout">{this.props.t('user:pane_logout_button')}</UiButton>
            </div>
          }


        </div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'pane-user'
    ]
    return classes;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    redux: {
      lang_code: state.lang.lang_code,
      user: mapResourceFromCode(state, 'me', 'user/me'),
      authIsUserLoggedIn: stateAuthIsUserLoggedIn(state)
    }
  }
}

const mapDispatchToProps = dispatch => ({
  historyPush: (scheme_key) => { dispatch({type:'HISTORY_PUSH', scheme_key}) },
  logout: (scheme_key) => { dispatch({type:'AUTH_LOGOUT', scheme_key}) },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  ['item.items.me','lang']
)(withTranslation(['user'])(PaneUser))
