import React from 'react'
import { connect } from 'react-redux'
import { logRender } from '../../utils/logging'
import { withTranslation } from 'react-i18next'
import { deepEqualObjs2 } from '../../utils/objtools'
import { mapResourceFromCode } from '../resource/resource_selectors'
import Button from 'react-bootstrap/Button'
import { submitConsorgFormDeleteRoutine } from './consorg_routines'
import { modalAdd } from '../modals/modals_actions'

class ConsorgDelete extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || this.props.tReady !== nextProps.tReady
    )
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    return (
      <div className={this.getItemClasses().join(' ')} >
        <div className="grid g1_root g1_1col">
          <h2>{this.props.t('consorg:tab_delete_title')}</h2>
          <p className="chapeau">{this.props.t('consorg:delete_chapeau')}</p>
          <Button variant="danger" onClick={()=>this.deleteClick()} disabled={!this.props.redux.consorg.loaded}>{this.props.t('consorg:delete_button')}</Button>
         </div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'consorg',
      'delete'
    ]
    return classes
  }

  deleteClick() {
    this.props.modalAdd(
      'delete',
      {
        title: this.props.t('consorg:delete_modal_title'),
        message: this.props.t('consorg:delete_modal_message'),
        deleteAction: (modalInfo)=>{
          modalInfo.closeModal()
          this.props.submitConsorgFormDeleteRoutine({consorg_id: this.props.redux.consorg.data.id})
        },
        closeAction: null
      }
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    consorg: mapResourceFromCode(state, 'consorg', ownProps.resourceCode),
    lang_code: state.lang.lang_code,
  }
  return {
    redux
  }
}

const mapDispatchToProps = dispatch => ({
  submitConsorgFormDeleteRoutine: (consorg_id) => dispatch(submitConsorgFormDeleteRoutine(consorg_id)),
  modalAdd: (modalType, modalProps) => dispatch(modalAdd(modalType, modalProps))
})

ConsorgDelete = withTranslation(['common','consorg'])(ConsorgDelete)

export default connect(mapStateToProps, mapDispatchToProps)(ConsorgDelete)