import React, { Component } from 'react';
import PropTypes from 'prop-types';

class TabHeader extends Component {
  static propTypes = {
    activeTabCode: PropTypes.string.isRequired,
    tabLabel: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  onClick = () => {
    const { tabCode, onClick } = this.props;
    onClick(tabCode);
  }

  render() {
    const {
      onClick,
      props: {
        tabLabel
      },
    } = this;

    return (
      <li
        className={this.getItemClasses().join(' ')}
        onClick={onClick}
      >
        {tabLabel}
      </li>
    );
  }

  getItemClasses() {
    const {
      props: {
        activeTabCode,
        tabCode
      },
    } = this;
    var classes = [
      'ui-tab-header',
      'ui-tab-code--'+this.props.tabCode
    ]
    if (activeTabCode === tabCode)
      classes.push('active')
    return classes;
  }
}

export default TabHeader;