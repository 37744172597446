import { submitCredentialFormDeleteRoutine } from './credential_routines'
import { call } from 'redux-saga/effects'
import { deleteItem } from '../item/item_delete_saga.js'
import genericSubmissionSaga from '../item/genericSubmissionSaga'

function* saga(action) {
  const { ids } = action.payload

  const {error} = yield call(deleteItem, 'credential_delete', 'credential', ids)

  // Generic unhandled error
  if (error)
    throw error
}

export function* submitDeleteCredentialForm(action) {
  yield call(genericSubmissionSaga, {
    saga,
    action,
    routine: submitCredentialFormDeleteRoutine,
    successfull_title: 'credential:successfull_delete_title',
    successfull_body: 'credential:successfull_delete_body',
    unsuccessfull_title: 'credential:unsuccessfull_delete_title',
    unsuccessfull_body: 'credential:unsuccessfull_delete_body'
  })
}