import React from 'react'
import {logRender} from "../../utils/logging";
import { withTranslation } from 'react-i18next'
import {connect} from "react-redux";
import {deepEqualObjs2, isDef} from "../../utils/objtools";
import AnimDelay from "../ui/AnimDelay/AnimDelay";
import {mapResourceFromIds} from "../resource/resource_selectors";
import UiMessage from "../ui/UiMessage/UiMessage";

class ApiDeprecatedMessage extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || this.props.tReady !== nextProps.tReady
    )
  }

  componentDidMount() {
    this.postRender()
  }

  componentDidUpdate() {
    this.postRender()
  }

  postRender() {
    if (this.props.redux.api_related_products.need_request) {
      this.props.requestResource(this.props.redux.api_related_products.external_resource_infos)
    }
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    let counter = 0
    if (isDef(this.props.redux, 'api_related_products.items')){
      for (let item of this.props.redux.api_related_products.items){
        if(item.consolidated.state === 'deprecated')
          counter++
      }
    }

    let apiDeprecatedRelatedProducts
    if (counter > 0)
      apiDeprecatedRelatedProducts = (
        <AnimDelay animateAppearance={true} showDelay={100}>
          {/*<UiMessage type="warning" message={this.props.t('api:deprecated_related_products_before')}/>*/}
          <UiMessage type="warning" message={this.props.t('api:deprecatedRelatedProducts', {
            count: counter
          })}/>
        </AnimDelay>
      )


    return (
      <div className={this.getItemClasses().join(' ')} >
        {apiDeprecatedRelatedProducts}
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'api_deprecated_message'
    ]
    return classes;
  }
}


const mapStateToProps = (state, ownProps) => {
  var redux = {
    lang_code: state.lang.lang_code,
    api_related_products: mapResourceFromIds(state, 'api_related_products', ownProps.api_ids)
  }

  return {
    redux
  }
}

const mapDispatchToProps = dispatch => ({
  requestResource: (external_resource_infos) => dispatch({type:'RESOURCE_LOAD_REQUEST', external_resource_infos})
})

ApiDeprecatedMessage = withTranslation(
  ['api']
)(ApiDeprecatedMessage)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApiDeprecatedMessage)

