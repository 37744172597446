import React from 'react'
import { connect } from 'react-redux'
import { logRender } from '../../utils/logging'
import { withTranslation } from 'react-i18next'
import { deepEqualObjs2, isDefVal } from '../../utils/objtools'
import { mapResourceFromCode } from '../resource/resource_selectors'
import ResourceError from '../ui/ResourceError/ResourceError'
import LoginError from '../ui/LoginError/LoginError'
import Membership from '../membership/Membership'
import { membershipRoles } from '../../utils/enums/membershipRoles'
import { membershipStates } from '../../utils/enums/membershipStates'
import Loader from '../loader/Loader'
import setDocumentTitle from '../../utils/browser/title'
import UiH1 from '../ui/titles/UiH1'
import { stateAuthIsUserLoggedIn } from '../auth/auth_selectors'

class MyMemberships extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || this.props.tReady !== nextProps.tReady
    )
  }

  componentDidMount() {
    this.postRender()
  }

  componentDidUpdate() {
    this.postRender()
  }

  postRender() {
    if (this.props.tReady) {
      setDocumentTitle(this.props.t('mymemberships:page_title'))
    }
  }

  render() {
    logRender(this)
    if (!this.props.tReady) {
      return null
    }

    if (this.props.redux.isUserLoggedIn === false) {
      return <LoginError />
    }

    if (!this.props.redux.memberships.found) {
      return null
    }

    if (this.props.redux.memberships.error) {
      return <ResourceError />
    }

    var memberships = {
      pending: [],
      active: [],
      requested: []
    }
    if (this.props.redux.memberships.found) {
      for (var item of this.props.redux.memberships.items) {
        // TODO better filtering and anomaly detection
        const role = isDefVal(item, 'data.role')
        if (Object.values(membershipRoles).indexOf(role) === -1) {
          console.error('Unexpected role in the membership')
          continue
        }
        const state = isDefVal(item, 'data.state')
        if (Object.values(membershipStates).indexOf(state) === -1) {
          console.error('Unexpected state in the membership')
          continue
        }
        if (state === membershipStates.PENDING) {
          memberships.pending.push(item.resource_code)
        }
        else if (state === membershipStates.ACTIVE) {
          memberships.active.push(item.resource_code)
        }
        else if (state === membershipStates.REQUESTED) {
          memberships.requested.push(item.resource_code)
        }
      }
    }

    return (
      <div className={this.getItemClasses().join(' ')} >
        <div className="grid g1_root g1_1col">

          <UiH1>{this.props.t('mymemberships:page_title')}</UiH1>

          <h3>{this.props.t('mymemberships:list_request_title')}</h3>
          <p>{this.props.t('mymemberships:list_request_chapeau')}</p>
          {(memberships.requested.length > 0 &&
              memberships.requested.map((resourceCode) => <Membership key={resourceCode} mode_consorg={true} showConsorgLink={true} itemType="consorg_membership" resourceCode={resourceCode} />)
            ) ||
            ((!this.props.redux.memberships.found || this.props.redux.memberships.loading) &&
              <Loader loading={true} />
            ) ||
            <p className="empty_zone">{this.props.t('mymemberships:list_request_empty')}</p>
          }

          <h3>{this.props.t('mymemberships:list_pending_title')}</h3>
          <p>{this.props.t('mymemberships:list_pending_chapeau')}</p>
          {(memberships.pending.length > 0 &&
            memberships.pending.map((resourceCode) => <Membership key={resourceCode} mode_consorg={true} showConsorgLink={true} itemType="consorg_membership" resourceCode={resourceCode} can_accept={true} />)
          ) ||
          ((!this.props.redux.memberships.found || this.props.redux.memberships.loading) &&
            <Loader loading={true} />
          ) ||
          <p className="empty_zone">{this.props.t('mymemberships:list_pending_empty')}</p>
          }


          <h3>{this.props.t('mymemberships:list_active_title')}</h3>
          <p>{this.props.t('mymemberships:list_active_chapeau')}</p>
          {(memberships.active.length > 0 &&
              memberships.active.map((resourceCode) => <Membership key={resourceCode} mode_consorg={true} showConsorgLink={true} itemType="consorg_membership" resourceCode={resourceCode} />)
            ) ||
            ((!this.props.redux.memberships.found || this.props.redux.memberships.loading) &&
              <Loader loading={true} />
            ) ||
            <p className="empty_zone">{this.props.t('mymemberships:list_active_empty')}</p>
          }

        </div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'my-memberships',
      'full'
    ]
    classes.push(this.props.item_type)
    return classes
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    memberships: mapResourceFromCode(state, 'my_memberships', 'user/me/memberships'),
    lang_code: state.lang.lang_code,
    isUserLoggedIn: stateAuthIsUserLoggedIn(state)
  }
  return {
    redux
  }
}

MyMemberships = withTranslation(['common','mymemberships'])(MyMemberships)

export default connect(mapStateToProps, null)(MyMemberships)
