import React from 'react'
import { logRender } from '../../../utils/logging'
import ReactJsonSafe from '../ReactJsonSafe/ReactJsonSafe'
import Expandable from '../Expandable/Expandable'
import { deepEqualObjs2 } from '../../../utils/objtools'

class JsonExpandable extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.jsonobject, nextProps.jsonobject)
      || this.props.title !== nextProps.title
    )
  }

  render() {
    logRender(this)

    return (
      <Expandable key="json_expandable_content" title={this.props.title}>
        <ReactJsonSafe src={this.props.jsonobject} />
      </Expandable>
    )
  }
}

export default JsonExpandable;