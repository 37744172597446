import React from 'react'
import {connect} from '../../utils/redux'
import {logRender} from '../../utils/logging'
import {withTranslation} from 'react-i18next'
import {isDef, deepEqualObjs2, isDefVal} from '../../utils/objtools'
import {mapResourceFromCode, mapResourceFromIds} from '../resource/resource_selectors'
import JsonExpandable from '../ui/JsonExpandable/JsonExpandable'
import Teaser from '../teaser/Teaser'
import ItemGFMDescription from '../ui/ItemGFMDescription/ItemGFMDescription'
import ItemContact from '../ui/ItemContact/ItemContact'
import NotFound from '../ui/NotFound/NotFound'
import ResourceError from '../ui/ResourceError/ResourceError'
import setDocumentTitle from '../../utils/browser/title'
import SingleHeader from '../ui/SingleHeader/SingleHeader'
import AnimDelay from '../ui/AnimDelay/AnimDelay'
import SubscriptionToPlan from '../subscription_plans/SubscriptionToPlan'
import SubscriptionToScope from '../subscription_scopes/SubscriptionToScope'
import Visibility from '../../utils/debug/Visibility'
import {stateGetProductDefinitionFeaturedAPIs} from './product_selectors'
import branding from "../portal/branding";
import UiButton from "../ui/buttons/UiButton";
import {stateGetSelectedConsorgResourceCode} from "../consorg/consorg_selectors";
import {stateAuthIsUserLoggedIn} from "../auth/auth_selectors";
import ProductManifesto from "./sale_ui/ProductManifesto";
import MediasPool from "./medias_pool/MediasPool";
import UiMessage from "../ui/UiMessage/UiMessage";
import {buildExternalResourceUri} from "../../utils/urls/external";

class Product extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || this.props.tReady !== nextProps.tReady
    )
  }

  componentDidMount() {
    this.postRender()
  }

  componentDidUpdate() {
    this.postRender()
  }

  postRender() {
    if (this.props.redux.product.need_request) {
      this.props.requestResource(this.props.redux.product.external_resource_infos)
    }
    setDocumentTitle(isDefVal(this.props, 'redux.product.consolidated.title'))
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    // Assume Optimistic Rendering
    if (this.props.redux.catalog_has_products === false || (this.props.redux.catalog_has_products && !this.props.redux.product.found)) {
      return <NotFound
                target='product'
                resource_types={['private_product','product']}
                resource_name={this.props.redux.product_uname} />
    }
    if (this.props.redux.product.error || this.props.redux.product_def.error) {
      return <ResourceError/>
    }

    var presentation = null
    var presentation1 = []
    var presentation2 = []

    if (isDef(this.props.redux, 'product_def.data.definition.info.contact') && !branding.open)
      presentation2.push(<ItemContact key="contact"
                                      contact={this.props.redux.product_def.data.definition.info.contact}/>)

    if (this.props.redux.authIsUserLoggedIn && (branding.kind === 'sg' || branding.kind === 'cdn')) {
      presentation2.push(<div key="new_issue" className="actions">
        <UiButton variant="secondary"
                  onClick={() => this.props.historyPush('issue_new', {co: this.props.redux.consorg.ids.consorg_uname})}>{this.props.t('consorg:new_issue_external_button')}</UiButton>
      </div>)
    }

    if(!branding.open){
      presentation2.push(<div key="go_kibana" className="actions">
        <UiButton variant="danger" type="button" icon="elastic" onClick={()=>window.open(this.props.redux.elasticsearch_product_url, "_blank")}>Go Elastic Kibana</UiButton>
      </div>)
    }

    // if (isDef(this.props.redux, 'externalDocs'))
    //   presentation2.push(<ItemDocumentation key="documentation" documentation={swagger_developed.externalDocs} />)
    if (isDef(this.props.redux, 'product_def.consolidated.description'))
      presentation1.push(<ItemGFMDescription key="description" title="Description"
                                             content={this.props.redux.product_def.consolidated.description}/>)
    // if (isDef(this.props.redux, 'tags')) {
    //   for (var tag of swagger_developed.tags) {
    //     presentation1.push(<ApiTag key={tag.name} tag={tag} />)
    //   }
    // }
    if (presentation1.length || presentation2.length)
      presentation = (
        <AnimDelay animateAppearance={true} showDelay={0}>
          <div className="item_full_presentation">
            <div className="presentation_content gridg g1_2t1t_2_1">
              <div key="presentation1">{presentation1}</div>
              <div key="presentation2">{presentation2}</div>
            </div>
          </div>
        </AnimDelay>
      )

    var featured_apis = []
    if (isDef(this.props.redux, 'featuredApis.api_resource_codes')) {
      for (var api_resource_code of this.props.redux.featuredApis.api_resource_codes) {
        featured_apis.push(<Teaser key={api_resource_code} item_type={'api'} resource_code={api_resource_code}/>)
      }
    }
    if (featured_apis.length) {
      featured_apis = (
        <AnimDelay animateAppearance={true} showDelay={100}>
          <div className="featured_apis">
            <h2>{this.props.t('product:featured_apis')}</h2>
            <div className="featured_apis_list grid g1_432col">
              {featured_apis}
            </div>
          </div>
        </AnimDelay>
      )
    }

    let DeprecatedMessage
    var subscription = []

    if (isDef(this.props.redux, 'product')) {
      if (this.props.redux.product.consolidated.state === "deprecated") {
        DeprecatedMessage = (<UiMessage type="warning" message={this.props.t('product:deprecated_message')}/>)
      } else {
        if (this.props.redux.product.consolidated.universe === "2.0" || this.props.redux.product.consolidated.universe === "2.1") {
          if (isDef(this.props.redux, 'product_def.external_resource_infos.resource_code') && isDef(this.props.redux, 'featuredApis.api_resource_codes')) {
            subscription.push(<SubscriptionToPlan key="subscription" item_type={'product_def'}
                                                  resource_code={this.props.redux.product_def.external_resource_infos.resource_code}
                                                  api_counter={this.props.redux.featuredApis.api_resource_codes.length}/>);
          }
        } else if (this.props.redux.product.consolidated.universe === "3.0" && !this.props.redux.loading) {
          subscription.push(<SubscriptionToScope key="subscription"
                                                 productDefinitionResourceCode={this.props.redux.product_def.resource_code}
                                                 productResourceCode={this.props.redux.product.resource_code}/>)
        }
      }
    }

    if (subscription.length)
      subscription = (
        <AnimDelay animateAppearance={true} showDelay={200}>
          {subscription}
        </AnimDelay>
      )




    var pills = []
    if (isDef(this.props.redux.product.consolidated, 'version'))
      pills.push(<div key="version" className="pill secondary">{this.props.redux.product.consolidated.version}</div>)
    if (isDef(this.props.redux.product.consolidated, 'state'))
      pills.push(<div key="state"
                      className="pill secondary">{this.props.t('common:' + this.props.redux.product.consolidated.state)}</div>)

    if (!branding.open) {
      if (isDef(this.props.redux.product, 'data.space') && this.props.redux.product.data.space !== '') {
        pills.push(<div key={this.props.redux.product.data.space}
                        className="pill highlight">{this.props.redux.product.data.space.toUpperCase()}</div>)
      }
      if (isDef(this.props.redux.product, 'data.domain') && this.props.redux.product.data.domain !== '') {
        pills.push(<div key={this.props.redux.product.data.domain}
                        className="pill highlight">{this.props.redux.product.data.domain.toUpperCase()}</div>)
      }
    }


    let manifesto
    if (this.props.redux.product.consolidated.universe === "3.0" && isDef(this.props.redux.product_def, 'ids.organisation_id') && isDef(this.props.redux.product_def, 'data.definition.configuration.medias.upperBanner'))
      manifesto = (<ProductManifesto consolidated={this.props.redux.product.consolidated}
                                     pills={pills}
                                     loading={this.props.redux.loading}
                                     media_name={this.props.redux.product_def.data.definition.configuration.medias.upperBanner.mediaName}
                                     organization={this.props.redux.product_def.ids.organisation_id}
                                     resource_code={this.props.redux.product_def.resource_code}
                                     is_user_logged_in={this.props.redux.authIsUserLoggedIn}
                                     description={this.props.redux.product_def.consolidated.description}
                                     consorg_uname={isDef(this.props.redux, 'consorg.ids.consorg_uname') ? this.props.redux.consorg.ids.consorg_uname : ''}/>)


    let medias
    if (this.props.redux.product.consolidated.universe === "3.0" && isDef(this.props.redux.product_def, 'data.definition.configuration.medias') && this.props.redux.product_def.data.definition.configuration.medias.sections.length > 0 && isDef(this.props.redux.product_def, 'ids.organisation_id')){
      medias = (<MediasPool medias={this.props.redux.product_def.data.definition.configuration.medias}
                            organization={this.props.redux.product_def.ids.organisation_id}
                            summary={this.props.redux.product_def.data.definition.info.summary}/>)


    }

    return (
      <div className={this.getItemClasses().join(' ')}>

        <Visibility condition={!manifesto}>
          <div className="grid g1_root g1_1col">
            <SingleHeader item_type="product" consolidated={this.props.redux.product.consolidated} pills={pills}
                          loading={this.props.redux.loading}/>
            {DeprecatedMessage}
            {presentation}
          </div>
        </Visibility>

        {manifesto}
        {medias}

        <div className="grid g1_root g1_1col">

          {!manifesto &&
          featured_apis
          }

          {subscription}


          <Visibility condition={false}>
            <h2>Raw</h2>
            <JsonExpandable key="signage" title="Signage" jsonobject={this.props.redux.product}/>
            <JsonExpandable key="definition" title="Definition" jsonobject={this.props.redux.product_def}/>
          </Visibility>

        </div>

      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'product'
    ]

    if (!isDef(this.props.redux.product_def, 'data.definition.configuration.medias.upperBanner'))
      classes.push('full')

    classes.push(this.props.item_type)
    return classes;
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    product: mapResourceFromIds(state, 'product', ownProps.page.routeProps.match.params),
    product_def: mapResourceFromIds(state, 'product_def', ownProps.page.routeProps.match.params),
    product_uname: ownProps.page.routeProps.match.params.product_uname,
    loading: false,
    catalog_has_products: isDefVal(state, 'portal.context.catalog_has_products'),
    catalog_has_apis: isDefVal(state, 'portal.context.catalog_has_apis'),
    catalog_has_scopes: isDefVal(state, 'portal.context.catalog_has_scopes'),
    lang_code: state.lang.lang_code,
    authIsUserLoggedIn: stateAuthIsUserLoggedIn(state),

  }

  redux.featuredApis = stateGetProductDefinitionFeaturedAPIs(state, redux.product_def.resource_code)

  if (redux.authIsUserLoggedIn) {
    redux.consorg = mapResourceFromCode(state, 'consorg', stateGetSelectedConsorgResourceCode(state))
  }

  if(isDef(redux.product,'ids') && isDef(redux.product,'consolidated.name')){
    redux.elasticsearch_product_url = buildExternalResourceUri("elasticsearch_product_url",
      {
      catalog_id: redux.product.ids.catalog_id,
      organisation_id: redux.product.ids.organisation_id,
      product_uname: redux.product.consolidated.name, product_name: ':product_name',
      phrase: ':phrase', organization: ':organization', catalog:':catalog'})
  }

  redux.loading = redux.catalog_has_scopes === null || redux.catalog_has_apis === null || redux.catalog_has_products === null || redux.product.loading || redux.product.need_request || redux.product_def.loading || redux.product_def.need_request

  return {
    redux
  }
}

const mapDispatchToProps = dispatch => ({
  requestResource: (external_resource_infos) => dispatch({type: 'RESOURCE_LOAD_REQUEST', external_resource_infos}),
  historyPush: (scheme_key, queryParams) => {
    dispatch({type: 'HISTORY_PUSH', scheme_key, queryParams})
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  ['item.items.product', 'item.items.product_def', 'portal.context', 'lang']
)(withTranslation(['common', 'product','consorg','subscriptionscopes'])(Product))
