import React from 'react'
import i18n from '../../../utils/lang/i18n'
import UiProperty from "../../ui/UiProperty/UiProperty";

const SummaryModal = ({ closeAction, title, message, summary_list, warning }) => {

  var summary = []
  for (let item of summary_list){
    summary.push(<UiProperty key={item.name} name={item.name} value={'status : '+item.state} />)
  }

  return (
    <div className="modal-content">
      <div className="modal-header">
        <h4 className="modal-title">{title}</h4>
        <button type="button" className="close" aria-label="Close" onClick={closeAction}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <p>{message}</p>
        {summary}

        {/*Better UI for this bloc */}
        {warning &&
        <div className="msg-warning">
          {warning}
        </div>
        }

      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={closeAction}>{i18n.t('common:close')}</button>
      </div>
    </div>
  )
}

export default SummaryModal
