import React from 'react'
import { connect } from 'react-redux'
import { logRender } from '../../utils/logging'
import { withTranslation } from 'react-i18next'
import { deepEqualObjs2, isDefVal, getFirstDef } from '../../utils/objtools'
import { mapResourceFromIds } from '../resource/resource_selectors'
import setDocumentTitle from '../../utils/browser/title'
import NotFound from '../ui/NotFound/NotFound'
import ResourceError from '../ui/ResourceError/ResourceError'
import Tabs from '../ui/Tabs/Tabs'
import TabContent from '../ui/Tabs/TabContent'
import SingleHeader from '../ui/SingleHeader/SingleHeader'
import ConsorgEdit from './ConsorgEdit'
import ConsorgOverview from './ConsorgOverview'
import ConsorgMembers from './ConsorgMembers'
import ConsorgApplications from './ConsorgApplications'
import ConsorgDelete from './ConsorgDelete'
import { stateIsResourceCodeSelectedConsorg, stateIsUserBelongsConsorg, stateIsUserOwnsConsorg } from './consorg_selectors'
import { getPortalCurrentCatalogParam } from '../portal/portal_selectors'
import { stateAuthIsUserLoggedIn } from '../auth/auth_selectors'
import { membershipVisibilities } from '../../utils/enums/membershipVisibilities'
import ConsorgIssues from "./ConsorgIssues";
import branding from "../portal/branding";

class Consorg extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || this.props.tReady !== nextProps.tReady
    )
  }

  componentDidMount() {
    this.postRender()
  }

  componentDidUpdate() {
    this.postRender()
  }

  postRender() {
    if (this.props.redux.consorg.need_request) {
      this.props.requestResource(this.props.redux.consorg.external_resource_infos)
    }
    if (this.props.tReady && isDefVal(this.props, 'redux.consorg.consolidated.title')) {
      setDocumentTitle(isDefVal(this.props, 'redux.consorg.consolidated.title') + ' - ' + this.props.t('consorg:tab_'+this.props.redux.tabCode+'_title'))
    }
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    const redux = this.props.redux
    if (!redux.consumerOrgsConfiguration) {
      return null
    }

    // Assume Optimistic Rendering
    if (redux.catalog_has_consorgs === false || (redux.catalog_has_consorgs && !redux.consorg.found)) {
      return <NotFound />
    }
    if (redux.consorg.error) {
      return <ResourceError />
    }

    var pills = []
    // var bandeau = []
    if (redux.consorg_active) {
      pills.push(<div key="version" className="pill active_co">Active</div>)
      // bandeau.push(<div key="bandeau" className="bandeau">{this.props.t('consorg:selected_consorg_teaser_bandeau')}</div>)
    }

    return (
      <div className={this.getItemClasses().join(' ')} >
        <div className="grid g1_root g1_1col">

          <SingleHeader key="header" item_type="consorg" consolidated={redux.consorg.consolidated} pills={pills} loading={redux.loading} />

          <Tabs activeTabCode={redux.tabCode}>
            <TabContent tabCode="overview" displayCondition={true} tabLabel={this.props.t('consorg:tab_overview_title')}>
              <ConsorgOverview resourceCode={redux.consorg.resource_code} />
            </TabContent>
            <TabContent tabCode="applications" displayCondition={redux.isUserBelongsConsorg} tabLabel={this.props.t('consorg:tab_applications_title')}>
              <ConsorgApplications resourceCode={isDefVal(redux.consorg, 'external_resource_infos.resource_code')} />
            </TabContent>
            <TabContent tabCode="members" displayCondition={redux.isUserBelongsConsorg
              || (redux.authIsUserLoggedIn && redux.consumerOrgsConfiguration.membershipVisibility === membershipVisibilities.LOGGED_USERS)
              || redux.consumerOrgsConfiguration.membershipVisibility === membershipVisibilities.EVERYONE} tabLabel={this.props.t('consorg:tab_members_title')}>
              <ConsorgMembers resourceCode={isDefVal(redux.consorg, 'external_resource_infos.resource_code')} />
            </TabContent>
            <TabContent tabCode="issues" displayCondition={redux.isUserBelongsConsorg && (branding.kind === 'sg' || branding.kind === 'cdn')} tabLabel={this.props.t('consorg:tab_issues_title')}>
              <ConsorgIssues resourceCode={isDefVal(redux.consorg, 'external_resource_infos.resource_code')} />
            </TabContent>
            <TabContent tabCode="edit" displayCondition={redux.isUserOwnsConsorg && redux.consumerOrgsConfiguration.canOwnersEdit} tabLabel={this.props.t('consorg:tab_edit_title')}>
              <ConsorgEdit data={redux.consorg.data} />
            </TabContent>
            <TabContent tabCode="delete" displayCondition={redux.isUserOwnsConsorg && redux.consumerOrgsConfiguration.canOwnersDelete} tabLabel={this.props.t('consorg:tab_delete_title')}>
              <ConsorgDelete resourceCode={isDefVal(redux.consorg, 'external_resource_infos.resource_code')} />
            </TabContent>
          </Tabs>

        </div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'consorg',
      'full'
    ]
    return classes
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    consorg: mapResourceFromIds(state, 'consorg', ownProps.page.routeProps.match.params),
    catalog_has_consorgs: isDefVal(state, 'portal.context.catalog_has_consorgs'),
    lang_code: state.lang.lang_code,
    tabCode: getFirstDef(ownProps.page.routeProps.match.params, "tab_code", "overview"),
    authIsUserLoggedIn: stateAuthIsUserLoggedIn(state)
  }
  redux.consorg_active = stateIsResourceCodeSelectedConsorg(state, redux.consorg.resource_code)
  redux.isUserBelongsConsorg = stateIsUserBelongsConsorg(state, redux.consorg.resource_code)
  redux.isUserOwnsConsorg = stateIsUserOwnsConsorg(state, redux.consorg.resource_code)
  redux.loading = redux.catalog_has_consorgs === null || redux.consorg.loading || redux.consorg.need_request
  redux.consumerOrgsConfiguration = getPortalCurrentCatalogParam(state, 'consumerOrgsConfiguration')
  return {
    redux
  }
}

const mapDispatchToProps = dispatch => ({
  requestResource: (external_resource_infos) => dispatch({type:'RESOURCE_LOAD_REQUEST', external_resource_infos})
})

Consorg = withTranslation(['common','consorg'])(Consorg)

export default connect(mapStateToProps, mapDispatchToProps)(Consorg)
