import React from 'react'
import {connect} from 'react-redux'
import {logRender} from '../../../utils/logging'
import {withTranslation} from 'react-i18next'
import {deepEqualObjs2} from '../../../utils/objtools'
import Icon from "../../icon/Icon";


class UiBlock extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || this.props.tReady !== nextProps.tReady
    )
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    var title = this.props.title
    var icon = this.props.icon
    var value = this.props.value
    var unit = this.props.unit


    return (
      <div className={this.getItemClasses().join(' ')}>
        <div className="wrapper">
          <div className="block_icon">
            <Icon type={icon}/>
          </div>
          <div className="block_values">
            <h6>{title}</h6>
            <span className="value">{value} </span>
            <span className="unit"> {unit}</span>
          </div>
        </div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'ui_block'
    ]
    return classes
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    lang_code: state.lang.lang_code
  }
  return {
    redux
  }
}

UiBlock = withTranslation(['common', 'application'])(UiBlock)

export default connect(mapStateToProps, null)(UiBlock)
