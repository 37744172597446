import React from 'react'
import { connect } from '../../../utils/redux'
import { logRender } from '../../../utils/logging'
import { isDef, deepEqualObjs2 } from '../../../utils/objtools'
import OrganisationTeaser from '../../organisation/OrganisationTeaser'
import { withTranslation } from 'react-i18next'

class PaneOrganisations extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || this.props.tReady !== nextProps.tReady
    )
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    var current_organisation = null
    if (this.props.redux.current_organisation_id)
      current_organisation = (
        <div className="ggmenu-pane-section">
          <h3 key="current_organisation_h3">{this.props.t('organisation:pane_current_title')}</h3>
          <p className="chapeau" key="chapeau">{this.props.t('organisation:pane_current_intro')}</p>
          <OrganisationTeaser key={this.props.redux.current_organisation_id} organisation_id={this.props.redux.current_organisation_id} with_link={false} />
        </div>
      )

    var other_organisations = null
    if (this.props.redux.other_organisation_ids.length > 0) {
      var other_organisations_list = []
      this.props.redux.other_organisation_ids.forEach((organisation_id)=>{
        other_organisations_list.push(
          <OrganisationTeaser key={organisation_id} organisation_id={organisation_id} with_link={true} />
        )
      })
      other_organisations = (
        <div className="ggmenu-pane-section grid g2_1col">
          <h3 key="other_organisations_h3">{this.props.t('organisation:pane_switch_title')}</h3>
          <p className="chapeau" key="chapeau">{this.props.t('organisation:pane_switch_intro')}</p>
          {other_organisations_list}
        </div>
      )
    }

    return (
      <div className={this.getItemClasses().join(' ')}>
        <div className="grid g2_root g2_1col">
          <h2>{this.props.t('organisation:pane_title')}</h2>
          {current_organisation}
          {other_organisations}
        </div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'pane-organisations'
    ]
    return classes;
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    current_organisation_id: null,
    other_organisation_ids: []
  }
  if (isDef(state.portal, 'context.organisation.organisation_id')) {
    redux.current_organisation_id = state.portal.context.organisation.organisation_id
    for (var organisation_id in state.portal.context.organisations) {
      if (organisation_id !== redux.current_organisation_id) {
        redux.other_organisation_ids.push(organisation_id)
      }
    }
  }
  return {
    redux
  }
}

export default connect(
  mapStateToProps,
  null,
  ['portal.context']
)(withTranslation('organisation')(PaneOrganisations));