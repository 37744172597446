import React from 'react'
import { logRender } from '../../../utils/logging'
import { withTranslation } from 'react-i18next'
import {deepEqualObjs2, getObjExcluding} from '../../../utils/objtools'
import UiProperty from "../UiProperty/UiProperty";

class ItemProperties extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.tReady !== nextProps.tReady
      || !deepEqualObjs2(this.props.properties, nextProps.properties)
      //|| JSON.stringify(this.props.documentation) !== JSON.stringify(nextProps.documentation)
    )
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    var properties = []
    var uncovered_members = getObjExcluding(this.props.properties.info, ['description','contact','x-irt-code','x-trigram-code','termsOfService','x-api-audience','version','title','license'])
    for (var uncovered_member_key in uncovered_members) {
      properties.push(<UiProperty key={uncovered_member_key} name={uncovered_member_key} value={uncovered_members[uncovered_member_key]} />)
    }
    uncovered_members = getObjExcluding(this.props.properties, ['paths','info','definitions','externalDocs','parameters','tags','security','securityDefinitions','x-sg-configuration','responses', 'basePath','x-ibm-endpoints','servers','components'])

    for (uncovered_member_key in uncovered_members) {
      properties.push(<UiProperty key={uncovered_member_key} name={uncovered_member_key} value={uncovered_members[uncovered_member_key]} />)
    }


    return (
      <div className={this.getItemClasses().join(' ')} >
        <h4>{this.props.t('item:properties')}</h4>
        {properties}
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'item-documentation'
    ]
    return classes;
  }
}

export default withTranslation('item')(ItemProperties);
