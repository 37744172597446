import { takeEvery } from 'redux-saga/effects'
import { submitApplicationFormNewRoutine, submitApplicationFormEditRoutine, submitApplicationFormDeleteRoutine } from './application_routines'
import { submitNewApplicationForm } from './application_new_saga'
import { submitEditApplicationForm } from './application_edit_saga'
import { submitDeleteApplicationForm } from './application_delete_saga'

const applicationSagas = [
  takeEvery(submitApplicationFormNewRoutine.TRIGGER, submitNewApplicationForm),
  takeEvery(submitApplicationFormEditRoutine.TRIGGER, submitEditApplicationForm),
  takeEvery(submitApplicationFormDeleteRoutine.TRIGGER, submitDeleteApplicationForm),
]
export default applicationSagas