import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mqsync_update } from './mqsync_actions'
import './mqsync.scss'
import branding from '../../components/portal/branding'

class MQSync extends Component {

	constructor(props) {
    super(props);

		// initial state
		this.state = {
      windowHeight: undefined,
      windowWidth: undefined,
      grid_debug: false,
    }

    // Binding
    this.toolingClick = this.toolingClick.bind(this);
  }

  range_groups = {
    max: 'desktop',
    lg: 'desktop',
    md: 'desktop',
    sm: 'tablet',
    xs: 'mobile'
  }

	toolingClick() {
		this.setState((state) => {
			if (state.grid_debug)
				return {grid_debug : false}
			else
				return {grid_debug : true}
	  });
	}

  handleResize = () => {
    var activeMQ = null
    var activeMQgroup = null

    // Get active MQ
    // Fix for Opera issue when using font-family to store value
    if (window.opera){
      activeMQ = window.getComputedStyle(document.body,':after').getPropertyValue('content')
    }
    // For all other modern browsers
    else if (window.getComputedStyle)
    {
      activeMQ = window.getComputedStyle(document.head,null).getPropertyValue('font-family')
    }
    // For oldIE
    else {
      // Use .getCompStyle instead of .getComputedStyle so above check for window.getComputedStyle never fires true for old browsers
      window.getCompStyle = function(el, pseudo) {
        this.el = el
        this.getPropertyValue = function(prop) {
          var re = /(-([a-z]){1})/g
          if (prop === 'float') prop = 'styleFloat'
          if (re.test(prop)) {
            prop = prop.replace(re, function () {
              return arguments[2].toUpperCase()
            })
          }
          return el.currentStyle[prop] ? el.currentStyle[prop] : null
        }
        return this
      }
      var compStyle = window.getCompStyle(document.getElementsByTagName('head')[0], "")
      activeMQ = compStyle.getPropertyValue("font-family")
    }
    activeMQ = activeMQ.replace(/"/g, "").replace(/'/g, "")

    // Get active MQ Group
    activeMQgroup = this.range_groups[activeMQ]

    // Detect MQS issue
    if (activeMQgroup === null || activeMQ === null)
      console.log('MQS Error : active range/group not found !')

    this.props.mqsync_update(
      window.innerWidth,
      window.innerHeight,
      activeMQ,
      activeMQgroup
    )

    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth
    })
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  render() {
    if (branding.env !== "development") {
      return null
    }
    var grid_debug = []
    if (false) {
      // display test elements
      grid_debug.push(<div key="grid_examples" className="grid_examples">
        <div className="grid g1_root g1_543col grid_example">
          <div className="">g1_543col</div>
          <div className=""></div>
          <div className=""></div>
          <div className=""></div>
          <div className=""></div>
          <div className=""></div>
          <div className=""></div>
          <div className=""></div>
          <div className=""></div>
          <div className=""></div>
        </div>
        <div className="grid g1_root g1_432col grid_example">
          <div className="">g1_432col</div>
          <div className=""></div>
          <div className=""></div>
          <div className=""></div>
          <div className=""></div>
          <div className=""></div>
          <div className=""></div>
          <div className=""></div>
        </div>
        <div className="grid g1_root g1_321col grid_example">
          <div className="">g1_321col</div>
          <div className=""></div>
          <div className=""></div>
          <div className=""></div>
          <div className=""></div>
          <div className=""></div>
        </div>
        <div className="grid g1_root g1_4col grid_example">
          <div className="">g1_4col</div>
          <div className=""></div>
          <div className=""></div>
          <div className=""></div>
          <div className=""></div>
          <div className=""></div>
          <div className=""></div>
          <div className=""></div>
        </div>
        <div className="grid g1_root g1_3col grid_example">
          <div className="">g1_3col</div>
          <div className=""></div>
          <div className=""></div>
          <div className=""></div>
          <div className=""></div>
          <div className=""></div>
        </div>
        <div className="grid g1_root g1_2col grid_example">
          <div className="">g1_2col</div>
          <div className=""></div>
          <div className=""></div>
          <div className=""></div>
        </div>
        <div className="grid g1_root g1_1col grid_example">
          <div className="">g1_1col</div>
          <div className=""></div>
        </div>
      </div>)
    }
    if (this.state.grid_debug)
      grid_debug.push(<div key="grid_debug_wrapper" id="grid_debug_wrapper"><div className="grid_debug grid_debug_normal" ></div></div>)
    return (
      <div className="mqsync_tooling" onClick={this.toolingClick}>
        <div className="mqsync_window_size">{this.state.windowWidth} x {this.state.windowHeight}</div>
        <div className="mqsync_range_debug"></div>
        {grid_debug}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  mqsync_update: (vw, vh, rv_range, rv_group) => { dispatch(mqsync_update(vw, vh, rv_range, rv_group)) }
})

export default connect(
	null,
	mapDispatchToProps
)(MQSync);