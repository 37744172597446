import { takeEvery } from 'redux-saga/effects'
import { submitSubscriptionScopesFormNewRoutine, submitSubscriptionScopesFormDeleteRoutine, submitSubscriptionScopesFormCancelRoutine} from './subscription_scopes_routines'
import { submitNewSubscriptionScopesForm } from './subscription_scopes_new_saga'
import { submitDeleteSubscriptionScopesForm } from "./subscription_scopes_delete_saga";
import { submitCancelSubscriptionScopesForm } from "./subscription_scopes_cancel_saga";

const subscriptionScopesSagas = [
  takeEvery(submitSubscriptionScopesFormNewRoutine.TRIGGER, submitNewSubscriptionScopesForm),
  takeEvery(submitSubscriptionScopesFormDeleteRoutine.TRIGGER, submitDeleteSubscriptionScopesForm),
  takeEvery(submitSubscriptionScopesFormCancelRoutine.TRIGGER, submitCancelSubscriptionScopesForm),
]
export default subscriptionScopesSagas
