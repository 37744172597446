import React from 'react'
import {connect} from '../../utils/redux'
import {logRender} from '../../utils/logging'
import {withTranslation} from 'react-i18next'
import {isDefVal} from '../../utils/objtools'
import {mapResourceFromCode, mapResourceFromIds} from '../resource/resource_selectors'
import ResourceError from '../ui/ResourceError/ResourceError'
import {stateIsUserOwnsConsorg} from '../consorg/consorg_selectors'
import Loader from '../loader/Loader'
import PlanSubscription from "../plan_subscription/PlanSubscription";
import {getPortalCurrentCatalogParam} from "../portal/portal_selectors";
import {stateIsProducerAdminAuthenticated} from "../../utils/user";
import branding from "../portal/branding";
import ScopeSubscription from "../scope_subscription/ScopeSubscription";
import Filter from "../filter/Filter";
import Visibility from "../../utils/debug/Visibility";

class ApplicationPlansSubscriptions extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      rawView: null,
      columnView: null,
      defaultView: 'raw',
      displayScopes: true,
      displayPlans: true,
    }

    this.changeView = this.changeView.bind(this)
  }

  static getDerivedStateFromProps(props, state) {

      if (props.redux.plan_subscriptions.need_request) {
        props.requestResource(props.redux.plan_subscriptions.external_resource_infos)
      }
      if (props.redux.scope_subscriptions.need_request) {
        props.requestResource(props.redux.scope_subscriptions.external_resource_infos)
      }

    var defaultView = state.rawView === null

    return {
      rawView: defaultView ? true : state.rawView,
      columnView: state.columnView,
      displayScopes: state.displayScopes,
      displayPlans: state.displayPlans
    }
  }

  changeView (type){

    switch (type) {
      case 'raw':
        this.setState({
          rawView: true,
          columnView: false
        })
        break
      case 'column':
        this.setState({
          rawView: false,
          columnView: true
        });
        break
      case 'scope':
        this.setState({
          displayScopes: !this.state.displayScopes
        });
        break
      case 'plan':
        this.setState({
          displayPlans: !this.state.displayPlans
        });
        break
      default:
        console.log("the type is not valid")
    }
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    if (!this.props.redux.application.found) {
      return null
    }

    if (this.props.redux.plan_subscriptions.error) {
      return <ResourceError />
    }




    var item
    var plan_subscriptions = {
      active: [],
      pending: []
    }


    if (this.props.redux.plan_subscriptions.found) {
      for (item of this.props.redux.plan_subscriptions.items) {
        if (isDefVal(item, 'data.status') === 'ACTIVE') {
          plan_subscriptions.active.push({
            resourceCode: item.resource_code,
            key: item.data.id
          })
        }
        else {
          plan_subscriptions.pending.push({
            resourceCode: item.resource_code,
            key: item.data.id
          })
        }
      }
    }

    var scope_subscriptions = {
      active: [],
      pending: []
    }


    if (this.props.redux.scope_subscriptions.found) {
      for (item of this.props.redux.scope_subscriptions.items) {
        if (isDefVal(item, 'data.status') === 'ACTIVE') {
          scope_subscriptions.active.push({
            resourceCode: item.resource_code
          })
        }
        else {
          scope_subscriptions.pending.push({
            resourceCode: item.resource_code
          })
        }
      }
    }

    const owner_or_admin = this.props.redux.isUserOwnsConsorg || this.props.redux.isProducerAdminUser
    const owner_or_admin_on_internal = branding.kind === "int" && owner_or_admin
    const owner_on_open = branding.kind !== "int" && this.props.redux.isUserOwnsConsorg
    const delete_allowed = owner_or_admin_on_internal || owner_on_open || !this.props.redux.isCurrentCatalogSensitive

    const plan_can_delete = plan_subscriptions.active.length > 0 && delete_allowed
    const scope_can_delete = scope_subscriptions.active.length > 0 && delete_allowed
    const plan_can_cancel = false
    const scope_can_cancel = owner_or_admin_on_internal || owner_on_open

    // const can_cancel = plan_subscriptions.pending.length > 0 && this.props.redux.isUserOwnsConsorg
    // const can_edit = this.props.redux.isUserOwnsConsorg

    return (
      <div className={this.getItemClasses().join(' ')} >
        <div className="grid g1_root g1_1col">

          <div className="actions_wrapper">
            <div className="smart_filter">

              <Visibility condition={this.state.displayPlans || this.state.displayScopes}>
                <Filter key="raw" type="raw" icon={this.state.rawView ? "raw_white" : "raw_black"}
                        isActive={this.state.rawView} clickHandler={this.changeView}/>
                <Filter key="column" type="column" icon={this.state.columnView ? "column_white" : "column_black"}
                        isActive={this.state.columnView} clickHandler={this.changeView}/>
                |&nbsp;&nbsp;&nbsp;
              </Visibility>


              <Filter key="plan" type="plan" label={this.state.displayPlans ? this.props.t('application:filter_hide_plans') + ' (' + (plan_subscriptions.pending.length + plan_subscriptions.active.length)  + ') ' : this.props.t('application:filter_display_plans')  + ' (' + (plan_subscriptions.pending.length + plan_subscriptions.active.length)  + ') '}
                      isActive={this.state.displayPlans === true} clickHandler={this.changeView}/>
              <Filter key="scope" type="scope" label={this.state.displayScopes ? this.props.t('application:filter_hide_scopes')  + ' (' + (scope_subscriptions.pending.length + scope_subscriptions.active.length)  + ') ' : this.props.t('application:filter_display_scopes') + ' (' + (scope_subscriptions.pending.length + scope_subscriptions.active.length)  + ') '}
                      isActive={this.state.displayScopes === true} clickHandler={this.changeView}/>
            </div>
          </div>

          <Visibility condition={this.state.displayPlans || this.state.displayScopes}>
            <h2>{this.props.t('application:tab_subscriptions_title')}</h2>

            <h3>{this.props.t('application:tab_subscriptions_list_pending_title')}</h3>

            <div className={this.state.columnView ? 'grid g1_211col' : 'grid g1_1col'}>
              <Visibility condition={this.state.displayPlans}>
                {(plan_subscriptions.pending.length > 0 &&
                  plan_subscriptions.pending.map((plan_subscription) => <PlanSubscription key={plan_subscription.key} resourceCode={plan_subscription.resourceCode} can_cancel={plan_can_cancel} />)
                ) ||
                ((!this.props.redux.plan_subscriptions.found || this.props.redux.plan_subscriptions.loading) &&
                  <Loader loading={true} />
                )}
              </Visibility>

              <Visibility condition={this.state.displayScopes}>
                {(scope_subscriptions.pending.length > 0 &&
                  scope_subscriptions.pending.map((scope_subscription) => <ScopeSubscription key={scope_subscription.resourceCode} resourceCode={scope_subscription.resourceCode} can_cancel={scope_can_cancel} />)
                ) ||
                ((!this.props.redux.scope_subscriptions.found || this.props.redux.scope_subscriptions.loading) &&
                  <Loader loading={true} />
                )}
              </Visibility>
            </div>

            {scope_subscriptions.pending.length === 0 && plan_subscriptions.pending.length === 0 &&
            <p className="empty_zone">{this.props.t('application:tab_subscriptions_list_empty')}</p>
            }

            <h3>{this.props.t('application:tab_subscriptions_list_active_title')}</h3>

            <div className={this.state.columnView ? 'grid g1_211col' : 'grid g1_1col'}>
              <Visibility condition={this.state.displayPlans}>
                {(plan_subscriptions.active.length > 0 &&
                  plan_subscriptions.active.map((plan_subscription) => <PlanSubscription key={plan_subscription.key} resourceCode={plan_subscription.resourceCode} can_delete={plan_can_delete}/>)
                ) ||
                ((!this.props.redux.plan_subscriptions.found || this.props.redux.plan_subscriptions.loading) &&
                  <Loader loading={true} />
                )}
              </Visibility>

              <Visibility condition={this.state.displayScopes}>
                {(scope_subscriptions.active.length > 0 &&
                  scope_subscriptions.active.map((scope_subscription) => <ScopeSubscription key={scope_subscription.resourceCode} resourceCode={scope_subscription.resourceCode} can_delete={scope_can_delete} />)
                ) ||
                ((!this.props.redux.scope_subscriptions.found || this.props.redux.scope_subscriptions.loading) &&
                  <Loader loading={true} />
                )}
              </Visibility>
            </div>

            {scope_subscriptions.active.length === 0 && plan_subscriptions.active.length === 0 &&
            <p className="empty_zone">{this.props.t('application:tab_subscriptions_list_empty')}</p>
            }
          </Visibility>

          <Visibility condition={!this.state.displayPlans && !this.state.displayScopes}>
            <h3 className="hidden_message">{this.props.t('application:subscriptions_list_hidden')}</h3>
          </Visibility>



        </div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'application',
      'subscriptions'
    ]
    return classes
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    application: mapResourceFromCode(state, 'application', ownProps.resourceCode),
    lang_code: state.lang.lang_code
  }
  redux.plan_subscriptions = mapResourceFromIds(state, 'application_plans_subscriptions', redux.application.ids)
  redux.scope_subscriptions = mapResourceFromIds(state, 'application_scope_subscriptions', redux.application.ids)
  redux.consorg = mapResourceFromIds(state, 'consorg', redux.application.ids)
  redux.isUserOwnsConsorg = stateIsUserOwnsConsorg(state, redux.consorg.resource_code)
  redux.isCurrentCatalogSensitive = getPortalCurrentCatalogParam(state, 'isSensitiveCatalog')
  redux.isProducerAdminUser = stateIsProducerAdminAuthenticated(state)

  return {
    redux
  }
}

const mapDispatchToProps = dispatch => ({
  requestResource: (external_resource_infos) => dispatch({type:'RESOURCE_LOAD_REQUEST', external_resource_infos})
})

ApplicationPlansSubscriptions = withTranslation(['common','application'])(ApplicationPlansSubscriptions)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  ['item.items.application','item.items.consorg','lang','auth','portal.context','list.lists.application_plans_subscriptions','list.lists.application_scope_subscriptions']
)(ApplicationPlansSubscriptions)
