import React, { Component } from 'react'
import { deepEqualObjs2 } from '../../../utils/objtools'
import branding from '../../portal/branding'

class UiH1 extends Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.children, nextProps.children)
    )
  }

  render() {

    return (
      <h1 className={this.getItemClasses().join(' ')}>
        <span className="text">
          {this.props.children}

          {branding.kind !== "baas" &&
            <span className="bar" style={{left: (Math.floor(Math.random() * 60)+30)+"%", width: (Math.floor(Math.random() * 30)+20)+"%"}}></span>
          }

        </span>
      </h1>
    );
  }

  getItemClasses() {
    var classes = [
      "ui-h1"
    ]
    return classes;
  }
}

export default UiH1;
