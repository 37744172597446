import { isDefVal } from '../../utils/objtools'

/**
 * Get current login status
 */
export function stateAuthIsUserLoggedIn(state) {
  return isDefVal(state, 'auth.logged_in')
}

/**
 * Current logged in user id
 */
export function stateAuthUserId(state) {
  return isDefVal(state, 'auth.user_id')
}

/**
 * Does the user have memberships
 */
export function stateAuthUserHasMemberships(state) {
  return isDefVal(state, 'auth.user_has_memberships')
}

/**
 * Get user invitations count
 */
export function stateAuthGetUserInvitationCount(state) {
  return isDefVal(state, 'auth.user_invitations_count')
}
