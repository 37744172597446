import {put, call, select} from 'redux-saga/effects'
import { alterItem } from '../item/item_alter_saga.js'
import genericFormSubmissionSaga from '../item/genericFormSubmissionSaga'
import {submitIssueFormDeleteRoutine} from "./issue_routines";
import {getPortalCurrentOrganisationAndCatalogIds} from "../portal/portal_selectors";

function* saga(action) {
  const values = {...action.payload}

  // prepare some ids
  var ids = yield select(getPortalCurrentOrganisationAndCatalogIds)

  ids = {
    ...values.consorg.ids,
    ...values.issue.ids,
    ...ids
  }

  var body = {
    "newStatus": "closed"
  }

  // Update the application
  const {error, data} = yield call(alterItem, 'issue_delete', 'issue', ids, body)

  // Generic unhandled error
  if (error)
    throw error

  // Check edited
  if (!data.id || data.id !== ids.issue_id)
    throw new Error('Missing or wrong id in the closed issue.')
  if (!data.status || data.status !== "closed")
    throw new Error('Missing or wrong status in the closed issue.')

  // extend ids
  ids = {
    ...ids,
    tab_code: 'issues'
  }

  // go to the updated issues list
  yield put({type: 'HISTORY_PUSH', scheme_key:'consorg', ids})
}

export function* submitDeleteIssueForm(action) {
  yield call(genericFormSubmissionSaga, {
    saga,
    action,
    routine: submitIssueFormDeleteRoutine,
    successfull_title: 'issue:successfull_delete_title',
    successfull_body: 'issue:successfull_delete_body',
    unsuccessfull_title: 'issue:unsuccessfull_delete_title',
    unsuccessfull_body: 'issue:unsuccessfull_delete_body'
  })
}
