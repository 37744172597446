import React from 'react'
import {logRender} from "../../utils/logging";
import { withTranslation } from 'react-i18next'
import {connect} from "react-redux";
import {deepEqualObjs2, isDef} from "../../utils/objtools";
import {mapResourceFromIds} from "../resource/resource_selectors";
import {getPortalCurrentOrganisationAndCatalogIds} from "../portal/portal_selectors";
import {buildExternalResourceInfos} from "../../utils/urls/external";
import UiItem from "../ui/UiItem/UiItem";

class ApiKatInformation extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || this.props.tReady !== nextProps.tReady
    )
  }

  componentDidMount() {
    this.postRender()
  }

  componentDidUpdate() {
    this.postRender()
  }

  postRender() {
    if (this.props.redux.api_kat_information.need_request) {
      this.props.requestResource(this.props.redux.api_kat_information.external_resource_infos)
    }

    if (!this.props.redux.api_kat_information.found && this.props.redux.external_resource_infos) {
      this.props.requestResource(this.props.redux.external_resource_infos)
    }
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    var apiKatInformation = []

    if (isDef(this.props, 'code_irt')){
      apiKatInformation.push(<UiItem key="irt" subtitle="Irt" slim_value={this.props.code_irt} type="slim"/>)
    }

    if (isDef(this.props, 'trigram')){
      apiKatInformation.push(<UiItem key="trigram" subtitle="Trigram" slim_value={this.props.trigram} type="slim"/>)
    }

    if (isDef(this.props, 'audience')){
      apiKatInformation.push(<UiItem key="audience" subtitle="Audience" slim_value={this.props.audience} type="slim"/>)
    }

    if (isDef(this.props.redux, 'api_kat_information.data.rto') && this.props.redux.api_kat_information.data.rto.length > 0){
      apiKatInformation.push(<UiItem key="rto" subtitle="Rto" slim_value={this.props.redux.api_kat_information.data.rto} type="slim"/>)
    }

    if (isDef(this.props.redux, 'api_kat_information.data.rpo') && this.props.redux.api_kat_information.data.rpo.length > 0){
      apiKatInformation.push(<UiItem key="rpo" subtitle="Rpo" slim_value={this.props.redux.api_kat_information.data.rpo} type="slim"/>)
    }

    if (isDef(this.props.redux, 'api_kat_information.data.dicp') && this.props.redux.api_kat_information.data.dicp.length > 0){
      apiKatInformation.push(<UiItem key="dicp" subtitle="Dicp" slim_value={this.props.redux.api_kat_information.data.dicp} type="slim"/>)
    }


    return (
      <div className={this.getItemClasses().join(' ')} >
        {apiKatInformation}
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'api-kat'
    ]
    return classes;
  }
}


const mapStateToProps = (state, ownProps) => {
  const portalIds = getPortalCurrentOrganisationAndCatalogIds(state)
  var redux = {
    lang_code: state.lang.lang_code,
    api_kat_information: mapResourceFromIds(state, 'api_kat_information', {...portalIds,code_irt : ownProps.code_irt, trigram : ownProps.trigram })
  }

  redux.external_resource_infos = buildExternalResourceInfos('api_kat_information', {...portalIds,code_irt : ownProps.code_irt, trigram : ownProps.trigram })

  return {
    redux
  }
}

const mapDispatchToProps = dispatch => ({
  requestResource: (external_resource_infos) => dispatch({type:'RESOURCE_LOAD_REQUEST', external_resource_infos})
})

ApiKatInformation = withTranslation(
  ['api']
)(ApiKatInformation)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApiKatInformation)

