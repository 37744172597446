import React from 'react'
import { logRender } from '../../../utils/logging'
import { getFirstDef } from '../../../utils/objtools'

const Steps = {
  'noRender': 0,
  'preRender': 10,
  'hide': 20,
  'holdshow': 25,
  'show': 30
}

class AnimDelay extends React.Component {

  constructor(props) {
    super(props)

    const renderDelay = getFirstDef(this.props, 'renderDelay', 0)
    const isRenderingDelayed = renderDelay > 0
    const isAppearanceAnimated = getFirstDef(this.props, 'animateAppearance', false)
    const showDelay = getFirstDef(this.props, 'showDelay', 0)

    var step = Steps.show
    if (isRenderingDelayed)
      step = Steps.noRender
    else if (isAppearanceAnimated)
      step = Steps.hide

    this.state = {
      isAppearanceAnimated,
      showDelay,
      isRenderingDelayed,
      renderDelay,
      step
    }

    this.timeouts = []
  }

  componentDidMount() {
    this.postRender()
  }

  componentDidUpdate() {
    this.postRender()
  }

  componentWillUnmount() {
    this.timeouts.forEach(clearTimeout)
  }

  setTimeout() {
    this.timeouts.push(setTimeout.apply(null, arguments))
  }

  postRender() {
    // TODO State machine à factoriser
    var canShow = getFirstDef(this.props, 'canShow', true)
    if (this.state.step === Steps.noRender) {
      this.setTimeout(() => {
        this.setState({step: Steps.preRender})
        this.setTimeout(() => {
          if (this.state.isAppearanceAnimated) {
            this.setState({step: Steps.hide})
          }
          if (!canShow) {
            this.setState({step: Steps.holdshow})
          }
          else {
            this.setTimeout(() => {
              this.setState({step: Steps.show})
            }, 10 + this.state.showDelay)
          }
        }, this.state.renderDelay)
      }, 10)
    }
    else if (!this.state.isRenderingDelayed && this.state.step === Steps.hide) {
      if (!canShow) {
        this.setState({step: Steps.holdshow})
      }
      else {
        this.setTimeout(() => {
          this.setState({step: Steps.show})
        }, 10 + this.state.showDelay)
      }
    }
    else if (this.state.step === Steps.holdshow && canShow) {
      this.setTimeout(() => {
        this.setState({step: Steps.show})
      }, 10 + this.state.showDelay)
    }
  }

  render() {
    logRender(this)

    var content = null
    if (this.state.step >= Steps.hide) {
      content = this.props.children
    }

    if (this.state.isAppearanceAnimated) {
      return (
        <div className={this.getItemClasses().join(' ')} >
          {content}
        </div>
      )
    }
    else {
      return (
        <React.Fragment>
          {content}
        </React.Fragment>
      )
    }
  }

  getItemClasses() {
    var classes = [
      'anim-delay'
    ]
    if (this.state.isAppearanceAnimated) {
      classes.push('animated')
      if (this.state.step === Steps.show) {
        classes.push('show')
      }
    }
    return classes;
  }
}

export default AnimDelay;