import { copyObj, propertyCopy, isDef } from '../../utils/objtools'
import { buildExternalResourceAliases, buildExternalResourceCode } from '../../utils/urls/external'
import { gatherIds } from '../item/item_reducer'

const redList = (
  state = {
    // Raw lists from backend
    lists: {
      catalog_products: {aliases:{}},
      api_related_products: {aliases:{}},
      scope_featured_public_apis: {aliases:{}},
      catalog_apis: {aliases:{}},
      catalog_consorgs: {aliases:{}},
      catalog_scopes: {aliases:{}},
      organisation_static_contents: {aliases:{}},
      consorg_applications: {aliases:{}},
      consorg_memberships: {aliases:{}},
      consorg_issues: {aliases:{}},
      issue_comments: {aliases:{}},
      my_memberships: {aliases:{}},
      application_credentials: {aliases:{}},
      application_scope_subscriptions: {aliases:{}},
      application_plans_subscriptions: {aliases:{}},
    },
    // Consolidated views
    consorgs_user_belongs: null, // COs in which user is member or owner
    consorgs_user_owns: null, // COs in which user is owner
    consorgs_user_memberships: null, // CO-Membership map for current user
  },
  action
) => {
  var new_state = null
  var aliases, alias, scheme_key
  switch (action.type) {

    case "PORTAL_CHANGE_CATALOG_START":
      // On catalog change we clear data
      new_state = {
        // Raw lists from backend
        lists: {
          catalog_products: {aliases:{}},
          catalog_scopes: {aliases:{}},
          api_related_products: {aliases:{}},
          scope_featured_public_apis: {aliases:{}},
          catalog_apis: {aliases:{}},
          catalog_consorgs: {aliases:{}},
          organisation_static_contents: state.lists.organisation_static_contents,
          consorg_applications: {aliases:{}},
          consorg_memberships: {aliases:{}},
          consorg_issues: {aliases:{}},
          issue_comments: {aliases:{}},
          my_memberships: {aliases:{}},
          application_credentials: {aliases:{}},
          application_scope_subscriptions: {aliases:{}},
          application_plans_subscriptions: {aliases:{}},
        },
        // Consolidated views
        consorgs_user_belongs: null,
        consorgs_user_owns: null,
        consorgs_user_memberships: null
      }
      break

    case "AUTH_HAS_LOGGED_OUT":
      new_state = copyObj(state)
      new_state.consorgs_user_belongs = null
      new_state.consorgs_user_owns = null
      new_state.consorgs_user_memberships = null
      propertyCopy(new_state, 'lists')
      new_state.lists.catalog_consorgs = {aliases:{}}
      new_state.lists.consorg_applications = {aliases:{}}
      new_state.lists.consorg_memberships = {aliases:{}}
      new_state.lists.consorg_issues = {aliases:{}}
      new_state.lists.issue_comments = {aliases:{}}
      new_state.lists.my_memberships = {aliases:{}}
      new_state.lists.application_credentials = {aliases:{}}
      new_state.lists.application_scope_subscriptions = {aliases:{}}
      new_state.lists.application_plans_subscriptions = {aliases:{}}
      break

    case "FLUSH_PRIVATE_LISTS":
      new_state = copyObj(state)
      // We can activate the very strict mode by flushing also products. Backend will go 403
      // new_state.lists.catalog_private_products = {aliases:{}}
      new_state.lists.catalog_private_apis = {aliases:{}}
      new_state.lists.catalog_private_scopes = {aliases:{}}
      break

    case "LIST_WARMUP_REQUEST":
      scheme_key = action.external_resource_infos.scheme_key
      if (isDef(state.lists, [scheme_key, action.external_resource_infos.resource_code])) {
        break
      }
      new_state = copyObj(state)
      propertyCopy(new_state, ['lists', scheme_key, 'aliases'])
      new_state.lists[scheme_key][action.external_resource_infos.resource_code] = {
        status: 'warmup',
        external_resource_infos: action.external_resource_infos,
        //data: null
        itemsResourceCodes: []
      }
      // add aliases
      aliases = buildExternalResourceAliases(scheme_key, action.external_resource_infos.ids)
      for (alias of aliases) {
        new_state.lists[scheme_key].aliases[alias] = action.external_resource_infos.resource_code
      }
      break

    case "LIST_LOAD_START":
      new_state = copyObj(state)
      scheme_key = action.external_resource_infos.scheme_key
      propertyCopy(new_state, ['lists', scheme_key, 'aliases'])
      new_state.lists[scheme_key][action.external_resource_infos.resource_code] = {
        status: 'loading',
        external_resource_infos: action.external_resource_infos,
        //data: null
        itemsResourceCodes: []
      }
      // add aliases
      aliases = buildExternalResourceAliases(scheme_key, action.external_resource_infos.ids)
      for (alias of aliases) {
        new_state.lists[scheme_key].aliases[alias] = action.external_resource_infos.resource_code
      }
      break

    case "LIST_LOAD_ERROR":
      new_state = copyObj(state)
      scheme_key = action.external_resource_infos.scheme_key
      propertyCopy(new_state, ['lists', scheme_key])
      new_state.lists[scheme_key][action.external_resource_infos.resource_code] = {
        status: 'error',
        external_resource_infos: action.external_resource_infos,
        //data: null
        itemsResourceCodes: []
      }
      break

    case "LIST_LOAD_SUCCESS":
      new_state = copyObj(state)
      scheme_key = action.external_resource_infos.scheme_key
      propertyCopy(new_state, ['lists', scheme_key, 'aliases'])
      var itemsResourceCodes = []
      if (Array.isArray(action.data)) { // TODO TEMP pour le cas des 200 avec un body listant des erreurs
        for (var item_data of action.data) {
          var ids = {...action.external_resource_infos.ids, ...gatherIds(action.external_resource_infos.scheme.item_type, item_data)}
          const resourceCode = buildExternalResourceCode(action.external_resource_infos.scheme.item_type, ids)
          if (!resourceCode) {
            console.error('Could not build resourceCode of a list item.', action, ids)
            continue
          }
          itemsResourceCodes.push(resourceCode)
        }
      }
      new_state.lists[scheme_key][action.external_resource_infos.resource_code] = {
        status: 'loaded',
        external_resource_infos: action.external_resource_infos,
        data: action.data, // For performance reason, the brut data is not stored, as we store partial items and resource codes list
        itemsResourceCodes
      }
      // add aliases
      aliases = buildExternalResourceAliases(scheme_key, action.external_resource_infos.ids)
      for (alias of aliases) {
        new_state.lists[scheme_key].aliases[alias] = action.external_resource_infos.resource_code
      }
      break

    case "LIST_UPDATE_VIEW":
      new_state = copyObj(state)
      propertyCopy(new_state, action.view_code)
      new_state[action.view_code] = action.content
      break

    default:
      break
  }
  if (new_state) {
    return new_state
  }
  else {
    return state
  }
}

export default redList
