import { copyObj, propertyCopy, deepCopyObj } from '../../utils/objtools'

const defaultVisibleItems = 24

const redFinder = (
  state = {
    results: {
      items: []
    },
    filters: {
      fulltext: {
        name: 'fulltext',
        type: 'input',
        title_i18n: 'finder:filter_fulltext_title',
        placeholder: 'finder:filter_fulltext_placeholder',
        rules: {
          fields: [
            {
              path: 'consolidated.title',
              weight: 10
            },
            {
              path: 'consolidated.name',
              weight: 7
            },
            {
              path: 'consolidated.version',
              weight: 5
            },
            {
              path: 'consolidated.state',
              weight: 3
            },
            {
              path: 'consolidated.space',
              weight: 10
            },
            {
              path: 'consolidated.domain',
              weight: 10
            }
          ]
        }
      },
      itemtype: {
        name: 'itemtype',
        type: 'checkbox',
        title_i18n: 'finder:filter_itemtype_title',
        options: {
          'product': {
            name: 'product',
            title_i18n: 'finder:filter_itemtype_option_product'
          },
          'private_product': {
            name: 'private_product',
            title_i18n: 'finder:filter_itemtype_option_private_product'
          },
          'api': {
            name: 'api',
            title_i18n: 'finder:filter_itemtype_option_api'
          },
          'private_api': {
            name: 'private_api',
            title_i18n: 'finder:filter_itemtype_option_private_api'
          },
          'consorg': {
            name: 'consorg',
            title_i18n: 'finder:filter_itemtype_option_consorg'
          },
          'application': {
            name: 'application',
            title_i18n: 'finder:filter_itemtype_option_application'
          },
          'scope': {
            name: 'scope',
            title_i18n: 'finder:filter_itemtype_option_scope'
          },
          'private_scope': {
            name: 'private_scope',
            title_i18n: 'finder:filter_itemtype_option_private_scope'
          }
        }
      },
      itemstate: {
        name: 'itemstate',
        type: 'checkbox',
        title_i18n: 'finder:filter_itemstate_title',
        item_field_path: 'consolidated.state',
        options: {
          'published': {
            name: 'published',
            title_i18n: 'finder:filter_itemstate_option_published'
          },
          'deprecated': {
            name: 'deprecated',
            title_i18n: 'finder:filter_itemstate_option_deprecated'
          },
          // 'archived': {
          //   name: 'archived',
          //   title_i18n: 'finder:filter_itemstate_option_archived'
          // },
          'running': {
            name: 'running',
            title_i18n: 'finder:filter_itemstate_option_running'
          }
          // 'stopped': {
          //   name: 'stopped',
          //   title_i18n: 'finder:filter_itemstate_option_stopped'
          // }
        }
      },
      // universe: {
      //   name: 'universe',
      //   type: 'checkbox',
      //   title_i18n: 'finder:filter_universe_title',
      //   item_field_path: 'consolidated.universe',
      //   options: {
      //     '1.0': {
      //       name: '1.0',
      //       title_i18n: 'finder:filter_universe_option_10'
      //     },
      //     '2.0': {
      //       name: '2.0',
      //       title_i18n: 'finder:filter_universe_option_20'
      //     },
      //     '2.1': {
      //       name: '2.1',
      //       title_i18n: 'finder:filter_universe_option_21'
      //     },
      //     '3.0': {
      //       name: '3.0',
      //       title_i18n: 'finder:filter_universe_option_30'
      //     }
      //   }
      // },
      domain: {
        name: 'domain',
        type: 'checkbox',
        title_i18n: 'finder:filter_domain_title',
        item_field_path: 'consolidated.domain',
        options: {}
      },
      space: {
        name: 'space',
        type: 'checkbox',
        title_i18n: 'finder:filter_space_title',
        item_field_path: 'consolidated.space',
        options: {}
      }
    },
    sort_modes: {},
    search_state: {
      status: null,
      sort_mode: 'created_at_desc',
      view_mode: 'teaser',
      visible_items: defaultVisibleItems,
      filters: {
        //itemtype: ['consorg','application','product','api']
      }
    }
  },
  action
) => {
  var new_state = null
  switch (action.type) {

    case "PORTAL_CHANGE_CATALOG_START":
      new_state = copyObj(state)
      propertyCopy(new_state, 'search_state')
      new_state.results = {
          items: []
        }
      new_state.search_state.status = null
      break

    case "FINDER_FILTER_SPACES_SET":
      new_state = copyObj(state)
      propertyCopy(new_state, 'filters')
      new_state.filters.space.options = deepCopyObj(action.spaces_options)
      break

    case "FINDER_FILTER_DOMAINS_SET":
      new_state = copyObj(state)
      propertyCopy(new_state, 'filters')
      new_state.filters.domain.options = deepCopyObj(action.domains_options)
      break

    case "FINDER_FILTER_VALUE_SET":
      new_state = copyObj(state)
      propertyCopy(new_state, 'search_state.filters')
      new_state.search_state.filters[action.filter_name] = deepCopyObj(action.filter_value)
      break

    case "FINDER_REFRESH_RESULTS_START":
      new_state = copyObj(state)
      propertyCopy(new_state, 'search_state')
      new_state.search_state.status = 'loading'
      break

    case "FINDER_REFRESH_RESULTS_SET":
      new_state = copyObj(state)
      propertyCopy(new_state, 'search_state')
      new_state.search_state.status = 'loaded'
      new_state.search_state.visible_items = defaultVisibleItems
      new_state.results = action.results
      break

    case "FINDER_SET_VIEW_COUNT":
      new_state = copyObj(state)
      propertyCopy(new_state, 'search_state')
      new_state.search_state.visible_items = action.count
      break

    default:
      break
  }
  if (new_state) {
    return new_state
  }
  else {
    return state
  }
}

export default redFinder;
