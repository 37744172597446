import React from 'react'
import { logRender } from '../../utils/logging'
import Icon from '../icon/Icon'
import { connect } from '../../utils/redux'
import { getPortalCurrentOrganisationAndCatalogIds } from '../portal/portal_selectors'
import { mapResourceFromIds } from '../resource/resource_selectors'
import ScopeCartFinalForm from './ScopeCartFinalForm'
import { bindPromiseCreators } from 'redux-saga-routines'
import { submitFinalFormAddScopeToCartHandler, submitFinalFormEditScopeCartHandler } from './scope_routines'
import { stateGetCartScope } from '../cart/cart_selectors'
import { isDef, getFirstDef, isDefVal } from '../../utils/objtools'
import { withTranslation } from 'react-i18next'
import UiProperty from '../ui/UiProperty/UiProperty'
import { buildUri } from '../../utils/urls/internal'
import { Link } from "react-router-dom"

class ScopeCard extends React.Component {

  componentDidMount() {
    this.postRender()
  }

  componentDidUpdate() {
    this.postRender()
  }

  postRender() {
    if (this.props.redux.scope.need_request) {
      this.props.requestResource(this.props.redux.scope.external_resource_infos)
    }
    // // TEMP
    // if (!this.props.redux.scope.found && this.props.redux.external_resource_infos) {
    //   this.props.requestResource(this.props.redux.external_resource_infos)
    // }
    // //
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    if (!this.props.redux.scope.loaded || !isDef(this.props.redux.scope, 'data.claimsGroup')) {
      return null
    }

    var selected_scopeClaim = '_none'
    var form_op = 'new'
    if (this.props.redux.cartScope) {
      selected_scopeClaim = this.props.redux.cartScope.scopeClaim
      form_op = 'edit'
    }

    var initialValues = {
      // Hidden
      name: this.props.name,
      context: this.props.context,
      // Fields
      scopeClaim: selected_scopeClaim
    }

    var scopeClaims_options = {
      "_none": '-'
    }

    for (var claimName in this.props.redux.scope.data.claimsGroup) {
      const claim = this.props.redux.scope.data.claimsGroup[claimName]
      var label = claimName
      if (isDef(claim, 'rateLimit')) {
        label += ' ('+this.props.t('scope:claimProp_rateLimit')+' '+claim.rateLimit+')'
      }
      scopeClaims_options[claimName] = label
    }

    const title = getFirstDef(this.props.redux.scope, 'data.title', this.props.name)

    var post_title = []
    if (isDefVal(this.props.redux.scope, 'data.approvalsRequired') === true) {
      post_title.push( <div key="approval" className="pill secondary">{this.props.t('scope:approval_required')}</div> )
    }
    else {
      post_title.push( <div key="approval" className="pill secondary">{this.props.t('scope:no_approval_required')}</div> )
    }

    var cartouche =
      <div className="cartouche">
        <div className="pre_title">
          <Icon type="scope"/>
          <div key="type" className="pill">SCOPE</div>
        </div>
        <div className="title" key="title">{title}</div>
        <div className="post_title">
          {post_title}
        </div>
      </div>
    if (this.props.redux.uri) {
      cartouche = <Link to={this.props.redux.uri}>{cartouche}</Link>
    }

    return (
      <div className={this.getItemClasses().join(' ')}>
        <div className="content">
          {cartouche}
          <div className="corps">
            <UiProperty key="code" name="Code" value={this.props.name} />
            {form_op === 'new' &&
              <ScopeCartFinalForm form_op={form_op} onSubmit={this.props.submitFinalFormAddScopeToCartHandler} scopeClaims_options={scopeClaims_options} initialValues={initialValues} type="linked"/>
            }
            {form_op === 'edit' &&
              <ScopeCartFinalForm form_op={form_op} onSubmit={this.props.submitFinalFormEditScopeCartHandler} scopeClaims_options={scopeClaims_options} initialValues={initialValues} type="linked"/>
            }
          </div>
        </div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'scope-card'
    ]
    return classes
  }
}

const mapStateToProps = (state, ownProps) => {
  const portalIds = getPortalCurrentOrganisationAndCatalogIds(state)
  var redux = {
    lang_code: state.lang.lang_code,
    scope: mapResourceFromIds(state, 'scope', {...portalIds, scope_id: ownProps.name}),
    cartScope: stateGetCartScope(state, ownProps.name),
    // // TEMP stuff until scope list is available
    //   external_resource_infos: buildExternalResourceInfos('scope', {...portalIds, scope_id: ownProps.name}),
    // //
    uri: null
  }
  if (redux.scope.loaded) {
    redux.uri = buildUri('scope', {...redux.scope.ids, lang_code: redux.lang_code})
  }
  return {
    redux
  }
}

const mapDispatchToProps = dispatch => ({
  requestResource: (external_resource_infos) => dispatch({type:'RESOURCE_LOAD_REQUEST', external_resource_infos}),
  ...bindPromiseCreators({
    submitFinalFormAddScopeToCartHandler,
    submitFinalFormEditScopeCartHandler
  }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  ['item.items.scope','cart.selectedScopes','lang','portal.context.catalog','portal.context.organisation']
)(withTranslation(
    ['scope']
)(ScopeCard))
