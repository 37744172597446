export function getPatternIds(pattern) {
  var regexp = /:([Za-z_]+)/g
  var match
  var ids = []
  while ((match = regexp.exec(pattern))) {
    ids.push(match[1])
  }
  return ids
}

export function parseQueryParams(queryString) {
  var query = {}
  var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&')
  for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split('=')
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
  }
  return query
}

export function buildQueryString(queryParams) {
  var queryString = ''
  for (var key in queryParams) {
    if (!queryString) {
      queryString += '?'
    }
    else {
      queryString += '&'
    }
    queryString += key
    queryString += '='
    queryString += queryParams[key]
  }
  return queryString
}