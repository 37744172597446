import React from 'react'
import {connect} from 'react-redux'
import {logRender} from '../../utils/logging'
import {withTranslation} from 'react-i18next'
import {deepEqualObjs2} from '../../utils/objtools'
import {mapResourceFromCode, mapResourceFromIds} from '../resource/resource_selectors'
import Button from 'react-bootstrap/Button'
import {modalAdd} from '../modals/modals_actions'
import {submitCredentialFinalFormNewHandler} from '../credential/credential_routines'
import CredentialFinalForm from '../credential/CredentialFinalForm'
import ResourceError from '../ui/ResourceError/ResourceError'
import Credential from '../credential/Credential'
import Loader from '../loader/Loader'
import {bindPromiseCreators} from 'redux-saga-routines'
import Visibility from '../../utils/debug/Visibility'
import {stateIsUserBelongsConsorg, stateIsUserOwnsConsorg} from '../consorg/consorg_selectors'
import {getPortalCurrentCatalogParam} from "../portal/portal_selectors";
import {stateIsProducerAdminAuthenticated} from "../../utils/user";
import branding from "../portal/branding";

class ApplicationCredentials extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || this.props.tReady !== nextProps.tReady
    )
  }

  componentDidMount() {
    this.postRender()
  }

  componentDidUpdate() {
    this.postRender()
  }

  postRender() {
    if (this.props.redux.credentials.need_request) {
      this.props.requestResource(this.props.redux.credentials.external_resource_infos)
    }
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    if (!this.props.redux.application.found)
      return null

    if (this.props.redux.credentials.error) {
      return <ResourceError />
    }

    var credentials = []
    if (this.props.redux.credentials.found) {
      for (var item of this.props.redux.credentials.items) {
        credentials.push({
          resourceCode: item.resource_code
        })
      }
    }

    const owner_or_admin = this.props.redux.isUserOwnsConsorg || this.props.redux.isProducerAdminUser
    const owner_or_admin_on_internal = branding.kind === "int" && owner_or_admin
    const owner_on_open = branding.kind !== "int" && this.props.redux.isUserOwnsConsorg
    const can_edit = owner_or_admin_on_internal || owner_on_open || !this.props.redux.isCurrentCatalogSensitive
    const can_delete = credentials.length > 1 && can_edit
    const can_verify = this.props.redux.isUserBelongsConsorg

    return (
      <div className={this.getItemClasses().join(' ')} >
        <div className="grid g1_root g1_1col">

          <h2>{this.props.t('application:tab_credentials_title')}</h2>

          {/*hidden temporary */}
          <Visibility condition={false}>
            <h3>{this.props.t('application:tab_credentials_add_title')}</h3>
            <Button variant="secondary" onClick={()=>this.createClick()}>{this.props.t('credential:add_credential_button')}</Button>
          </Visibility>

          <h3>{this.props.t('application:tab_credentials_list_title')}</h3>
          {(credentials.length > 0 &&
              credentials.map((credential) => <Credential key={credential.resourceCode} resourceCode={credential.resourceCode} can_delete={can_delete} can_edit={can_edit} can_verify={can_verify} />)
            ) ||
            ((!this.props.redux.credentials.found || this.props.redux.credentials.loading) &&
              <Loader loading={true} />
            ) ||
            <p className="empty_zone">{this.props.t('application:tab_credentials_list_empty')}</p>
          }

        </div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'application',
      'credentials'
    ]
    return classes
  }

  createClick() {
    this.props.modalAdd(
      'form',
      {
        title: this.props.t('credential:add_modal_title'),
        message: this.props.t('credential:add_modal_message'),
        FormComponent: CredentialFinalForm,
        formComponentProps: {
          form_op: "new",
          initialValues: {
            ids: this.props.redux.application.ids,
          }
        },
        submitAction: this.props.submitCredentialFinalFormNewHandler,
        closeAction: null
      }
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    application: mapResourceFromCode(state, 'application', ownProps.resourceCode),
    lang_code: state.lang.lang_code
  }
  redux.credentials = mapResourceFromIds(state, 'application_credentials', redux.application.ids)
  redux.consorg = mapResourceFromIds(state, 'consorg', redux.application.ids)
  redux.isUserOwnsConsorg = stateIsUserOwnsConsorg(state, redux.consorg.resource_code)
  redux.isUserBelongsConsorg = stateIsUserBelongsConsorg(state, redux.consorg.resource_code)
  redux.isProducerAdminUser = stateIsProducerAdminAuthenticated(state)
  redux.isCurrentCatalogSensitive = getPortalCurrentCatalogParam(state, 'isSensitiveCatalog')
  return {
    redux
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindPromiseCreators({
    submitCredentialFinalFormNewHandler,
    // other promise creators can be here...
  }, dispatch),
  modalAdd: (modalType, modalProps) => dispatch(modalAdd(modalType, modalProps)),
  requestResource: (external_resource_infos) => dispatch({type:'RESOURCE_LOAD_REQUEST', external_resource_infos})
})

ApplicationCredentials = withTranslation(['common','application','credential'])(ApplicationCredentials)

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationCredentials)
