import React from 'react'
import {logRender} from "../../utils/logging";
import { withTranslation } from 'react-i18next'
import {connect} from "react-redux";
import {deepEqualObjs2, isDef} from "../../utils/objtools";
import Teaser from "../teaser/Teaser";
import AnimDelay from "../ui/AnimDelay/AnimDelay";
import {mapResourceFromCode, mapResourceFromIds} from "../resource/resource_selectors";
import {buildExternalResourceInfos} from "../../utils/urls/external";
import {stateGetSelectedConsorgResourceCode} from "../consorg/consorg_selectors";
import {getPortalCurrentOrganisationAndCatalogIds} from "../portal/portal_selectors";
import {stateAuthIsUserLoggedIn} from "../auth/auth_selectors";

class ScopeFeaturedApis extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || this.props.tReady !== nextProps.tReady
    )
  }

  componentDidMount() {
    this.postRender()
  }

  componentDidUpdate() {
    this.postRender()
  }

  postRender() {
    if (this.props.redux.scope_featured_public_apis.need_request) {
      this.props.requestResource(this.props.redux.scope_featured_public_apis.external_resource_infos)
    }

    if (!this.props.redux.scope_featured_public_apis.found && this.props.redux.external_resource_infos_public_apis) {
      this.props.requestResource(this.props.redux.external_resource_infos_public_apis)
    }

    if (this.props.redux.scope_featured_private_apis && this.props.redux.scope_featured_private_apis.need_request) {
      this.props.requestResource(this.props.redux.scope_featured_private_apis.external_resource_infos_private_apis)
    }

    if (this.props.redux.scope_featured_private_apis && !this.props.redux.scope_featured_private_apis.found && this.props.redux.external_resource_infos_private_apis) {
      this.props.requestResource(this.props.redux.external_resource_infos_private_apis)
    }
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    var scopeFeaturedApis = []
    if (isDef(this.props.redux, 'scope_featured_public_apis.itemsResourceCodes')){
      for (let item of this.props.redux.scope_featured_public_apis.itemsResourceCodes){
        scopeFeaturedApis.push(<Teaser key={item} item_type="api" resource_code={item}/>)
      }
    }

    if (isDef(this.props.redux, 'scope_featured_private_apis.itemsResourceCodes')){
      for (let item of this.props.redux.scope_featured_private_apis.itemsResourceCodes){
        scopeFeaturedApis.push(<Teaser key={item} item_type="api" resource_code={item}/>)
      }
    }

    if (scopeFeaturedApis.length)
      scopeFeaturedApis = (
        <AnimDelay animateAppearance={true} showDelay={100}>
          <h2>{this.props.t('scope:featured_in_apis')}</h2>
          <div className="grid g1_432col">
            {scopeFeaturedApis}
          </div>
        </AnimDelay>
      )


    return (
      <div className={this.getItemClasses().join(' ')} >
        {scopeFeaturedApis}
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'scope_featured_apis'
    ]
    return classes;
  }
}


const mapStateToProps = (state, ownProps) => {
  const portalIds = getPortalCurrentOrganisationAndCatalogIds(state)

  var redux = {
    lang_code: state.lang.lang_code,
    authIsUserLoggedIn: stateAuthIsUserLoggedIn(state),
    scope_featured_public_apis: mapResourceFromIds(state, 'scope_featured_public_apis', {...portalIds, ...ownProps.scope_ids})
  }

  redux.external_resource_infos_public_apis = buildExternalResourceInfos('scope_featured_public_apis', {...portalIds, ...ownProps.scope_ids})

  if (redux.authIsUserLoggedIn) {
    redux.consorg = mapResourceFromCode(state, 'consorg', stateGetSelectedConsorgResourceCode(state))
    redux.scope_featured_private_apis = mapResourceFromIds(state, 'scope_featured_private_apis', {...portalIds,...ownProps.scope_ids, ...redux.consorg.ids})
    redux.external_resource_infos_private_apis = buildExternalResourceInfos('scope_featured_private_apis', {...portalIds,...ownProps.scope_ids, ...redux.consorg.ids})
  }

  return {
    redux
  }
}

const mapDispatchToProps = dispatch => ({
  requestResource: (external_resource_infos) => dispatch({type:'RESOURCE_LOAD_REQUEST', external_resource_infos})
})

ScopeFeaturedApis = withTranslation(
  ['api','scope']
)(ScopeFeaturedApis)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScopeFeaturedApis)

