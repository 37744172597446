import { submitApplicationFormDeleteRoutine } from './application_routines'
import { put, call } from 'redux-saga/effects'
import { deleteItem } from '../item/item_delete_saga.js'
import genericSubmissionSaga from '../item/genericSubmissionSaga'

function* saga(action) {

  const {values, modalInfos} = action.payload
  // prepare some ids
  var ids = values.ids

  const {error} = yield call(deleteItem, 'application_delete', 'application', ids)

  // Generic unhandled error
  if (error)
    throw error

  // extend ids
  ids = {
    ...ids,
    tab_code: 'applications'
  }

  modalInfos.closeModal()

  // go to the parent consorg
  yield put({type: 'HISTORY_PUSH', scheme_key:'consorg', ids})
}

export function* submitDeleteApplicationForm(action) {
  yield call(genericSubmissionSaga, {
    saga,
    action,
    routine: submitApplicationFormDeleteRoutine,
    successfull_title: 'application:successfull_delete_title',
    successfull_body: 'application:successfull_delete_body',
    unsuccessfull_title: 'application:unsuccessfull_delete_title',
    unsuccessfull_body: 'application:unsuccessfull_delete_body'
  })
}
