import React from 'react'
import { connect } from 'react-redux'
import { logRender } from '../../utils/logging'
import { withTranslation } from 'react-i18next'
import { deepEqualObjs2, isDef, isDefVal } from '../../utils/objtools'
import setDocumentTitle from '../../utils/browser/title'
import { submitIssueFinalFormNewHandler } from './issue_routines'
import SingleHeader from '../ui/SingleHeader/SingleHeader'
import { stateGetSelectedConsorgId, stateIsUserBelongsConsorg } from '../consorg/consorg_selectors'
import Visibility from '../../utils/debug/Visibility'
import { stateAuthIsUserLoggedIn } from '../../components/auth/auth_selectors'
import { getPortalPageQueryParams, getPortalCurrentOrganisationAndCatalogIds } from '../portal/portal_selectors'
import {mapResourceFromCode, stateFindResourceByIds} from '../resource/resource_selectors'
import SenderHeader from "../ui/SenderHeader/SenderHeader";
import IssueFinalForm from "./IssueFinalForm";
import {bindPromiseCreators} from "redux-saga-routines";

class IssueNew extends React.Component {


  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || this.props.tReady !== nextProps.tReady
    )
  }

  componentDidMount() {
    this.postRender()
  }

  componentDidUpdate() {
    this.postRender()
  }

  postRender() {
    if (this.props.tReady) {
      setDocumentTitle(this.props.t('issue:form_new_title'))
    }
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    var initialValues = {
      relationship_consorg: this.props.redux.relationship_consorg,
      user: this.props.redux.user
    }

    return (
      <Visibility
        condition={this.props.redux.authIsUserLoggedIn !== false}
        resourceErrorDetails="You must be logged in to create an issue.">

        <div className={this.getItemClasses().join(' ')} >
          <div className="grid g1_root g1_1col">
            <SingleHeader key="header" item_type="issue" consolidated={{title: this.props.t('issue:form_new_title')}} pills={null} loading={false} />
            <div className="item_full_presentation">
              <div className="presentation_content gridg g1_2t1t_2_1">
                <div className="form">
                  <SenderHeader key="sender_header" item_type="main" user={this.props.redux.user}/>
                  <IssueFinalForm form_op="new" onSubmit={this.props.submitIssueFinalFormNewHandler} initialValues={initialValues} />
                </div>
                {/*TODO show when labels are managed */}
                {/*<div>*/}
                {/*  <h4>{this.props.t('issue:labels_title')}</h4>*/}
                {/*  <p className="empty_zone">{this.props.t('issue:empty_labels')}</p>*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
        </div>
      </Visibility>
    )
  }

  getItemClasses() {
    var classes = [
      'issue',
      'form_new',
      'form_page'
    ]
    return classes
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    lang_code: state.lang.lang_code,
    user: mapResourceFromCode(state, 'me', 'user/me'),
    selected_consorg_id: stateGetSelectedConsorgId(state),
    authIsUserLoggedIn: stateAuthIsUserLoggedIn(state),
    portalPageQueryParams: getPortalPageQueryParams(state)
  }
  // Default CO comes from currently selected or from query param
  redux.relationship_consorg = redux.selected_consorg_id
  if (isDef(redux, 'portalPageQueryParams.co')) {
    var ids = getPortalCurrentOrganisationAndCatalogIds(state)
    ids = {
      ...ids,
      consorg_uname: redux.portalPageQueryParams.co
    }
    var consorg = stateFindResourceByIds(state, 'consorg', ids)
    if (stateIsUserBelongsConsorg(state, isDefVal(consorg, 'external_resource_infos.resource_code'))) {
      redux.relationship_consorg = consorg.ids.consorg_id
    }
  }
  return {
    redux
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindPromiseCreators({
    submitIssueFinalFormNewHandler
  }, dispatch)
})

IssueNew = withTranslation(
  ['common','consorg','issue']
)(IssueNew)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IssueNew)
