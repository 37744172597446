import {call, put, select, takeEvery} from 'redux-saga/effects'
import {notificationAdd, notificationAddError} from '../notifications/notifications_actions'
import {getFirstDef, isDef, isDefVal} from '../../utils/objtools'
import {
  getPortalCatalogs,
  getPortalCurrentCatalog,
  getPortalCurrentOrganisation,
  getPortalDefaultCatalogId
} from './portal_selectors'
import {buildExternalResourceInfos} from '../../utils/urls/external'
import i18n from '../../utils/lang/i18n'
import branding from '../portal/branding'
import {stateAuthIsUserLoggedIn} from '../auth/auth_selectors'
import {membershipVisibilities} from '../../utils/enums/membershipVisibilities'
import {resourceVisibilities} from '../../utils/enums/resourceVisibilities'
import {allowMembershipRequest} from '../../utils/enums/allowMembershipRequest'

const portalSagas = [
  takeEvery("PORTAL_LOAD_CONTEXT_START", loadContext),
  takeEvery("PORTAL_CHANGE_CATALOG_REQUEST", changeCatalog),
  takeEvery("DISPLAY_PAGE", findRequestedCatalog),
  takeEvery("ITEM_LOAD_SUCCESS", checkCatalogAndOrganisationLoad),
  takeEvery("ITEM_LOAD_ERROR", checkCatalogAndOrganisationLoad),
  takeEvery("LIST_LOAD_SUCCESS", checkCatalogContent),
  takeEvery("LIST_LOAD_SUCCESS", checkOrganisationContent)
]
export default portalSagas

// Portal Context Load

function* loadContext() {
  try {
    const response = yield call(apiLoadContext)
    if (response.status !== 200) {
      throw new Error("Could not load the portal context.")
    }
    var context = yield response.json()
    // Check catalogs
    if (!isDef(context, 'catalogs'))
      throw new Error("Catalogs list is missing in the loaded context.")
    // Check organisations
    if (!isDef(context, 'organisations'))
      throw new Error("Organisations list is missing in the loaded context.")
    // Check and set current org
    if (!isDef(context.organisations, context.portal_default_organisation_id))
      throw new Error("Default organisation does not exist in the context.")
    context.organisation = context.organisations[context.portal_default_organisation_id] // it will not change so no need to dispatch an action
    // Check current catalog
    if (!isDef(context.catalogs, context.portal_default_catalog_id))
      throw new Error("Default catalog does not exist in the context.")
    // yield put(notificationAdd({
    //   title: 'Context loaded',
    //   body: 'The portal context has been successfully loaded.',
    //   autoclose_ms: 0,
    // }))
    // Dispatch context load success
    yield put({type: 'PORTAL_LOAD_CONTEXT_SUCCESS', context})
    // add org menu item if multiple
    if (Object.keys(context.organisations).length > 1) {
      const current_organisation = yield select(getPortalCurrentOrganisation)
      var organisation_item = {
        name: 'organisation',
        type: 'opener',
        title: current_organisation.title,
        icon_type: 'organisation',
        target_pane: 'organisations',
        show: {
          desktop: 'bandeau',
          tablet: 'bandeau',
          mobile: 'bandeau',
        }
      }
      yield put({type: 'PORTAL_SET_MENU_ITEM', item: organisation_item})
    }
    // Ask for load of the organisation resource
    var ids = {
      organisation_id: context.organisation.organisation_id
    }
    var external_resource_infos = buildExternalResourceInfos('organisation', ids)
    yield put({type: 'RESOURCE_LOAD_REQUEST', external_resource_infos})
  }
  catch (error) {
    yield put(notificationAddError({error}));
    yield put({type: 'PORTAL_LOAD_CONTEXT_ERROR', error: {name: error.name, message: error.message}})
  }
}

function apiLoadContext() {
  var filename = 'portal_context'
  if (branding.env === 'development') {
    filename += '.development.' + branding.kind
  }
  var fetchHeaders = new Headers()
  var fetchRequest = new Request('/context/'+filename+'.json')
  var fetchParams = {
    method: 'GET',
    headers: fetchHeaders,
    mode: 'cors'
  }
  return fetch(fetchRequest, fetchParams)
}

// Catalog change

function* changeCatalog(action) {
  try {
    // TODO handle elsewhere menu closing delay as this saga is on the critical first access path
    //yield delay(300) // We just want the menu to close before the change to avoid unesthetic visible menu pane content change

    yield put({type: 'PORTAL_CHANGE_CATALOG_START', catalog_id: action.catalog_id})

    const current_catalog = yield select(getPortalCurrentCatalog)
    const current_organisation = yield select(getPortalCurrentOrganisation)

    var catalog_item = {
      name: 'catalog',
      type: 'opener',
      title: current_catalog.title,
      target_pane: 'catalogs',
      icon_type: 'catalog',
      show: {
        desktop: 'bandeau',
        tablet: 'bandeau',
        mobile: 'bandeau',
      }
    }
    yield put({type: 'PORTAL_SET_MENU_ITEM', item: catalog_item})

    var title = i18n.t('common:portal_catalog_change_title')
    var body = i18n.t('common:portal_catalog_change_body', {
      catalog_title: current_catalog.title
    })
    yield put(notificationAdd({title, body, autoclose_ms: 4000}))

    // Ask for load of the catalog resource
    var ids = {
      organisation_id: current_organisation.organisation_id,
      catalog_id: current_catalog.catalog_id
    }
    var external_resource_infos = buildExternalResourceInfos('catalog', ids)
    yield put({type: 'RESOURCE_LOAD_REQUEST', external_resource_infos})

    if (yield select(stateAuthIsUserLoggedIn)) {
      // User's memberships in catalog's consumer orgs
      var external_resource_infos2 = buildExternalResourceInfos('my_memberships', ids)
      yield put({type: 'RESOURCE_LOAD_REQUEST', external_resource_infos:external_resource_infos2, force:true})
    }

    // A activer si besoin
    //yield put({type: 'PORTAL_CHANGE_CATALOG_SUCCESS', catalog_id: action.catalog_id})
  }
  catch (error) {
    yield put(notificationAddError({error}));
    // A activer si besoin
    //yield put({type: 'PORTAL_CHANGE_CATALOG_ERROR', error: {name: error.name, message: error.message}, catalog_id: action.catalog_id})
  }
}

// Change catalog from requested page, or set the default is non is requested

function* findRequestedCatalog(action) {
  const current_catalog = yield select(getPortalCurrentCatalog)
  if (isDef(action, 'routeProps.match.params.catalog_uname')) {
    if (current_catalog && current_catalog.name === action.routeProps.match.params.catalog_uname)
      return
    var catalogs = yield select(getPortalCatalogs)
    for (var catalog_id in catalogs) {
      var catalog = catalogs[catalog_id]
      if (catalog.name === action.routeProps.match.params.catalog_uname) {
        yield(put({type: 'PORTAL_CHANGE_CATALOG_REQUEST', catalog_id: catalog_id}))
        return
      }
    }
    var title = i18n.t('common:portal_catalog_not_found_title')
    var body = i18n.t('common:portal_catalog_not_found_body')
    yield put(notificationAddError({title, body}))
    // On signale que ce catalog_uname est invalide pour autoriser des redirections si présent dans l'URL
    yield(put({type: 'PORTAL_CATALOG_UNAME_WRONG', catalog_uname: action.routeProps.match.params.catalog_uname}))
  }
  // Go to default catalog
  var default_catalog_id = yield select(getPortalDefaultCatalogId)
  yield(put({type: 'PORTAL_CHANGE_CATALOG_REQUEST', catalog_id: default_catalog_id}))
}

// Update readiness of current catalog and organization

function* checkCatalogAndOrganisationLoad(action) {
  var item_type = isDefVal(action, 'external_resource_infos.scheme.item_type')
  var status = action.type === 'ITEM_LOAD_SUCCESS' ? 'success' : 'error'
  switch(item_type) {
    case "catalog":
      var catalog_id = isDefVal(action, 'external_resource_infos.ids.catalog_id')
      const current_catalog = yield select(getPortalCurrentCatalog)
      if (current_catalog.catalog_id === catalog_id) {
        var catalogParams = {
          consumerOrgsConfiguration: {
            canOwnersDelete: getFirstDef(action, 'data.consumerOrgsConfiguration.canOwnersDelete', false),
            canOwnersEdit: getFirstDef(action, 'data.consumerOrgsConfiguration.canOwnersEdit', false),
            canUsersCreate: getFirstDef(action, 'data.consumerOrgsConfiguration.canUsersCreate', false),
            resourceVisibility: getFirstDef(action, 'data.consumerOrgsConfiguration.resourceVisibility', resourceVisibilities.PRIVATE_TO_MEMBERS),
            membershipVisibility: getFirstDef(action, 'data.consumerOrgsConfiguration.membershipVisibility', membershipVisibilities.PRIVATE_TO_MEMBERS),
            allowMembershipRequest: getFirstDef(action, 'data.consumerOrgsConfiguration.allowMembershipRequest', allowMembershipRequest.DISABLED),
          },
          isSensitiveCatalog: getFirstDef(action, 'data.sensitive', false)
        }

        yield(put({type: 'PORTAL_CATALOG_STATUS', catalog_id, status, catalogParams}))
      }
      break
    case "organisation":
        var organisation_id = isDefVal(action, 'external_resource_infos.ids.organisation_id')
        const current_organisation = yield select(getPortalCurrentOrganisation)
        if (current_organisation.organisation_id === organisation_id) {
          var organisationParams = {
            usersConfiguration: isDefVal(action, 'data.usersConfiguration')
          }
          yield(put({type: 'PORTAL_ORGANISATION_STATUS', status, organisationParams}))
        }
        break
    default:
      break
  }
}

// Update availability of catalog content

function* checkCatalogContent(action) {
  const list = isDefVal(action, 'data')
  const value = Array.isArray(list) && list.length>0
  switch(isDefVal(action, 'external_resource_infos.scheme_key')) {
    case "catalog_products":
      yield put({type: 'PORTAL_CATALOG_HAS_PRODUCTS', value})
      break
    case "catalog_apis":
      yield put({type: 'PORTAL_CATALOG_HAS_APIS', value})
      break
    case "catalog_consorgs":
      yield put({type: 'PORTAL_CATALOG_HAS_CONSORGS', value})
      break
    case "catalog_scopes":
      yield put({type: 'PORTAL_CATALOG_HAS_SCOPES', value})
      break
    // TODO show when ressource is OK
    // case "catalog_applications":
    //   yield put({type: 'PORTAL_CATALOG_HAS_APPLICATIONS', value})
    //   break
    default:
      break
  }
}

// Update availability of organisation content

function* checkOrganisationContent(action) {
  const list = isDefVal(action, 'data')
  const value = Array.isArray(list) && list.length>0
  switch(isDefVal(action, 'external_resource_infos.scheme_key')) {
    case "organisation_static_contents":
      yield put({type: 'PORTAL_ORGANISATION_HAS_STATIC_CONTENT', value})
      break
    default:
      break
  }
}
