import React from 'react'
import { connect } from '../../utils/redux'
import { logRender } from '../../utils/logging'
import { isDef, isDefVal, deepEqualObjs2 } from '../../utils/objtools'
import { withTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'
import { buildUri } from '../../utils/urls/internal'

class Page extends React.Component {

  debug(...args) {
    //console.log(...args)
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      !deepEqualObjs2(this.props.routeProps.location.pathname, nextProps.routeProps.location.pathname)
      || this.props.redux.catalog_uname !== nextProps.redux.catalog_uname
      || this.props.redux.lang_code !== nextProps.redux.lang_code
    ) {
      this.debug('xxxxxxxxxxxxxxxx')
      this.debug('x ', this.props.routeProps.location.pathname, nextProps.routeProps.location.pathname)
      this.debug('x ', this.props.redux.catalog_uname, nextProps.redux.catalog_uname)
      this.debug('x ', this.props.redux.lang_code, nextProps.redux.lang_code)
      return true
    }
    return false
  }

  componentDidMount() {
    window.scrollTo(0,0)
    this.postRender()
  }

  componentDidUpdate() {
    this.postRender()
  }

  postRender() {
    this.debug('POST')

    // If language in the route does not match the i18n language, change it
    var lang_code = isDefVal(this.props, 'routeProps.match.params.lang_code')
    if (lang_code && lang_code !== this.props.i18n.language) {
      this.debug('Changing i18n language to match the route : '+lang_code)
      this.props.i18n.changeLanguage(lang_code)
    }

    // Update redux store lang_code if necessary
    // We track the lang in the redux store beacause i18n language change cannot be tracked in shouldComponentUpdate (language is the same in props and nextProps (bug))
    if (this.props.redux.lang_code !== this.props.i18n.language) {
      this.debug('Dispatching new lang_code into lang.lang_code : '+this.props.i18n.language)
      this.props.dispatchLang(this.props.i18n.language)
    }

    // Dispatch the displayed page if all props are OK
    if (lang_code && lang_code === this.props.i18n.language && this.props.redux.lang_code === this.props.i18n.language) {
      if (this.props.redux.location && this.props.redux.location.key === this.props.routeProps.location.key) {
        this.debug('DISPATCH PAGE already done')
      }
      else {
        this.debug('DISPATCH PAGE '+this.props.routeProps.location.pathname)
        this.props.dispatchPage(this.props.scheme, this.props.routeProps)
      }
    }
  }

  render() {
    logRender(this)
    this.debug('RENDER')

    // Si la route ne contient pas de langue, redirection avec une langue dans la route
    var lang_code = isDefVal(this.props, 'routeProps.match.params.lang_code')
    if (!lang_code) {
      this.debug('No language in route, redirect')
      return <Redirect to={'/'+this.props.i18n.language+this.props.routeProps.location.pathname+this.props.routeProps.location.search} />
    }
    // Bypass rendering due to lang incoherence
    else if ((lang_code !== this.props.i18n.language) || !this.props.redux.lang_code || this.props.redux.lang_code !== this.props.i18n.language) {
      this.debug('RENDER CANCELED due to lang incoherence')
      return null
    }

    // Check Catalog Name in Route
    var route_catalog_uname = isDefVal(this.props, 'routeProps.match.params.catalog_uname')
    var ids, uri, redirect_to
    // We have a current catalog defined but the route does not show it
    if (!route_catalog_uname && this.props.redux.catalog_uname) {
      ids = {
        lang_code: this.props.redux.lang_code,
        catalog_uname: this.props.redux.catalog_uname
      }
      uri = buildUri('catalog', ids) + this.props.routeProps.location.search
      this.debug('Redirect with catalog_uname in route: '+uri)
      return <Redirect to={uri} />
    }
    // Catalog défini et une route contenant un catalog différent
    // On ne redirige que si le catalog_uname est signalé comme erroné (sinon on redirigerait à tort aussi sur les tentatives de switch)
    else if (route_catalog_uname && this.props.redux.catalog_uname && this.props.redux.catalog_uname_wrong && route_catalog_uname === this.props.redux.catalog_uname_wrong && route_catalog_uname !== this.props.redux.catalog_uname) {
      if (this.props.scheme.key.substring(0,8) !== 'notfound') {
        // Sur une route existante on peut reconstruire la route
        ids = this.props.routeProps.match.params
        ids.catalog_uname = this.props.redux.catalog_uname
        uri = buildUri(this.props.scheme.key, ids) + this.props.routeProps.location.search
        this.debug('Redirecting to route with catalog: '+uri)
        return <Redirect to={uri} />
      }
      else {
        // Sur une notfound on doit faire un replace pour garder la partie dumb
        uri = this.props.routeProps.location.pathname.replace('/'+this.props.routeProps.match.params.catalog_uname+'/', '/'+this.props.redux.catalog_uname+'/') + this.props.routeProps.location.search
        this.debug('Redirect notfound route with catalog: '+uri)
        return <Redirect to={uri} />
      }
    }
    // The current route require a redirect
    else if ((redirect_to = isDefVal(this.props.scheme, 'redirect_to'))) {
      ids = {...this.props.routeProps.match.params, ...redirect_to.ids}
      uri = buildUri(redirect_to.scheme_key, ids) + this.props.routeProps.location.search
      this.debug('Scheme require redirect to : '+uri)
      return <Redirect to={uri} />
    }
    // Bypass rendering due to catalog readiness
    if (!this.props.redux.catalog_uname) {
      this.debug('RENDER CANCELED due to catalog readiness')
      return null
    }

    this.debug('RENDER REAL')

    // Page content
    var content = 'No Content';
    if (isDef(this.props, 'scheme.component')) {
      var Component = this.props.scheme.component
      //content = <Component key={this.props.routeProps.location.key} page={{scheme:this.props.scheme, routeProps:this.props.routeProps}} />
      // 2020 02 03 no key to allow component decide if it needs rerender when it handles multiples locations (tabs, etc.)
      content = <Component page={{scheme:this.props.scheme, routeProps:this.props.routeProps}} />
    }
    return (
      <div className="page">
        {content}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    redux: {
      lang_code: state.lang.lang_code,
      catalog_uname: isDefVal(state, 'portal.context.catalog.name'),
      catalog_uname_wrong: isDefVal(state, 'portal.context.catalog_uname_wrong'),
      location: isDefVal(state, 'portal.page.routeProps.location')
    }
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchPage: (scheme, routeProps) => dispatch({type:'DISPLAY_PAGE', scheme, routeProps}),
  dispatchLang: (lang_code) => dispatch({type:'LANG_SET_LANG_CODE', lang_code})
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  ['lang','portal.context','portal.page']
)(withTranslation()(Page));
