import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button'
import Icon from '../../icon/Icon'

class UiButton extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    icon: PropTypes.string
  };

  render() {
    const {
      props: {
        onClick,
        children,
        icon,
        variant
      },
    } = this;

    var rendered_icon = null
    if (icon) {
      if (variant === 'secondary')
        rendered_icon = <Icon type={icon} />
      else
        rendered_icon = <Icon type={'white_'+icon} />
    }

    return (
      <Button
        {...this.props}
        className={this.getItemClasses().join(' ')}
        onClick={onClick}
      >
        {rendered_icon}
        {children}
      </Button>
    );
  }

  getItemClasses() {
    var classes = [
      "ui-button"
    ]
    return classes;
  }
}

export default UiButton;