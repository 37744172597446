import React from 'react'
import { logRender } from '../../utils/logging'
import { getFirstDef } from '../../utils/objtools'
import { Form as FinalForm} from 'react-final-form'
import { Form } from 'react-bootstrap'
import UiButton from '../ui/buttons/UiButton'
import Loader from '../loader/Loader'
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import Visibility from "../../utils/debug/Visibility";
import AnimDelay from "../ui/AnimDelay/AnimDelay";
import branding from "../portal/branding";

class ApplicationDeleteFinalForm extends React.Component {

  constructor(props) {
    super(props)

    this.validateForm = this.validateForm.bind(this)
  }

  validateForm = (values) => {
    const errors = {}


    return errors
  }

  render() {
    logRender(this)


    if (['new','edit'].indexOf(getFirstDef(this.props, 'form_op', '')) < 0 ) {
      console.error('form_op is not defined or not handled')
      return null
    }

    const t = this.props.t

    return (
      <FinalForm
        initialValues={this.props.initialValues}
        onSubmit={this.props.onSubmit}
        validate={this.validateForm}
      >
        {({ handleSubmit, pristine, submitting }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Visibility condition={submitting && !branding.open}>
                <AnimDelay animateAppearance={true} renderDelay={2500}>
                  <div className="bored_motion">
                    {/*background animation here */}
                  </div>
                  <p className="bored_description">{t('subscriptionplans:bored_description')}</p>
                </AnimDelay>
              </Visibility>

              <div className="form-actions">
                <Loader loading={submitting} />
                <UiButton variant="primary" type="submit" disabled={(pristine && this.props.form_op==='edit') || submitting}>{t('common:delete')}</UiButton>
              </div>
            </Form>
          )
        }}
      </FinalForm>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    lang_code: state.lang.lang_code,
  }

  return {
    redux
  }
}

export default connect(
  mapStateToProps,
  null
)(withTranslation(
  ['forms','common','application', 'subscriptionplans']
)(ApplicationDeleteFinalForm))


