import React from 'react'
import { connect } from '../../utils/redux'
import { logRender } from '../../utils/logging'
import { isDef, isDefVal } from '../../utils/objtools'
import Icon from '../icon/Icon'

class OrganisationTeaser extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      JSON.stringify(this.props.redux) !== JSON.stringify(nextProps.redux)
    )
  }

  render() {
    logRender(this)
    var organisation
    if (!(organisation = isDefVal(this.props.redux, 'organisation')))
      return null
    var content = <div className="content">
        <Icon type="organisation" />
        <div className="type">ORGANISATION</div>
        <div className="title">{organisation.title}</div>
        <div className="description">{organisation.description}</div>
      </div>
    if (this.props.with_link)
      content = <a target="_blank" rel="noopener noreferrer" href={organisation.portal_url}>{content}</a>
    return (
      <div className={this.getItemClasses().join(' ')} >
        {content}
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'organisation',
      'teaser'
    ]
    if (this.props.can_change)
      classes.push('can_change')
    return classes;
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {}
  if (isDef(ownProps, 'organisation_id')) {
    if (isDef(state.portal, 'context.organisations.'+ownProps.organisation_id)) {
      redux.organisation = state.portal.context.organisations[ownProps.organisation_id]
    }
  }
  return {
    redux
  }
}

export default connect(
  mapStateToProps,
  null,
  'portal'
)(OrganisationTeaser)
