import React from 'react'
import { Form } from 'react-bootstrap'
import DOMpurify from 'dompurify'

const ControlCommentTextarea = ({ input, meta, placeholder, rows }) => {
  // TODO NTH handle the warning case if needed (not handled in bootstrap ?)
  // console.log("touched :", meta.touched,
  //   "\nerror :",meta.error,
  //   "\nwarn :", meta.warn,
  //   "\nsubmitError :",meta.submitError,
  //   "\ndirtySinceLastSubmit :", meta.dirtySinceLastSubmit)

    const escapedValue = DOMpurify.sanitize(input.value)

  return (
    <div>
      <Form.Control as="textarea"
        {...input}
        rows={rows}
        placeholder={placeholder}
        value={escapedValue}
        isInvalid={
          (meta.touched && (meta.error || meta.warn) && meta.active)
          || (meta.submitError && !meta.dirtySinceLastSubmit)
        }
      />

      {meta.touched &&
        ((meta.error && <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>) ||
         (meta.warning && <Form.Control.Feedback type="invalid">{meta.warning}</Form.Control.Feedback>))}

      {meta.submitError && !meta.dirtySinceLastSubmit ? <Form.Control.Feedback type="invalid">{meta.submitError}</Form.Control.Feedback> : null}

    </div>
  )
}

export default ControlCommentTextarea
