import React from 'react'
import {connect} from 'react-redux'
import {logRender} from '../../utils/logging'
import {withTranslation} from 'react-i18next'
import PeriodicalPool from "../perdiodical_pool/PeriodicalPool"
import Filter from "../filter/Filter"
import {mapResourceFromCode} from "../resource/resource_selectors";
import {isDef} from "../../utils/objtools";
import {buildExternalResourceUri} from "../../utils/urls/external";
import UiButton from "../ui/buttons/UiButton";
import Visibility from "../../utils/debug/Visibility";
import branding from "../portal/branding";


class ApplicationAnalytics extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      selectedPeriod: null,
      periods: []
    }
    this.changeFilter = this.changeFilter.bind(this)
  }

  static getDerivedStateFromProps(props, state) {

    var analytics_periods = [
      {
        label: "30 seconds",
        query: "30_seconds",
        default: false
      },
      {
        label: "1 min",
        query: "1_min",
        default: false
      },
      {
        label: "30 mins",
        query: "30_mins",
        default: false
      },
      {
        label: "1 hour",
        query: "1_hr",
        default: false
      },
      {
        label: "1 day",
        query: "1_day",
        default: true
      },
      {
        label: "7 days",
        query: "7_days",
        default: false
      },
      {
        label: "30 days",
        query: "30_days",
        default: false
      }
    ]
    var defaultPeriod = "1_day"

    return {
      selectedPeriod: state.selectedPeriod ? state.selectedPeriod : defaultPeriod,
      periods: analytics_periods
    }

  }

  changeFilter(type) {

    switch (type) {
      case '30_seconds':
        this.setState({
          selectedPeriod: "30_seconds"
        })
        break
      case '1_min':
        this.setState({
          selectedPeriod: "1_min"
        });
        break
      case '30_mins':
        this.setState({
          selectedPeriod: "30_mins"
        });
        break
      case '1_hr':
        this.setState({
          selectedPeriod: "1_hr"
        });
        break
      case '1_day':
        this.setState({
          selectedPeriod: "1_day"
        });
        break
      case '7_days':
        this.setState({
          selectedPeriod: "7_days"
        });
        break
      case '30_days':
        this.setState({
          selectedPeriod: "30_days"
        });
        break
      default:
        console.log("the type is not valid")
    }

  }


  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    var periods = []

    for (let period of this.state.periods) {
      periods.push(<Filter key={'period_' + period.query} type={period.query} label={period.label}
                           isActive={period.query === this.state.selectedPeriod} clickHandler={this.changeFilter}/>)
    }

    var elastic_kibana = (<UiButton variant="danger" type="button" icon="elastic" onClick={()=>window.open(this.props.redux.elasticsearch_application_url, "_blank")}>Go Elastic Kibana</UiButton>)


    var periodical_pool
    if(isDef(this.props.redux.application.ids,'organisation_id')
      && isDef(this.props.redux.application.ids,'catalog_id')
      && isDef(this.props.redux.application.ids,'consorg_id')
      && isDef(this.props.redux.application.ids,'application_id'))
      periodical_pool = (<PeriodicalPool query={this.state.selectedPeriod} ids={this.props.redux.application.ids}/>)

    return (
      <div className={this.getItemClasses().join(' ')}>
        <div className="grid g1_root g1_1col">
          <h2>{this.props.t('application_analytics:title')}</h2>
          <div className="graph">
            <h3>{this.props.t('application_analytics:graph_sub_title')}</h3>
            <div className="periods">
              <p>{this.props.t('application_analytics:graph_description')}</p>
              <div className="item_full_presentation">
                <div className="presentation_content gridg g1_2t1t_2_1">
                  <div>
                    {periods}
                  </div>
                  <div className="actions">
                    <Visibility condition={branding.kind === "int"}>
                      {elastic_kibana}
                    </Visibility>
                  </div>
                </div>
              </div>
            </div>
            {periodical_pool}
          </div>
        </div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'application',
      'analytics'
    ]
    return classes
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    lang_code: state.lang.lang_code,
    application: mapResourceFromCode(state, 'application', ownProps.resourceCode)
  }

  if(isDef(redux.application.ids,'application_name'))
  redux.elasticsearch_application_url = buildExternalResourceUri("elasticsearch_application_url", {...redux.application.ids, client_app_name: ':client_app_name', phrase: ':phrase', organization: ':organization', catalog:':catalog'})

  return {
    redux
  }
}

const mapDispatchToProps = dispatch => ({
  requestResource: (external_resource_infos) => dispatch({type: 'RESOURCE_LOAD_REQUEST', external_resource_infos})
})

ApplicationAnalytics = withTranslation(['common', 'application_analytics'])(ApplicationAnalytics)

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationAnalytics)


