import { submitConsorgFormNewRoutine } from './consorg_routines'
import { put, select, call, delay } from 'redux-saga/effects'
import { getPortalCurrentOrganisationAndCatalogIds } from '../portal/portal_selectors'
import { getFirstDef } from '../../utils/objtools'
import { newItem } from '../item/item_new_saga.js'
import genericFormSubmissionSaga from '../item/genericFormSubmissionSaga'

function* saga(action) {
  const values = {...action.payload}

  var body = {
    "name": values.name,
    "title": values.title,
    "description": getFirstDef(values, 'description', '')
  }

  // prepare some ids
  var ids = yield select(getPortalCurrentOrganisationAndCatalogIds)

  // Create the consorg
  var {error, data} = yield call(newItem, 'consorg_new', 'consorg', ids, body)

  // Generic unhandled error
  if (error)
    throw error

  // TODO handle specific erros (duplicate name)
  // var submission_error = {name: 'The consumer organization name already exists in the catalog'}
  // return submission_error

  // Check created
  if (!data.name)
    throw new Error('No name found in the newly created consumer organization.')

  // extend ids
  ids = {
    ...ids,
    consorg_uname: data.name,
    consorg_id: data.id,
    tab_code: 'overview'
  }

  // go to the newly created consorg
  yield delay(500) // A tiny tempo to hide flashing "Not Found"
  yield put({type: 'HISTORY_PUSH', scheme_key:'consorg', ids})
}

export function* submitNewConsorgForm(action) {
  yield call(genericFormSubmissionSaga, {
    saga,
    action,
    routine: submitConsorgFormNewRoutine,
    successfull_title: 'consorg:successfull_create_title',
    successfull_body: 'consorg:successfull_create_body',
    unsuccessfull_title: 'consorg:unsuccessfull_create_title',
    unsuccessfull_body: 'consorg:unsuccessfull_create_body'
  })
}