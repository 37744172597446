import React from 'react'
import { connect } from 'react-redux'
import { logRender } from '../../utils/logging'
import { getFirstDef } from '../../utils/objtools'
import { Form as FinalForm, Field as FinalField} from 'react-final-form'
import { withTranslation } from 'react-i18next'
import { Form } from 'react-bootstrap'
import UiButton from '../ui/buttons/UiButton'
import ControlInput from '../../utils/forms/ControlInput'
import ControlTextarea from '../../utils/forms/ControlTextarea'
// import ControlSelect from '../../utils/forms/ControlSelect'
// import { resourceVisibility_options } from '../../utils/enums/resourceVisibility_options'
// import { membershipVisibility_options } from '../../utils/enums/membershipVisibility_options'
// import { allowMembershipRequest_options } from '../../utils/enums/allowMembershipRequest_options'
// import { translate_enumeration } from '../../utils/enums/enums_tools'
import { /*check_incompatibilities,*/ buildMachineName, machineNameRegExp } from '../../utils/forms/forms_tools'
import Loader from '../loader/Loader'
import createDecorator from 'final-form-calculate'

const calculateFields = createDecorator(
  {
    field: 'title',
    updates: {
      name: (titleValue, allValues, prevValues) => {
        var previousVirtualName = buildMachineName(getFirstDef(prevValues, 'title', ''))
        var newName = buildMachineName(titleValue)
        if (previousVirtualName !== newName) {
          if (getFirstDef(allValues, 'name', '') === previousVirtualName || getFirstDef(allValues, 'name', '') === '') {
            return newName
          }
        }
        return allValues.name
      }
    }
  }
)

class ConsorgFinalForm extends React.Component {

  constructor(props) {
    super(props)

    this.validateForm = this.validateForm.bind(this)
  }

  validateForm(values) {
    const errors = {}

    if (!values.title) {
      errors.title = this.props.t('forms:error_required')
    }
    else if (values.title !== values.title.trim()) {
      errors.title = this.props.t('forms:error_no_begin_or_end_space')
    }

    if (!values.name) {
      errors.name = this.props.t('forms:error_required')
    }
    else if (!values.name.match(machineNameRegExp)) {
      errors.name = this.props.t('forms:error_technical_name_pattern')
    }

    if (values.description && values.description !== values.description.trim()) {
      errors.description = this.props.t('forms:error_no_begin_or_end_space')
    }

    // var incompatibilities = {
    //   resourceVisibility: {
    //     logged_users: {
    //       membershipVisibility: {
    //         values: ['everyone'],
    //         error_message: "Visibility cannot be more restrictive than the members visibility."
    //       }
    //     },
    //     private_to_members: {
    //       membershipVisibility: {
    //         values: ['everyone','logged_users'],
    //         error_message: "Resource visibility cannot be more restrictive than the members visibility."
    //       },
    //       allowMembershipRequest: {
    //         values: ['logged_users'],
    //         error_message: "Resource visibility cannot be more restrictive than the request for membership."
    //       }
    //     }
    //   },
    //   membershipVisibility: {
    //     everyone: {
    //       resourceVisibility: {
    //         values: ['logged_users','private_to_members'],
    //         error_message: "Members visibility cannot be less restrictive than the resource visibility."
    //       }
    //     },
    //     logged_users: {
    //       resourceVisibility: {
    //         values: ['private_to_members'],
    //         error_message: "Members visibility cannot be less restrictive than the resource visibility."
    //       }
    //     }
    //   },
    //   allowMembershipRequest: {
    //     logged_users: {
    //       resourceVisibility: {
    //         values: ['private_to_members'],
    //         error_message: "Request for membership cannot be less restrictive than the resource visibility."
    //       }
    //     }
    //   }
    // }
    // check_incompatibilities(incompatibilities, values, errors)

    return errors
  }

  render() {
    logRender(this)

    if (!this.props.tReady)
      return null

    if (['new','edit'].indexOf(getFirstDef(this.props, 'form_op', '')) < 0 ) {
      console.error('form_op is not defined or not handled')
      return null
    }

    const t = this.props.t

    // var resourceVisibility_options_translated = translate_enumeration(resourceVisibility_options, t)
    // var membershipVisibility_options_translated = translate_enumeration(membershipVisibility_options, t)
    // var allowMembershipRequest_options_translated = translate_enumeration(allowMembershipRequest_options, t)

    return (
      <FinalForm
        initialValues={this.props.initialValues}
        onSubmit={this.props.onSubmit}
        validate={this.validateForm}
        decorators={[calculateFields]}
      >
        {({ handleSubmit, pristine, form, submitting }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="title">
                <Form.Label>{t('forms:field_title')}</Form.Label>
                <FinalField name="title" type='text' component={ControlInput} placeholder={t('forms:field_title')} />
                <Form.Text className="text-muted">{t('forms:field_title_desc')}</Form.Text>
              </Form.Group>
              <Form.Group controlId="name">
                <Form.Label>{t('forms:field_name')}</Form.Label>
                <FinalField name="name" type='text' component={ControlInput} placeholder={t('forms:field_name')} />
                <Form.Text className="text-muted">{t('forms:field_name_desc')}</Form.Text>
              </Form.Group>
              <Form.Group controlId="description">
                <Form.Label>{t('forms:field_description')}</Form.Label>
                <FinalField name="description" type='text' component={ControlTextarea} placeholder="" rows="5" />
                <Form.Text className="text-muted">{t('forms:field_description_desc')}</Form.Text>
              </Form.Group>
              {/* <fieldset>
                <legend>{t('consorg:fieldset_visibility')}</legend>
                <Form.Group controlId="resourceVisibility">
                  <Form.Label>{t('consorg:field_resourceVisibility')}</Form.Label>
                  <FinalField name="resourceVisibility" component={ControlSelect} options={resourceVisibility_options_translated} />
                  <Form.Text className="text-muted">{t('consorg:field_resourceVisibility_desc')}</Form.Text>
                </Form.Group>
                <Form.Group controlId="membershipVisibility">
                  <Form.Label>{t('consorg:field_membershipVisibility')}</Form.Label>
                  <FinalField name="membershipVisibility" component={ControlSelect} options={membershipVisibility_options_translated} />
                  <Form.Text className="text-muted">{t('consorg:field_membershipVisibility_desc')}</Form.Text>
                </Form.Group>
              </fieldset>
              <fieldset>
                <legend>{t('consorg:fieldset_functionalities')}</legend>
                <Form.Group controlId="allowMembershipRequest">
                  <Form.Label>{t('consorg:field_allowMembershipRequest')}</Form.Label>
                  <FinalField name="allowMembershipRequest" component={ControlSelect} options={allowMembershipRequest_options_translated} />
                  <Form.Text className="text-muted">{t('consorg:field_allowMembershipRequest_desc')}</Form.Text>
                </Form.Group>
              </fieldset> */}
              <div className="form-actions">
                <UiButton variant="primary" type="submit" disabled={pristine||submitting}>{t(this.props.form_op==='new'?'forms:submit_create':'forms:submit_update')}</UiButton>
                <UiButton variant="secondary" type="button" disabled={pristine||submitting} onClick={form.reset}>{t('forms:reset')}</UiButton>
                <Loader loading={submitting} />
              </div>
            </Form>
          )
        }}
      </FinalForm>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    redux: {
      //lang_code: state.lang.lang_code
    }
  }
}

export default
  connect(
    mapStateToProps,
    null
  )(withTranslation(
    ['forms','consorg'/*,'enums'*/]
  )(ConsorgFinalForm))
