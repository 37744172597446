import React from 'react'
import Expandable from '../../ui/Expandable/Expandable'
import i18n from '../../../utils/lang/i18n'


const SecretModal = ({ closeAction, title, message, client_id, secret }) => {
  return (
    <div className="modal-content">
      <div className="modal-header">
        <h4 className="modal-title">{title}</h4>
        <button type="button" className="close" aria-label="Close" onClick={closeAction}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <p>{message}</p>
        <h4>{i18n.t('credential:client_id')}</h4>
        <p>{client_id}</p>
        <h4>{i18n.t('credential:client_secret')}</h4>
        <p><button type="button" className="btn btn-secondary" onClick={()=>copyClipBoard(secret)}>{i18n.t('credential:secret_modal_copy_clipboard')}</button></p>
        <Expandable title={i18n.t('credential:secret_modal_show')}>
          <div className="standard_content">
            <p>{secret}</p>
          </div>
        </Expandable>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-primary" onClick={closeAction}>{i18n.t('common:close')}</button>
      </div>
    </div>
  )

  function copyClipBoard(value) {
    var tempInput = document.createElement("input");
    tempInput.style = "position: absolute; left: -10000px; top: -10000px";
    tempInput.value = value;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
  }
}

export default SecretModal
