
import { submitConsorgFormEditRoutine } from './consorg_routines'
import { put, select, call } from 'redux-saga/effects'
import { getPortalCurrentOrganisationAndCatalogIds } from '../portal/portal_selectors'
import { getFirstDef } from '../../utils/objtools'
import { editItem } from '../item/item_edit_saga.js'
import genericFormSubmissionSaga from '../item/genericFormSubmissionSaga'

function* saga(action) {
  const values = {...action.payload}

  var body = {
    "name": values.name,
    "title": values.title,
    "description": getFirstDef(values, 'description', '')
  }

  // prepare some ids
  var ids = yield select(getPortalCurrentOrganisationAndCatalogIds)
  ids = {
    ...ids,
    consorg_id: values.consorg_id
  }

  // Update the consorg
  const {error, data} = yield call(editItem, 'consorg_edit', 'consorg', ids, body)

  // Generic unhandled error
  if (error)
    throw error

  // TODO handle specific erros (duplicate name)
  // var submission_error = {name: 'The consumer organization name already exists in the catalog'}
  // return submission_error

  // Check edited
  if (!data.id || data.id !== ids.consorg_id)
    throw new Error('Missing or wrong id in the edited consumer organization.')

  // extend ids
  ids = {
    ...ids,
    consorg_uname: data.name,
    tab_code: 'overview'
  }

  // go to the updated consorg
  yield put({type: 'HISTORY_PUSH', scheme_key:'consorg', ids})
}

export function* submitEditConsorgForm(action) {
  yield call(genericFormSubmissionSaga, {
    saga,
    action,
    routine: submitConsorgFormEditRoutine,
    successfull_title: 'consorg:successfull_edit_title',
    successfull_body: 'consorg:successfull_edit_body',
    unsuccessfull_title: 'consorg:unsuccessfull_edit_title',
    unsuccessfull_body: 'consorg:unsuccessfull_edit_body'
  })
}