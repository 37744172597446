import {copyObj, isDef, propertyCopy} from "../../utils/objtools"
import { saveLocalStorage, loadLocalStorage } from '../../utils/localstorage'

const redCart = (
  state = {
    selectedScopes: {},
    scopeCount: 0,
    consolidatedContext: null,
    catalog_id: null
  },
  action
) => {
  var new_state = null
  switch (action.type) {

    // Add a scope/claim to cart
    case 'CART_SCOPES_ADD':
      new_state = copyObj(state)
      propertyCopy(new_state, 'selectedScopes')
      new_state.selectedScopes[action.name] = {
        name: action.name,
        scopeClaim: action.scopeClaim,
        context: action.context
      }
      new_state.scopeCount = Object.keys(new_state.selectedScopes).length
      new_state.consolidatedContext = consolidateContext(new_state)
      saveLocalStorage('sparky_consumer_last_cart', new_state)
      break

    // Change a scope's claim in the cart
    case 'CART_SCOPES_EDIT':
      if (isDef(state.selectedScopes, action.name)) {
        new_state = copyObj(state)
        propertyCopy(new_state, 'selectedScopes')
        new_state.selectedScopes[action.name] = {
          name: action.name,
          scopeClaim: action.scopeClaim,
          context: action.context
        }
        new_state.scopeCount = Object.keys(new_state.selectedScopes).length
        new_state.consolidatedContext = consolidateContext(new_state)
        saveLocalStorage('sparky_consumer_last_cart', new_state)
      }
      break

    // Remove scope from cart
    case 'CART_SCOPES_REMOVE':
      if (isDef(state.selectedScopes, action.name)) {
        new_state = copyObj(state)
        propertyCopy(new_state, 'selectedScopes')
        delete new_state.selectedScopes[action.name]
        new_state.scopeCount = Object.keys(new_state.selectedScopes).length
        new_state.consolidatedContext = consolidateContext(new_state)
        saveLocalStorage('sparky_consumer_last_cart', new_state)
      }
      break

    // Flush the cart
    case 'CART_SCOPES_FLUSH':
      new_state = copyObj(state)
      new_state.selectedScopes = {}
      new_state.scopeCount = 0
      new_state.consolidatedContext = null
      saveLocalStorage('sparky_consumer_last_cart', new_state)
      break

    // Flush the cart when starting a catalog change
    case 'PORTAL_CHANGE_CATALOG_START':
      new_state = {
        selectedScopes: {},
        scopeCount: 0,
        consolidatedContext: null,
        catalog_id: action.catalog_id
      }
      break

    // Try to retore the cart from local storage
    // TODO à basculer plus tard sur PORTAL_CATALOG_HAS_SCOPES pour valider que les scopes restaurés sont bien présents dans le catalog
    case 'PORTAL_CATALOG_STATUS':
      new_state = {
        selectedScopes: {},
        scopeCount: 0,
        consolidatedContext: null,
        catalog_id: state.catalog_id
      }
      if (action.status === 'success') {
        var last_cart = loadLocalStorage('sparky_consumer_last_cart')
        if (last_cart && last_cart.catalog_id === action.catalog_id) {
          new_state = last_cart
        }
      }
      saveLocalStorage('sparky_consumer_last_cart', new_state)
      break

    default:
      break

  }
  if (new_state) {
    return new_state
  }
  else {
    return state
  }
}

export default redCart

/**
 * Consolidate cart context
 */
function consolidateContext(state) {
  if (state.scopeCount === 0) {
    return null
  }
  const context = {
    apiDefinitionResourceCodes: [],
    productDefinitionResourceCodes: [],
    productResourceCodes: [],
    api_ids: [],
  }
  for (var name in state.selectedScopes) {
    context.apiDefinitionResourceCodes = [...context.apiDefinitionResourceCodes, ...state.selectedScopes[name].context.apiDefinitionResourceCodes]
    context.apiDefinitionResourceCodes = [...new Set(context.apiDefinitionResourceCodes)]
    context.productDefinitionResourceCodes = [...context.productDefinitionResourceCodes, ...state.selectedScopes[name].context.productDefinitionResourceCodes]
    context.productDefinitionResourceCodes = [...new Set(context.productDefinitionResourceCodes)]
    context.productResourceCodes = [...context.productResourceCodes, ...state.selectedScopes[name].context.productResourceCodes]
    context.productResourceCodes = [...new Set(context.productResourceCodes)]
    context.api_ids = [...context.api_ids, ...state.selectedScopes[name].context.api_ids]
    context.api_ids = [...new Set(context.api_ids)]
  }
  return context
}
