import React from 'react';
import { logRender } from '../../utils/logging';
import Toast from 'react-bootstrap/Toast'
import { dateFormatFromTS } from '../../utils/date'

class Notification extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      phase: props.show?'pre-show':'hidden' // en pratique on va toujours init avec true...
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show && !this.props.show) {
      this.setState({phase: 'pre-hide'})
    }
  }

  render() {
    logRender(this)
    var wrapper_classes = ['toast_wrapper','type_'+this.props.message_type]
    var wrapper_style={}
    // console.log(this.state)
    var show = this.state.phase === 'show' || this.state.phase === 'pre-hide'
    switch(this.state.phase) {
      case 'pre-show':
        setTimeout(() => {
          this.setState({phase: 'show'})
          this.forceUpdate()
        }, 10)
        // prepare autoclose if needed
        if(this.props.autoclose_ms > 0) {
          setTimeout(() => {
            this.props.onClose(this.props.message_id)
          }, this.props.autoclose_ms)
        }
        break
      case 'pre-hide':
        wrapper_style.height = this.containerRef.clientHeight+'px'
        setTimeout(() => {
          this.setState({phase: 'hidden'})
          this.forceUpdate()
        }, 10)
        break
      case 'hidden':
        wrapper_style.height = '0px'
        break
      default:
        break
    }
    return (
      <div className={wrapper_classes.join(' ')} style={wrapper_style} ref={element => this.containerRef = element}>
        <div className="toast_inner_wrapper">
          <Toast show={show} onClose={() => this.props.onClose(this.props.message_id)} >
            <Toast.Header>
              <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
              <strong className="mr-auto">{this.props.title}</strong>
              <small>{dateFormatFromTS(this.props.date)}</small>
            </Toast.Header>
            <Toast.Body>{this.props.body}</Toast.Body>
          </Toast>
        </div>
      </div>
    )
  }
}

export default Notification
