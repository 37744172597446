import { isDef, copyObj, propertyCopy } from '../../utils/objtools'

const redNotifications = (
  state = {
    last_message_id: 0,
    messages: {}
  },
  action
) => {
  var new_state = null
  switch (action.type) {

    case 'NOTIFICATION_ADD':
      new_state = copyObj(state)
      propertyCopy(new_state, 'messages')
      new_state.last_message_id++
      var message_id = new_state.last_message_id
      new_state.messages[message_id] = {
        message_type: action.message_type,
        title: action.title,
        date: action.date,
        body: action.body,
        autoclose_ms: action.autoclose_ms,
        displayed_date: 0
      }
      // TODO Prune old messages
      break

    case 'NOTIFICATION_DISPLAYED':
      if (isDef(state.messages, action.message_id)) {
        new_state = copyObj(state)
        propertyCopy(new_state, ['messages', action.message_id])
        new_state.messages[action.message_id].displayed_date = Date.now()
      }
      break

    // case 'CREDS_LOGOUT':
    // case 'DATA_FLUSH':
    //   return {
    //     last_message_id: state.last_message_id,
    //     messages: {}
    //   }

    default:
      break

  }
  if (new_state) {
    return new_state
  }
  else {
    return state
  }
}

export default redNotifications
