import React from 'react'
import { Form } from 'react-bootstrap'
import DOMpurify from 'dompurify'

const ControlSelect = ({ input, meta, options }) => {
    const escapedValue = DOMpurify.sanitize(input.value)
  // TODO NTH handle the warning case if needed (not handled in bootstrap ?)
  var options_items = []
  for (var option_key in options) {
    options_items.push(<option key={option_key} value={option_key}>{options[option_key]}</option>)
  }
  return (
    <div>
      <Form.Control as="select"
        {...input}
        value={escapedValue}
        isInvalid={
          (meta.touched && (meta.error || meta.warn))
          || (meta.submitError && !meta.dirtySinceLastSubmit)
        }
      >
        {options_items}
      </Form.Control>

      {meta.touched &&
        ((meta.error && <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>) ||
         (meta.warning && <Form.Control.Feedback type="invalid">{meta.warning}</Form.Control.Feedback>))}

      {meta.submitError && !meta.dirtySinceLastSubmit ? <Form.Control.Feedback type="invalid">{meta.submitError}</Form.Control.Feedback> : null}

    </div>
  )
}

export default ControlSelect
