export const membershipStates = {
  ACTIVE: 'active',
  PENDING: 'pending',
  DECLINED: 'declined',
  REQUESTED: 'requested',
  REFUSED: 'refused'
}

export const membershipStates_options = {
  [membershipStates.ACTIVE]:"enums:membershipState_option_active",
  [membershipStates.PENDING]:"enums:membershipState_option_pending",
  [membershipStates.DECLINED]:"enums:membershipState_option_declined",
  [membershipStates.REQUESTED]:"enums:membershipState_option_requested",
  [membershipStates.REFUSED]:"enums:membershipState_option_refused"
}
