import { put, takeEvery, select, call, delay } from 'redux-saga/effects'
import { notificationAddError } from '../notifications/notifications_actions'
import { getPortalOrganisationSparkyManagerEndpoint, getPortalCurrentOrganisationId, stateIsPortalDebug } from '../portal/portal_selectors'
import { stateFindResourceCodeByIds, stateFindResourceByCodeOrAlias } from '../resource/resource_selectors'
import i18n from '../../utils/lang/i18n'
import { getUrl } from '../../utils/fetch'
import { isDefVal, isDef, getFirstDef } from '../../utils/objtools'
import NamedError from '../../utils/error/NamedError'
import HttpStatusError from '../../utils/error/HttpStatusError'

const itemSagas = [
  takeEvery("ITEM_LOAD_REQUEST", loadItemRequest),
  takeEvery("DISPLAY_PAGE", fillLastSeenSingle)
]
export default itemSagas

// Get catalog lists

function* loadItemRequest(action) {
  var data = null
  var error = null
  var url = null
  var status = null
  try {
    var api_endpoint = null
    var organization_id = null
    switch(isDefVal(action.external_resource_infos, 'scheme.source')) {
      case "portal":
        api_endpoint = ''
        break
      default:
        api_endpoint = yield select(getPortalOrganisationSparkyManagerEndpoint)
        if (!api_endpoint)
          throw new Error("Could not get manager's api endpoint.")
        organization_id = yield select(getPortalCurrentOrganisationId)
        if (!organization_id)
          throw new Error("Could not get organization_id.")
        break
    }
    const force = isDefVal(action, 'force')===true
    var resource = yield select(stateFindResourceByCodeOrAlias, action.external_resource_infos.scheme.item_type, action.external_resource_infos.resource_code)
    if (!force && resource && (['loaded','loading'].indexOf(isDefVal(resource, 'status')) >= 0)) {
      //console.info('skipping ITEM_LOAD_REQUEST')
      return
    }
    yield put({type: 'ITEM_LOAD_START', external_resource_infos: action.external_resource_infos})
    url = api_endpoint + action.external_resource_infos.resource_uri
    const {fetch_response, fetch_error} = yield call(getUrl, url, organization_id)
    if (fetch_error) {
      throw fetch_error
    }
    status = fetch_response.status
    if (fetch_response.status !== 200) {
      throw new HttpStatusError(fetch_response.status)
    }
    // Content type is found from the resource at scheme level or from file extension at item level
    var expectedContentType = 'application/json'
    switch(isDefVal(action.external_resource_infos, 'ids.file_extension')) {
      case 'html':
        expectedContentType = 'text/html'
        break
      case 'json':
        expectedContentType = 'application/json'
        break
      default:
        expectedContentType = getFirstDef(action.external_resource_infos.scheme, 'defaultExpectedContentType', expectedContentType)
        break
    }
    switch(expectedContentType) {
      case 'application/json':
        data = yield fetch_response.json()
        for (var path of getFirstDef(action.external_resource_infos.scheme, 'expected_paths', [])) {
          if (!isDef(data, path)) {
            throw new Error('Missing content at path '+path)
          }
        }
        break
      case 'text/html':
        var text = yield fetch_response.text()
        if (text.substr(0,15)==='<!DOCTYPE html>') {
          // This is a temporary way to track 404 (TODO: nginx conf should not redirect to index.html in some folders like public/static_content)
          throw new NamedError("Could not access the resource item", "Content was not found")
        }
        data = {markup: text}
        break
      default:
        break
    }
  }
  catch (catched_error) {
    error = catched_error
  }
  if (error) {
    if (getFirstDef(action, 'external_resource_infos.scheme.notifyOnError', true)) {
      var body = i18n.t('common:item_load_error_body', {
        scheme_key: action.external_resource_infos.scheme_key,
        resource_uri: action.external_resource_infos.resource_uri
      })
      var title = i18n.t('common:item_load_error_title')
      yield put(notificationAddError({title, body, error}))
    }
    yield put({type: 'ITEM_LOAD_ERROR', external_resource_infos: action.external_resource_infos, error})
  }
  else if (data) {
    yield put({type: 'ITEM_LOAD_SUCCESS', external_resource_infos: action.external_resource_infos, data})
  }
  if (yield select(stateIsPortalDebug)) {
    yield put({type: 'LOG_EXTERNAL_QUERY', scheme_key: action.external_resource_infos.scheme_key, url, status, has_error: error?true:false, has_data: data?true:false})
  }
}

// Keep track of last seen single pages

function* fillLastSeenSingle(action) {
  switch(action.scheme.key) {
    case 'product':
    case 'api':
    case 'scope':
    case 'consorg':
    case 'application':
      yield delay(1000) // We wait because on direct access the resource may not yet exist and we are not in a hurry storing last seen single
      var resource_code = yield select(stateFindResourceCodeByIds, action.scheme.key, action.routeProps.match.params)
      if (resource_code)
        yield put({
          type: 'ITEM_SEEN_SINGLE',
          item_type: action.scheme.key,
          resource_code
        })
      break
    default:
      break
  }
}
