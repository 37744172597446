import React from 'react'
import {connect} from '../../utils/redux'
import {logRender} from '../../utils/logging'
import {withTranslation} from 'react-i18next'
import Manifesto from '../edito/manifesto/Manifesto'
import setDocumentTitle from '../../utils/browser/title'
import {deepEqualObjs2} from '../../utils/objtools'
import PushAbout from "../edito/push/PushAbout"
import PushStart from "../edito/push/PushStart"
import PushLevels from "../edito/push/PushLevels"
import PushProducts from "../edito/push/PushProducts"
import branding from "../portal/branding";
import WorkflowMain from "../edito/workflow/WorkflowMain";
import WorkflowFooter from "../edito/workflow/WorkflowFooter";
import PushSolutions from "../edito/push/PushSolutions";

class Home extends React.Component {

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || this.props.tReady !== nextProps.tReady
    )
  }

  componentDidMount() {
    setDocumentTitle('Home')
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    return (
      <div className="home">

        <Manifesto/>

        {branding.kind === 'baas' ? <PushProducts/> : <PushAbout/>}

        {branding.open &&
          <PushStart/>
        }

        {branding.open &&
          <PushLevels/>
        }

        {branding.open && branding.kind !== 'baas' &&
          <PushProducts/>
        }

        {!branding.open &&
          <WorkflowMain/>
        }

        {!branding.open &&
          <WorkflowFooter/>
        }

        {branding.kind === 'baas' &&
          <PushSolutions/>
        }

      </div>
    )
  }

  handleClick(target) {
    this.props.finderGo(target)
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    redux: {
      lang_code: state.lang.lang_code
    }
  }
}

const mapDispatchToProps = dispatch => ({
  finderGo: (target) => {
    dispatch({type: 'FINDER_GO', target})
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  'lang'
)(withTranslation(['home', 'application', 'product', 'api', 'consorg'])(Home))
