import React from 'react'
import { connect } from '../../utils/redux'
import { logRender } from '../../utils/logging'
import { withTranslation } from 'react-i18next'
import Icon from '../icon/Icon'
import { Link } from "react-router-dom"
import { buildUri } from '../../utils/urls/internal'
import { isDefVal } from '../../utils/objtools'
import AnimDelay from '../ui/AnimDelay/AnimDelay'
import { stateFindResourceByCodeOrAlias } from '../resource/resource_selectors'
import { stateGetSelectedConsorgResourceCode } from '../consorg/consorg_selectors'

class MiniTeaser extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      JSON.stringify(this.props.redux) !== JSON.stringify(nextProps.redux)
      || this.props.tReady !== nextProps.tReady
    )
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    var post_title = []
    if (isDefVal(this.props.redux.consolidated, 'version'))
      post_title.push(<div key="version" className="pill secondary">{this.props.redux.consolidated.version}</div>)

    var statePillFlag = this.props.redux.consolidated.state === 'deprecated' ? 'pill secondary yellow_flag' : this.props.redux.consolidated.state === 'published' ? 'pill secondary green_flag' : 'pill secondary'
    if (isDefVal(this.props.redux.consolidated, 'state'))
      post_title.push(<div key="state" className={statePillFlag}>{this.props.t('common:'+this.props.redux.consolidated.state)}</div>)

    var cartouche =
      <div className="cartouche">
        <div className="pre_title">
          <Icon type={this.props.item_type} />
          <div className="pill">{this.props.t(this.props.item_type+':badge')}</div>
        </div>
        <div className="title">{this.props.redux.consolidated.title}</div>
        <div className="post_title">
          {post_title}
        </div>
      </div>


    if (this.props.redux.uri) {
      cartouche = <Link to={this.props.redux.uri} id={this.props.e2e_key}>{cartouche}</Link>
    }

    return (
      <div className={this.getItemClasses().join(' ')} >
        <AnimDelay animateAppearance={true} >
          {cartouche}
        </AnimDelay>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'mini_teaser_c'
    ]
    classes.push(this.props.item_type)

    if(isDefVal(this.props.redux,'consolidated.state'))
    classes.push(this.props.redux.consolidated.state === 'deprecated' ? 'deprecated' : '')

    return classes;
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    lang_code: state.lang.lang_code,
    consolidated: {},
    selectedConsorgResourceCode: stateGetSelectedConsorgResourceCode(state)
  }
  var resource
  if ((resource = stateFindResourceByCodeOrAlias(state, ownProps.item_type, ownProps.resource_code))) {
    redux.consolidated = resource.consolidated
    redux.uri = buildUri(ownProps.item_type, {tab_code: 'overview', ...resource.ids, lang_code: redux.lang_code})
  }

  return {
    redux
  }
}

export default connect(
  mapStateToProps,
  null,
  ['lang','item.items']
)(withTranslation(['common','product','api','consorg','application','scope'])(MiniTeaser))
