import React from 'react'
import { connect } from 'react-redux'
import { logRender } from '../../utils/logging'
import { getFirstDef, isDefVal } from '../../utils/objtools'
import { Form as FinalForm, Field as FinalField} from 'react-final-form'
import { withTranslation } from 'react-i18next'
import { Form } from 'react-bootstrap'
import UiButton from '../ui/buttons/UiButton'
import ControlInput from '../../utils/forms/ControlInput'
import ControlTextarea from '../../utils/forms/ControlTextarea'
import ControlSelect from '../../utils/forms/ControlSelect'
import Loader from '../loader/Loader'
import { stateFindItemByCodeOrAlias } from '../item/item_selectors'
import { stateIsPortalDebug } from '../../components/portal/portal_selectors'


class IssueFinalForm extends React.Component {

  constructor(props) {
    super(props)

    this.validateForm = this.validateForm.bind(this)
  }

  validateForm = (values) => {
    const errors = {}

    if (!values.title) {
      errors.title = this.props.t('forms:error_required')
    }
    else if (values.title !== values.title.trim()) {
      errors.title = this.props.t('forms:error_no_begin_or_end_space')
    }

    if (!values.description) {
      errors.description = this.props.t('forms:error_required')
    }
    else if (values.description && values.description !== values.description.trim()) {
      errors.description = this.props.t('forms:error_no_begin_or_end_space')
    }


    return errors
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    // if (!this.props.redux.relationship_consorg_options_ready)
    //   return null

    if (['new','edit'].indexOf(getFirstDef(this.props, 'form_op', '')) < 0 ) {
      console.error('form_op is not defined or not handled')
      return null
    }

    const t = this.props.t

    return (
      <FinalForm
        initialValues={this.props.initialValues}
        onSubmit={this.props.onSubmit}
        validate={this.validateForm}
      >
        {({ handleSubmit, pristine, form, submitting }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="title">
                <Form.Label>{t('forms:field_title')}</Form.Label>
                <FinalField name="title" type='text' component={ControlInput} placeholder={t('forms:field_title')} />
                <Form.Text className="text-muted">{t('issue:field_title_desc')}</Form.Text>
              </Form.Group>
              <Form.Group controlId="description">
                <Form.Label>{t('forms:field_description')}</Form.Label>
                <FinalField name="description" type='text' component={ControlTextarea} placeholder="" rows="5" />
                <Form.Text className="text-muted">{t('forms:field_description_desc')}</Form.Text>
              </Form.Group>

              <fieldset>
                <legend>{t('issue:fieldset_relationship')}</legend>
                <Form.Group controlId="relationship_consorg">
                  <Form.Label>{t('issue:field_relationship_consorg')}</Form.Label>
                  <FinalField name="relationship_consorg" component={ControlSelect} options={this.props.redux.relationship_consorg_options} />
                  <Form.Text className="text-muted">{t('issue:field_relationship_consorg_desc')}</Form.Text>
                </Form.Group>
              </fieldset>

              <div className="form-actions">
                <UiButton variant="primary" type="submit" disabled={pristine||submitting}>{t(this.props.form_op==='new'?'forms:submit_create':'forms:submit_update')}</UiButton>
                <UiButton variant="secondary" type="button" disabled={pristine||submitting} onClick={form.reset}>{t('forms:reset')}</UiButton>
                <Loader loading={submitting} />
              </div>
            </Form>
          )
        }}
      </FinalForm>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    lang_code: state.lang.lang_code,
    relationship_consorg_options: {"_none": '-'},
    //relationship_consorg_options_ready: false,
    stateIsPortalDebug: stateIsPortalDebug(state)
  }
  var consorg_resource_codes = isDefVal(state, 'list.consorgs_user_belongs')
  if (consorg_resource_codes && consorg_resource_codes.length > 0) {
    //redux.relationship_consorg_options_ready = true
    for (var consorg_resource_code of consorg_resource_codes) {
      var consorg = stateFindItemByCodeOrAlias(state, 'consorg', consorg_resource_code)
      redux.relationship_consorg_options[consorg.data.id] = consorg.consolidated.title
    }
  }
  return {
    redux
  }
}

export default connect(
  mapStateToProps,
  null
)(withTranslation(
  ['forms','issues','enums']
)(IssueFinalForm))
