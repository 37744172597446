import React from 'react'
import {logRender} from "../../../utils/logging";
import { withTranslation } from 'react-i18next'
import {isDef} from "../../../utils/objtools";

class UiItem extends React.Component {

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    var title
    if (isDef(this.props,'title') && this.props.title.length)
      title = <h4 className="title">{this.props.title}</h4>

    var subtitle
    if (isDef(this.props,'subtitle') && this.props.subtitle.length)
      subtitle = <div className="subtitle">{this.props.subtitle}</div>

    var regularValue
    if (isDef(this.props,'regular_value') && this.props.regular_value.length)
      regularValue = <h2 className="regular-value">{this.props.regular_value}</h2>

    var slimValue
    if (isDef(this.props,'slim_value') && this.props.slim_value.length)
      slimValue = <h4 className="slim-value">{this.props.slim_value}</h4>

    return (
      <div className={this.getItemClasses().join(' ')} >
        {title}
        {subtitle}
        {regularValue}
        {slimValue}
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'ui-item'
    ]
    classes.push(this.props.type)
    return classes;
  }
}

export default withTranslation(['common'])(UiItem)
