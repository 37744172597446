import React from 'react'
import {connect} from '../../utils/redux'
import {logRender} from '../../utils/logging'
import {withTranslation} from 'react-i18next'
import {deepEqualObjs2, getFirstDef, isDefVal} from '../../utils/objtools'
import {mapResourceFromIds} from '../resource/resource_selectors'
import {stateIsUserBelongsConsorg, stateIsUserOwnsConsorg} from '../consorg/consorg_selectors'
import setDocumentTitle from '../../utils/browser/title'
import NotFound from '../ui/NotFound/NotFound'
import ResourceError from '../ui/ResourceError/ResourceError'
import Tabs from '../ui/Tabs/Tabs'
import TabContent from '../ui/Tabs/TabContent'
import ApplicationEdit from './ApplicationEdit'
import ApplicationDelete from './ApplicationDelete'
import ApplicationOverview from './ApplicationOverview'
import ApplicationCredentials from './ApplicationCredentials'
import ApplicationScopesSubscriptions from './ApplicationScopesSubscriptions'
import {submitApplicationFormDeleteRoutine} from './application_routines'
import {modalAdd} from '../modals/modals_actions'
import SingleHeader from '../ui/SingleHeader/SingleHeader'
import ApplicationAnalytics from "./ApplicationAnalytics";
import branding from '../portal/branding'
import ApplicationPlansSubscriptions from "./ApplicationPlansSubscriptions";
import {getPortalCurrentCatalogParam} from "../portal/portal_selectors";
import {stateIsProducerAdminAuthenticated} from "../../utils/user";

class Application extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || this.props.tReady !== nextProps.tReady
    )
  }

  componentDidMount() {
    this.postRender()
  }

  componentDidUpdate() {
    this.postRender()
  }

  postRender() {
    if (this.props.redux.consorg.need_request) {
      this.props.requestResource(this.props.redux.consorg.external_resource_infos)
    }
    if (this.props.redux.consorg_applications.need_request) {
      this.props.requestResource(this.props.redux.consorg_applications.external_resource_infos)
    }
    if (this.props.redux.application.need_request) {
      this.props.requestResource(this.props.redux.application.external_resource_infos)
    }
    if (this.props.tReady && isDefVal(this.props, 'redux.application.consolidated.title')) {
      setDocumentTitle(isDefVal(this.props, 'redux.application.consolidated.title') + ' - ' + this.props.t('application:tab_'+this.props.redux.tabCode+'_title'))
    }
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    const redux = this.props.redux

    // Assume Optimistic Rendering
    if (redux.isUserBelongsConsorg === false || (redux.isUserBelongsConsorg && redux.consorg_applications.found && !redux.consorg_applications.loading && !redux.consorg_applications.need_request && !redux.application.found)) {
      return <NotFound />
    }
    if (redux.consorg.error || redux.application.error) {
      return <ResourceError />
    }

    const owner_or_admin = this.props.redux.isUserOwnsConsorg || this.props.redux.isProducerAdminUser
    const owner_or_admin_on_internal = branding.kind === "int" && owner_or_admin
    const owner_on_open = branding.kind !== "int" && this.props.redux.isUserOwnsConsorg
    const can_delete = owner_or_admin_on_internal || owner_on_open || !this.props.redux.isCurrentCatalogSensitive

    var pills = []

    return (
      <div className={this.getItemClasses().join(' ')} >
        <div className="grid g1_root g1_1col">

          <SingleHeader item_type="application" consolidated={redux.application.consolidated} pills={pills} loading={redux.loading} />

          <Tabs activeTabCode={redux.tabCode}>
            <TabContent tabCode="overview" displayCondition={true} tabLabel={this.props.t('application:tab_overview_title')}>
              <ApplicationOverview resourceCode={redux.application.resource_code} />
            </TabContent>
            <TabContent tabCode="credentials" displayCondition={true} tabLabel={this.props.t('application:tab_credentials_title')}>
              <ApplicationCredentials resourceCode={isDefVal(redux.application, 'external_resource_infos.resource_code')} />
            </TabContent>
            <TabContent tabCode="subscriptions" displayCondition={true} tabLabel={this.props.t('application:tab_subscriptions_title')}>
              {(branding.kind === "int" || branding.kind === "assu-partners" || branding.kind === "assu-group" || branding.kind === "cgi-intranet" || branding.kind === "cgi-extranet" || branding.kind === "baas")
                  ? (<ApplicationPlansSubscriptions
                      resourceCode={isDefVal(redux.application, 'external_resource_infos.resource_code')}/>)
                  : (<ApplicationScopesSubscriptions
                      resourceCode={isDefVal(redux.application, 'external_resource_infos.resource_code')}/>)
              }
            </TabContent>
            <TabContent tabCode="analytics" displayCondition={branding.kind !== 'sgf'} tabLabel={this.props.t('application:tab_analytics_title')}>
              <ApplicationAnalytics resourceCode={isDefVal(redux.application, 'external_resource_infos.resource_code')} />
            </TabContent>
            <TabContent tabCode="edit" displayCondition={true} tabLabel={this.props.t('application:tab_edit_title')}>
              <ApplicationEdit application={redux.application} />
            </TabContent>
            <TabContent tabCode="delete" displayCondition={can_delete} tabLabel={this.props.t('application:tab_delete_title')}>
              <ApplicationDelete resourceCode={isDefVal(redux.application, 'external_resource_infos.resource_code')} />
            </TabContent>
          </Tabs>

        </div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'application',
      'full'
    ]
    return classes
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    consorg: mapResourceFromIds(state, 'consorg', ownProps.page.routeProps.match.params),
    application: mapResourceFromIds(state, 'application', ownProps.page.routeProps.match.params),
    lang_code: state.lang.lang_code,
    tabCode: getFirstDef(ownProps.page.routeProps.match.params, "tab_code", "overview")
  }
  redux.isUserBelongsConsorg = stateIsUserBelongsConsorg(state, redux.consorg.resource_code)
  redux.isUserOwnsConsorg = stateIsUserOwnsConsorg(state, redux.consorg.resource_code)
  redux.isProducerAdminUser = stateIsProducerAdminAuthenticated(state)
  redux.consorg_applications = mapResourceFromIds(state, 'consorg_applications', redux.consorg.ids)
  redux.isCurrentCatalogSensitive = getPortalCurrentCatalogParam(state, 'isSensitiveCatalog')
  redux.loading = redux.consorg.loading || redux.consorg.need_request || redux.consorg_applications.loading || redux.application.loading || redux.application.need_request

  return {
    redux
  }
}

const mapDispatchToProps = dispatch => ({
  requestResource: (external_resource_infos) => dispatch({type:'RESOURCE_LOAD_REQUEST', external_resource_infos}),
  submitApplicationFormDeleteRoutine: (application_id) => dispatch(submitApplicationFormDeleteRoutine(application_id)),
  modalAdd: (modalType, modalProps) => dispatch(modalAdd(modalType, modalProps))
})

Application = withTranslation(['common','application'])(Application)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  ['item.items.application','item.items.consorg','item.items.application_analytics','lang','auth','portal.context','list.lists.consorg_applications']
  )(Application)
