import { deepCopyObj, isDef, getFirstDef } from '../objtools'
import {identifySwaggerVersion} from './identifySwaggerVersion';

export const swaggerDevelopRefs = (swagger) => {
  if (typeof swagger !== 'object')
    return null

  var developed_swagger = deepCopyObj(swagger)
  let api_spec = identifySwaggerVersion(swagger)
  // Developpement des references
  developed_swagger = swaggerDevelopRefsRecurse(developed_swagger, developed_swagger, 0)
  // Complète paramètres dans les operations
  // Copie de props si non overriden dans les opérations
  var consumes = getFirstDef(developed_swagger, 'consumes', null)
  var produces = getFirstDef(developed_swagger, 'produces', null)
  let securityDefinitions
  let security = []
  let basePath
  let servers

  if(api_spec === 2){
    securityDefinitions = getFirstDef(developed_swagger, 'securityDefinitions', null)
    security = getFirstDef(developed_swagger, 'security', null)
    basePath = getFirstDef(developed_swagger, 'basePath', null)
  }
  else if(api_spec === 3){
    let flows = {...getFirstDef(developed_swagger, 'components.securitySchemes.oauth-flows.flows', null)}
    securityDefinitions = flows

    for (let flow in flows){
      let flowKey = {}
      flowKey[flow] = [flows[flow]]
      security.push(flowKey)
    }

    servers = isDef(developed_swagger, 'servers') ? developed_swagger.servers : null
  }

  let gateways = getFirstDef(developed_swagger, 'x-sg-configuration.gateways', null) ? getFirstDef(developed_swagger, 'x-sg-configuration.gateways', null) : getFirstDef(developed_swagger, 'x-ibm-endpoints', null)

  if (isDef(developed_swagger, 'paths')) {
    for (var key_path in developed_swagger.paths) {
      var key_method
      if (isDef(developed_swagger.paths[key_path], 'parameters')) {
        var parameters = developed_swagger.paths[key_path].parameters
        for (key_method in developed_swagger.paths[key_path]) {
          if (key_method !== 'parameters') {
            // Complète les paramètres
            if (!isDef(developed_swagger.paths[key_path][key_method], 'parameters'))
              developed_swagger.paths[key_path][key_method].parameters = parameters
            else {
              var method_parameters_names = []
              for (var method_parameter of developed_swagger.paths[key_path][key_method].parameters) {
                method_parameters_names.push(method_parameter.name)
              }
              for (var parameter of parameters) {
                if (method_parameters_names.indexOf(parameter.name) === -1)
                  developed_swagger.paths[key_path][key_method].parameters.push(parameter)
              }
            }
          }
        }
        // supression de la props parameters qui n'est plus utile à la racine
        delete developed_swagger.paths[key_path].parameters
      }
      for (key_method in developed_swagger.paths[key_path]) {
        // Copie des props si non overriden dans l'operation
        if (consumes && !isDef(developed_swagger.paths[key_path][key_method], 'consumes'))
          developed_swagger.paths[key_path][key_method].consumes = consumes
        if (produces && !isDef(developed_swagger.paths[key_path][key_method], 'produces'))
          developed_swagger.paths[key_path][key_method].produces = produces
        if (isDef(developed_swagger.paths[key_path][key_method], 'deprecated'))
          developed_swagger.paths[key_path][key_method].deprecated = getFirstDef(developed_swagger.paths[key_path][key_method], 'deprecated', false)
        if (securityDefinitions)
          developed_swagger.paths[key_path][key_method].securityDefinitions = securityDefinitions
        if (api_spec === 2){
          if (security && !isDef(developed_swagger.paths[key_path][key_method], 'security'))
            developed_swagger.paths[key_path][key_method].security = security
        }
        else if (api_spec === 3){
          if (security)
            developed_swagger.paths[key_path][key_method].security = security
        }
        if (gateways && (!isDef(developed_swagger.paths[key_path][key_method], 'x_ibm_endpoints') || !isDef(developed_swagger.paths[key_path][key_method], 'gateways')))
          developed_swagger.paths[key_path][key_method]['gateways'] = gateways
        if (basePath && !isDef(developed_swagger.paths[key_path][key_method], 'basePath'))
          developed_swagger.paths[key_path][key_method].basePath = basePath
        if (servers && !isDef(developed_swagger.paths[key_path][key_method], 'servers'))
          developed_swagger.paths[key_path][key_method].servers = servers
      }
    }
  }
  // Debug cyclic, à voir si on le garde toujours et affiche les erreurs
  if (isCyclic(developed_swagger)) {
    console.error('Developed swagger seems cyclic !')
  }
  return developed_swagger
}

const swaggerDevelopRefsRecurse = (current_node, full_swagger, depth) => {
  if (depth>50) {
    console.warn('Swagger Develop : abnormal depth, aborting')
    return
  }
  if (typeof current_node === 'object') {
    for (var obj_key in current_node) {
      if (typeof current_node[obj_key] == 'object' && current_node[obj_key] !== null) {
        // test if element is a ref
        //var obj_keys = Object.keys(current_node[obj_key])
        //if (obj_keys.length === 1 && obj_keys[0] === '$ref') {
        if (current_node[obj_key].hasOwnProperty('$ref')) { // Normalement il ne devrait y avoir que $ref mais on a des cas qui ne respectent pas la spec swagger 2.0
          var ref_path = current_node[obj_key]['$ref']
          var ref_path_parts = ref_path.split('/')
          if (ref_path_parts[0] !== '#') {
            console.warn('Swagger Develop : unexpected ref format')
            continue
          }
          ref_path_parts.splice(0,1)
          var ref_data
          if ((ref_data = getFirstDef(full_swagger, [ref_path_parts], null))) {
            current_node[obj_key] = {...ref_data}
            continue
          }
          console.warn('Swagger Develop : referenced data was not found')
        }
        // if not a ref, go deeper
        current_node[obj_key] = swaggerDevelopRefsRecurse(current_node[obj_key], full_swagger, depth+1)
      }
    }
  }
  return current_node
}





/**
 * test if an object is cyclic
 * @param {*} obj
 */
function isCyclic(obj) {
  var keys = [];
  var stack = [];
  var stackSet = new Set();
  var detected = false;

  function detect(obj, key) {
    if (obj && typeof obj != 'object') { return; }

    if (stackSet.has(obj)) { // it's cyclic! Print the object and its locations.
      var oldindex = stack.indexOf(obj);
      var l1 = keys.join('.') + '.' + key;
      var l2 = keys.slice(0, oldindex + 1).join('.');
      console.error('Swagger has a circular definition : ' + l1 + ' = ' + l2 + ' = ' + obj);
      //console.error(obj);
      detected = true;
      return;
    }

    keys.push(key);
    stack.push(obj);
    stackSet.add(obj);
    for (var k in obj) { //dive on the object's children
      if (Object.prototype.hasOwnProperty.call(obj, k)) { detect(obj[k], k); }
    }

    keys.pop();
    stack.pop();
    stackSet.delete(obj);
    return;
  }

  detect(obj, 'obj');
  return detected;
}
