import React from 'react'
import { connect } from '../../utils/redux'
import { logRender } from '../../utils/logging'
import { withTranslation } from 'react-i18next'
import { isDef, isDefVal2, deepCopyObj, deepEqualObjs2 } from '../../utils/objtools'
import Form from 'react-bootstrap/Form'
import FinderFilterCheck from './FinderFilterCheck'

class FinderFilter extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      value: null,
      redux_synced: true // when true we are sync with redux, when false we have a value not yet sent to redux then we have to keep displaying our state value
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // Sync state with redux if permitted
    if (prevState.redux_synced) {
      return {
          value: nextProps.redux.value
        }
    }
    return null
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || this.props.tReady !== nextProps.tReady
      || !deepEqualObjs2(this.state.value, nextState.value)
    )
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    if (!this.props.redux.filter)
      return null
    var filter = this.props.redux.filter

    var content = []
    var notice = []
    var value = this.state.value
    switch (filter.type) {
      case 'input':
        value = value===null?'':value
        content.push(
            <Form.Control
              key={filter.name}
              type="input"
              placeholder={this.props.t(filter.placeholder)}
              name={filter.name} value={value}
              onChange={this.handleChange}
            />
          )
        break
      case 'checkbox':
      //case 'radio':
        if (!isDef(filter, 'options'))
          return null

        for (var option_key in filter.options) {
          var option = filter.options[option_key]

          value = Array.isArray(value)?value:[]
          var checked = (value.indexOf(option.name) !== -1)
          var label = 'xxx'
          if (isDef(option,'title_i18n')) {
            label = this.props.t(option.title_i18n)
          }
          else if (isDef(option,'title')) {
            label = option.title
          }
          // content.push(
          //   <Form.Check
          //     key={filter.name+'-'+option.name}
          //     id={filter.name+'-'+option.name}
          //     type={filter.type}
          //     label={label}
          //     onChange={this.handleChange}
          //     name={filter.name}
          //     value={option.name}
          //     checked={checked}
          //   />
          // )
          content.push(
            <FinderFilterCheck
              key={filter.name+'--'+option.name}
              // id={filter.name+'-'+option.name}
              // type={filter.type}
              label={label}
              // onChange={this.handleChange}
              // name={filter.name}
              name={option.name}
              checked={checked}
              handleClick={this.handleClick}
            />
          )
        }
        notice.push(<h4 key="filter-notice-title">{this.props.t('finder:filter_notice_title')}</h4>)
        notice.push(<p key="filter-notice">{this.props.t('finder:filter_'+this.props.redux.filter.name+'_notice')}</p>)
        break
      default:
        return null
    }

    return (
      <div className={this.getItemClasses().join(' ')} >
        {content}
        {notice}
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'filter'
    ]
    if (this.props.redux.filter) {
      classes.push('name-'+this.props.redux.filter.name)
      classes.push('type-'+this.props.redux.filter.type)
    }
    return classes;
  }

  handleClick(name, checked) {
    var value
    switch(this.props.redux.filter.type) {
      case 'checkbox':
        value = deepCopyObj(this.state.value)
        if (!Array.isArray(value))
          value = []
        var index = value.indexOf(name)
        if (checked) {
          if (index === -1)
            value.push(name)
        }
        else {
          if (index !== -1)
            value.splice(index, 1)
        }
        break
      default:
        return
    }
    this.setState({
      redux_synced: false,
      value: value
    })
    clearTimeout(this._debounce_timer)
    this._debounce_timer = setTimeout(
      ()=>{
        this.props.finderSetFilter(this.props.redux.filter.name, value)
        this.setState({
          redux_synced: true
        })
      }
      ,200 // Debounce timer
    )
  }

  handleChange(event) {
    // TODO refacto
    //console.log(event.target)
    const target = event.target
    const name = target.name
    if (typeof name !== 'undefined') {
      var value
      switch(this.props.redux.filter.type) {
        case 'input':
          value = target.value
          break
        // case 'checkbox':
        // //case 'radio':
        //   value = deepCopyObj(this.state.value)
        //   if (!Array.isArray(value))
        //     value = []
        //   var index = value.indexOf(target.value)
        //   if (target.checked) {
        //     if (index === -1)
        //       value.push(target.value)
        //   }
        //   else {
        //     if (index !== -1)
        //       value.splice(index, 1)
        //   }
        //   break
        default:
          return
      }
      this.setState({
        redux_synced: false,
        value: value
      })
      clearTimeout(this._debounce_timer)
      this._debounce_timer = setTimeout(
        ()=>{
          this.props.finderSetFilter(name, value)
          this.setState({
            redux_synced: true
          })
        }
        ,200 // Debounce timer
      )
    }
  }

  componentWillUnmount() {
    clearTimeout(this._debounce_timer)
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    lang_code: state.lang.lang_code,
    filter: null,
    value: null
  }
  var filter
  if ((filter = isDefVal2(state.finder.filters, ownProps.filter_name))) {
    redux.filter = filter
  }
  var value
  if ((value = isDefVal2(state.finder.search_state.filters, ownProps.filter_name))) {
    redux.value = value
  }
  return {
    redux
  }
}

const mapDispatchToProps = dispatch => ({
  finderSetFilter: (filter_name, filter_value) => {
    dispatch({type:'FINDER_FILTER_VALUE_REQUEST', filter_name, filter_value});
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  ['lang','finder']
)(withTranslation('finder')(FinderFilter));
