import React from 'react'
import { connect } from '../../../utils/redux'
import { logRender } from '../../../utils/logging'
import { withTranslation } from 'react-i18next'
import Icon from '../../icon/Icon'
import { mapResourceFromCode } from '../../resource/resource_selectors'
import { getFirstDef } from '../../../utils/objtools'
import { stateAuthGetUserInvitationCount } from '../../auth/auth_selectors'

class ItemMe extends React.Component {

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    var title = getFirstDef(this.props.redux.user, ['data.givenName','data.name'], this.props.t('menu:user_title'))

    var invitations = []
    if (this.props.redux.invitationsCount && this.props.redux.invitationsCount > 0) {
      invitations.push(<span key="invitations" className="notif_count">{this.props.redux.invitationsCount}</span>)
    }

    return (
      <React.Fragment>
        <Icon type="user" />
        <span>{title}</span>
        {invitations}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    redux: {
      user: mapResourceFromCode(state, 'me', 'user/me'),
      invitationsCount: stateAuthGetUserInvitationCount(state)
    }
  }
}

export default connect(
  mapStateToProps,
  null,
  ['item.items.me','auth']
)(withTranslation(['menu'])(ItemMe))