import { getFirstDef2, isDefVal2, isDef } from '../../utils/objtools'

/**
 * Find item in state given an scheme_key and resource code or alias
 */
export function stateFindItemByCodeOrAlias(state, scheme_key, resource_code_or_alias) {
  // Try to get the item directly (resource_code_or_alias is a code)
  var item = getFirstDef2(state, [['item', 'items', scheme_key, resource_code_or_alias]], null)
  if (item)
    return item
  // Try to get the item by alias (resource_code_or_alias is an alias)
  var resource_code = getFirstDef2(state, [['item', 'items', scheme_key, 'aliases', resource_code_or_alias]], null)
  if (resource_code)
    return getFirstDef2(state, [['item', 'items', scheme_key, resource_code]], null)
  // The resource_code_or_alias is neither a code nor an alias
  return null
}

/**
 * Given an scheme_key and resource_alias, get the resource code
 */
export function stateFindItemResourceCodeFromAlias(state, scheme_key, resource_alias) {
  return isDefVal2(state, ['item', 'items', scheme_key, 'aliases', resource_alias])
}

/**
 * Get items of given types
 */
export function stateGetItemsOfTypes(state, item_types) {
  var items = []
  for (var item_type of item_types) {
    if (isDef(state.item.items, item_type))
      for (var resource_code in state.item.items[item_type]) {
        if (resource_code !== 'aliases')
          items.push(state.item.items[item_type][resource_code])
      }
  }
  return items
}

/**
 * Get items ressource_codes of given types
 */
export function stateGetItemsResourceCodesOfTypes(state, item_types) {
  var ressource_codes = []
  for (var item_type of item_types) {
    if (isDef(state.item.items, item_type))
      for (var resource_code in state.item.items[item_type]) {
        if (resource_code !== 'aliases')
          ressource_codes.push(resource_code)
      }
  }
  return ressource_codes
}

/**
 * Given a scheme_key get 'count' last resource_code seen and currently usable
 */
export function stateGetNLastSeenResourceCode(state, scheme_key, count) {
  var resource_codes = []
  if (isDef(state, ['item', 'last_seen_single_usable', scheme_key])) {
    for (var key in state.item.last_seen_single_usable[scheme_key]) {
      resource_codes.push(state.item.last_seen_single_usable[scheme_key][key])
      if (resource_codes.length >= count)
        break
    }
  }
  return resource_codes
}