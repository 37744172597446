import React from 'react'
import { connect } from 'react-redux'
import { logRender } from '../../utils/logging'
import { withTranslation } from 'react-i18next'
import { deepEqualObjs2, isDefVal } from '../../utils/objtools'
import { mapResourceFromCode, mapResourceFromIds } from '../resource/resource_selectors'
import Button from 'react-bootstrap/Button'
import { modalAdd } from '../modals/modals_actions'
import MembershipFinalForm from '../membership/MembershipFinalForm'
import { submitMembershipFinalFormNewHandler } from '../membership/membership_routines'
import { bindPromiseCreators } from 'redux-saga-routines'
import { getPortalCurrentOrganisationParam } from '../portal/portal_selectors'
import ResourceError from '../ui/ResourceError/ResourceError'
import Membership from '../membership/Membership'
import { membershipRoles } from '../../utils/enums/membershipRoles'
import { membershipStates } from '../../utils/enums/membershipStates'
import Loader from '../loader/Loader'
import { stateIsUserBelongsConsorg, stateIsUserOwnsConsorg } from './consorg_selectors'
import { stateAuthUserId } from '../auth/auth_selectors'

class ConsorgMembers extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || this.props.tReady !== nextProps.tReady
    )
  }

  componentDidMount() {
    this.postRender()
  }

  componentDidUpdate() {
    this.postRender()
  }

  postRender() {
    if (this.props.redux.memberships.need_request) {
      this.props.requestResource(this.props.redux.memberships.external_resource_infos)
    }
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    if (!this.props.redux.consorg.found)
      return null

    if (!this.props.redux.consumerOrgMemberInvitationField || ['mail','matricule'].indexOf(this.props.redux.consumerOrgMemberInvitationField) === -1)
      return <ResourceError details="consumerOrgMemberInvitationField is missing or not handled." />

    if (this.props.redux.memberships.error) {
      return <ResourceError />
    }

    var memberships = {
      pending: [],
      owners: [],
      members: [],
      requests: []
    }
    var item
    var userOwnMembership

    if (this.props.redux.memberships.found) {

      var multipleOwnersExist = false
      var ownersCount = 0
      for (item of this.props.redux.memberships.items) {
        if (isDefVal(item, 'data.role') === membershipRoles.OWNER && isDefVal(item, 'data.state') === membershipStates.ACTIVE) {
          ownersCount++
          if (ownersCount>1) {
            multipleOwnersExist = true
            break
          }
        }
      }
      for (item of this.props.redux.memberships.items) {
        const role = isDefVal(item, 'data.role')
        if (Object.values(membershipRoles).indexOf(role) === -1) {
          console.error('Unexpected role in the membership')
          continue
        }
        const state = isDefVal(item, 'data.state')
        if (Object.values(membershipStates).indexOf(state) === -1) {
          console.error('Unexpected state in the membership')
          continue
        }
        userOwnMembership = this.props.redux.userId !== null && this.props.redux.userId === isDefVal(item, 'data.user.id')
        if (state === membershipStates.PENDING) {
          memberships.pending.push({
            resourceCode: item.resource_code,
            can_delete : this.props.redux.userOwnsConsorg,
            can_edit: this.props.redux.userOwnsConsorg,
            userOwnMembership
          })
        }
        else if (state === membershipStates.REQUESTED) {
          memberships.requests.push({
            resourceCode: item.resource_code,
            can_allow : this.props.redux.userOwnsConsorg,
            userOwnMembership
          })
        }
        else if (role === membershipRoles.OWNER) {
          memberships.owners.push({
            resourceCode: item.resource_code,
            can_delete : (userOwnMembership && multipleOwnersExist) || this.props.redux.userOwnsConsorg,
            can_edit: userOwnMembership && multipleOwnersExist,
            userOwnMembership
          })
        }
        else if (role === membershipRoles.MEMBER) {
          memberships.members.push({
            resourceCode: item.resource_code,
            can_delete : this.props.redux.userOwnsConsorg || userOwnMembership,
            can_edit: this.props.redux.userOwnsConsorg,
            userOwnMembership
          })
        }
      }
    }

    return (
      <div className={this.getItemClasses().join(' ')} >
        <div className="grid g1_root g1_1col">

          <h2>{this.props.t('consorg:tab_members_title')}</h2>

          {this.props.redux.userOwnsConsorg &&
            <React.Fragment>
              <h3>{this.props.t('consorg:tab_members_invite_title')}</h3>
              <Button variant="secondary" onClick={()=>this.createClick()}>{this.props.t('membership:invite_member_button')}</Button>
            </React.Fragment>
          }

          <h3>{this.props.t('consorg:tab_members_list_requests_title')}</h3>
          {(memberships.requests.length > 0 &&
            memberships.requests.map((membership) => <Membership key={membership.resourceCode} can_allow={membership.can_allow} userOwnMembership={membership.userOwnMembership} itemType="consorg_membership" resourceCode={membership.resourceCode} />)
          ) ||
          ((!this.props.redux.memberships.found || this.props.redux.memberships.loading) &&
            <Loader loading={true} />
          ) ||
          <p className="empty_zone">{this.props.t('consorg:tab_members_list_requests_empty')}</p>
          }

          <h3>{this.props.t('consorg:tab_members_list_pending_title')}</h3>
          {(memberships.pending.length > 0 &&
              memberships.pending.map((membership) => <Membership key={membership.resourceCode} can_edit={membership.can_edit} can_delete={membership.can_delete} userOwnMembership={membership.userOwnMembership} itemType="consorg_membership" resourceCode={membership.resourceCode} />)
            ) ||
            ((!this.props.redux.memberships.found || this.props.redux.memberships.loading) &&
              <Loader loading={true} />
            ) ||
            <p className="empty_zone">{this.props.t('consorg:tab_members_list_pending_empty')}</p>
          }


          <h3>{this.props.t('consorg:tab_members_list_owners_title')}</h3>
          <p>{this.props.t('consorg:tab_members_list_owners_chapeau')}</p>
          {(memberships.owners.length > 0 &&
              memberships.owners.map((membership) => <Membership key={membership.resourceCode} can_edit={membership.can_edit} can_delete={membership.can_delete} userOwnMembership={membership.userOwnMembership} itemType="consorg_membership" resourceCode={membership.resourceCode} />)
            ) ||
            ((!this.props.redux.memberships.found || this.props.redux.memberships.loading) &&
              <Loader loading={true} />
            ) ||
            <p className="empty_zone">{this.props.t('consorg:tab_members_list_owners_empty')}</p>
          }

          <h3>{this.props.t('consorg:tab_members_list_members_title')}</h3>
          <p>{this.props.t('consorg:tab_members_list_members_chapeau')}</p>
          {(memberships.members.length > 0 &&
              memberships.members.map((membership) => <Membership key={membership.resourceCode} can_edit={membership.can_edit} can_delete={membership.can_delete} userOwnMembership={membership.userOwnMembership} itemType="consorg_membership" resourceCode={membership.resourceCode} />)
            ) ||
            ((!this.props.redux.memberships.found || this.props.redux.memberships.loading) &&
              <Loader loading={true} />
            ) ||
            <p className="empty_zone">{this.props.t('consorg:tab_members_list_members_empty')}</p>
          }

        </div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'consorg',
      'members'
    ]
    return classes
  }

  createClick() {
    this.props.modalAdd(
      'form',
      {
        title: this.props.t('membership:invite_modal_title'),
        message: this.props.t('membership:invite_modal_message'),
        FormComponent: MembershipFinalForm,
        formComponentProps: {
          form_op: "new",
          initialValues: {
            ids: this.props.redux.consorg.ids,
            consumerOrgMemberInvitationField: this.props.redux.consumerOrgMemberInvitationField,
            membershipRole: membershipRoles.MEMBER
          }
        },
        submitAction: this.props.submitMembershipFinalFormNewHandler,
        closeAction: null
      }
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    consorg: mapResourceFromCode(state, 'consorg', ownProps.resourceCode),
    //user_role: stateGetUserConsorgRole(state, ownProps.resourceCode),
    userBelongsConsorg: stateIsUserBelongsConsorg(state, ownProps.resourceCode),
    userOwnsConsorg: stateIsUserOwnsConsorg(state, ownProps.resourceCode),
    userId: stateAuthUserId(state),
    lang_code: state.lang.lang_code,
    consumerOrgMemberInvitationField: getPortalCurrentOrganisationParam(state, 'usersConfiguration.consumerOrgMemberInvitationField')
  }
  redux.memberships = mapResourceFromIds(state, 'consorg_memberships', redux.consorg.ids)

  return {
    redux
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindPromiseCreators({
      submitMembershipFinalFormNewHandler,
      // other promise creators can be here...
    }, dispatch),
  modalAdd: (modalType, modalProps) => dispatch(modalAdd(modalType, modalProps)),
  requestResource: (external_resource_infos) => dispatch({type:'RESOURCE_LOAD_REQUEST', external_resource_infos})
})

ConsorgMembers = withTranslation(['common','consorg','membership'])(ConsorgMembers)

export default connect(mapStateToProps, mapDispatchToProps)(ConsorgMembers)
