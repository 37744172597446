import React from 'react'
import {logRender} from '../../../utils/logging';
import { withTranslation } from 'react-i18next'
import {connect} from "react-redux";
import {getPortalOrganisationSparkyManagerEndpoint} from "../../portal/portal_selectors";

class ImgMedia extends React.Component {

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    const buildSrc = (organization, mediaName) => {return this.props.redux.sparky_manager_api_endpoint+'/consumer/organizations/'+organization+'/medias/'+mediaName+'?format=binary'}

    return (
      <div className={this.getItemClasses().join(' ')} >
        <img src={buildSrc(this.props.organization,this.props.media_name)} alt={this.props.title} onClick={this.props.onClick}/>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'img-media'
    ]
    return classes;
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    lang_code: state.lang.lang_code,
    sparky_manager_api_endpoint: getPortalOrganisationSparkyManagerEndpoint(state)
  }

  return {
    redux
  }
}

ImgMedia = withTranslation(['common'])(ImgMedia)

export default connect(mapStateToProps, null)(ImgMedia)

