export const resourceVisibilities = {
  EVERYONE: 'everyone',
  LOGGED_USERS: 'logged_users',
  PRIVATE_TO_MEMBERS: 'private_to_members'
}

export const resourceVisibilities_options = {
  [resourceVisibilities.EVERYONE]:"enums:resourceVisibility_option_everyone",
  [resourceVisibilities.LOGGED_USERS]:"enums:resourceVisibility_option_logged_users",
  [resourceVisibilities.PRIVATE_TO_MEMBERS]:"enums:resourceVisibility_option_private_to_members",
}