import { takeEvery } from 'redux-saga/effects'
import { submitIssueFormNewRoutine, submitIssueFormDeleteRoutine} from './issue_routines'
import { submitNewIssueForm } from './issue_new_saga'
import {submitDeleteIssueForm} from "./issue_delete_saga";

const issueSagas = [
  takeEvery(submitIssueFormNewRoutine.TRIGGER, submitNewIssueForm),
  takeEvery(submitIssueFormDeleteRoutine.TRIGGER, submitDeleteIssueForm)
]
export default issueSagas
