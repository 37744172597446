import { copyObj } from '../objtools'

const redLang = (
  state = {
    lang_code: null
  },
  action
) => {
  var new_state = null
  switch (action.type) {

    case "LANG_SET_LANG_CODE":
      new_state = copyObj(state)
      new_state.lang_code = action.lang_code
      break

    default:
      break

  }
  if (new_state) {
    return new_state
  }
  else {
    return state
  }
}

export default redLang
