import React from 'react'
import {logRender} from '../../utils/logging'
import {removeDuplicates, isDef} from '../../utils/objtools'
import Icon from "../icon/Icon"
import Button from "react-bootstrap/Button"
import Feedback from '../feedback/Feedback'
import {connect} from "react-redux"
import Expandable from "../ui/Expandable/Expandable"
import {stateGetSelectedConsorgId} from "../consorg/consorg_selectors";
import {stateAuthIsUserLoggedIn} from "../auth/auth_selectors";
import {withTranslation} from "react-i18next";

var isPlanMatch = false

class Plan extends React.Component {


  render() {
    logRender(this);

    var title = <h4 className="title" key="title">{this.props.plan.title}</h4>
    var description = <p key="description">{this.props.plan.description ? this.props.plan.description : <i>There is no description for this plan</i>}</p>
    var currentPlanApis = [];
    var currentPlanApproval = null
    var currentPlanRate = null
    var currentPlanOperations = []
    var currentPlanPaths = []
    var consolidatedOperationsPaths = []

    if(isDef(this.props.plan, 'approval')){
      currentPlanApproval = this.props.plan.approval ? "requires approval" : "no approval required"
    }

    if(isDef(this.props.plan, 'rate-limit'))
    {
      currentPlanRate = this.props.plan["rate-limit"].value;
    }
    else if(isDef(this.props.plan, 'rate-limits') && Object.keys(this.props.plan['rate-limits'])[0])
    {
      currentPlanRate = this.props.plan["rate-limits"][Object.keys(this.props.plan['rate-limits'])[0]].value;
    }
    else{
      currentPlanRate = "unlimited";
    }
    if(isDef(this.props.plan, 'apis'))
    {

      for (let related_api in Object.keys(this.props.plan.apis)){
        currentPlanApis.push(Object.keys(this.props.plan.apis)[related_api]);
      }

      for (let api in Object.keys(this.props.plan.apis)) {

        if(isDef(this.props.plan.apis[Object.keys(this.props.plan.apis)[api]], 'operations')){
            for(let operation in this.props.plan.apis[Object.keys(this.props.plan.apis)[api]].operations){
              currentPlanOperations.push(this.props.plan.apis[Object.keys(this.props.plan.apis)[api]].operations[operation].operation)
              currentPlanPaths.push(this.props.plan.apis[Object.keys(this.props.plan.apis)[api]].operations[operation].path)
              consolidatedOperationsPaths.push(
                {
                  operation:this.props.plan.apis[Object.keys(this.props.plan.apis)[api]].operations[operation].operation,
                  path:this.props.plan.apis[Object.keys(this.props.plan.apis)[api]].operations[operation].path
                })

            }
        }
        else{
          currentPlanOperations.push("all operations")
          currentPlanPaths.push("all paths")
        }
      }
      if (Object.keys(this.props.plan.apis).length === 0){
        currentPlanOperations.push("all operations")
        currentPlanPaths.push("all paths")
      }
    }
    else {
      currentPlanOperations.push("all operations")
      currentPlanPaths.push("all paths")
    }

    currentPlanOperations = removeDuplicates(currentPlanOperations)
    currentPlanPaths = removeDuplicates(currentPlanPaths)

    isPlanMatch = (currentPlanApproval === this.props.state.currentApproval) &&
      (currentPlanRate === this.props.state.currentRate) &&
      (currentPlanOperations.find(operation => operation === this.props.state.currentOperation) === this.props.state.currentOperation)

    var feedbacks = []
    feedbacks.push(<Feedback key="approval-feedback" type="Approval" label={currentPlanApproval}
                             match={currentPlanApproval === this.props.state.currentApproval}/>)
    feedbacks.push(<Feedback key="rate-feedback" type="Rating" label={currentPlanRate}
                             match={currentPlanRate === this.props.state.currentRate}/>)

    for (let operation in currentPlanOperations){
      feedbacks.push(<Feedback key={'operations-feedback-' + operation} type="Operations" label={currentPlanOperations[operation]}
                               match={currentPlanOperations[operation] === this.props.state.currentOperation}/>)
    }


    var apis_details = []
    if (currentPlanApis.length){
      for (let api in currentPlanApis){
        apis_details.push(<div className="pill" key={"api_pill-"+api}>{currentPlanApis[api]}</div>)
      }
    }

    if (apis_details.length){
      apis_details = (
        <Expandable key="apis" title="Apis details">
          <div className="standard_content">
              {apis_details}
          </div>
        </Expandable>
      )
    }

    var paths_details = []
    if (consolidatedOperationsPaths.length > 1){
      for (let item of consolidatedOperationsPaths){
        paths_details.push(<div key={"path_pill-"+item.operation+item.path}>{item.operation+':'+item.path}</div>)
      }
    }

    if (paths_details.length > 1){
      paths_details = (
        <Expandable key="paths" title="Paths details">
          <div className="standard_content">
              {paths_details}
          </div>
        </Expandable>
      )
    }

    return (
      <div className={this.getItemClasses().join(' ')}>
        <div className="content">
          <div className="cartouche">
            <div className="pre_title">
              <div className="icon">
                {isPlanMatch &&
                <Icon type="plan_white"/>
                }
                {!isPlanMatch &&
                <Icon type="plan"/>
                }
              </div>
              <div className="pill">plan</div>
            </div>
            {title}
            {description}
          </div>
          <div className="corps">

            <h4>Specifications</h4>
            <div className="feedbacks">
              {feedbacks}
              <div className="static">
                {/*Apis*/}
                {currentPlanApis.length > 0 &&
                <label className="text">
                  Apis in product :&nbsp;
                  <div className="pill">{currentPlanApis.length}/{this.props.api_counter}</div>
                </label>
                }
                {currentPlanApis.length === 0 &&
                <label className="text">
                  Apis in product :&nbsp;
                  <div className="pill">all</div>
                </label>
                }

              </div>
              <div className="static">
                {/*Paths*/}
                {currentPlanPaths.length > 1 &&
                <label className="text">
                  Paths in plan :&nbsp;
                  <div className="pill">{currentPlanPaths.length}</div>
                </label>
                }
                {currentPlanPaths.length === 1 &&
                <label className="text">
                  Paths in plan :&nbsp;
                  <div className="pill">all</div>
                </label>
                }
              </div>
            </div>

            <div className="expandable_details">
              {apis_details}
              {paths_details}
            </div>
          </div>

          <div className="disabled_subscription_reasons">
            {!this.props.redux.authIsUserLoggedIn &&
              <span> {this.props.t('plan_filters:disabled_subscription_auth')}</span>
            }
            {!this.props.redux.selected_consorg_id &&
              <span> {this.props.t('plan_filters:disabled_subscription_consorg')}</span>
            }
          </div>

          <div className="teaser-tools">
            <Button key="subscribe_button" variant={isPlanMatch ? 'tertiary' : 'secondary'} onClick={() => this.handleClick(this.props.name)} disabled={!this.props.redux.authIsUserLoggedIn || !this.props.redux.selected_consorg_id}>{this.props.t('plan_filters:subscribe_button')}</Button>
          </div>

        </div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'plan',
      'bloc'
    ]
    if (isPlanMatch) {
      classes.push('match')
    }
    return classes;
  }

  handleClick(name){
    this.props.subscriptionHandler(name);
  }

}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    lang_code: state.lang.lang_code,
    selected_consorg_id: stateGetSelectedConsorgId(state),
    authIsUserLoggedIn: stateAuthIsUserLoggedIn(state),
  }
  return {
    redux
  }
}

export default connect(
  mapStateToProps,
  null
)(withTranslation(
  ['plan_filters']
)(Plan))
