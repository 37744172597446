import React from 'react';
import { ResponsiveBar } from '@nivo/bar'

export const AvailabilitySummaryGraph = ({ data, legend, prim_color, sec_color }) => (
  <ResponsiveBar
    data={data}
    //keys={['available','unavailable']}
    keys={['available']}
    layout="horizontal"
    margin={{ top: 50, right: 100, bottom: 80, left: 80 }}
    labelFormat={v => `${v}%`}
    colors={(d)=>{return d.index===1 ? prim_color : sec_color}}
    // colors={["#e9041e","#999999"]}
    labelTextColor="#ffffff"
    innerPadding= {4}
    tooltip={({ id, value, color }) => (
      <div>
        <strong>{legend}:</strong> {value}ms
      </div>
    )}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: legend,
      legendPosition: 'middle',
      legendOffset: 32
    }}
    theme={{
      axis: {
        fontSize: "14px",
        tickColor: "#ffffff",
        ticks: {
          line: {
            stroke: "#ffffff"
          },
          text: {
            fill:"#ffffff"
          }
        },
        legend: {
          text: {
            fill: "#ffffff"
          }
        }
      },
      tooltip: {
        color: "#ffffff",
        container: {
          background: '#151515'
        }
      }
    }}
  />
)
