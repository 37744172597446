import React from 'react'
import { connect } from 'react-redux'
import { logRender } from '../../utils/logging'
import { getFirstDef } from '../../utils/objtools'
import { Form as FinalForm, Field as FinalField} from 'react-final-form'
import { withTranslation } from 'react-i18next'
import { Form } from 'react-bootstrap'
import UiButton from '../ui/buttons/UiButton'
import Loader from '../loader/Loader'
import ControlInputPassword from "../../utils/forms/ControlInputPassword";

class CredentialFinalForm extends React.Component {

  constructor(props) {
    super(props)

    this.validateForm = this.validateForm.bind(this)
  }

  validateForm = (values) => {
    const errors = {}

    if (!values.secret) {
      errors.secret = this.props.t('forms:error_required')
    }
    else if (values.secret !== values.secret.trim()) {
      errors.secret = this.props.t('forms:error_no_begin_or_end_space')
    }


    return errors
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    if (['new','edit'].indexOf(getFirstDef(this.props, 'form_op', '')) < 0 ) {
      console.error('form_op is not defined or not handled')
      return null
    }

    const t = this.props.t

    return (
      <FinalForm
        initialValues={this.props.initialValues}
        onSubmit={this.props.onSubmit}
        validate={this.validateForm}
      >
        {({ handleSubmit, pristine, form, submitting }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="secret">
                <Form.Label>{t('credential:field_secret')}</Form.Label>
                <FinalField name="secret" type='password' component={ControlInputPassword} placeholder={t('credential:field_secret')} />
                <Form.Text className="text-muted">{t('credential:field_secret_desc') + this.props.initialValues.credentialId}</Form.Text>
              </Form.Group>
              <div className="form-actions">
                <Loader loading={submitting} />
                <UiButton variant="primary" type="submit" disabled={(pristine && this.props.form_op==='edit') || submitting}>{t('credential:verify_button')}</UiButton>
                <UiButton variant="secondary" type="button" disabled={pristine||submitting} onClick={form.reset}>{t('forms:reset')}</UiButton>
              </div>
            </Form>
          )
        }}
      </FinalForm>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    lang_code: state.lang.lang_code
  }
  return {
    redux
  }
}

export default connect(
    mapStateToProps,
    null
  )(withTranslation(
    ['forms','enums','credential']
  )(CredentialFinalForm))
