import { copyObj } from '../../utils/objtools'

const redAuth = (
  state = {
    logged_in: null,
    user_is_producer_admin: null,
    user_has_memberships: null,
    user_invitations_count: null,
    user_id: null
  },
  action
) => {
  var new_state = null
  switch (action.type) {

    case 'AUTH_NOT_LOGGED_IN':
      new_state = copyObj(state)
      new_state.logged_in = false
      new_state.user_is_producer_admin = false
      new_state.user_has_memberships = null
      new_state.user_invitations_count = null
      new_state.user_id = null
      break

    case 'AUTH_HAS_LOGGED_OUT':
      new_state = copyObj(state)
      new_state.logged_in = false
      new_state.user_is_producer_admin = false
      new_state.user_has_memberships = null
      new_state.user_invitations_count = null
      new_state.user_id = null
      break

    case 'AUTH_IS_LOGGED_IN':
      new_state = copyObj(state)
      new_state.logged_in = true
      new_state.user_is_producer_admin = action.user_data.roles.includes('ROLE_PRODUCER_ADMIN')
      new_state.user_has_memberships = null // not yet known
      new_state.user_invitations_count = null // not yet known
      new_state.user_id = action.user_data.id
      break

    case 'PORTAL_CHANGE_CATALOG_START':
      new_state = copyObj(state)
      new_state.user_has_memberships = null
      new_state.user_invitations_count = null
      break

    case 'AUTH_USER_HAS_MEMBERSHIPS':
      new_state = copyObj(state)
      new_state.user_has_memberships = action.value
      break

    case 'AUTH_USER_HAS_INVITATIONS':
      new_state = copyObj(state)
      new_state.user_invitations_count = action.count
      break

    case 'AUTH_USER_HAS_ACCEPT_INVITATION':
      new_state = copyObj(state)
      new_state.user_invitations_count = action.count
      break

    default:
      break
  }
  if (new_state) {
    return new_state
  }
  else {
    return state
  }
}

export default redAuth;
