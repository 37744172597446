import { getFirstDef } from '../../utils/objtools'

/**
 * Get Finder search state
 */
export function stateGetFinderSearchState(state) {
  return state.finder.search_state
}

/**
 * Get Finder filter
 */
export function stateGetFinderFilter(state, filter_name) {
  return getFirstDef(state.finder.filters, filter_name, null)
}