import { select, call } from 'redux-saga/effects'
import genericFormSubmissionSaga from '../item/genericFormSubmissionSaga'
import {getPortalCurrentOrganisationAndCatalogIds} from "../portal/portal_selectors"
import {newItem} from "../item/item_new_saga"
import {submitCommentFormNewRoutine} from "./comment_routines";


function* saga(action) {
  const values = {...action.payload}

  // prepare some ids
  var ids = yield select(getPortalCurrentOrganisationAndCatalogIds)

  ids = {
    ...values.consorg.ids,
    ...values.issue.ids,
    ...ids
  }

  // We need to encode the body to avoid WAF errors when prohibited characters are present

  var base64Body = btoa(unescape(encodeURIComponent(values.description)))

  var body = {
    "body": base64Body,
    "username": values.user.data.name
  }

  // Create the issue
  var {error, data} = yield call(newItem, 'comment_new', 'issue_comments', ids, body)


  // Generic unhandled error
  if (error)
    throw error

  // Check created
  if (!data.body)
    throw new Error('No body found in the newly created comment.')
  if (!data.id)
    throw new Error('No id found in the newly created issue.')
  if (!data.username)
    throw new Error('No username found in the newly created issue.')

}

export function* submitNewCommentForm(action) {
  yield call(genericFormSubmissionSaga, {
    saga,
    action,
    routine: submitCommentFormNewRoutine,
    successfull_title: 'comment:successfull_create_title',
    successfull_body: 'comment:successfull_create_body',
    unsuccessfull_title: 'comment:unsuccessfull_create_title',
    unsuccessfull_body: 'comment:unsuccessfull_create_body'
  })
}
