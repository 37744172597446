import React from 'react'
import { logRender } from '../../utils/logging'
import { withTranslation } from 'react-i18next'
import JsonExpandable from '../ui/JsonExpandable/JsonExpandable'
import Expandable from '../ui/Expandable/Expandable'
import ApiParameter from './ApiParameter'
import ApiResponse from './ApiResponse'
import { isDef, getFirstDef, getObjExcluding, deepEqualObjs2 } from '../../utils/objtools'
import ItemGFMDescription, { needsGFM } from '../ui/ItemGFMDescription/ItemGFMDescription'
import UiProperty from '../ui/UiProperty/UiProperty'
import ApiTag from './ApiTag'
import ApiPath from './ApiPath'
import Visibility from "../../utils/debug/Visibility";

class ApiMethod extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.tReady !== nextProps.tReady
      || !deepEqualObjs2(this.props.data, nextProps.data)
    )
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    var tags_content = []
    var tags = null
    if ((tags = getFirstDef(this.props.data, 'tags', null))) {
      for (var tag of tags) {
        tags_content.push(<ApiTag key={tag} tag={{name:tag}} />)
      }
      if (tags_content.length)
        tags_content = <div key="tags" className="operation-tags">{tags_content}</div>
    }

    var properties = []
    var uncovered_members = getObjExcluding(this.props.data, ['description', 'summary', 'parameters', 'responses', 'tags', 'security', 'securityDefinitions', 'basePath', 'x-ibm-endpoints'])
    for (var uncovered_member_key in uncovered_members) {
      properties.push(<UiProperty key={uncovered_member_key} name={uncovered_member_key} value={uncovered_members[uncovered_member_key]} />)
    }
    if (properties.length > 0) {
      properties = (
          <div key="properties" className="grid g1_211col__m4">
            {properties}
          </div>
        )
      properties = [<h3 key="properties_h3">{this.props.t('api:properties')}</h3>].concat(properties)
    }

    var inside
    var query_elements = {}

    var parameters_content = []
    var parameters = {
      header: [],
      path: [],
      query: [],
      body: [],
      formData: [],
      other: []
    }
    if (isDef(this.props.data, 'parameters')) {
      for (var parameter of this.props.data.parameters) {
        inside = getFirstDef(parameter, 'in', 'other')
        if (!isDef(parameters, inside))
          inside = 'other'
        parameters[inside].push(
          <ApiParameter key={inside+'-'+parameter.name} data={parameter} />
        )
        if (inside === 'query')
          query_elements[parameter.name] = parameter.type
      }
      for (inside in parameters) {
        if (parameters[inside].length > 0) {
          parameters_content.push(<h4 key={'h4_'+inside}>{inside}</h4>)
          parameters_content = parameters_content.concat(parameters[inside])
        }
      }
      if (parameters_content.length)
        parameters_content = [<h3 key="parameters_h3">{this.props.t('api:parameters')}</h3>].concat(parameters_content)
    }

    var securities_content = []
    if (isDef(this.props.data, 'security')) {
      // this.props.data.security.push(this.props.data.security[0]) // test multiple security requirement alternatives
      // this.props.data.security.push([]) // test empty security requirement
      // We may have several security alternatives (it is an OR : https://swagger.io/specification/v2/#operationObject)
      var security_alternative = 0
      for (var security_requirement_object of this.props.data.security) {
        security_alternative++
        var securities = {
          header: [],
          path: [],
          query: [],
          body: [],
          formData: [],
          other: []
        }
        var found_securities = 0
        for (var security_name in security_requirement_object) {
          found_securities++
          if (!isDef(this.props.data, ['securityDefinitions', security_name])) {
            // Warn about unexpected case of security requirement pointing to non existing security definition
            securities['other'].push(<p key={security_name+'-'+security_alternative}>Security Definition not found.</p>)
            continue
          }
          var full_security = {
            ...this.props.data.securityDefinitions[security_name],
            securityDefinition: security_name
          }
          if (!isDef(full_security, 'name'))
            full_security.name = security_name
          if (security_requirement_object[security_name].length > 0)
            full_security = {...full_security, required_scopes: security_requirement_object[security_name]}
          delete full_security.scopes // list of possible scopes are useless here
          inside = getFirstDef(full_security, 'in', 'other')
          if (!isDef(securities, inside))
            inside = 'other'
          securities[inside].push(
            <ApiParameter key={inside+'-'+full_security.name+'-'+security_alternative} data={full_security} />
          )
          if (inside === 'query')
            query_elements[full_security.name] = full_security.type
        }

        var security_title = this.props.t('api:security')
        if (this.props.data.security.length>1)
          security_title += ' (alternative '+security_alternative+')'
        securities_content.push(<h3 key={'security-h3-'+security_alternative}>{security_title}</h3>)

        if (found_securities > 0)
          for (inside in securities) {
            if (securities[inside].length > 0) {
              securities_content.push(<h4 key={'h4-'+inside+'-'+security_alternative}>{inside}</h4>)
              securities_content = securities_content.concat(securities[inside])
            }
          }
        else
          securities_content.push(<p key={'nosecurityinfo-'+security_alternative}>No security requirement</p>)
      }
    }

    var paths = []
    if (isDef(this.props.data, 'gateways')) {
      //swagger_developed['x-ibm-endpoints'].push(swagger_developed['x-ibm-endpoints'][0]) // debug multiple items
      for (var gateway of this.props.data['gateways']) {
        var api_path = {
          ...gateway,
          path: this.props.path,
          method: this.props.method,
          query_elements: query_elements
        }
        if (isDef(this.props.data, 'basePath'))
          api_path.basePath = this.props.data.basePath
        if (isDef(this.props.data, 'servers'))
          api_path.servers = this.props.data.servers
        paths.push(<ApiPath key="" api_path={api_path} api_spec={this.props.api_spec}/>)
      }
    }
    if (paths.length > 0)
      paths = [<h3 key="paths_h3">{this.props.t('api:paths')}</h3>].concat(paths)

    var responses_content = []
    if (isDef(this.props.data, 'responses')) {
      for (var key_response in this.props.data.responses) {
        var title_response = null
        if (isDef(this.props.data.responses[key_response], 'description') && !needsGFM(this.props.data.responses[key_response].description)) {
          title_response = this.props.data.responses[key_response].description
        }
        responses_content.push(
            <Expandable key={key_response} tag={key_response} title={title_response}>
              <ApiResponse key="response" response={key_response} data={this.props.data.responses[key_response]} />
            </Expandable>
          )
      }
      if (responses_content.length)
        responses_content = [<h3 key="responses_h3">{this.props.t('api:responses')}</h3>].concat(responses_content)
    }

    return (
      <div className={this.getItemClasses().join(' ')} >
        <ItemGFMDescription key="description" title="Description" content={this.props.data.description} />
        {tags_content}
        {properties}
        {paths}
        {parameters_content}
        {/*This elements are deplicated in API component*/}
        {/*{securities_content}*/}
        {responses_content}
        <Visibility condition={false} >
          <h3 key="raw_data_h3">Raw</h3>
          <JsonExpandable key="raw_data" title="Operation raw definition" jsonobject={this.props.data}/>
        </Visibility>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'api-method'
    ]
    classes.push(this.props.method)
    return classes;
  }
}

export default withTranslation('api')(ApiMethod);
