import React from 'react'
import { connect } from 'react-redux'
import { logRender } from '../../utils/logging'
import { withTranslation } from 'react-i18next'
import { isDef, deepEqualObjs2, getFirstDef } from '../../utils/objtools'
import { mapResourceFromCode, mapResourceFromIds } from '../resource/resource_selectors'
import ItemGFMDescription from '../ui/ItemGFMDescription/ItemGFMDescription'
import UiProperty from '../ui/UiProperty/UiProperty'
import Teaser from '../teaser/Teaser'
import branding from "../portal/branding";

class ApplicationOverview extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || this.props.tReady !== nextProps.tReady
    )
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    var presentation = null
    var presentation1 = []
    var presentation2 = []
    if (isDef(this.props.redux, 'application.consolidated.description'))
      presentation1.push(<ItemGFMDescription title="Description" key="description" content={this.props.redux.application.consolidated.description} />)
    //presentation2.push(<Button variant="primary" onClick={()=>this.historyPush('application_new')}>{this.props.t('application:edit_button')}</Button>)
    if (presentation1.length || presentation2.length)
      presentation = (
        <div className="item_full_presentation">
          <div className="presentation_content gridg g1_2t1t_2_1">
            <div key="presentation1">{presentation1}</div>
            <div key="presentation2">{presentation2}</div>
          </div>
        </div>
      )

    var resource_properties = (
      <div className="resource_properties">
        <h2>{this.props.t('common:properties')}</h2>
        <div className="properties_list">
          {/*people confuse it with client ID*/}
          {/*<UiProperty key="id" name="id" value={getFirstDef(this.props.redux.application, 'data.id', '-')} />*/}
          <UiProperty key="name" name={this.props.t('forms:field_name')} value={getFirstDef(this.props.redux.application, 'data.name', '-')} />
          <UiProperty key="organization" name={this.props.t('common:organization')} value={getFirstDef(this.props.redux.application, 'data.organization', '-')} />
          <UiProperty key="catalog" name={this.props.t('common:catalog')} value={getFirstDef(this.props.redux.application, 'data.catalog', '-')} />
          {!branding.open &&
          <UiProperty key="irtCode" name={this.props.t('common:irtCode')} value={getFirstDef(this.props.redux.application, 'data.irtCode', '-')} />
          }
          {!branding.open &&
          <UiProperty key="trigramCode" name={this.props.t('common:trigramCode')} value={getFirstDef(this.props.redux.application, 'data.trigramCode', '-')}/>
          }

        </div>
      </div>
    )

    var consorg_properties = (
      <div className="resource_properties">
        <h3>{this.props.t('common:consumer_organization')}</h3>
        <Teaser key="app_consorg" item_type="consorg" resource_code={this.props.redux.consorg.resource_code} />
      </div>
    )

    return (
      <div className={this.getItemClasses().join(' ')} >
        <div className="grid g1_root g1_1col">
          {presentation}
          <div className="grid g1_211col">
            <div>
              <h2>...</h2>
            </div>
            <div>
              {resource_properties}
              {consorg_properties}
            </div>
          </div>
        </div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'application',
      'overview'
    ]
    return classes
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    application: mapResourceFromCode(state, 'application', ownProps.resourceCode),
    lang_code: state.lang.lang_code
  }
  redux.consorg = mapResourceFromIds(state, 'consorg', redux.application.ids)
  return {
    redux
  }
}

ApplicationOverview = withTranslation(['common','application','forms'])(ApplicationOverview)

export default connect(mapStateToProps, null)(ApplicationOverview)
