import { isDef, copyObj, propertyCopy } from '../../utils/objtools';

const redModals = (
  state = {
    last_modal_id: 0,
    modals: {}
  },
  action
) => {
  var new_state = null
  switch (action.type) {

    case 'MODAL_ADD':
      new_state = copyObj(state)
      propertyCopy(new_state, 'modals')
      new_state.last_modal_id++
      var modal_id = new_state.last_modal_id
      new_state.modals[modal_id] = {
        modalType: action.modalType,
        modalProps: action.modalProps,
        displayed_date: 0
      }
      // TODO PRUNE OLD HERE ?
      break

    case 'MODAL_DISPLAYED':
      if (isDef(state.modals, action.modal_id)) {
        new_state = copyObj(state)
        propertyCopy(new_state, ['modals', action.modal_id])
        new_state.modals[action.modal_id].displayed_date = Date.now()
      }
      break

    // case 'CREDS_LOGOUT':
    // case 'DATA_FLUSH':
    //   return {
    //     last_modal_id: state.last_modal_id,
    //     modals: {}
    //   }

    default:
      break

  }
  if (new_state) {
    return new_state
  }
  else {
    return state
  }
}

export default redModals
