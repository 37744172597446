import React from 'react'
import { connect } from '../../utils/redux'
import { logRender } from '../../utils/logging'
import { withTranslation } from 'react-i18next'
import { deepEqualObjs2, isDefVal } from '../../utils/objtools'
import { mapResourceFromCode, mapResourceFromIds } from '../resource/resource_selectors'
import ResourceError from '../ui/ResourceError/ResourceError'
import { stateIsUserOwnsConsorg, stateIsUserBelongsConsorg } from '../consorg/consorg_selectors'
import ScopeSubscription from '../scope_subscription/ScopeSubscription'
import Loader from '../loader/Loader'
import {getPortalCurrentCatalogParam} from "../portal/portal_selectors";

class ApplicationScopesSubscriptions extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || this.props.tReady !== nextProps.tReady
    )
  }

  componentDidMount() {
    this.postRender()
  }

  componentDidUpdate() {
    this.postRender()
  }

  postRender() {
    if (this.props.redux.scope_subscriptions.need_request) {
      this.props.requestResource(this.props.redux.scope_subscriptions.external_resource_infos)
    }
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    if (!this.props.redux.application.found) {
      return null
    }

    if (this.props.redux.scope_subscriptions.error) {
      return <ResourceError />
    }

    var scope_subscriptions = {
      active: [],
      pending: []
    }


    if (this.props.redux.scope_subscriptions.found) {
      for (var item of this.props.redux.scope_subscriptions.items) {
        if (isDefVal(item, 'data.status') === 'ACTIVE') {
          scope_subscriptions.active.push({
            resourceCode: item.resource_code
          })
        }
        else {
          scope_subscriptions.pending.push({
            resourceCode: item.resource_code
          })
        }
      }
    }

    const delete_allowed = this.props.redux.isUserOwnsConsorg || !this.props.redux.isCurrentCatalogSensitive

    const can_delete = scope_subscriptions.active.length > 0 && delete_allowed

    const can_cancel = this.props.redux.isUserBelongsConsorg

    // const can_cancel = scope_subscriptions.pending.length > 0 && this.props.redux.isUserOwnsConsorg
    // const can_edit = this.props.redux.isUserOwnsConsorg


    return (
      <div className={this.getItemClasses().join(' ')} >
        <div className="grid g1_root g1_1col">

          <h2>{this.props.t('application:tab_subscriptions_title')}</h2>

          <h3>{this.props.t('application:tab_subscriptions_list_pending_title')}</h3>
          {(scope_subscriptions.pending.length > 0 &&
              scope_subscriptions.pending.map((scope_subscription) => <ScopeSubscription key={scope_subscription.resourceCode} resourceCode={scope_subscription.resourceCode} can_cancel={can_cancel} />)
            ) ||
            ((!this.props.redux.scope_subscriptions.found || this.props.redux.scope_subscriptions.loading) &&
              <Loader loading={true} />
            ) ||
            <p className="empty_zone">{this.props.t('application:tab_subscriptions_list_empty')}</p>
          }

          <h3>{this.props.t('application:tab_subscriptions_list_active_title')}</h3>
          {(scope_subscriptions.active.length > 0 &&
              scope_subscriptions.active.map((scope_subscription) => <ScopeSubscription key={scope_subscription.resourceCode} resourceCode={scope_subscription.resourceCode} can_delete={can_delete} light_dislay={true}/>)
            ) ||
            ((!this.props.redux.scope_subscriptions.found || this.props.redux.scope_subscriptions.loading) &&
              <Loader loading={true} />
            ) ||
            <p className="empty_zone">{this.props.t('application:tab_subscriptions_list_empty')}</p>
          }

        </div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'application',
      'subscriptions'
    ]
    return classes
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    application: mapResourceFromCode(state, 'application', ownProps.resourceCode),
    lang_code: state.lang.lang_code
  }
  redux.scope_subscriptions = mapResourceFromIds(state, 'application_scope_subscriptions', redux.application.ids)
  redux.consorg = mapResourceFromIds(state, 'consorg', redux.application.ids)
  redux.isUserOwnsConsorg = stateIsUserOwnsConsorg(state, redux.consorg.resource_code)
  redux.isUserBelongsConsorg = stateIsUserBelongsConsorg(state, redux.consorg.resource_code)
  redux.isCurrentCatalogSensitive = getPortalCurrentCatalogParam(state, 'isSensitiveCatalog')
  return {
    redux
  }
}

const mapDispatchToProps = dispatch => ({
  requestResource: (external_resource_infos) => dispatch({type:'RESOURCE_LOAD_REQUEST', external_resource_infos})
})

ApplicationScopesSubscriptions = withTranslation(['common','application'])(ApplicationScopesSubscriptions)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  ['item.items.application','item.items.consorg','lang','auth','portal.context','list.lists.application_scope_subscriptions']
)(ApplicationScopesSubscriptions)
