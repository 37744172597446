import React from 'react'
import {logRender} from '../../utils/logging'
import {isDef} from '../../utils/objtools'
import Filter from '../filter/Filter'
import AnimDelay from '../ui/AnimDelay/AnimDelay'
import {withTranslation} from "react-i18next";

class PlansFilters extends React.Component {

  render() {
    logRender(this);

    var approvals_filters = []
    if(isDef(this.props, 'approvals')){
      for (let item in this.props.approvals){
        approvals_filters.push(<Filter key={'approval-' + item} type="approvals" label={this.props.approvals[item]} isActive={this.props.approvals[item] === this.props.state.currentApproval} clickHandler={this.props.filtersHandler}/>)
      }
    }

    var rates_filters = []
    if(isDef(this.props, 'rates')){
      for (let item in this.props.rates) {
        rates_filters.push(<Filter key={'rate-' + item} type="rates" label={this.props.rates[item]} isActive={this.props.rates[item] === this.props.state.currentRate} clickHandler={this.props.filtersHandler}/>)
      }
    }

    var operations_filters = []
    if(isDef(this.props, 'operations')){
      for (let item in this.props.operations) {
        operations_filters.push(<Filter key={'operation-' + item} type="operations" label={this.props.operations[item]} isActive={this.props.operations[item] === this.props.state.currentOperation} clickHandler={this.props.filtersHandler}/>)
      }
    }

    var plans_filters = []
    if (approvals_filters.length > 0 || rates_filters.length > 0 || operations_filters.length > 0)
      plans_filters = (
        <AnimDelay animateAppearance={true} showDelay={500}>
          <h2>{this.props.t('plan_filters:title')}</h2>
          <p>{this.props.t('plan_filters:description')}</p>
          <div className="grid g1_root g1_321col">
            <div className="approval">
              <h3>{this.props.t('plan_filters:approval_bloc_title')}</h3>
              <p>{this.props.t('plan_filters:approval_bloc_desc')}</p>
              <div className="tags">{approvals_filters}</div>
            </div>
            <div className="rating">
              <h3>{this.props.t('plan_filters:rating_bloc_title')}</h3>
              <p>{this.props.t('plan_filters:rating_bloc_desc')}</p>
              <div className="tags">{rates_filters}</div>
            </div>
            <div className="operations">
              <h3>{this.props.t('plan_filters:operation_bloc_title')}</h3>
              <p>{this.props.t('plan_filters:operation_bloc_desc')}</p>
              <div className="tags">{operations_filters}</div>
            </div>
          </div>
        </AnimDelay>)

    return (
      <div className={this.getItemClasses().join(' ')}>
        {plans_filters}
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'filters'
    ]
    return classes;
  }
}


export default withTranslation('plan_filters')(PlansFilters);
