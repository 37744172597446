const getBanner = (kind) => {
    switch (kind){
        case 'cdn':
            return '/assets/footer_logos_cdn.svg'
        default:
            return null
    }
}

const getInternalLinks = (kind) => {
    switch (kind){
        case 'int':
        case 'sgf':
            return [
                {
                    label: 'Documentation',
                    link: 'https://sgithub.fr.world.socgen/pages/DigitalAgence/sparkyx-doc/#/'
                },
                {
                    label: 'Dashboard',
                    link: 'https://sgithub.fr.world.socgen/pages/DigitalAgence/sparky-dashboard/#/support/documentation'
                },
                {
                    label: 'Support',
                    link: 'https://sgithub.fr.world.socgen/pages/DigitalAgence/sparky-dashboard/#/support/request'
                }
            ]
        default:
            return []
    }
}

const getLogo = (kind) => {
    switch (kind){
        case 'baas':
            return '/assets/baas_logo_black.svg'
        case 'sg':
            return '/assets/sg_logo_white.svg'
        case 'cdn':
            return '/assets/cdn_logo_white.svg'
        case 'int':
            return '/assets/int_logo_white.svg'
        case 'assu-group':
            return '/assets/int_logo_white.svg'
        case 'assu-partners':
            return '/assets/sg_logo_white.svg'
        case 'cgi-intranet':
            return '/assets/int_logo_white.svg'
        case 'cgi-extranet':
            return '/assets/sg_logo_white.svg'
        case 'sgbdp-internal':
            return '/assets/sg_logo_white.svg'
        case 'sgbdp-open':
            return '/assets/sg_logo_white.svg'
        case 'bfcoi-internal':
            return '/assets/sg_logo_white.svg'
        case 'bfcoi-open':
            return '/assets/sg_logo_white.svg'
        case 'sgnc-internal':
            return '/assets/sg_logo_white.svg'
        case 'sgnc-open':
            return '/assets/sg_logo_white.svg'
        default:
            return '/assets/int_logo_white.svg'
    }
}

const getCopyright = (kind) => {
    switch (kind){
        case 'int':
            return '© Sparky Consumer'
        case 'sg':
            return '© Société Générale'
        case 'cdn':
            return '© Groupe Crédit du Nord'
        case 'sgf':
            return '© Société Générale Factoring'
        case 'baas':
            return '© EURO - BAAS'
        default:
            return '© Société Générale'
    }
}

const getSocialMedias = (kind) => {
    switch (kind){
        case 'sg':
            return [
                {
                    iconType: 'linkedin',
                    link: 'https://fr.linkedin.com/company/societe-generale'
                },
                {
                    iconType: 'twitter',
                    link: 'https://twitter.com/societegenerale'
                }
            ]
        case 'baas':
            return [
                {
                    iconType: 'linkedin_black',
                    link: 'https://fr.linkedin.com/company/societe-generale'
                },
                {
                    iconType: 'twitter_black',
                    link: 'https://twitter.com/societegenerale'
                }
            ]
        case 'cdn':
            return [
                {
                    iconType: 'facebook',
                    link: 'https://fr-fr.facebook.com/creditdunord'
                },
                {
                    iconType: 'linkedin',
                    link: 'https://fr.linkedin.com/company/groupe-credit-du-nord'
                },
                {
                    iconType: 'twitter',
                    link: 'https://twitter.com/creditdunord'
                },
                {
                    iconType: 'instagram',
                    link: 'https://www.instagram.com/groupecreditdunord/?hl=fr'
                }
            ]
        case 'sgf':
            return [
                {
                    iconType: 'facebook',
                    link: 'https://www.facebook.com/sharer/sharer.php?u=https://factoring.societegenerale.com/fr/nous-connaitre/propos/'
                },
                {
                    iconType: 'linkedin',
                    link: 'https://www.linkedin.com/shareArticle?mini=true&url=https://factoring.societegenerale.com/fr/nous-connaitre/propos/'
                },
                {
                    iconType: 'twitter',
                    link: 'https://twitter.com/share?url=https%3A%2F%2Ffactoring.societegenerale.com%2Ffr%2Fnous-connaitre%2Fpropos%2F&text=A+propos'
                }
            ]
        default:
            return []
    }
}

const FooterConfig = {
    getBanner,
    getInternalLinks,
    getLogo,
    getCopyright,
    getSocialMedias,
}

export default FooterConfig;

