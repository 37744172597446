import React from 'react'
import { logRender } from '../../utils/logging'
import { getFirstDef } from '../../utils/objtools'
import { Form as FinalForm, Field as FinalField} from 'react-final-form'
import { withTranslation } from 'react-i18next'
import { Form } from 'react-bootstrap'
import UiButton from '../ui/buttons/UiButton'
import ControlSelect from '../../utils/forms/ControlSelect'
import { translate_enumeration } from '../../utils/enums/enums_tools'
import { membershipRoles_options } from '../../utils/enums/membershipRoles'
import Loader from '../loader/Loader'

class RequestMembershipFinalForm extends React.Component {

  constructor(props) {
    super(props)

    this.validateForm = this.validateForm.bind(this)
  }

  validateForm(values) {
    const errors = {}

    return errors
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    if (['new','edit'].indexOf(getFirstDef(this.props, 'form_op', '')) < 0 ) {
      console.error('form_op is not defined or not handled')
      return null
    }

    const t = this.props.t

    var membershipRoles_options_translated = translate_enumeration(membershipRoles_options, t)

    return (
      <FinalForm
        initialValues={this.props.initialValues}
        onSubmit={this.props.onSubmit}
        validate={this.validateForm}
      >
        {({ handleSubmit, pristine, form, submitting }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <fieldset>
                <legend>{t('membership:fieldset_role')}</legend>
                <Form.Group controlId="membershipRole">
                  <Form.Label>{t('membership:field_membershipRole')}</Form.Label>
                  <FinalField name="membershipRole" component={ControlSelect} options={membershipRoles_options_translated} />
                  <Form.Text className="text-muted">{t('membership:field_membershipRole_desc')}</Form.Text>
                </Form.Group>
              </fieldset>
              <div className="form-actions">
                <Loader loading={submitting} />
                <UiButton variant="primary" type="submit" disabled={(pristine && this.props.form_op==='edit') ||submitting}>{t(this.props.form_op==='new'?'membership:submit_request':'forms:submit_update')}</UiButton>
                <UiButton variant="secondary" type="button" disabled={pristine||submitting} onClick={form.reset}>{t('forms:reset')}</UiButton>
              </div>
            </Form>
          )
        }}
      </FinalForm>
    )
  }
}

export default withTranslation(
    ['forms','membership','enums']
  )(RequestMembershipFinalForm)
