import React from 'react'
import {logRender} from '../../utils/logging'


class Feedback extends React.Component {


  render() {
    logRender(this);

    var label = this.props.label
    var type = this.props.type


    return (
      <div className={this.getItemClasses().join(' ')}>
        <label className="text">{type} : </label>
        <div className="pill">{ label }</div>
      </div>
    )
  }


  getItemClasses() {
    var classes = [
      'feedback',
    ];
    if (this.props.match) {
      classes.push('match')
    }
    return classes;
  }
}

export default Feedback;

