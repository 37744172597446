import React from 'react'
import { connect } from 'react-redux'
import { logRender } from '../../utils/logging'
import { buildUri } from '../../utils/urls/internal'
import { Link } from "react-router-dom"
import itemTypes from './items'
import { isDef, getFirstDef, isDefVal, deepEqualObjs2 } from '../../utils/objtools'
import Icon from '../icon/Icon'
import { withTranslation } from 'react-i18next'
import UiButton from '../ui/buttons/UiButton'

class GGMenuItem extends React.Component {

  constructor(props) {
    super(props);

    this.rollHandler = this.rollHandler.bind(this)
    this.clickHandler = this.clickHandler.bind(this)
    this.actionHandler = this.actionHandler.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || this.props.t !== nextProps.t
    )
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    if (!this.props.redux.display)
      return null

    if (!this.props.redux.lang_code || !this.props.redux.route_ids)
      return null

    var content, title
    if (isDef(this.props.redux.item, 'component')) {
      // Item is a component
      var ItemComponent = itemTypes[this.props.redux.item.component]
      var component_props = getFirstDef(this.props.redux.item, 'component_props', {})
      content =
        <div className="title">
          <ItemComponent {...this.props} {...component_props} />
        </div>
    }
    else if (isDef(this.props.redux.item, 'button_variant')) {
      if (isDef(this.props.redux.item, 'title_i18n'))
        title = this.props.t(this.props.redux.item.title_i18n)
      else
        title = this.props.redux.item.title
      content =
        <div className="title">
          <UiButton variant={this.props.redux.item.button_variant} onClick={this.actionHandler} icon={this.props.redux.item.icon_type} >
            <span>{title}</span>
          </UiButton>
        </div>
    }
    else if (isDef(this.props.redux.item, 'icon_type')) {
      // Item is icon + text
      if (isDef(this.props.redux.item, 'title_i18n'))
        title = this.props.t(this.props.redux.item.title_i18n)
      else
        title = this.props.redux.item.title
      content =
        <div className="title">
          <Icon type={this.props.redux.item.icon_type} />
          <span>{title}</span>
        </div>
    }
    else {
      // Item is text only
      if (isDef(this.props.redux.item, 'title_i18n'))
        title = this.props.t(this.props.redux.item.title_i18n)
      else
        title = this.props.redux.item.title
      content =
        <div className="title">
          <span>{title}</span>
        </div>
    }
    switch (this.props.redux.item.type) {
      case 'normal':
        var uri = '/'
        if (isDef(this.props.redux, 'item.target_uri')) {
          uri = this.props.redux.item.target_uri
        }
        else if (isDef(this.props.redux, 'item.target_scheme_key')) {
          var ids = {...this.props.redux.route_ids, ...this.props.redux.item.ids}
          uri = buildUri(this.props.redux.item.target_scheme_key, ids)
          if (!uri) {
            return null
          }
        }
        return (
          <div className={this.getItemClasses().join(' ')}>
            <Link to={uri}>{content}</Link>
          </div>
        )
      case 'opener':
      case 'group_opener':
        return (
          <div className={this.getItemClasses().join(' ')} onClick={this.clickHandler} onMouseEnter={this.rollHandler}>
            {content}
          </div>
        )
      case 'action':
        return (
          <div className={this.getItemClasses().join(' ')} onClick={this.actionHandler}>
            {content}
          </div>
        )
      case 'action_button':
        // click handler is already set on the button
        return (
          <div className={this.getItemClasses().join(' ')} >
            {content}
          </div>
        )
      case 'autonomous':
        return (
          <div className={this.getItemClasses().join(' ')} >
            {content}
          </div>
        )
      default:
        console.error('item type error')
        return null
    }
  }

  getItemClasses() {
    var classes = [
      'ggmenu-menu-item',
      'name-'+this.props.name,
      'type-'+this.props.redux.item.type
    ]
    return classes;
  }

  clickHandler() {
    this.props.menuItemRoll(this.props.name)
  }

  rollHandler() {

  }

  actionHandler() {
    var action_type = isDefVal(this.props, 'redux.item.action_type')
    if (action_type)
      this.props.menuItemAction(action_type)
  }
}

const mapStateToProps = (state, ownProps) => {
  const redux = {
    lang_code: state.lang.lang_code,
    item: state.ggmenu.items[ownProps.name],
    route_ids: getFirstDef(state.portal.page, 'routeProps.match.params', null),
    display: true
  }
  var display_lang_condition
  if ((display_lang_condition = isDefVal(redux, 'item.display_lang_condition')))
    redux.display = redux.display && display_lang_condition === redux.lang_code
  var display_state_condition
  if ((display_state_condition = isDefVal(redux, 'item.display_state_condition')))
    redux.display = redux.display && display_state_condition(state)
  return {redux}
}

const mapDispatchToProps = dispatch => ({
  menuItemRoll: (item_key) => { dispatch({type: 'GGMENU_MENUITEM_OPENER_TRIGGER', item_key}) },
  menuItemAction: (type) => { dispatch({type}) }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // cannot use utils/redux connect here beacause of unpredictable display_state_condition dependencies
)(withTranslation('menu')(GGMenuItem))
