import { submitConsorgFormDeleteRoutine } from './consorg_routines'
import { put, select, call } from 'redux-saga/effects'
import { getPortalCurrentOrganisationAndCatalogIds } from '../portal/portal_selectors'
import { deleteItem } from '../item/item_delete_saga.js'
import genericSubmissionSaga from '../item/genericSubmissionSaga'

function* saga(action) {
  const { consorg_id } = action.payload

  // prepare some ids
  var ids = yield select(getPortalCurrentOrganisationAndCatalogIds)
  ids = {
    ...ids,
    consorg_id
  }

  const {error} = yield call(deleteItem, 'consorg_delete', 'consorg', ids)

  // Generic unhandled error
  if (error)
    throw error

  // go to home ('catalog' route, not 'home' route, to avoid a switch to default catalog)
  yield put({type: 'HISTORY_PUSH', scheme_key:'catalog', ids})
}

export function* submitDeleteConsorgForm(action) {
  yield call(genericSubmissionSaga, {
    saga,
    action,
    routine: submitConsorgFormDeleteRoutine,
    successfull_title: 'consorg:successfull_delete_title',
    successfull_body: 'consorg:successfull_delete_body',
    unsuccessfull_title: 'consorg:unsuccessfull_delete_title',
    unsuccessfull_body: 'consorg:unsuccessfull_delete_body'
  })
}