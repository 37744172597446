import React from 'react'
import { connect } from '../../utils/redux'
import { logRender } from '../../utils/logging'
import { withTranslation } from 'react-i18next'
import { deepEqualObjs2, getFirstDef } from '../../utils/objtools'
import { mapResourceFromCode } from '../resource/resource_selectors'
import Icon from '../icon/Icon'
import Button from 'react-bootstrap/Button'
import Visibility from '../../utils/debug/Visibility'
import {modalAdd} from "../modals/modals_actions";
import UiProperty from "../ui/UiProperty/UiProperty";
import { submitSubscriptionPlansFinalFormDeleteHandler } from "../subscription_plans/subscription_plans_routines";
import SubscriptionPlansDeleteFinalForm from "../subscription_plans/SubscriptionPlansDeleteFinalForm";
import {bindPromiseCreators} from "redux-saga-routines";
import {buildUri} from "../../utils/urls/internal";
import {Link} from "react-router-dom";

class PlanSubscription extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || this.props.tReady !== nextProps.tReady
    )
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    const plan_subscription = this.props.redux.plan_subscription

    // Assume Optimistic Rendering
    if (plan_subscription.error) {
      return <p>Error</p>
    }

    var title = getFirstDef(plan_subscription, ['data.product.title'], '--')
    title = <Link to={this.props.redux.plan_uri}>{title}</Link>

    var status = getFirstDef(plan_subscription, ['data.status'], '--')

    var content_pills = []
    content_pills.push(<div key="type" className="pill">{this.props.t('plan_subscription:badge')}</div>)
    content_pills.push(<div key="status" className="pill secondary">{status}</div>)

    return (
      <div className={this.getItemClasses().join(' ')} >
        <div className="content">
          <Icon type="black_scope" />
          <div className="infos">
            {content_pills}
            <h4 key="title">{title}</h4>
          </div>
          <div className="actions">
            <Visibility key="delete_button" condition={this.props.can_delete}>
              <Button key="delete_button" variant="danger" onClick={()=>this.deleteClick()}>{this.props.t('plan_subscription:delete_button')}</Button>
            </Visibility>
            <Visibility key="cancel_button" condition={this.props.can_cancel}>
              <Button key="cancel_button" variant="danger" onClick={()=>this.cancelClick()}>{this.props.t('plan_subscription:cancel_button')}</Button>
            </Visibility>
             {/*TODO show when ressource is OK*/}
            {/*{otherContentActions}*/}
          </div>
          <div className="details">
            <UiProperty key="productName" name="Product Version" value={getFirstDef(plan_subscription, ['data.product.version'], '--')} />
            <UiProperty key="productVersion" name="Plan Name" value={getFirstDef(plan_subscription, ['data.plan'], '--')} />
            {/*{details}*/}
          </div>
        </div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'plan_subscription',
      'bloc'
    ]
    return classes
  }

  deleteClick() {
    this.props.modalAdd(
      'form',
        {
          title: this.props.t('plan_subscription:delete_modal_title'),
          message: this.props.t('plan_subscription:delete_modal_message'),
          FormComponent: SubscriptionPlansDeleteFinalForm,
          formComponentProps: {
            form_op: "new",
            initialValues: {
              ids: {
                ...this.props.redux.plan_subscription.ids,
                product_id: this.props.redux.plan_subscription.data.product.id,
              }
            }
          },
          submitAction: this.props.submitSubscriptionPlansFinalFormDeleteHandler,
          closeAction: false
        }
    )
  }
  cancelClick() {
      // TODO
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    plan_subscription: mapResourceFromCode(state, 'plan_subscription', ownProps.resourceCode),
    lang_code: state.lang.lang_code
  }

  redux.plan_uri = buildUri('product', { ...redux.plan_subscription.ids, product_uname:redux.plan_subscription.data.product.thirdSystemReference.name, lang_code: redux.lang_code}) + ':' + redux.plan_subscription.data.product.version

  return {
    redux
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindPromiseCreators({
    submitSubscriptionPlansFinalFormDeleteHandler
  }, dispatch),
  modalAdd: (modalType, modalProps) => dispatch(modalAdd(modalType, modalProps))
})

PlanSubscription = withTranslation(['common','plan','plan_subscription'])(PlanSubscription)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  ['item.items.plan_subscription','lang']
)(PlanSubscription)
