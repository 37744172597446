import { isDef, isDefVal } from '../objtools'
import { getPatternIds } from './tools'

/**
 * Map des uris externes
 */
const _external_resource_schemes = {

  // Lists

  catalog_products: {
    info: 'List of public products in a catalog.',
    type: 'list',
    item_type: 'product',
    uri_pattern: "/consumer/organizations/:organisation_id/catalogs/:catalog_id/products",
    resource_code_pattern: 'catalog/:catalog_id/products'
  },

  catalog_private_products: {
    info: "List of private products in a catalog, visible by members of a specific consumer organization.",
    type: 'list',
    item_type: 'product',
    uri_pattern: "/consumer/organizations/:organisation_id/catalogs/:catalog_id/private/products?consumer-organization=:consorg_id",
    resource_code_pattern: 'catalog/:catalog_id/private/products?consumer-organization=:consorg_id'
  },

  catalog_apis: {
    info: 'List of public apis in a catalog.',
    type: 'list',
    item_type: 'api',
    uri_pattern: '/consumer/organizations/:organisation_id/catalogs/:catalog_id/apis',
    resource_code_pattern: 'catalog/:catalog_id/apis'
  },

  catalog_private_apis: {
    info: 'List of private apis in a catalog, visible by members of a specific consumer organization.',
    type: 'list',
    item_type: 'api',
    uri_pattern: "/consumer/organizations/:organisation_id/catalogs/:catalog_id/private/apis?consumer-organization=:consorg_id",
    resource_code_pattern: 'catalog/:catalog_id/private/apis?consumer-organization=:consorg_id'
  },

  catalog_consorgs: {
    info: 'List of consumer organizations, either public or visible by the current user.',
    type: 'list',
    item_type: 'consorg',
    uri_pattern: '/consumer/organizations/:organisation_id/catalogs/:catalog_id/consumer-organizations',
    resource_code_pattern: 'catalog/:catalog_id/consorgs'
  },

  catalog_scopes: {
    info: 'List of public scopes in a catalog.',
    type: 'list',
    item_type: 'scope',
    uri_pattern: '/consumer/organizations/:organisation_id/catalogs/:catalog_id/scopes',
    resource_code_pattern: 'catalog/:catalog_id/scopes'
  },

  catalog_private_scopes: {
    info: 'List of private scopes in a catalog.',
    type: 'list',
    item_type: 'scope',
    uri_pattern: '/consumer/organizations/:organisation_id/catalogs/:catalog_id/private/scopes?consumer-organization=:consorg_id',
    resource_code_pattern: 'catalog/:catalog_id/private/scopes?consumer-organization=:consorg_id'
  },

  consorg_applications: {
    info: 'List of applications in a consumer organization, only visible by its members.',
    type: 'list',
    item_type: 'application',
    uri_pattern: '/consumer/organizations/:organisation_id/catalogs/:catalog_id/consumer-organizations/:consorg_id/applications',
    resource_code_pattern: 'consorg/:consorg_id/applications'
  },

  consorg_memberships: {
    info: 'List of a consumer organization memberships.',
    type: 'list',
    item_type: 'consorg_membership',
    uri_pattern: '/consumer/organizations/:organisation_id/catalogs/:catalog_id/consumer-organizations/:consorg_id/memberships',
    resource_code_pattern: 'consorg/:consorg_id/memberships'
  },

  consorg_issues: {
    info: 'List of issues in a consumer organization, only visible by its members.',
    type: 'list',
    item_type: 'issue',
    uri_pattern: '/consumer/organizations/:organisation_id/catalogs/:catalog_id/consumer-organizations/:consorg_id/tickets?state=all&page=1',
    resource_code_pattern: 'consorg/:consorg_id/tickets?state=all&page=1'
  },

  issue_comments: {
    info: 'List of issue comments in a consumer organization, only visible by its members.',
    type: 'list',
    item_type: 'comment',
    uri_pattern: '/consumer/organizations/:organisation_id/catalogs/:catalog_id/consumer-organizations/:consorg_id/tickets/:issue_id/comments',
    resource_code_pattern: 'issue/:issue_id/comments'
  },

  organisation_static_contents: {
    info: 'List of static content available in the current organization.',
    type: 'list',
    source: 'portal',
    item_type: 'static_content',
    uri_pattern: '/static_content/:organisation_uname/static_content.json',
    resource_code_pattern: 'org/:organisation_id/static_content',
  },

  my_memberships: {
    info: 'List of the current logged in user memberships (pending and active ones), filtered on a catalog.',
    type: 'list',
    item_type: 'consorg_membership',
    uri_pattern: '/consumer/organizations/:organisation_id/users/me/memberships?catalog=:catalog_id',
    resource_code_pattern: 'user/me/memberships'
  },

  // Items

  me: {
    info: 'Give the session status of the user in the current organization (header).',
    type: 'item',
    item_type: 'me',
    uri_pattern: "/consumer/users/me",
    future_uri_pattern: "/consumer/organizations/:organisation_id/users/me",
    resource_code_pattern: 'user/me',
    notifyOnError: false
  },

  organisation: {
    info: 'Single referential resource organization.',
    type: 'item',
    item_type: 'organisation',
    uri_pattern: '/consumer/referentials/organizations/:organisation_id',
    expected_paths: [
      'code',
      'label'
    ],
    resource_code_pattern: 'org/:organisation_id',
    load_associates: ['organisation_static_contents']
  },

  catalog: {
    info: 'Single referential resource catalog.',
    type: 'item',
    item_type: 'catalog',
    uri_pattern: '/consumer/referentials/organizations/:organisation_id/catalogs/:catalog_id',
    expected_paths: [
      'code',
      'label'
    ],
    resource_code_pattern: 'catalog/:catalog_id',
    resource_aliases: [
      {alias: '/orgs/:organisation_id/catalogs/:catalog_uname'},
      {alias: '/catalogs/:catalog_uname'}
    ],
    harvest_ids: ['organisation'],
    load_associates: ['catalog_products', 'catalog_apis', 'catalog_consorgs', 'catalog_scopes']
  },

  auth_adapters: {
    info: 'Single referential Sparky AS auth adapters.',
    type: 'item',
    item_type: 'auth_adapters',
    uri_pattern: '/consumer/referentials/organizations/:organisation_id/catalogs/:catalog_id/authentication-adapters',
    resource_code_pattern: 'org/:organisation_id/catalog/:catalog_id/authentication-adapters',
  },

  api: {
    info: 'Single resource api, either public or visible by the current user.',
    type: 'item',
    item_type: 'api',
    uri_pattern: '/consumer/organizations/:organisation_id/catalogs/:catalog_id/apis/:api_id',
    resource_code_pattern: 'api/:api_id',
    resource_aliases: [
      {alias: '/catalogs/:catalog_uname/apis/:api_uname'}
    ],
    harvest_ids: ['catalog'],
    load_associates: ['api_def'],
    warmup_associates: ['api_related_products']
  },
  api_def: {
    info: 'Single resource api definition, either public or visible by the current user.',
    type: 'item',
    item_type: 'api_def',
    uri_pattern: '/consumer/organizations/:organisation_id/catalogs/:catalog_id/apis/:api_id/definition',
    resource_code_pattern: 'api_def/:api_id',
    resource_aliases: [
      {alias: '/catalogs/:catalog_uname/apis_definitions/:api_uname'}
    ],
    harvest_ids: ['catalog','api']
  },
  api_wsdl: {
    info: 'SOAP api WSDL, either public or visible by the current user.',
    type: 'item',
    item_type: 'api_wsdl',
    uri_pattern: '/consumer/organizations/:organisation_id/catalogs/:catalog_id/apis/:api_id/wsdl',
    resource_code_pattern: 'api/:api_id/wsdl',
    resource_aliases: [
      {alias: '/catalogs/:catalog_uname/apis/:api_uname/wsdl'}
    ],
    harvest_ids: ['catalog','api']
  },

  api_related_products: {
    info: "List of products related to a given API.",
    type: 'list',
    item_type: 'product',
    uri_pattern: "/consumer/organizations/:organisation_id/catalogs/:catalog_id/products?api-id=:api_id",
    resource_code_pattern: 'catalog/:catalog_id/products?api-id=:api_id',
  },

  product: {
    info: 'Single resource product, either public or visible by the current user.',
    type: 'item',
    item_type: 'product',
    uri_pattern: "/consumer/organizations/:organisation_id/catalogs/:catalog_id/products/:product_id",
    resource_code_pattern: 'product/:product_id',
    resource_aliases: [
      {alias: '/catalogs/:catalog_uname/products/:product_uname'}
    ],
    harvest_ids: ['catalog'],
    load_associates: ['product_def']
  },
  product_def: {
    info: 'Single resource product definition, either public or visible by the current user.',
    type: 'item',
    item_type: 'product_def',
    uri_pattern: "/consumer/organizations/:organisation_id/catalogs/:catalog_id/products/:product_id/definition",
    resource_code_pattern: 'product_definition/:product_id',
    resource_aliases: [
      {alias: '/catalogs/:catalog_uname/products/:product_uname'}
    ],
    harvest_ids: ['catalog','product']
  },

  scope: {
    info: 'Single resource scope.',
    type: 'item',
    item_type: 'scope',
    uri_pattern: '/consumer/organizations/:organisation_id/catalogs/:catalog_id/scopes/:scope_id',
    resource_code_pattern: 'scope/:scope_id',
    resource_aliases: [
      {alias: '/catalogs/:catalog_uname/scopes/:scope_uname'}
    ],
    harvest_ids: ['catalog']
  },

  scope_featured_public_apis: {
    info: "List of public apis protected by a scope.",
    type: 'list',
    item_type: 'api',
    uri_pattern: "/consumer/organizations/:organisation_id/catalogs/:catalog_id/scopes/:scope_id/public-apis",
    resource_code_pattern: 'catalog/:catalog_id/scopes/:scope_uname/public-apis',
  },

  scope_featured_private_apis: {
    info: "List of private apis protected by a scope.",
    type: 'list',
    item_type: 'api',
    uri_pattern: "/consumer/organizations/:organisation_id/catalogs/:catalog_id/consumer-organizations/:consorg_id/scopes/:scope_id/private-apis",
    resource_code_pattern: 'catalog/:catalog_id/consumer-organizations/:consorg_id/scopes/:scope_id/private-apis',
  },

  subscription_scopes_new: {
    info: 'Subscribe to scopes',
    type: 'item_new',
    item_type: 'subscriptionscopes',
    uri_pattern: "/consumer/organizations/:organisation_id/catalogs/:catalog_id/consumer-organizations/:consorg_id/applications/:application_id/scopes",
    refresh_force_resources: ['application_scope_subscriptions']
  },

  subscription_scopes_delete: {
    info: 'Unsubscribe from scopes',
    type: 'item_delete',
    item_type: 'subscriptionscopes',
    uri_pattern: "/consumer/organizations/:organisation_id/catalogs/:catalog_id/consumer-organizations/:consorg_id/applications/:application_id/scopes/:scope_name",
    refresh_force_resources: ['application_scope_subscriptions']
  },

  subscription_scopes_cancel: {
    info: 'Cancel an pending subscription to scope',
    type: 'item_delete',
    item_type: 'subscriptionscopes',
    uri_pattern: "/consumer/organizations/:organisation_id/catalogs/:catalog_id/consumer-organizations/:consorg_id/applications/:application_id/scopes/:scope_name/cancel-pending",
    refresh_force_resources: ['application_scope_subscriptions']
  },

  subscription_plan_new: {
    info: 'Subscribe to plan',
    type: 'item_new',
    item_type: 'subscriptionplan',
    uri_pattern: "/consumer/organizations/:organisation_id/catalogs/:catalog_id/consumer-organizations/:consorg_id/applications/:application_id/plans",
    refresh_force_resources: ['application_plans_subscriptions']
  },

  subscription_plans_delete: {
    info: 'Unsubscribe from plan',
    type: 'item_delete',
    item_type: 'subscriptionplan',
    uri_pattern: "/consumer/organizations/:organisation_id/catalogs/:catalog_id/consumer-organizations/:consorg_id/applications/:application_id/products/:product_id/plans/:plan_id",
    refresh_force_resources: ['application_plans_subscriptions']
  },

  application: {
    info: 'Single resource application.',
    type: 'item',
    item_type: 'application',
    uri_pattern: "/consumer/organizations/:organisation_id/catalogs/:catalog_id/consumer-organizations/:consorg_id/applications/:application_id",
    resource_code_pattern: 'application/:application_id',
    resource_aliases: [
      {alias: '/catalogs/:catalog_uname/consorgs/:consorg_uname/applications/:application_uname'}
    ],
    harvest_ids: ['catalog'],
    warmup_associates: ['application_credentials', 'application_scope_subscriptions','application_plans_subscriptions']
  },

  application_new: {
    info: 'Create a new application.',
    type: 'item_new',
    item_type: 'application',
    uri_pattern: "/consumer/organizations/:organisation_id/catalogs/:catalog_id/consumer-organizations/:consorg_id/applications",
    refresh_force_resources: ['application', 'consorg_applications']
  },
  application_edit: {
    info: 'Edit an application.',
    type: 'item_edit',
    item_type: 'application',
    uri_pattern: '/consumer/organizations/:organisation_id/catalogs/:catalog_id/consumer-organizations/:consorg_id/applications/:application_id',
    refresh_force_resources: ['application', 'consorg_applications']
  },
  application_delete: {
    info: 'Delete an application.',
    type: 'item_delete',
    item_type: 'application',
    uri_pattern: '/consumer/organizations/:organisation_id/catalogs/:catalog_id/consumer-organizations/:consorg_id/applications/:application_id',
    refresh_force_resources: ['consorg_applications']
  },

  application_credentials: {
    info: 'List of credentials of an application.',
    type: 'list',
    item_type: 'credential',
    uri_pattern: '/consumer/organizations/:organisation_id/catalogs/:catalog_id/consumer-organizations/:consorg_id/applications/:application_id/credentials',
    resource_code_pattern: 'application/:application_id/credentials',
  },

  application_scope_subscriptions: {
    info: 'List of scope subscriptions of an application.',
    type: 'list',
    item_type: 'scope_subscription',
    uri_pattern: '/consumer/organizations/:organisation_id/catalogs/:catalog_id/consumer-organizations/:consorg_id/applications/:application_id/scopes',
    resource_code_pattern: 'application/:application_id/scopes',
  },

  application_plans_subscriptions: {
    info: 'List of plans subscriptions of an application.',
    type: 'list',
    item_type: 'plan_subscription',
    uri_pattern: '/consumer/organizations/:organisation_id/catalogs/:catalog_id/consumer-organizations/:consorg_id/applications/:application_id/plans',
    resource_code_pattern: 'application/:application_id/plans',
  },

  issue: {
    info: 'Single resource issue.',
    type: 'item',
    item_type: 'issue',
    uri_pattern: "/consumer/organizations/:organisation_id/catalogs/:catalog_id/consumer-organizations/:consorg_id/tickets/:issue_id",
    resource_code_pattern: 'issue/:issue_id',
    resource_aliases: [
      {alias: '/catalogs/:catalog_uname/consorgs/:consorg_uname/issues/:issue_uname'}
    ],
    harvest_ids: ['catalog'],
    warmup_associates: ['issue_comments']
  },
  issue_new: {
    info: 'Create a new issue.',
    type: 'item_new',
    item_type: 'issue',
    uri_pattern: "/consumer/organizations/:organisation_id/catalogs/:catalog_id/consumer-organizations/:consorg_id/tickets",
    refresh_force_resources: ['issue','consorg_issues']
  },
  issue_delete: {
    info: 'Edit an issue.',
    type: 'item_delete',
    item_type: 'issue',
    uri_pattern: "/consumer/organizations/:organisation_id/catalogs/:catalog_id/consumer-organizations/:consorg_id/tickets/:issue_id",
    refresh_force_resources: ['issue','consorg_issues']
  },

  comment: {
    info: 'Single resource comment.',
    type: 'item',
    item_type: 'comment',
    uri_pattern: "xxx",
    resource_code_pattern: 'issue/:issue_id/comment/:comment_id',
  },
  comment_new: {
    info: 'Create a new comment.',
    type: 'item_new',
    item_type: 'comment',
    uri_pattern: "/consumer/organizations/:organisation_id/catalogs/:catalog_id/consumer-organizations/:consorg_id/tickets/:issue_id/comments",
    refresh_force_resources: ['issue','issue_comments']
  },

  consorg_membership: {
    info: 'Consumer organization membership.',
    type: 'item',
    item_type: 'consorg_membership',
    uri_pattern: 'not_used',
    resource_code_pattern: 'membership/:consorg_membership_id',
  },
  consorg_membership_new: {
    info: 'Create a new consumer organization membership.',
    type: 'item_new',
    item_type: 'consorg_membership',
    uri_pattern: '/consumer/organizations/:organisation_id/catalogs/:catalog_id/consumer-organizations/:consorg_id/memberships',
    refresh_force_resources: ['consorg_memberships']
  },
  consorg_membership_alter: {
    info: 'Alter a consumer organization membership.',
    type: 'item_alter',
    item_type: 'consorg_membership',
    uri_pattern: '/consumer/organizations/:organisation_id/catalogs/:catalog_id/consumer-organizations/:consorg_id/memberships/:consorg_membership_id',
    refresh_force_resources: ['consorg_memberships']
  },
  consorg_membership_invitation_alter: {
    info: 'Accept or refuse a consorg invitation.',
    type: 'item_alter',
    item_type: 'consorg_membership',
    uri_pattern: '/consumer/organizations/:organisation_id/catalogs/:catalog_id/consumer-organizations/:consorg_id/memberships/:consorg_membership_id',
    refresh_force_resources: ['my_memberships']
  },
  consorg_membership_delete: {
    info: 'Delete a consumer organization membership.',
    type: 'item_delete',
    item_type: 'consorg_membership',
    uri_pattern: '/consumer/organizations/:organisation_id/catalogs/:catalog_id/consumer-organizations/:consorg_id/memberships/:consorg_membership_id',
    refresh_force_resources: ['consorg_memberships']
  },

  consorg_membership_request_new: {
    info: 'Create a new consumer organization membership request.',
    type: 'item_new',
    item_type: 'consorg_membership',
    uri_pattern: '/consumer/organizations/:organisation_id/catalogs/:catalog_id/consumer-organizations/:consorg_id/memberships/request',
    refresh_force_resources: ['consorg_memberships','my_memberships']
  },
  consorg_membership_request_alter: {
    info: 'Alter a consumer organization membership request.',
    type: 'item_alter',
    item_type: 'consorg_membership',
    uri_pattern: '/consumer/organizations/:organisation_id/catalogs/:catalog_id/consumer-organizations/:consorg_id/memberships/request/:consorg_membership_id',
    refresh_force_resources: ['consorg_memberships','my_memberships']
  },
  consorg_membership_request_invitation_alter: {
    info: 'Accept or refuse a consorg request.',
    type: 'item_alter',
    item_type: 'consorg_membership',
    uri_pattern: '/consumer/organizations/:organisation_id/catalogs/:catalog_id/consumer-organizations/:consorg_id/memberships/request/:consorg_membership_id',
    refresh_force_resources: ['consorg_memberships','my_memberships']
  },

  consorg: {
    info: 'Single resource consumer organization, either public or visible by the current user.',
    type: 'item',
    item_type: 'consorg',
    uri_pattern: '/consumer/organizations/:organisation_id/catalogs/:catalog_id/consumer-organizations/:consorg_id',
    resource_code_pattern: 'consorg/:consorg_id',
    resource_aliases: [
      {alias: '/catalogs/:catalog_uname/consorgs/:consorg_uname'}
    ],
    harvest_ids: ['catalog'],
    warmup_associates: ['consorg_memberships', 'consorg_applications', 'consorg_issues']
  },
  consorg_new: {
    info: 'Create a new consumer organization.',
    type: 'item_new',
    item_type: 'consorg',
    uri_pattern: '/consumer/organizations/:organisation_id/catalogs/:catalog_id/consumer-organizations',
    refresh_force_resources: ['consorg', 'catalog_consorgs', 'my_memberships']
  },
  consorg_edit: {
    info: 'Edit a consumer organization.',
    type: 'item_edit',
    item_type: 'consorg',
    uri_pattern: '/consumer/organizations/:organisation_id/catalogs/:catalog_id/consumer-organizations/:consorg_id',
    refresh_force_resources: ['consorg', 'catalog_consorgs']
  },
  consorg_delete: {
    info: 'Delete a consumer organization.',
    type: 'item_delete',
    item_type: 'consorg',
    uri_pattern: '/consumer/organizations/:organisation_id/catalogs/:catalog_id/consumer-organizations/:consorg_id',
    refresh_force_resources: ['catalog_consorgs', 'my_memberships']
  },

  credential: {
    info: 'Application credential.',
    type: 'item',
    item_type: 'credential',
    uri_pattern: '/consumer/organizations/:organisation_id/catalogs/:catalog_id/consumer-organizations/:consorg_id/applications/:application_id/credentials/:credential_id',
    resource_code_pattern: 'credential/:credential_id',
  },
  credential_new: {
    info: 'Create a new application credential.',
    type: 'item',
    item_type: 'credential',
    uri_pattern: '/consumer/organizations/:organisation_id/catalogs/:catalog_id/consumer-organizations/:consorg_id/applications/:application_id/credentials',
    refresh_force_resources: ['application_credentials']
  },
  credential_edit: {
    info: 'Edit an application credential.',
    type: 'item',
    item_type: 'credential',
    uri_pattern: '/consumer/organizations/:organisation_id/catalogs/:catalog_id/consumer-organizations/:consorg_id/applications/:application_id/credentials/:credential_id',
    refresh_force_resources: ['application_credentials'] // credential - En attendant issue sur single
  },
  credential_reset: {
    info: 'Reset an application credential.',
    type: 'item',
    item_type: 'credential',
    uri_pattern: '/consumer/organizations/:organisation_id/catalogs/:catalog_id/consumer-organizations/:consorg_id/applications/:application_id/credentials/:credential_id/reset',
    refresh_force_resources: ['application_credentials'] // credential - En attendant issue sur single
  },
  credential_verify: {
    info: 'Verify an application credential.',
    type: 'item',
    item_type: 'credential',
    uri_pattern: '/consumer/organizations/:organisation_id/catalogs/:catalog_id/consumer-organizations/:consorg_id/applications/:application_id/credentials/:credential_id/verify',
    refresh_force_resources: ['application_credentials'] // credential - En attendant issue sur single
  },
  credential_delete: {
    info: 'Delete an application credential.',
    type: 'item',
    item_type: 'credential',
    uri_pattern: '/consumer/organizations/:organisation_id/catalogs/:catalog_id/consumer-organizations/:consorg_id/applications/:application_id/credentials/:credential_id',
    refresh_force_resources: ['application_credentials']
  },

  scope_subscription: {
    info: 'Application scope subscription.',
    type: 'item',
    item_type: 'scope_subscription',
    uri_pattern: 'xxx',
    resource_code_pattern: 'application/:application_id/scopes/:scope_id',
  },

  scope_subscription_routed_apis: {
    info: 'List of apis routed by a subscribed scope.',
    type: 'item',
    item_type: 'scope_subscription_routed_apis',
    uri_pattern: '/consumer/organizations/:organisation_id/catalogs/:catalog_id/consumer-organizations/:consorg_id/applications/:application_id/scopes/:scope_id/routed-apis',
    resource_code_pattern: 'application/:application_id/scopes/:scope_uname/routed-apis',
  },

  plan_subscription: {
    info: 'Application plan subscription.',
    type: 'item',
    item_type: 'plan_subscription',
    uri_pattern: 'xxx',
    resource_code_pattern: 'application/:application_id/plans/:plan_subscription_id',
  },

  api_kat_information: {
    info: 'KAT API information',
    type: 'item',
    item_type: 'api_kat_information',
    uri_pattern: '/consumer/referentials/thirds?source=kat&codeIrt=:code_irt&trigram=:trigram',
    resource_code_pattern: 'referentials/thirds?codeIrt=:code_irt&trigram=:trigram',
  },

  application_analytics: {
    info: 'Analytics data of an application.',
    type: 'item',
    item_type: 'application_analytics',
    uri_pattern: '/consumer/organizations/:organisation_id/catalogs/:catalog_id/consumer-organizations/:consorg_id/applications/:application_id/analytics?period=:period',
    resource_code_pattern: 'application/:application_id/analytics?period=:period',
  },

  elasticsearch_application_url: {
    info: 'Elastic search Application url',
    type: 'item',
    item_type: 'elasticsearch_application_url',
    uri_pattern: 'https://elasticsearch.prd.xbl.aws.socgen/_plugin/kibana/app/kibana#/dashboard/a490f430-d10b-11ea-9472-1fd58938c50d?_g=(filters:!())&_a=(description:\'\',filters:!((meta:(alias:!n,disabled:!f,key:client_app_name,negate:!f,params:(query::application_name),type:phrase,value::application_name),query:(match:(client_app_name:(query::application_name,type:phrase)))),(meta:(alias:!n,disabled:!f,key:organization,negate:!f,params:(query::organisation_id),type:phrase,value::organisation_id),query:(match:(organization:(query::organisation_id,type:phrase)))),(meta:(alias:!n,disabled:!f,key:catalog,negate:!f,params:(query::catalog_id),type:phrase,value::catalog_id),query:(match:(catalog:(query::catalog_id,type:phrase))))))',
  },

  elasticsearch_api_url: {
    info: 'Elastic search Api url',
    type: 'item',
    item_type: 'elasticsearch_api_url',
    uri_pattern: 'https://elasticsearch.prd.xbl.aws.socgen/_plugin/kibana/app/kibana#/dashboard/a490f430-d10b-11ea-9472-1fd58938c50d?_g=(filters:!())&_a=(description:\'\',filters:!((meta:(alias:!n,disabled:!f,key:api_name,negate:!f,params:(query::api_uname),type:phrase,value::api_uname),query:(match:(api_name:(query::api_uname,type:phrase)))),(meta:(alias:!n,disabled:!f,key:organization,negate:!f,params:(query::organisation_id),type:phrase,value::organisation_id),query:(match:(organization:(query::organisation_id,type:phrase)))),(meta:(alias:!n,disabled:!f,key:catalog,negate:!f,params:(query::catalog_id),type:phrase,value::catalog_id),query:(match:(catalog:(query::catalog_id,type:phrase))))))',
  },

  elasticsearch_product_url: {
    info: 'Elastic search Product url',
    type: 'item',
    item_type: 'elasticsearch_product_url',
    uri_pattern: 'https://elasticsearch.prd.xbl.aws.socgen/_plugin/kibana/app/kibana#/dashboard/a490f430-d10b-11ea-9472-1fd58938c50d?_g=(filters:!())&_a=(description:\'\',filters:!((meta:(alias:!n,disabled:!f,key:product_name,negate:!f,params:(query::product_uname),type:phrase,value::product_uname),query:(match:(product_name:(query::product_uname,type:phrase)))),(meta:(alias:!n,disabled:!f,key:organization,negate:!f,params:(query::organisation_id),type:phrase,value::organisation_id),query:(match:(organization:(query::organisation_id,type:phrase)))),(meta:(alias:!n,disabled:!f,key:catalog,negate:!f,params:(query::catalog_id),type:phrase,value::catalog_id),query:(match:(catalog:(query::catalog_id,type:phrase))))))',
  },

  static_content: {
    info: 'Single resource static content.',
    type: 'item',
    source: 'portal',
    item_type: 'static_content',
    defaultExpectedContentType: 'text/html',
    uri_pattern: '/static_content/:organisation_id/:lang_code/:static_content_code.:file_extension',
    resource_code_pattern: 'lang/:lang_code/static_content/:static_content_code',
    resource_aliases: [
      {alias: '/static_content/:lang_code/:static_content_name'}
    ],
  },

}
for (var scheme_key in _external_resource_schemes) {
  _external_resource_schemes[scheme_key].scheme_key = scheme_key
  if (isDef(_external_resource_schemes[scheme_key], 'uri_pattern'))
    _external_resource_schemes[scheme_key].uri_pattern_ids = getPatternIds(_external_resource_schemes[scheme_key].uri_pattern)
  if (isDef(_external_resource_schemes[scheme_key], 'resource_code_pattern'))
    _external_resource_schemes[scheme_key].resource_code_pattern_ids = getPatternIds(_external_resource_schemes[scheme_key].resource_code_pattern)
  if (isDef(_external_resource_schemes[scheme_key], 'resource_aliases')) {
    for (var alias_key in _external_resource_schemes[scheme_key].resource_aliases) {
      _external_resource_schemes[scheme_key].resource_aliases[alias_key].alias_ids = getPatternIds(_external_resource_schemes[scheme_key].resource_aliases[alias_key].alias)
    }
  }
}
export const external_resource_schemes = _external_resource_schemes


/**
 * Build EXTERNAL resource uri from type and ids
 * @param {string} type
 * @param {array} ids
 */
export function buildExternalResourceUri(scheme_key, ids) {
  var scheme
  if ((scheme = isDefVal(external_resource_schemes, scheme_key))) {
    var uri_id
    var uri = scheme.uri_pattern
    for (uri_id of scheme.uri_pattern_ids) {
      if (!isDef(ids, uri_id)) {
        console.error("buildExternalResourceUri: missing "+uri_id+" for scheme_key:"+scheme_key)
        return null
      }
      uri = uri.replace(':'+uri_id, ids[uri_id])
    }
    return uri
  }
  console.error("buildExternalResourceUri: no case found for scheme_key:"+scheme_key)
  return null
}

/**
 * Build EXTERNAL resource code from scheme_key and ids
 * @param {string} type
 * @param {array} ids
 */
export function buildExternalResourceCode(scheme_key, ids) {
  var scheme
  if ((scheme = isDefVal(external_resource_schemes, scheme_key))) {
    var id
    var resource_code = scheme.resource_code_pattern
    for (id of scheme.resource_code_pattern_ids) {
      if (!isDef(ids, id)) {
        // console.warn("buildExternalResourceCode: missing "+id+" for scheme_key:"+scheme_key)
        // console.log(ids)
        return null
      }
      resource_code = resource_code.replace(':'+id, ids[id])
    }
    return resource_code
  }
  console.error("buildExternalResourceCode: no case found for "+scheme_key)
  return null
}

/**
 * Build EXTERNAL resource infos from scheme_key and ids
 * @param {string} type
 * @param {array} ids
 */
export function buildExternalResourceInfos(scheme_key, ids) {
  var resource_uri = buildExternalResourceUri(scheme_key, ids)
  var resource_code = buildExternalResourceCode(scheme_key, ids)
  if (!resource_uri || !resource_code)
    return null
  return {
    resource_uri,
    resource_code,
    scheme_key,
    scheme: external_resource_schemes[scheme_key],
    ids
  }
}

/**
 * Build external resource aliases (resource_code aliases using unames, etc.)
 * @param {*} scheme_key
 * @param {*} ids
 */
export function buildExternalResourceAliases(scheme_key, ids) {
  var aliases = []
  var resource_aliases
  if ((resource_aliases = isDefVal(external_resource_schemes, scheme_key+'.resource_aliases'))) {
    aliases_loop:for(var resource_alias of resource_aliases) {
      var alias = resource_alias.alias
      for(var id of resource_alias.alias_ids) {
        if (!isDef(ids, id)) {
          //console.log('Id '+id+' missing to build alias for '+scheme_key)
          continue aliases_loop
        }
        alias = alias.replace(':'+id, ids[id])
      }
      aliases.push(alias)
    }
  }
  return aliases
}
