import { logAction } from '../logging'
import { copyObj } from '../objtools'

const redMQSync = (
  state = {
    vw: null,
    vh: null,
    rv_range: null,
    rv_group: null
  },
  action
) => {
  logAction(action)
  var new_state = null
  switch (action.type) {

    case 'MQSYNC_UPDATE':
      new_state = copyObj(state)
      new_state.vw = action.vw
      new_state.vh = action.vh
      new_state.rv_range = action.rv_range
      new_state.rv_group = action.rv_group
      break

    default:
      break
  }
  if (new_state) {
    return new_state
  }
  else {
    return state
  }
}

export default redMQSync;
