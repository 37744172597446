import React from 'react'
import { logRender } from '../../utils/logging'
import { getFirstDef } from '../../utils/objtools'
import { Form as FinalForm, Field as FinalField} from 'react-final-form'
import { withTranslation } from 'react-i18next'
import { Form } from 'react-bootstrap'
import UiButton from '../ui/buttons/UiButton'
import ControlSelect from '../../utils/forms/ControlSelect'
import Loader from '../loader/Loader'

class ScopeCartFinalForm extends React.Component {

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    if (['new','edit'].indexOf(getFirstDef(this.props, 'form_op', '')) < 0 ) {
      console.error('form_op is not defined or not handled')
      return null
    }
    const form_op = this.props.form_op

    const t = this.props.t

    return (
      <FinalForm
        initialValues={this.props.initialValues}
        onSubmit={this.props.onSubmit}
        validate={this.validateForm}
      >
        {({ handleSubmit, pristine, form, submitting }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <fieldset>
                {form_op === 'new' &&
                  <h4>{t('scope:fieldset_claim_new')}</h4>
                }
                {form_op === 'edit' &&
                  <h4>{t('scope:fieldset_claim_edit')}</h4>
                }
                <Form.Group controlId="scopeClaim">
                  <Form.Label>{t('scope:field_scopeClaim')}</Form.Label>
                  <FinalField name="scopeClaim" component={ControlSelect} options={this.props.scopeClaims_options} />
                  <Form.Text className="text-muted">{t('scope:field_scopeClaim_desc')}</Form.Text>
                </Form.Group>
              </fieldset>
              <div className="form-actions">
                <Loader loading={submitting} />
                <UiButton variant="primary" type="submit" disabled={pristine||submitting}>{t(form_op==='new'?'scope:submit_add':'scope:submit_update')}</UiButton>
                <UiButton variant="secondary" type="button" disabled={pristine||submitting} onClick={form.reset}>{t('forms:reset')}</UiButton>
              </div>
            </Form>
          )
        }}
      </FinalForm>
    )
  }
}

export default withTranslation(
    ['forms','scope']
  )(ScopeCartFinalForm)
