import ConfirmModal from './ConfirmModal'
import DeleteModal from './DeleteModal'
import SecretModal from './SecretModal'
import FormModal from './FormModal'
import SummaryModal from "./SummaryModal";
import FeedbackModal from "./FeedbackModal";
import ResetModal from "./ResetModal";
import HttpResponseModal from "./HttpResponseModal";
import MediaModal from "./MediaModal";

/**
 * Modal Types catalog
 */
export const modalTypes = {
  ConfirmModal,
  DeleteModal,
  SecretModal,
  FormModal,
  SummaryModal,
  FeedbackModal,
  ResetModal,
  HttpResponseModal,
  MediaModal
}

/**
 * List of action callbacks names in all modal types
 */
export const modalActionCallbacks = [
  'closeAction',
  'confirmAction',
  'deleteAction',
  'resetAction'
]
