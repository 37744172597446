import React from 'react'
import { connect } from 'react-redux'
import { logRender } from '../../../utils/logging'
import { withTranslation } from 'react-i18next'
import {deepEqualObjs2, isDef} from '../../../utils/objtools'
import SingleHeader from "../../ui/SingleHeader/SingleHeader";
import ItemGFMDescription from "../../ui/ItemGFMDescription/ItemGFMDescription";
import {getPortalOrganisationSparkyManagerEndpoint} from "../../portal/portal_selectors";
import UiButton from "../../ui/buttons/UiButton";
import AnimDelay from "../../ui/AnimDelay/AnimDelay";
import MiniTeaser from "../../mini_teaser/MiniTeaser";
import {stateGetProductDefinitionFeaturedAPIs} from "../product_selectors";
import branding from "../../portal/branding";

class ProductManifesto extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (!deepEqualObjs2(this.props.redux, nextProps.redux)
      || !deepEqualObjs2(this.props.resource_code, nextProps.resource_code)
      || !deepEqualObjs2(this.props.loading, nextProps.loading)
      || this.props.tReady !== nextProps.tReady
    )
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    const bgUrl = this.props.redux.sparky_manager_api_endpoint+'/consumer/organizations/'+this.props.organization+'/medias/'+this.props.media_name+'?format=binary'

    let newIssue
    if(this.props.is_user_logged_in && (branding.kind === 'sg' || branding.kind === 'cdn'))
    newIssue = (<div key="new_issue" className="actions">
      <UiButton variant="primary"
                onClick={() => this.props.historyPush('issue_new', {co: this.props.consorg_uname})}>{this.props.t('consorg:new_issue_external_button')}</UiButton>
    </div>)

    let featured_apis = []
    if (isDef(this.props.redux, 'featuredApis.api_resource_codes')) {
      for (let api_resource_code of this.props.redux.featuredApis.api_resource_codes) {
        featured_apis.push(<MiniTeaser key={api_resource_code} item_type={'api'} resource_code={api_resource_code}/>)
      }
    }
    if (featured_apis.length) {
      featured_apis = (
        <AnimDelay animateAppearance={true} showDelay={100}>
          <div className="featured_apis">
            <div className="featured_apis_list">
              {featured_apis}
            </div>
          </div>
        </AnimDelay>
      )
    }

    return (
      <div className={this.getItemClasses().join(' ')} style={{backgroundImage: `url(${bgUrl})`}}>
        <div className="product_manifesto_overlay"></div>
        <div className="product_manifesto_content grid g1_root g1_1col">

          <div className="item_full_presentation">
            <div className="presentation_content gridg g1_2t1t_2_1">
              <div className="blur-wrapper">
                <SingleHeader item_type="product" consolidated={this.props.consolidated} pills={this.props.pills} loading={this.props.loading}/>
                <ItemGFMDescription key="description" title="Description" content={this.props.description}/>
              </div>
              <div>
                {featured_apis}
                {newIssue}
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'product-manifesto'
    ]
    return classes;
  }
}

const mapStateToProps = (state, ownProps) => {

  var redux = {
      lang_code: state.lang.lang_code,
      sparky_manager_api_endpoint: getPortalOrganisationSparkyManagerEndpoint(state),
      featuredApis: stateGetProductDefinitionFeaturedAPIs(state, ownProps.resource_code)
    }

  return {
    redux
  }
}

const mapDispatchToProps = dispatch => ({
  historyPush: (scheme_key, queryParams) => {
    dispatch({type: 'HISTORY_PUSH', scheme_key, queryParams})
  }
})


ProductManifesto = withTranslation(['common','consorg'])(ProductManifesto)

export default connect(mapStateToProps, mapDispatchToProps)(ProductManifesto)
