import React from 'react'
import { connect } from 'react-redux'
import { logRender } from '../../../utils/logging'
import { withTranslation } from 'react-i18next'
import UiH1 from '../../ui/titles/UiH1'
import { withStaticContentCode } from '../../static_content/static_content_hoc'
import { deepEqualObjs2, getFirstDef } from '../../../utils/objtools'
import UiButton from '../../ui/buttons/UiButton'
import AnimDelay from '../../ui/AnimDelay/AnimDelay'
import branding from "../../portal/branding";

class Manifesto extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.staticContent.data, nextProps.staticContent.data)
      || !deepEqualObjs2(this.props.redux, nextProps.redux)
      || this.props.tReady !== nextProps.tReady
    )
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    const title = getFirstDef(this.props, 'staticContent.data.intro_title', ' ')
    const accroche = getFirstDef(this.props, 'staticContent.data.intro_accroche', ' ')
    const canshow = title !== ' ' && accroche !== ' '

    let video
    switch (branding.kind){
      case 'sg':
      case 'int':
        video = (
            <video id="manifesto_video" autoPlay muted loop>
              <source src="/assets/edito/consumer_sg_ad_video_manifesto.mp4" type="video/mp4"/>
            </video>
        )
        break
      default:
        break
    }

    return (
      <div className={this.getItemClasses().join(' ')} >
        <div className="manifesto_overlay"></div>
        {video}
        <div className={`grid g1_root g1_1col manifesto_content ${branding.kind === 'baas' ? 'half' : 'full'}`}>
          <AnimDelay animateAppearance={true} canShow={canshow}>
            <UiH1>{title}</UiH1>
          </AnimDelay>
          <AnimDelay animateAppearance={true} canShow={canshow} showDelay={200}>
            <h6>{accroche}</h6>
          </AnimDelay>
          <AnimDelay animateAppearance={true} canShow={canshow} showDelay={400}>
            <div className="manifesto_buttons_row">
              {branding.kind !== 'baas' &&
                  <span className="manifesto_buttons">
                  <UiButton variant="primary" onClick={()=>this.props.finderGo('products_and_apis')}
                            icon={`${branding.kind === 'baas' ? '' : 'search'}`}>{this.props.t('manifesto:cta_start')}</UiButton>
                </span>
              }
              {branding.kind === 'baas' &&
                  <span className="manifesto_buttons">
                  <UiButton variant="primary" onClick={()=>window.open('https://docs.scalexpert.societegenerale.com/apidocs/3mLlrPx3sPtekcQvEEUg/ready-to-start/before-you-start', "_blank")} icon={`${branding.kind === 'baas' ? '' : 'search'}`}>{this.props.t('manifesto:cta_start')}</UiButton>
                </span>
              }
              {branding.kind === 'baas' &&
                <span className="manifesto_buttons right-arrow">
                  <UiButton variant="link" onClick={()=>this.props.finderGo('api')} icon="">{this.props.t('manifesto:cta_catalog_2')}</UiButton>
                </span>
              }
            </div>
          </AnimDelay>
        </div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'manifesto'
    ]
    classes.push(branding.kind)
    return classes;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    redux: {
      lang_code: state.lang.lang_code
    }
  }
}

const mapDispatchToProps = dispatch => ({
  finderGo: (target) => { dispatch({type:'FINDER_GO', target}) }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStaticContentCode(
  'manifesto'
)(withTranslation(
  'manifesto'
)(Manifesto)))
