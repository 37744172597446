import { createRoutine, promisifyRoutine } from 'redux-saga-routines'

// New Subscription
// Routine for Form
export const submitSubscriptionPlansFormNewRoutine = createRoutine('SUBMIT_SUBSCRIPTION_PLANS_FORM_NEW')
export const submitSubscriptionPlansFinalFormNewHandler = promisifyRoutine(submitSubscriptionPlansFormNewRoutine)

// Delete Subscription
export const submitSubscriptionPlansFormDeleteRoutine = createRoutine('SUBMIT_SUBSCRIPTION_PLANS_FORM_DELETE')
export const submitSubscriptionPlansFinalFormDeleteHandler = promisifyRoutine(submitSubscriptionPlansFormDeleteRoutine)
