import React, { useState, useEffect } from 'react'

export default function FinderFilterCheck(props) {
  const [checked, setChecked] = useState(props.checked)

  const getClasses = function() {
    var classes = ['finder-filter-check']
    if (checked)
      classes.push('checked')
    return classes
  }

  useEffect(() => {
    setChecked(props.checked)
  }, [props.checked])

  const handleClick = function() {
    setChecked(!checked)
    props.handleClick(props.name, !checked)
  }

  return <div className={getClasses().join(' ')} onClick={handleClick}>
    {props.label}
  </div>
}