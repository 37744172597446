import React from 'react'
import { connect } from 'react-redux'
import { logRender } from '../../utils/logging'
import { withTranslation } from 'react-i18next'
import UiH1 from '../ui/titles/UiH1'
import { withStaticContentCode } from '../static_content/static_content_hoc'
import {deepEqualObjs2, getFirstDef, isDefVal} from '../../utils/objtools'
import UiButton from "../ui/buttons/UiButton";
import AnimDelay from "../ui/AnimDelay/AnimDelay";

class GroupPlatforms extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.staticContent.data, nextProps.staticContent.data)
      || !deepEqualObjs2(this.props.redux, nextProps.redux)
    )
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    if (!this.props.staticContent.found) {
      return null
    }

    const canshow = this.props.staticContent.loaded === true

    return (
      <div className={this.getItemClasses().join(' ')} >
        <div className="grid g1_root g1_1col">

          <UiH1>{this.props.t('group_platforms:title')}</UiH1>
          <AnimDelay animateAppearance={true} canShow={canshow}>
            <p className="intro">{isDefVal(this.props.staticContent, 'data.intro')}</p>
          </AnimDelay>

          <div className="content grid g1_321col">
            {getFirstDef(this.props, 'staticContent.data.platforms', []).map(({title, logo, description, link}) => (
              <div className="item" key={title}>
                <h3>{title}</h3>
                <div className="brand_img">
                  <img src={logo} alt={title}/>
                </div>
                <p>{description}</p>
                <div className="action_btns">
                  <UiButton onClick={()=>window.open(link, "_blank")}
                            variant="secondary">Visit</UiButton>
                </div>
              </div>
            ))}
          </div>

        </div>
      </div>
    )
  }

  handleClick(target) {
    this.props.finderGo(target)
  }

  getItemClasses() {
    var classes = [
      'group_platforms',
      'full'
    ]
    return classes;
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    lang_code: state.lang.lang_code
  }

  return {
    redux
  }
}


export default connect(
  mapStateToProps,
  null
)(withStaticContentCode(
  'group_platforms'
)(withTranslation(
  'group_platforms'
)(GroupPlatforms)))
