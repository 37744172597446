import React from 'react'
import { logRender } from '../../../utils/logging'
import { withTranslation } from 'react-i18next'
import { isDef, deepEqualObjs2 } from '../../../utils/objtools'

class ItemContact extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.tReady !== nextProps.tReady
      ||!deepEqualObjs2(this.props.contact, nextProps.contact)
    )
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    var content = []
    if (isDef(this.props, 'contact.name'))
      content.push(<div key="name" className="name">{this.props.contact.name}</div>)
    if (isDef(this.props, 'contact.email'))
      content.push(<div key="email" className="email"><a href={'mailto:'+this.props.contact.email}>{this.props.contact.email}</a></div>)
    if (isDef(this.props, 'contact.url'))
      content.push(<div key="url" className="url"><a target="_blank" rel="noopener noreferrer" href={this.props.contact.url}>{this.props.contact.url}</a></div>)

    return (
      <div className={this.getItemClasses().join(' ')} >
        <h4>Contact</h4>
        {content}
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'item-contact'
    ]
    return classes;
  }
}

export default withTranslation('item')(ItemContact);