import React from 'react'
import { connect } from '../../utils/redux'
import { logRender } from '../../utils/logging'
import { withTranslation } from 'react-i18next'
import { getFirstDef, deepEqualObjs2 } from '../../utils/objtools'
import Button from 'react-bootstrap/Button'

class FinderLoadMore extends React.Component {

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || this.props.tReady !== nextProps.tReady
    )
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    if (!this.props.redux.visible_items || !this.props.redux.total_items || this.props.redux.visible_items>=this.props.redux.total_items)
      return null

    var content = <Button onClick={()=>this.handleClick()}>{this.props.t('finder:load_more_button')}</Button>

    return (
      <div className={this.getItemClasses().join(' ')} >
        {content}
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'loadmore'
    ]
    return classes;
  }

  handleClick(event) {
    this.props.viewResultsCount(this.props.redux.visible_items + 24)
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    lang_code: state.lang.lang_code,
    visible_items: getFirstDef(state.finder.search_state, 'visible_items', null),
    total_items: getFirstDef(state.finder.results, 'total_items', null)
  }
  return {
    redux
  }
}

const mapDispatchToProps = dispatch => ({
  viewResultsCount: (count) => { dispatch({type:'FINDER_SET_VIEW_COUNT', count}) }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  ['lang','finder']
)(withTranslation('finder')(FinderLoadMore));
