import React from 'react'
import { logRender } from '../../../utils/logging'
import { withTranslation } from 'react-i18next'
import { isDef, deepEqualObjs2 } from '../../../utils/objtools'
import ApiKatInformation from "../../api/ApiKatInformation";
import branding from '../../portal/branding';
import AnimDelay from '../../ui/AnimDelay/AnimDelay'

class ItemTermsOfService extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.tReady !== nextProps.tReady
      || !deepEqualObjs2(this.props.content, nextProps.content)
      //|| JSON.stringify(this.props.documentation) !== JSON.stringify(nextProps.documentation)
    )
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    var content = []
    if (isDef(this.props, 'content'))
      content.push(<p key="terms">{this.props.content}</p>)

    var katInformation = []
    if (isDef(this.props, 'code_irt') && isDef(this.props, 'trigram') && !branding.open){
      katInformation.push(<ApiKatInformation key="kat_information" code_irt={this.props.code_irt} trigram={this.props.trigram} audience={this.props.audience}/>)
    }

    if(katInformation.length)
      katInformation = (
        <AnimDelay animateAppearance={true} showDelay={100}>
          <div className="kat_information">
            {katInformation}
          </div>
        </AnimDelay>
      )


    return (
      <div className={this.getItemClasses().join(' ')} >
        <h4>Terms of Service</h4>
        {content}
        {katInformation}
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'item-terms'
    ]
    return classes;
  }
}

export default withTranslation('item')(ItemTermsOfService);
