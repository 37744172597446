import { submitMembershipFormDeleteRoutine } from './membership_routines'
import { call, put } from 'redux-saga/effects'
import { deleteItem } from '../item/item_delete_saga.js'
import genericSubmissionSaga from '../item/genericSubmissionSaga'
import { buildExternalResourceInfos } from '../../utils/urls/external'

function* saga(action) {
  // prepare some ids
  var ids = {...action.payload.ids}
  var userOwnMembership = action.payload.userOwnMembership

  const {error} = yield call(deleteItem, 'consorg_membership_delete', 'consorg_membership', ids)

  // Generic unhandled error
  if (error)
    throw error

  // Check if the user has deleted its own membership
  if (userOwnMembership) {
    // reload consorg list, this will cascade trigger user rights on COs
    var external_resource_infos_catalog_consorgs = buildExternalResourceInfos('catalog_consorgs', ids)
    yield put({type: 'RESOURCE_LOAD_REQUEST', external_resource_infos: external_resource_infos_catalog_consorgs, force:true})
    // TODO redirect from consorg page if user has lots access to consorg ?
    //yield put({type: 'HISTORY_PUSH', scheme_key:'catalog', ids})
  }

}

export function* submitDeleteMembershipForm(action) {
  yield call(genericSubmissionSaga, {
    saga,
    action,
    routine: submitMembershipFormDeleteRoutine,
    successfull_title: 'membership:successfull_delete_title',
    successfull_body: 'membership:successfull_delete_body',
    unsuccessfull_title: 'membership:unsuccessfull_delete_title',
    unsuccessfull_body: 'membership:unsuccessfull_delete_body'
  })
}