import { stateFindResourceByCodeOrAlias, stateFindResourceByIds } from '../resource/resource_selectors'
import { isDef } from '../../utils/objtools'
import { buildExternalResourceCode } from '../../utils/urls/external'

/**
 * Get a product featured APIs
 */
export function stateGetProductDefinitionFeaturedAPIs(state, productDefinitionResourceCode) {
  const productDefinition = stateFindResourceByCodeOrAlias(state, 'product_def', productDefinitionResourceCode)
  if (!productDefinition) {
    return null
  }

  var featuredAPIs = {
    api_ids: [],
    api_resource_codes: [],
    api_def_resource_codes: []
  }
  var api_key
  var ids
  var resource

  // TODO get the product universe to search by name only on 2.x ? or manage with "else" and the good order ?
  // if (isDef(productDefinition, 'data.definition.apis')) {
  //   for (api_key in productDefinition.data.definition.apis) {
  //     if (isDef(productDefinition.data.definition.apis[api_key], 'name')) {
  //       ids = {
  //         ...productDefinition.ids,
  //         api_uname: productDefinition.data.definition.apis[api_key].name
  //       }
  //       resource = stateFindResourceByIds(state, 'api', ids)
  //       if (resource) {
  //         featuredAPIs.api_ids.push(resource.ids.api_id)
  //         featuredAPIs.api_resource_codes.push(resource.external_resource_infos.resource_code)
  //         featuredAPIs.api_def_resource_codes.push(buildExternalResourceCode('api_def', resource.ids))
  //       }
  //       // else {
  //       //   console.error('API '+productDefinition.data.definition.apis[api_key].name+' featured in product was not found')
  //       // }
  //     }
  //   }
  // }

  if (isDef(productDefinition, 'data.apis')) {
    for (api_key in productDefinition.data.apis) {
      if (isDef(productDefinition.data.apis[api_key], 'apiId')) {
        ids = {
          ...productDefinition.ids,
          api_id: productDefinition.data.apis[api_key].apiId
        }
        resource = stateFindResourceByIds(state, 'api', ids)
        if (resource) {
          featuredAPIs.api_ids.push(resource.ids.api_id)
          featuredAPIs.api_resource_codes.push(resource.external_resource_infos.resource_code)
          featuredAPIs.api_def_resource_codes.push(buildExternalResourceCode('api_def', resource.ids))
        }
        // else {
        //   console.error('API '+productDefinition.data.apis[api_key].id+' featured in product was not found')
        // }
      }
    }
  }
  return featuredAPIs
}
