import React from 'react'
import { connect } from '../../../utils/redux'
import { logRender } from '../../../utils/logging'
import { stateGetNLastSeenResourceCode } from '../../item/item_selectors'
import Teaser from '../../teaser/Teaser'
import { withTranslation } from 'react-i18next'
import UiButton from '../../ui/buttons/UiButton'
import { deepEqualObjs2 } from '../../../utils/objtools'

class PaneApplications extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || this.props.tReady !== nextProps.tReady
    )
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    var last_seen = []
    for (var resource_code of this.props.redux.last_seen_resource_codes) {
      last_seen.push(<Teaser key={resource_code} item_type="application" resource_code={resource_code} />)
    }
    if (last_seen.length > 0) {
      last_seen = (
        <div className="ggmenu-pane-section">
          <h3>{this.props.t('menu:lastseen_title')}</h3>
          <div className="grid g2_2col">
            {last_seen}
          </div>
        </div>
      )
    }

    return (
      <div className={this.getItemClasses().join(' ')}>
        <div className="grid g2_root g2_1col">

          <h2>{this.props.t('application:pane_title')}</h2>

          <div className="grid g2_221col">
            <div className="ggmenu-pane-section">
              <h3>{this.props.t('application:pane_find_title')}</h3>
              <p className="chapeau" key="chapeau">{this.props.t('application:pane_find_chapeau')}</p>
              <UiButton variant="primary" onClick={()=>this.props.finderGo('application')} icon="search">{this.props.t('application:pane_find_button')}</UiButton>
            </div>

            <div className="ggmenu-pane-section">
              <h3>{this.props.t('application:pane_create_title')}</h3>
              <p className="chapeau" key="chapeau">{this.props.t('application:pane_create_chapeau')}</p>
              <UiButton variant="secondary" onClick={()=>this.props.historyPush('application_new')} icon="add">{this.props.t('application:pane_create_button')}</UiButton>
            </div>
          </div>

          {last_seen}

        </div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'pane-applications'
    ]
    return classes;
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    lang_code: state.lang.lang_code,
    last_seen_resource_codes: stateGetNLastSeenResourceCode(state, 'application', 4)
  }
  return {
    redux
  }
}

const mapDispatchToProps = dispatch => ({
  finderGo: (target) => { dispatch({type:'FINDER_GO', target}) },
  historyPush: (scheme_key) => { dispatch({type:'HISTORY_PUSH', scheme_key}) }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  ['item.last_seen_single_usable','lang']
)(withTranslation(['menu','application'])(PaneApplications))