import React from 'react'
import { connect } from '../../../utils/redux'
import { logRender } from '../../../utils/logging'
import { isDef, deepEqualObjs2 } from '../../../utils/objtools'
import CatalogTeaser from '../../catalog/CatalogTeaser'
import { withTranslation } from 'react-i18next'

class PaneCatalogs extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || this.props.tReady !== nextProps.tReady
    )
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    var current_catalog = null
    if (this.props.redux.current_catalog_id) {
      current_catalog = (
        <div className="ggmenu-pane-section">
          <h3 key="current_catalog_h3">{this.props.t('catalog:pane_current_title')}</h3>
          <p className="chapeau" key="chapeau">{this.props.t('catalog:pane_current_intro')}</p>
          <CatalogTeaser key={this.props.redux.current_catalog_id} catalog_id={this.props.redux.current_catalog_id} with_link={false} />
        </div>
      )
    }

    var other_catalogs = null
    if (this.props.redux.other_catalog_ids.length > 0) {
      var other_catalogs_list = []
      this.props.redux.other_catalog_ids.forEach((catalog_id)=>{
        other_catalogs_list.push(
          <CatalogTeaser key={catalog_id} catalog_id={catalog_id} with_link={true} />
        )
      })
      other_catalogs = (
        <div className="ggmenu-pane-section grid g2_1col">
          <h3 key="other_catalogs_h3">{this.props.t('catalog:pane_switch_title')}</h3>
          <p className="chapeau" key="chapeau">{this.props.t('catalog:pane_switch_intro')}</p>
          {other_catalogs_list}
        </div>
      )
    }

    return (
      <div className={this.getItemClasses().join(' ')}>
        <div className="grid g2_root g2_1col">
          <h2>{this.props.t('catalog:pane_title')}</h2>
          {current_catalog}
          {other_catalogs}
        </div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'pane-catalogs'
    ]
    return classes;
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    lang_code: state.lang.lang_code,
    current_catalog_id: null,
    other_catalog_ids: []
  }
  if (isDef(state.portal, 'context.catalog.catalog_id')) {
    redux.current_catalog_id = state.portal.context.catalog.catalog_id
    for (var catalog_id in state.portal.context.catalogs) {
      if (catalog_id !== redux.current_catalog_id) {
        redux.other_catalog_ids.push(catalog_id)
      }
    }
  }
  return {
    redux
  }
}

export default connect(
  mapStateToProps,
  null,
  ['portal.context','lang']
)(withTranslation('catalog')(PaneCatalogs));