import React from 'react'
import { logRender } from '../../../utils/logging'

class ItemLogo extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return false
  }

  render() {
    logRender(this)
    return (
      <div className={this.getItemClasses().join(' ')}>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'item-logo'
    ]
    return classes;
  }
}

export default ItemLogo;
