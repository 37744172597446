import React from 'react'
import { logRender } from '../../../utils/logging'
import JsonExpandable from '../JsonExpandable/JsonExpandable'
import { isArrayOfStrings, deepEqualObjs2 } from '../../../utils/objtools'

class UiProperty extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.value, nextProps.value)
      || !deepEqualObjs2(this.props.name, nextProps.name)
    )
  }

  render() {
    logRender(this)

    var value = this.props.value
    var content_mode = 'name_and_value'
    if (isArrayOfStrings(this.props.value))
      value = this.props.value.map((value, index) => <p key={index}>{value}</p>)
    else if (typeof this.props.value === 'string' && this.props.value.trim().length === 0)
      value = '[Empty string]'
    else if (Array.isArray(this.props.value) && this.props.value.length === 0)
      value = '[Empty array]'
    else if (typeof this.props.value === 'object') {
      value = <JsonExpandable title={this.props.name} jsonobject={this.props.value}/>
      content_mode = 'value_only'
    }

    var content = null
    switch (content_mode) {
      case 'name_and_value':
        content = [
          <div key="name" className="name">{this.props.name}</div>,
          <div key="value" className="value">{value}</div>
        ]
        break
      case 'value_only':
        content = [
          <div key="value_only" className="value">{value}</div>
        ]
        break
      default:
        return null
    }

    return (
      <div className={this.getItemClasses().join(' ')} >
        <div className={['content', content_mode].join(' ')}>
          {content}
        </div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'ui-property'
    ]
    return classes;
  }
}

export default UiProperty;