import {takeEvery, call, select, put} from 'redux-saga/effects'
import history from './history'
import { stateGetLangCode } from '../lang/lang_selectors'
import { isDef, getFirstDef } from '../objtools'
import { buildUri } from '../urls/internal'
import { getPortalCurrentCatalogName, getPortalPageSchemeKey, getPortalPageMatchParams } from '../../components/portal/portal_selectors'
import { stateFindResourceByIds } from '../../components/resource/resource_selectors'
import { buildQueryString } from '../urls/tools'

const historySagas = [
  takeEvery("HISTORY_PUSH", historyPush),
  takeEvery("HISTORY_PUSH_BY_RESOURCE", historyPushByResource)
]
export default historySagas

function* historyPush(action) {
  var location = null
  // Direct location set
  if (isDef(action, 'location')) {
    location = action.location
  }
  // scheme key
  else {
    var scheme_key = null
    if (isDef(action, 'scheme_key'))
      scheme_key = action.scheme_key
    else
      scheme_key = yield select(getPortalPageSchemeKey)
    // ids from action
    var ids = getFirstDef(action, ['ids'], {})
    // ids from context
    var lang_code = yield select(stateGetLangCode)
    var catalog_uname = yield select(getPortalCurrentCatalogName)
    // ids from current match params
    var matchParams = yield select(getPortalPageMatchParams)
    // merge ids
    const final_ids = {...matchParams, lang_code, catalog_uname, ...ids}
    location = buildUri(scheme_key, final_ids)
  }
  if (location) {
    if (isDef(action, 'queryParams')) {
      location += buildQueryString(action.queryParams)
    }
    yield call(history.push, location)
  }
  else {
    console.error('historyPush could not define the push location.')
  }
}

function* historyPushByResource(action){
  var lang_code = yield select(stateGetLangCode)

  var resource = yield select(stateFindResourceByIds, action.scheme_key_resource, {...action.ids, lang_code})
  if (resource) {
    yield put({type: 'HISTORY_PUSH', scheme_key: action.scheme_key, ids:{...action.ids, lang_code, ...resource.ids}})
  }
}
