import React from 'react'
import { connect } from 'react-redux'
import { logRender } from '../../../utils/logging'
import { withTranslation } from 'react-i18next'
import setDocumentTitle from '../../../utils/browser/title'
import UiH1 from '../titles/UiH1'
import UiButton from "../buttons/UiButton";
import Visibility from "../../../utils/debug/Visibility";

class NotFound extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      JSON.stringify(this.props.redux) !== JSON.stringify(nextProps.redux)
      || this.props.tReady !== nextProps.tReady
    )
  }

  componentDidMount() {
    setDocumentTitle('Not Found')
  }

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null

    return (
      <div className="notfound">
        <div className="grid g1_root g1_1col">
          <UiH1>{this.props.t('notfound:title')}</UiH1>
          <p>{this.props.t('notfound:message')}</p>
        </div>
        <Visibility condition={this.props.target && this.props.resource_types && this.props.resource_name}>
          <div className="grid g1_root g1_1col">
            <hr />
            <h4>{this.props.t('notfound:search_in_finder_message')}</h4>
            <div><i className="query">"{this.props.resource_name}"</i></div>
            <UiButton variant="primary" onClick={()=>this.props.finderGo(this.props.target, this.props.resource_types, this.props.resource_name)} icon="search">{this.props.t('notfound:search_in_finder_btn')}</UiButton>
          </div>
          </Visibility>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    lang_code: state.lang.lang_code
  }
  return {
    redux
  }
}

const mapDispatchToProps = dispatch => ({
  finderGo: (target, resource_types, query) => {
    dispatch({type:'FINDER_GO', target});
    dispatch({type:'FINDER_FILTER_VALUE_REQUEST', filter_name:"itemtype", filter_value:resource_types});
    dispatch({type:'FINDER_FILTER_VALUE_REQUEST', filter_name:"fulltext", filter_value:query})
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('notfound')(NotFound));
