import React from 'react'
import i18n from '../../../utils/lang/i18n'

const DeleteModal = ({ closeAction, deleteAction, title, message, cancel_button_label, delete_button_label }) => {
  return (
    <div className="modal-content">
      <div className="modal-header">
        <h4 className="modal-title">{title}</h4>
        <button type="button" className="close" aria-label="Close" onClick={closeAction}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <p>{message}</p>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-danger" onClick={deleteAction}>{delete_button_label || i18n.t('common:delete')}</button>
        <button type="button" className="btn btn-secondary" onClick={closeAction}>{cancel_button_label || i18n.t('common:cancel')}</button>
      </div>
    </div>
  )
}

export default DeleteModal