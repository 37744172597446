import { put, call } from 'redux-saga/effects'
import { notificationAdd, notificationAddError } from '../notifications/notifications_actions'
import i18n from '../../utils/lang/i18n'
import { FORM_ERROR } from 'final-form'

export default function* genericFormSubmissionSaga({saga, action, routine, successfull_title, successfull_body, unsuccessfull_title, unsuccessfull_body}) {
  var saga_error = null
  var submission_error = null

  try {
    submission_error = yield call(saga, action)
    if (submission_error) {
      throw new Error('Submission error.')
    }

    // Success feedback
    yield put(notificationAdd({
      title: i18n.t(successfull_title),
      body: i18n.t(successfull_body)
    }))
    yield put(routine.success())
  }
  catch (catched_error) {
    saga_error = catched_error
  }

  if (submission_error) {
    // Field(s) level error(s)
    yield put(routine.success(submission_error)) // Promise resolve with errors
  }
  else if (saga_error) {
    // General error
    yield put(notificationAddError({
      title: i18n.t(unsuccessfull_title),
      body: i18n.t(unsuccessfull_body),
      error: saga_error
    }))
    yield put(routine.success({[FORM_ERROR]: saga_error})) // Promise resolve with errors
  }

  // End routine lifecycle
  yield put(routine.fulfill())
}