import React from 'react'
import {logRender} from "../../../utils/logging";
import { withTranslation } from 'react-i18next'
import Icon from "../../icon/Icon";

class UiIconBlock extends React.Component {

  render() {
    logRender(this)
    if (!this.props.tReady)
      return null


    return (
      <div className={this.getItemClasses().join(' ')} >
        <Icon type={this.props.icon}/>
        <div className="labels">
          <h4>{this.props.title}</h4>
          <h6>{this.props.subtitle}</h6>
        </div>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'ui_icon_block'
    ]
    classes.push(this.props.type)
    return classes;
  }
}

export default withTranslation(['common'])(UiIconBlock)
