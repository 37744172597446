import React from 'react'
import {connect} from 'react-redux'
import {logRender} from '../../../utils/logging'
import {withStaticContentCode} from '../../static_content/static_content_hoc'
import {deepEqualObjs2, getFirstDef, isDef} from '../../../utils/objtools'
import UiLevel from "../../ui/UiLevel/UiLevel"
import {mapResourceFromCode} from "../../resource/resource_selectors"
import {stateGetSelectedConsorgResourceCode} from "../../consorg/consorg_selectors"
import Icon from "../../icon/Icon";
import UiDoubleCounter from "../../ui/UiDoubleCounter/UiDoubleCounter";
import AnimDelay from "../../ui/AnimDelay/AnimDelay";

class WorkflowMain extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !deepEqualObjs2(this.props.redux, nextProps.redux)
      || !deepEqualObjs2(this.props.staticContent.data, nextProps.staticContent.data)
    )
  }

  render() {
    logRender(this)

    var catalogs_list = []
    this.props.redux.all_catalog_ids.forEach((catalog_id, i) => {
      if(catalog_id !== 'ycs' && i<4)
      catalogs_list.push(
        <UiLevel key={catalog_id} title={catalog_id} level={i++}/>
      )
    })

    return (
      <div className={this.getItemClasses().join(' ')}>
        <AnimDelay renderDelay={500} animateAppearance={true} showDelay={800}>
          <div className="grid g1_root g1_1col">
            <h2>{getFirstDef(this.props, 'staticContent.data.title', '&nbsp;')}</h2>
            <div className="item">
              <h3>{getFirstDef(this.props, 'staticContent.data.step_catalog_title', '&nbsp;')}</h3>
              <p>{getFirstDef(this.props, 'staticContent.data.step_catalog_description', '&nbsp;')}</p>
              <div className="content grid g1_root g1_4col">
                {catalogs_list}
              </div>
            </div>

            <div className="item">
              <h3>{getFirstDef(this.props, 'staticContent.data.step_consorg_title', '&nbsp;')}</h3>
              <p>{getFirstDef(this.props, 'staticContent.data.step_consorg_description', '&nbsp;')}</p>
              <div className="content grid g1_root g1_4col">

                <div className="wrapper">
                  <div className="block_icon">
                    <Icon type="consumer_org_owner"/>
                  </div>
                  <h4>{getFirstDef(this.props, 'staticContent.data.step_consorg_owners_label', '&nbsp;')}</h4>
                  <p>{getFirstDef(this.props, 'staticContent.data.step_consorg_owners_description', '&nbsp;')}</p>
                </div>

                <div className="wrapper">
                  <div className="block_icon">
                    <Icon type="consumer_org_member"/>
                  </div>
                  <h4>{getFirstDef(this.props, 'staticContent.data.step_consorg_members_label', '&nbsp;')}</h4>
                  <p>{getFirstDef(this.props, 'staticContent.data.step_consorg_members_description', '&nbsp;')}</p>
                </div>

                <div className="wrapper">
                  <div className="block_icon">
                    <Icon type="application"/>
                  </div>
                  <h4>{getFirstDef(this.props, 'staticContent.data.step_consorg_applications_label', '&nbsp;')}</h4>
                  <p>{getFirstDef(this.props, 'staticContent.data.step_consorg_applications_description', '&nbsp;')}</p>
                </div>

                <div className="wrapper">
                  <div className="block_icon">
                    <Icon type="product"/>
                  </div>
                  <h4>{getFirstDef(this.props, 'staticContent.data.step_consorg_private_products_label', '&nbsp;')}</h4>
                  <p>{getFirstDef(this.props, 'staticContent.data.step_consorg_private_products_description', '&nbsp;')}</p>
                </div>

              </div>
            </div>

            <div className="item">
              <h3>{getFirstDef(this.props, 'staticContent.data.step_explore_title', '&nbsp;')}</h3>
              <p>{getFirstDef(this.props, 'staticContent.data.step_explore_description', '&nbsp;')}</p>
              <div className="content">
                <UiDoubleCounter/>
              </div>
            </div>

            <div className="item">
              <h3>{getFirstDef(this.props, 'staticContent.data.step_subscription_title', '&nbsp;')}</h3>
              <p>{getFirstDef(this.props, 'staticContent.data.step_subscription_first_description', '&nbsp;')}</p>
              <p>{getFirstDef(this.props, 'staticContent.data.step_subscription_second_description', '&nbsp;')}</p>
            </div>

          </div>
        </AnimDelay>
      </div>
    )
  }

  getItemClasses() {
    var classes = [
      'workflow_main'
    ]
    return classes;
  }
}

const mapStateToProps = (state, ownProps) => {
  var redux = {
    lang_code: state.lang.lang_code,
    current_catalog_id: null,
    all_catalog_ids: [],
    consorg: mapResourceFromCode(state, 'consorg', stateGetSelectedConsorgResourceCode(state)),
  }

  if (isDef(state.portal, 'context.catalog.catalog_id')) {
    redux.current_catalog_id = state.portal.context.catalog.catalog_id
    for (var catalog_id in state.portal.context.catalogs) {
      redux.all_catalog_ids.push(catalog_id)
    }
  }
  return {
    redux
  }
}

export default connect(
  mapStateToProps,
  null
)(withStaticContentCode(
  'workflow'
)(WorkflowMain))
